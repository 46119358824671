import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, Grid } from "@material-ui/core";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
const useStyles = makeStyles((theme) => ({
  root: {},
  messageContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const MessageItem = (props) => {
  const classes = useStyles();

  return (
    <Grid item lg={6} md={6} sm={12} xs={12}>
      <Paper variant="outlined" className={classes.messageContainer}>
        {ReactHtmlParser(`${props.item.message}`)}
        {/* {console.log(props.item.message)} */}
      </Paper>
    </Grid>
  );
};
export default MessageItem;
