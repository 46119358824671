import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  makeStyles,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",

    // backgroundColor: theme.palette.background.default,
  },
  // titleContainer: {},
  // ListSubheader: {
  //   border: "1px solid green",
  // },
  // categories: {
  //   color: theme.palette.text.primary,

  //   fontSize: 16,
  //   fontWeight: 500,
  // },
  // link: {
  //   fontSize: 15,
  //   color: theme.palette.text.primary,
  //   textTransform: "Capitalize",
  // },
}));
const TypeMenu = (props) => {
  const { typeMenu, handleTypeClick } = props;
  const classes = useStyles();
  return (
    <div>
      <List disablePadding className={classes.list}>
        {typeMenu &&
          typeMenu.map((type, index) => {
            return (
              <>
                <ListItem
                  key={index}
                  button
                  // className={classes.listItem}
                  onClick={(e) => handleTypeClick(type, index)}
                >
                  <ListItemText primary={type.name} />

                  <ListItemSecondaryAction>
                    <IconButton>
                      <ExpandMoreIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </>
            );
          })}
      </List>
    </div>
  );
};

export default TypeMenu;
