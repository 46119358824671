import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorBoundary } from "react-error-boundary";
import ErrorIcon from "@material-ui/icons/Error";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "80vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));
function ErrorFallback({ error, componentStack, resetErrorBoundary }) {
  const classes = useStyles();
  return (
    <div role="alert" className={classes.root}>
      <ErrorIcon fontSize="50px" />

      {/* <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <pre>{componentStack}</pre> */}
      {/* <button onClick={resetErrorBoundary}>Try again</button> */}
    </div>
  );
}

export default ErrorFallback;
