import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import { clearOrderBy, orderByAsc, orderByDesc } from "../../redux/selector";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -theme.spacing(2),
    width: "100%",
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

const OrderFilter = ({ dispatch }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState("");

  const handleRadioChange = (event) => {
    let checkedValue = event.target.value;
    setValue(checkedValue);
    if (checkedValue === "ORDER_BY_ASC") {
      dispatch(orderByAsc());
    } else {
      dispatch(orderByDesc());
    }
  };

  const removeFilter = (e) => {
    dispatch(clearOrderBy());
    setValue("");
  };

  return (
    <form className={classes.root}>
      <FormControl
        fullWidth
        component="fieldset"
        className={classes.formControl}
      >
        <RadioGroup
          aria-label="price"
          name="price"
          value={value}
          onChange={handleRadioChange}
        >
          {console.log(value, "from body")}
          <FormControlLabel
            value={"ORDER_BY_ASC"}
            control={<Radio />}
            label="Low to high"
          />
          <FormControlLabel
            value={"ORDER_BY_DESC"}
            control={<Radio />}
            label="High to low"
          />
        </RadioGroup>
        {value !== "" ? (
          <Button
            type="submit"
            variant="outlined"
            color="secondary"
            className={classes.button}
            onClick={() => removeFilter()}
          >
            Reset Filters
          </Button>
        ) : null}
      </FormControl>
    </form>
  );
};
export default connect()(OrderFilter);
