import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";

import SearchIcon from "@material-ui/icons/Search";
import {
  CircularProgress,
  Popper,
  ListItem,
  List,
  ListItemSecondaryAction,
  ListItemText,
  Hidden,
  Button,
  Grid,
} from "@material-ui/core";
import { searchProducts } from "../../redux/selector";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 600,
    marginRight: theme.spacing(5),
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderColor: theme.palette.primary.light,
    borderWidth: 1,
    borderStyle: "solid",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "2vh",
      marginBottom: "2vh",
    },
  },
  inputcontainer: {
    height: "100%",
    width: "100%",
    // backgroundColor: "green",
  },
  input: {
    // padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  btn: {
    height: "6vh",
    borderRadius: 0,
    // backgroundColor: "red",
  },
}));
function CustomizedInputBase(props) {
  const [loadData, setLoadData] = useState([]);
  const [results, setResults] = useState([]);
  const [value, setValue] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const classes = useStyles();
  const dispatch = useDispatch();
  // function handleSearching(e) {
  //   if (e.keyCode !== 13) return;
  //   if (e.target.value.trim() === "") return;
  //   window.location.href = "/search?" + e.target.value;
  // }
  function handleSearchChange(e) {
    setValue(e.target.value);

    // setTimeout(() => {
    //   if (value.length < 1) return;
    //   const regSearch = new RegExp(value, "gim");
    //   let resultMatch = new Array();
    //   loadData.forEach(function (item, index) {
    //     if (item.name.search(regSearch) !== -1)
    //       resultMatch.push({ title: item.name });
    //   });
    //   resultMatch =
    //     resultMatch.length <= 5 ? resultMatch : resultMatch.slice(0, 5);

    //   // setIsLoading(false);
    //   // setResults(resultMatch);
    //   // console.log("reuslt match,", resultMatch, "searchResult", results);
    // }, 500);
    // if (results.length <= 0) {
    // }
    // if (e.target.value === "") {
    //   // setAnchorEl(null);
    // }
  }

  const handleSearch = () => {
    dispatch(searchProducts(value));
    props.history.push("/");
    setValue("");
  };
  return (
    <div className={classes.Container}>
      <Paper className={classes.root} elevation={0}>
        <div className={classes.inputcontainer}>
          <InputBase
            className={classes.input}
            placeholder="Search For Products"
            inputProps={{ "aria-label": "search For Products" }}
            onChange={(e) => handleSearchChange(e)}
            value={value}
          />
        </div>
        <Button
          startIcon={<SearchIcon />}
          color="primary"
          variant="contained"
          disableElevation
          size="large"
          className={classes.btn}
          onClick={() => handleSearch()}
        >
          Search
        </Button>
      </Paper>
      {/* <Hidden smUp>
        <IconButton className={classes.iconButton}>
          <SearchIcon className={classes.icon} />
        </IconButton>
      </Hidden>
      <Hidden smDown> */}
    </div>
  );
}
export default withRouter(CustomizedInputBase);
