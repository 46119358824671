import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",

    width: "100%",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  text: {
    color: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
}));
const FooterCopyright = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography className={classes.text}>
        COPYRIGHT @ Accurate Stitch Private Limited 2020.
      </Typography>
    </div>
  );
};
export default FooterCopyright;
