import React from "react";

import {
  Container,
  makeStyles,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  signUp: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1),
  },
  Card: {
    width: "100%",
    borderRadius: 0,
  },
}));
const ForgotPassword = () => {
  const classes = useStyles();

  return (
    <Card elevation={0} className={classes.Card}>
      <CardHeader
        title="Forgot Password ?"
        subheader="Enter Following details here"
        style={{ textAlign: "center" }}
      />
      <CardContent>
        <Grid container spacing={2} justify="center">
          <Grid item lg={10} sm={12} md={10} xs={12}>
            <TextField
              id="outlined-basic"
              label="Enter Email"
              variant="outlined"
              fullWidth
            />
          </Grid>

          <Grid item lg={6} sm={6} md={6} xs={6}>
            <Button
              fullWidth
              className={classes.signUp}
              size="large"
              variant="contained"
              color="primary"
            >
              Send Link
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ForgotPassword;
