import React from "react";

import OrderEdit from "./OrderEdit";
import OrderBill from "./OrderBill";
import { makeStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
} from "@material-ui/core";
import CalculateTotal from "../../utils/calculateTotal";

const OrderBar = (props) => {
  const { cart } = props;

  let valueTotal = CalculateTotal(cart);
  let grandTotal = (
    valueTotal.totalNetAmount + valueTotal.totalTaxAmount
  ).toFixed(2);

  const itemsOrder = cart.map(function (item, index) {
    // valueTotal += item.salePrice * Number(item.selectedOptions.QUANTITY);

    return (
      <div key={index}>
        <OrderEdit
          key={item.id}
          {...item}
          item={item}
          quantity={Number(item.selectedOptions.QUANTITY)}
        />
      </div>
    );
  });

  return (
    <Card elevation={1}>
      <CardHeader title="Your Order" />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item lg={12} xs={12}>
            {itemsOrder}
          </Grid>
          <Grid item lg={12} xs={12}>
            <OrderBill orderValue={grandTotal} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.shop.cart,
  };
};

export default connect(mapStateToProps, null)(OrderBar);
