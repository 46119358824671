import React from "react";
import { Grid } from "@material-ui/core";
import FooterSubMenu from "./FooterSubMenu";
import SubscribeMenu from "./SubscribeMenu";
import { FooterData } from "./FooterData";

const FooterMenu = () => {
  return (
    <div className="footer-menu">
      <Grid container justify="space-between">
        {FooterData.map((data, key) => {
          return (
            <Grid item lg={2} xs={6} key={key}>
              <FooterSubMenu data={data} />
            </Grid>
          );
        })}
        <Grid item xs={6} lg={3}>
          <SubscribeMenu />
        </Grid>
      </Grid>
    </div>
  );
};
export default FooterMenu;
