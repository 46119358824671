import React from "react";

import {
  Container,
  makeStyles,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  useTheme,
  Paper,
} from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../redux/action/useractions";
const useStyles = makeStyles((theme) => ({
  signUp: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      height: "5vh",
      width: "100%",
      margin: 0,
      padding: 0,
    },
  },
  Card: {
    width: "100%",

    borderRadius: 0,
  },
  errortext: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: "red",
    textAlign: "center",
  },
}));
const RegisterSite = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const actionError = useSelector((state) => state.utilReducer.error);
  const errorMsg = useSelector((state) => state.utilReducer.message);
  const [error, seterror] = React.useState(undefined);
  const [state, setState] = React.useState({
    usertype: "",
    email: "",
    mobile: "",
    password: "",
    confirmpassword: "",
  });

  const handleChange = (e) => {
    seterror(undefined);
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSignUp = (e) => {
    e.preventDefault();
    if (state.password !== state.confirmpassword) {
      seterror("password");
    }
    if (state.usertype === "") {
      seterror("user-type");
    }
    if (state.password === state.confirmpassword) {
      const signupobj = {
        email: state.email,
        password: state.password,
        mobile: state.mobile,
        usertype: state.usertype,
      };
      dispatch(registerUser(signupobj));
    }
  };
  const breakpoint = useTheme().breakpoints.down("md") ? "small" : "medium";
  return (
    <Card elevation={0} className={classes.Card}>
      <CardHeader
        title="Join Us"
        // subheader="Enter Following details here"
        style={{ textAlign: "center" }}
      />

      {!actionError ? (
        <Typography align="center">Enter Following details here</Typography>
      ) : (
        <CardContent>
          <Typography className={classes.errortext}>{errorMsg}</Typography>
        </CardContent>
      )}

      <CardContent>
        <form onSubmit={(e) => handleSignUp(e)}>
          <Grid container spacing={2} justify="center">
            <Grid item lg={10} sm={12} md={10} xs={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
                size={breakpoint}
                error={error === "user-type"}
                required={true}
              >
                <InputLabel id="user-type" htmlFor="user-type">
                  Select User Type
                </InputLabel>
                <Select
                  labelId="user-type"
                  id="user-type"
                  value={state.usertype}
                  fullWidth
                  label="Select User Type"
                  onChange={handleChange}
                  name="usertype"
                  inputProps={{
                    id: "user-type",
                  }}
                >
                  <MenuItem value="RETAILER">Retailer</MenuItem>
                  <MenuItem value="OWNER">Car Owner</MenuItem>
                </Select>
                {error === "user-type" && (
                  <FormHelperText>Please select user type</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item lg={10} sm={12} md={10} xs={12}>
              <TextField
                id="outlined-basic"
                label="Enter Email"
                variant="outlined"
                fullWidth
                type="email"
                value={state.email}
                size={breakpoint}
                required={true}
                name="email"
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item lg={10} sm={12} md={10} xs={12}>
              <TextField
                id="outlined-basic"
                label="Enter Mobile Number"
                variant="outlined"
                fullWidth
                value={state.mobile}
                name="mobile"
                required={true}
                type="number"
                size={breakpoint}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item lg={10} sm={12} md={10} xs={12}>
              <TextField
                id="outlined-basic"
                label="Enter Password"
                variant="outlined"
                fullWidth
                type="password"
                required={true}
                value={state.password}
                name="password"
                size={breakpoint}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item lg={10} sm={12} md={10} xs={12}>
              <TextField
                id="outlined-basic"
                label="Confirm Password"
                variant="outlined"
                fullWidth
                type="password"
                required={true}
                size={breakpoint}
                error={error === "password"}
                helperText={
                  error === "password" ? "Passwords do not match" : ""
                }
                value={state.confirmpassword}
                name="confirmpassword"
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item lg={6} sm={6} md={6} xs={6}>
              <Button
                fullWidth
                className={classes.signUp}
                size="large"
                variant="contained"
                color="primary"
                disabled={error}
                type="submit"
              >
                Sign Up
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default RegisterSite;
