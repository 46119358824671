import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  Card,
  CardHeader,
  Divider,
  CardContent,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  IconButton,
  TableBody,
  withStyles,
} from "@material-ui/core";
import Closeicon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    // [theme.breakpoints.down("md")]: {
    //   backgroundColor: "white",
    // },
  },
  card: {
    height: "80vh",
    width: "80vw",
  },
  TableContainer: {
    minHeight: "100%",
    maxHeight: "100%",
    overflow: "auto",
    borderColor: theme.palette.primary.light,
    borderWidth: 1,
    borderStyle: "solid",
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.background.main,
    fontSize: 18,
  },
  body: {
    fontSize: 24,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const TrackModal = (props) => {
  const { open, close, details } = props;
  const classes = useStyles();

  return (
    <div>
      <Modal open={open} className={classes.root}>
        <Card className={classes.card}>
          <CardHeader
            title="Shipment Tracking"
            action={
              <IconButton onClick={() => props.close()}>
                <Closeicon />
              </IconButton>
            }
          />
          <Divider />
          <CardContent>
            <TableContainer className={classes.TableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Location</StyledTableCell>
                    <StyledTableCell style={{ width: "50%" }}>
                      Details
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {details.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row.date}
                      </TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>{row.location}</TableCell>
                      <TableCell>{row.details}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Modal>
    </div>
  );
};
export default TrackModal;
