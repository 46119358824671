import React from "react";

import CartIcon from "@material-ui/icons/ShoppingCartOutlined";
import SecurityIcon from "@material-ui/icons/VerifiedUserOutlined";
import CreditCart from "@material-ui/icons/CreditCard";
import FaceIcon from "@material-ui/icons/InsertEmoticon";
import LoyalyIcon from "@material-ui/icons/LoyaltyOutlined";
import PT from "prop-types";
import {
  Container,
  makeStyles,
  Grid,
  Typography,
  Hidden,
} from "@material-ui/core";
import Useraccount from "./UserAccount";
import ExistingUser from "./ExistingUser";
import { withRouter, useParams, useLocation } from "react-router-dom";
import RegisterForm from "./RegisterForm";
import { pushtohistory } from "../../redux/action/historyActions";
import { useDispatch } from "react-redux";

const propTypes = {
  handleLogin: PT.func,
  isLogin: PT.bool,
};
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,

    minHeight: "80vh",
    [theme.breakpoints.down("md")]: {
      minHeight: "auto",
      marginBottom: 30,
    },
  },
  loginInfo: {
    borderRadius: 4,
    backgroundColor: theme.palette.secondary.light,
    minHeight: "78vh",
  },
  loginContainer: {
    borderColor: theme.palette.secondary.light,
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: 4,
    [theme.breakpoints.down("sm")]: {
      border: "none",
    },
  },
  title: {
    color: "white",
    fontSize: 25,
  },
  otherText: {
    color: "white",
    fontSize: 20,
  },
  icon: {
    color: "white",
    fontSize: 30,
  },
}));

const RedirectTo = () => {};

const LoginFormWrap = (props) => {
  const classes = useStyles();
  return (
    <Container maxWidth="md" className={classes.root}>
      <Grid container>
        <Hidden smDown>
          <Grid item lg={5} md={5}>
            <Grid
              container
              direction="column"
              justify="space-around"
              alignItems="center"
              className={classes.loginInfo}
            >
              <Grid item lg={10} md={10}>
                <Typography align="center" className={classes.otherText}>
                  Get access to your Orders, Wishlist and Recommendations
                </Typography>
              </Grid>
              <Grid
                item
                container
                lg={10}
                md={10}
                alignItems="center"
                justify="space-evenly"
              >
                <Grid item>
                  <CartIcon className={classes.icon} />
                </Grid>
                <Grid item>
                  <LoyalyIcon className={classes.icon} />
                </Grid>
                <Grid item>
                  <SecurityIcon className={classes.icon} />
                </Grid>
                <Grid item>
                  <CreditCart className={classes.icon} />
                </Grid>
                <Grid item>
                  <FaceIcon className={classes.icon} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Grid
          item
          container
          alignItems="center"
          justify="center"
          lg={7}
          xs={12}
          md={7}
          sm={12}
          className={classes.loginContainer}
        >
          <Grid item lg={12}>
            <RegisterForm />
          </Grid>
          <Grid item>
            <ExistingUser />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

const LoginDoneWrap = (props) => (
  <div>
    <Useraccount />
  </div>
);

const LoginSite = (props) => {
  console.log("login site props", props);

  //stub

  const { stub } = useParams();
  const location = useLocation().pathname;
  const dispatch = useDispatch();

  React.useEffect(() => {
    let newlocation = location === "/" ? location : location.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));
  }, []);
  return (
    <div>
      {props.isLogin ? (
        <LoginDoneWrap />
      ) : (
        <LoginFormWrap
          isLogin={props.isLogin}
          handleLogin={props.handleLogin}
        />
      )}
    </div>
  );
};

LoginSite.propTypes = propTypes;

export default withRouter(LoginSite);
