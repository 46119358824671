import phones from "../../data/productData/hotSale.json";
import objEncDec from "object-encrypt-decrypt";
import { act } from "react-test-renderer";
import { shopTypes, userTypes } from "../types";
import calculatePricefromPribands from "../../functions/calculatePriceFromPricabands";

const initialState = {
  isLoading: false,
  products: phones,
  type: "",
  typeIndex: "",
  mainCategory: "",
  mainCategoryIndex: "",
  subCategory: "",
  subCategoryIndex: "",
  subCategoryId: "",
  subCategoryProducs: [],
  loading: false,
  cart: [],
  addressId: undefined,
  paymentMethodId: undefined,
  payment: {
    paymentId: undefined,
    verificationCode: undefined,
  },
  shipmentMethodId: undefined,
  orderNumber: null,
  favourites: [],
  openPreference: false,
  openConfigurator: false,
  shippingMethods: [],
  attributeList: [],
};

const localCartVariable = "WUiOiJwcm9kNSIsIm1h";
const localFavouritesVariable = "ydC5qcGciLCJzZWxl";

function isEquivalent(a, b) {
  // Create arrays of property names
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length != bProps.length) {
    return false;
  }

  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName] && propName !== "QUANTITY") {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
}

const shopReducer = (state = initialState, action) => {
  let updatedCart;
  let updatedFavourites;
  let updatedItemIndex;

  switch (action.type) {
    case "SUBMIT_CONFIRM_ORDER_SUCCESS":
      return {
        ...state,
        cart: [],
      };
    case shopTypes.GET_USER_CART:
      return {
        ...state,
      };

    case shopTypes.GET_USER_CART_SUCCESS:
      return {
        ...state,
        cart: action.payload.data,
        // cart: action.payload.data,
      };

    case shopTypes.GET_USER_CART_FAIL:
      return {
        ...state,
      };
    case shopTypes.GET_USER_FAVORITES:
      return {
        ...state,
      };

    case shopTypes.GET_USER_FAVORITES_SUCCESS:
      return {
        ...state,
        favourites: action.payload.data,
        // FAVORITES: action.payload.data,
      };

    case shopTypes.GET_USER_FAVORITES_FAIL:
      return {
        ...state,
      };

    case "LOAD_CART_FAVOURITES":
      console.log("LOAD_CART_FAVOURITES REDUCED");

      //let updatedCart = JSON.parse(localStorage.getItem('nunchoCart'));
      let localCartData = localStorage.getItem(localCartVariable);
      let localFavData = localStorage.getItem(localFavouritesVariable);

      let updatedCart = undefined;
      if (localCartData === null) {
        updatedCart = [];
      } else {
        updatedCart = objEncDec.decrypt(localCartData);
      }

      let updatedFavourites = undefined;
      if (localFavData === null) {
        updatedFavourites = [];
      } else {
        updatedFavourites = objEncDec.decrypt(localFavData);
      }

      return { ...state, cart: updatedCart, favourites: updatedFavourites };

    case "INCREMENT_CART_ITEM_QUANTITY":
      // console.log("INCREMENT Payload : ", action.payload);
      let incrementCart = [...state.cart];
      updatedItemIndex = incrementCart.findIndex(
        (item) =>
          item.id === action.payload.id &&
          isEquivalent(item.selectedOptions, action.payload.selectedOptions)
      );

      const incrementedItem = {
        ...incrementCart[updatedItemIndex],
      };

      incrementedItem.selectedOptions.QUANTITY =
        parseInt(incrementedItem.selectedOptions.QUANTITY) + 1;

      if (incrementedItem.priceBands.length > 0) {
        incrementedItem.salePrice = calculatePricefromPribands(
          incrementedItem.priceBands,
          incrementedItem.selectedOptions.QUANTITY
        );
      }
      incrementCart[updatedItemIndex] = incrementedItem;

      //localStorage.setItem('nunchoCart', JSON.stringify(incrementCart));
      localStorage.setItem(localCartVariable, objEncDec.encrypt(incrementCart));
      return { ...state, cart: incrementCart };

    case "DECREMENT_CART_ITEM_QUANTITY":
      console.log("DECREMENT Payload : ", action.payload);
      let decrementCart = [...state.cart];
      updatedItemIndex = decrementCart.findIndex(
        (item) =>
          item.id === action.payload.id &&
          isEquivalent(item.selectedOptions, action.payload.selectedOptions)
      );

      const decrementedItem = {
        ...decrementCart[updatedItemIndex],
      };

      if (parseInt(decrementedItem.selectedOptions.QUANTITY) > 0) {
        decrementedItem.selectedOptions.QUANTITY =
          parseInt(decrementedItem.selectedOptions.QUANTITY) - 1;

        decrementCart[updatedItemIndex] = decrementedItem;

        //localStorage.setItem('nunchoCart', JSON.stringify(decrementCart));
        localStorage.setItem(
          localCartVariable,
          objEncDec.encrypt(decrementCart)
        );

        return { ...state, cart: decrementCart };
      } else {
        return { ...state };
      }

    //add product

    case shopTypes.ADD_PRODUCT_TO_CART_SUCCESS:
      let newCart = state.cart;
      newCart.push(action.payload.data);

      return {
        ...state,
        cart: [...newCart],
      };
    case shopTypes.ADD_PRODUCT_TO_FAVORITES_SUCCESS:
      let newFavourites = state.favourites;
      newFavourites.push(action.payload.data);

      return {
        ...state,
        favourites: [...newFavourites],
      };

    case shopTypes.ADD_PRODUCT_TO_CART_FAIL:
      return {
        ...state,
      };
    case shopTypes.ADD_PRODUCT_TO_FAVORITES_FAIL:
      return {
        ...state,
      };

    case "MOVE_TO_CART":
      updatedFavourites = [...state.favourites];
      updatedItemIndex = updatedFavourites.findIndex(
        (item) => item.id === action.payload.id
      );

      if (updatedItemIndex >= 0) {
        updatedFavourites.splice(updatedItemIndex, 1);

        //localStorage.setItem('nunchoCart', JSON.stringify(updatedCart));
        localStorage.setItem(
          localFavouritesVariable,
          objEncDec.encrypt(updatedFavourites)
        );

        return { ...state, favourites: updatedFavourites };
      } else {
        return { ...state };
      }

    case shopTypes.REMOVE_PRODUCT_FROM_CART_SUCCESS:
      let removeid = action.payload.data;
      let removeCart = state.cart.filter((item) => item.id !== removeid);
      return {
        ...state,
        cart: [...removeCart],
      };

    case shopTypes.REMOVE_PRODUCT_FROM_FAVORITES_SUCCESS:
      let favouriteremoveId = action.payload.data;
      let removeFavourite = state.favourites.filter(
        (item) => item.id !== favouriteremoveId
      );

      return {
        ...state,
        favourites: [...removeFavourite],
      };

    case "SELECT_TYPE":
      console.log("SELECT_TYPE SUCCESS  action : ", action);
      return {
        ...state,
        type: action.payload.type,
        typeIndex: action.payload.index,
        mainCategory: "",
        mainCategoryIndex: "",
        subCategory: "",
        subCategoryIndex: "",
      };
    case "SELECT_MAINCAT":
      console.log("SELECT_MAINCAT SUCCESS  action : ", action);
      return {
        ...state,
        mainCategory: action.payload.mainCategory,
        mainCategoryIndex: action.payload.index,
      };
    case "SELECT_SUBCAT":
      console.log("SELECT_SUBCAT SUCCESS  action : ", action);

      return {
        ...state,
        subCategory: action.payload.subcategory,
        subCategoryIndex: action.payload.index,
        subCategoryId: action.payload.id,
      };

    // case "GET_SUBCATEGORY_PRODUCTS":
    //   return {
    //     ...state,
    //     loading: true,
    //   };

    case "GET_SUBCATEGORY_PRODUCTS_SUCCESS":
      // console.log("new", JSON.parse(newProducts));
      return {
        ...state,
        loading: false,
        products: action.payload.data.products,
        attributeList: action.payload.data.subcategoryAttributeList,
      };

    case "GET_SUBCATEGORY_PRODUCTS_FAIL":
      return {
        ...state,
        loading: false,
      };

    case shopTypes.FILTER_PRODUCTS:
      // console.log("SEARCH_PRODUCTS SUCCESS  action : ", action);
      return { ...state, products: action.payload.data };

    case shopTypes.SEARCH_PRODUCT_SUCCESS:
      return {
        ...state,
        products: action.payload.data.content,
      };
    case "OPEN_PREFERENCE_MODEL":
      console.log("OPEN_PREFERENCE_MODEL REDUCED");
      return { ...state, openPreference: true };
    case "CLOSE_PREFERENCE_MODEL":
      console.log("CLOSE_PREFERENCE_MODEL REDUCED");
      return { ...state, openPreference: false };
    case "OPEN_CONFIGURATION_MODEL":
      console.log("OPEN_CONFIGURATION_MODEL REDUCED");
      return { ...state, openConfigurator: true };
    case "CLOSE_CONFIGURATION_MODEL":
      console.log("CLOSE_CONFIGURATION_MODEL REDUCED");
      return { ...state, openConfigurator: false };
    case "SET_PRODUCT_PREFERENCE":
      console.log("SET_PRODUCT_PREFERENCE REDUCED");

      let newUpdatedCart = [...state.cart];
      updatedItemIndex = newUpdatedCart.findIndex(
        (item) =>
          item.id === action.payload.id &&
          isEquivalent(item.selectedOptions, action.payload.selectedOptions)
      );

      if (updatedItemIndex < 0) {
        return state;
      } else {
        const updatedItem = {
          ...newUpdatedCart[updatedItemIndex],
        };
        let newPayload = action.payload;
        // newPayload.productId;
        // newPayload.selectedOptions;
        updatedItem.preferences = newPayload;
        newUpdatedCart[updatedItemIndex] = updatedItem;
        //localStorage.setItem('nunchoCart', JSON.stringify(newUpdatedCart));
        localStorage.setItem(
          localCartVariable,
          objEncDec.encrypt(newUpdatedCart)
        );

        return { ...state, cart: newUpdatedCart };
      }
    // return { ...state };

    case "SET_PRODUCT_CONFIGURATION":
      console.log("SET_PRODUCT_CONFIGURATION REDUCED");

      updatedCart = [...state.cart];
      updatedItemIndex = updatedCart.findIndex(
        (item) =>
          item.id === action.payload.productId &&
          isEquivalent(item.selectedOptions, action.payload.selectedOptions)
      );
      console.log("Item CART Index : ", updatedItemIndex);
      if (updatedItemIndex < 0) {
        return state;
      } else {
        const updatedItem = {
          ...updatedCart[updatedItemIndex],
        };
        let newPayload = action.payload;
        delete newPayload.productId;
        delete newPayload.selectedOptions;
        updatedItem.configuration = newPayload;
        updatedCart[updatedItemIndex] = updatedItem;
        //localStorage.setItem('nunchoCart', JSON.stringify(updatedCart));
        localStorage.setItem(localCartVariable, objEncDec.encrypt(updatedCart));

        return { ...state, cart: updatedCart };
      }

    case "SET_SHIPPING_ADDRESS":
      console.log("SET_SHIPPING_ADDRESS REDUCED");
      return { ...state, addressId: action.payload.addressId };
    case "SET_PAYMENT_DETAILS":
      console.log("SET_PAYMENT_DETAILS REDUCED");
      return {
        ...state,
        paymentMethodId: action.payload.paymentMethodId,
        payment: {
          paymentId: action.payload.paymentId,
          verificationCode: action.payload.verificationCode,
        },
      };
    case "SET_SHIPMENT_METHOD":
      console.log("SET_SHIPMENT_METHOD REDUCED");
      return { ...state, shipmentMethodId: action.payload.shipmentMethodId };
    case "SUBMIT_CONFIRM_ORDER_SUCCESS":
      console.log("SET_SHIPMENT_METHOD REDUCED");
      return { ...state, orderNumber: action.payload.data.order_number };

    case shopTypes.GET_SHIPPING_METHODS_SUCCESS:
      return {
        ...state,
        shippingMethods: action.payload.data.content,
      };

    case shopTypes.LOAD_ALL_PRODUCTS:
      return {
        ...state,
        products: phones,
      };

    case userTypes.LOGOUT_USER:
      return {
        ...state,
        cart: [],
        favourites: [],
      };
    default:
      return state;
  }
};

export default shopReducer;
