import moment from "moment";

const setExpireTime = async (value) => {
  try {
    // console.log(value, 'expire time');
    const expireTime = moment().add(value, "seconds").format();
    // console.log('set expire time', expireTime);
    await localStorage.setItem("expireTime", expireTime);
  } catch (e) {
    // saving error
  }
};
export default setExpireTime;
