import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ModalComponent from "./ModalComponent";
import LoginSite from "../user/LoginSite";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { Grid, IconButton } from "@material-ui/core";
import { closeLoginModal } from "../../redux/action/utilActions";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
  CloseIcon: {
    // backgroundColor: "white",
    color: "white",
  },
}));

const LoginModal = () => {
  const classes = useStyles();
  const loginmodal = useSelector((state) => state.utilReducer.loginmodal);
  const dispatch = useDispatch();
  const handleClose = () => {
    if (loginmodal === true) {
      dispatch(closeLoginModal());
    }
  };

  return (
    <ModalComponent open={loginmodal}>
      <div>
        <Grid container justify="flex-end">
          <IconButton size={30} onClick={() => handleClose()}>
            <CloseIcon className={classes.CloseIcon} />
          </IconButton>
        </Grid>
        <LoginSite close={() => handleClose()} />
      </div>
    </ModalComponent>
  );
};
export default LoginModal;
