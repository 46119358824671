import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
const useStyles = makeStyles((theme) => ({
  root: {},
}));

const NormalList = (props) => {
  const classes = useStyles();

  const { labels, handleToggle, checked, input } = props;
  return (
    <div className={classes.root}>
      <List className={classes.root} disablePadding>
        {labels.map((val, i) => (
          <ListItem
            key={i}
            role={undefined}
            dense
            button
            onClick={handleToggle(val)}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checked.indexOf(val) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": labels[i] }}
                value={input.value}
                name={input.name}
              />
            </ListItemIcon>
            <ListItemText id={labels[i]} primary={labels[i]} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};
export default NormalList;
