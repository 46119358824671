import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
} from "@material-ui/core";
import RecomendedItem from "./RecomendedItem";
const useStyles = makeStyles((theme) => ({
  root: {},
}));

const RecommendCarousel = ({ data }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card variant="outlined">
        <CardHeader title="Recomended Products" />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            {data.length > 0 &&
              data.map((item, index) => {
                return (
                  <Grid item lg={2} xs={3} key={index}>
                    <RecomendedItem {...item} key={index} />
                  </Grid>
                );
              })}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};
export default RecommendCarousel;
