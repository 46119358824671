import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { Redirect, withRouter, useParams, useLocation } from "react-router-dom";
import { toggleLoginModal } from "../../../redux/action/utilActions";
import { pushtohistory } from "../../../redux/action/historyActions";
const useStyles = makeStyles((theme) => ({
  root: {},
}));

const ResetPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  //stub

  const { stub } = useParams();
  const location = useLocation().pathname;

  React.useEffect(() => {
    let newlocation = location === "/" ? location : location.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));
    dispatch(toggleLoginModal("resetpassword"));
  }, []);

  return (
    <div className={classes.root}>
      {/* dsadsa */}
      <Redirect to="/useraccount" />
    </div>
  );
};
export default withRouter(ResetPassword);
