import { utilTypes } from "../types/index";

export const toggleLoginModal = (component) => {
  return {
    type: utilTypes.TOGGLE_LOGIN_MODAL,
    payload: component,
  };
};
export const closeLoginModal = () => {
  return {
    type: utilTypes.CLOSE_LOGIN_MODAL,
  };
};
export const addtonewsletter = (name, email) => {
  return {
    type: utilTypes.SET_NEWSLETTER,
    payload: {
      name: name,
      email: email,
    },
  };
};
