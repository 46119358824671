import React from "react";

import OrderEdit from "./OrderEdit";
import OrderBill from "./OrderBill";
import { makeStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import { Card, CardHeader, CardContent, Grid } from "@material-ui/core";
import CalculateTotal from "../../utils/calculateTotal";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
const OrderBar = (props) => {
  const classes = useStyles();

  let valueTotal = CalculateTotal(props.cart);
  let grandTotal = valueTotal.totalNetAmount + valueTotal.totalTaxAmount;
  return (
    <Card>
      <CardHeader title="Your Order" />

      <CardContent>
        <Grid container spacing={3}>
          <Grid item lg={12}>
            <OrderBill orderValue={grandTotal} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.shop.cart,
  };
};

export default connect(mapStateToProps, null)(OrderBar);
