import React, { useState } from "react";

import ConfirmOrder from "./ConfirmOrderHeader";
import OrderBar from "./OrderBar";
import PT from "prop-types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Grid,
  Typography,
  Divider,
  Radio,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormControlLabel,
  Hidden,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useSelector } from "react-redux";
const propTypes = {
  handleAddressConfirm: PT.func,
};
const useStyles = makeStyles((theme) => ({
  table: {
    // borderWidth: 1,
    // borderColor: theme.palette.gray.light,
    // borderStyle: "solid",
  },
  Card: {
    maxHeight: "80vh",
    overflow: "auto",
  },
}));
const AddressConfirm = (props) => {
  const [expanded, setExpanded] = React.useState(null);
  // const selectedAddressId =
  const [addressId, setAddressID] = useState(
    useSelector((state) => state.shop.addressId)
  );
  const [addresses, setAddresses] = useState([]);
  const handleChange = (e) => {
    setAddressID(e);
  };

  const stateAddresses = useSelector((state) => state.user.addresses);
  const classes = useStyles();

  React.useEffect(() => {
    setAddresses(stateAddresses);
  }, [stateAddresses]);

  const AddressTableDesktop = (data, index) => {
    return (
      <TableContainer>
        <Table
          className={classes.table}
          aria-label="a dense table"
          size="small"
        >
          <TableBody>
            <TableRow>
              <TableCell colSpan="2">
                <FormControlLabel
                  control={<Radio />}
                  label={data.addressName}
                  labelPlacement="end"
                  value={data.addressName}
                  onChange={(e) => handleChange(data.id)}
                  checked={data.id === addressId}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{"First Name"}</TableCell>
              <TableCell>{data.firstName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{"Last Name"}</TableCell>
              <TableCell>{data.lastName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{"Line 1"}</TableCell>
              <TableCell>{data.line1}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{"Line 2"}</TableCell>
              <TableCell>{data.line2}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{"Line 3"}</TableCell>
              <TableCell>{data.line3}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{"City"}</TableCell>
              <TableCell>{data.city}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{"State"}</TableCell>
              <TableCell>{data.state}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{"Country"}</TableCell>
              <TableCell>{data.country}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{"Zip code"}</TableCell>
              <TableCell>{data.zipCode}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  const handleExpand = (index) => {
    expanded === index ? setExpanded(null) : setExpanded(index);
  };

  const AddressTableMobile = (props) => {
    const { data, index } = props;

    return (
      <ExpansionPanel variant="outlined">
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <FormControlLabel
            aria-label={data.addressName}
            onClick={(event) => handleChange(data.id)}
            control={<Radio />}
            checked={data.id === addressId}
            label={data.addressName}
          />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="a dense table"
              size="small"
            >
              <TableBody>
                <TableRow>
                  <TableCell>{"First Name"}</TableCell>
                  <TableCell>{data.firstName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{"Last Name"}</TableCell>
                  <TableCell>{data.lastName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{"Line 1"}</TableCell>
                  <TableCell>{data.line1}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{"Line 2"}</TableCell>
                  <TableCell>{data.line2}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{"Line 3"}</TableCell>
                  <TableCell>{data.line3}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{"City"}</TableCell>
                  <TableCell>{data.city}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{"State"}</TableCell>
                  <TableCell>{data.state}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{"Country"}</TableCell>
                  <TableCell>{data.country}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{"Zip code"}</TableCell>
                  <TableCell>{data.zipCode}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };
  return (
    <Grid container spacing={5}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Card className={classes.Card}>
          <CardHeader title="Choose Address" />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              {addresses.map((data, index) => {
                return (
                  <Grid item key={index} lg={12} sm={12} md={12} xs={12}>
                    <AddressTableMobile data={data} index={index} />
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid
        item
        container
        justify="space-between"
        lg={12}
        md={12}
        xs={12}
        lg={12}
      >
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => props.back()}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            className="confrim-btn"
            color="primary"
            variant="contained"
            disabled={typeof addressId === "undefined"}
            onClick={() => props.handleAddressConfirm(addressId)}
          >
            Confirm Address
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

AddressConfirm.propTypes = propTypes;

export default AddressConfirm;
