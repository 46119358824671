import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Avatar,
} from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
const useStyles = makeStyles((theme) => ({
  Avatar: {
    color: theme.palette.background.darker,
    backgroundColor: theme.palette.primary.light,
  },
  orange: {
    color: theme.palette.background.darker,
    backgroundColor: theme.palette.primary.light,
  },
}));
const Useravatar = (props) => {
  const classes = useStyles();
  return (
    <Paper elevation={1}>
      <List className={classes.root}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt="Remy Sharp" className={classes.orange}>
              {props.firstName.slice(0, 1)}
              {props.lastName.slice(0, 1)}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Hello,"
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="h6"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {props.firstName}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      </List>
    </Paper>
  );
};
export default Useravatar;
