import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Hidden,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import PaymentIcon from "@material-ui/icons/AccountBalanceWallet";
import AccountIcon from "@material-ui/icons/AccountBox";
import ResetIcon from "@material-ui/icons/Lock";
import LogOutIcon from "@material-ui/icons/PowerSettingsNew";
import OrderIcon from "@material-ui/icons/StoreMallDirectory";
import RightIcon from "@material-ui/icons/ChevronRight";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: "uppercase",
  },
  icon: {
    fontSize: "2rem",
    color: theme.palette.primary.main,
  },
  nested: {
    paddingLeft: theme.spacing(9),
    textDecoration: "none",
  },
  secondaryListTitle: {
    fontSize: "0.9rem",
    color: theme.palette.text.secondary,
    textDecoration: "none",
  },
}));

const PaymentList = [
  {
    title: "Saved Cards",
    link: "/useraccount/savedcards",
  },
];
const AccountList = [
  {
    title: "Profile Information",
    link: "/useraccount/profile",
  },

  {
    title: "Manage Addresses",
    link: "/useraccount/addresses",
  },
];

const UserActions = () => {
  const dispatch = useDispatch();

  const ItemList = ({ icon, text, navLink, subList }) => {
    return (
      <List component="nav" aria-label="main mailbox folders">
        <ListItem button component={Link} to={navLink}>
          <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
          <ListItemText>
            <Typography className={classes.title}>{text}</Typography>
          </ListItemText>
          <Hidden smUp>
            <ListItemSecondaryAction>
              <IconButton>
                <RightIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </Hidden>
        </ListItem>
        {subList && (
          <List>
            {subList.map((data, index) => {
              return (
                <ListItem
                  key={index}
                  component={Link}
                  to={data.link}
                  className={classes.nested}
                >
                  <ListItemText>
                    <Typography className={classes.secondaryListTitle}>
                      {data.title}
                    </Typography>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        )}
      </List>
    );
  };

  const classes = useStyles();
  return (
    <Paper elevation={1}>
      <ItemList
        icon={<OrderIcon className={classes.icon} />}
        text="MY orders"
        navLink="/useraccount/myorders"
      />
      <Divider />
      <ItemList
        icon={<AccountIcon className={classes.icon} />}
        text="My Account"
        navLink="/useraccount/profile"
        subList={AccountList}
      />
      <Divider />
      <ItemList
        icon={<PaymentIcon className={classes.icon} />}
        text="Payments"
        navLink="/useraccount/savedcards"
        subList={PaymentList}
      />
      <Divider />

      <ItemList
        icon={<ResetIcon className={classes.icon} />}
        text="Reset Password"
        navLink="/useraccount/resetpassword"
      />
      <Divider />
      <ItemList
        icon={<LogOutIcon className={classes.icon} />}
        text="Logout"
        navLink="/useraccount/logout"
      />
    </Paper>
  );
};
export default UserActions;
