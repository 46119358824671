import React, { useState, useEffect } from "react";
import ProductImages from "./ProductImages";
import ProductHeader from "./ProductHeader";
import ProductColor from "./ProductColor";
import ProductSize from "./ProductSize";
import ProductCount from "./ProductCount";
import ProductInput from "./ProductInput";
import ProductDetail from "./ProductDetail";
import axios from "axios";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import ProductComment from "./ProductComment";
import ProductTabs from "./ProductsTabs";
import ProductPriceBands from "./ProductPriceBands";
const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
  },
  mainsection: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // maxHeight: "90vh",
    // overflow: "auto",
    // scrollbarColor: "none",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(0),
      maxHeight: "100%",
      overflow: "none",
    },
  },
  priceBandsContainer: {
    height: "15vh",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
  },
}));
const ProductInfo = (props) => {
  const classes = useStyles();
  const activeProduct = useSelector((state) => state.activeProduct);

  const [state, setState] = useState({
    selectedInput: {},
    selectedColor: "Please Select Color",
    selectedSize: "Please Select Size",
    dataProInfo: {
      id: "1",
      category: "",
      type: "",
      name: "Loading",
      defaultImage: { url: "" },
      price: { marketPrice: undefined, unitPrice: undefined },
      detail: { color: ["red", "blue"], size: ["", "", "", "", ""], des: "" },
      seller: "",
      sellerId: "",
      quantity: "",
      images: [{ url: "" }],
      productInputs: [{ orderInput: "Loading", inputOptions: ["Loading"] }],
      configurationId: "",
      specificationId: "",
      distributorId: null,
      bomId: "",
      reviews: [],
      moq: undefined,
    },
    canAddToCart: false,
  });
  function handleSelectColor(value) {
    value = value.replace(/^[a-z]?/, function ($0) {
      return $0.toUpperCase();
    });
    setState({ ...state, selectedColor: value });
  }
  function handleSelectSize(value) {
    setState({ ...state, selectedSize: value.toUpperCase() });
  }
  function handleAddOnceToCart() {
    setState({ ...state, canAddToCart: false });
  }
  const handleSelectInput = (value, inputIndex) => {
    let newSelectionArray = state.selectedInput;

    // newSelectionArray[inputIndex] = { [inputIndex]: value };
    newSelectionArray[inputIndex] = value;

    setState({
      ...state,
      selectedInput: newSelectionArray,
      canAddToCart:
        Object.keys(selectedInput).length === dataProInfo.productInputs.length
          ? true
          : false,
    });
  };

  useEffect(() => {
    // console.log("pro info effect");
    setState({
      ...state,

      dataProInfo: activeProduct,
      // moq: activeProduct.moq,
    });
  }, [activeProduct]);
  const {
    selectedInput,
    selectedColor,
    selectedSize,
    dataProInfo,
    canAddToCart,
  } = state;

  let selectedOptions = {};
  dataProInfo.productInputs.forEach(
    (ele, index) =>
      (selectedOptions = Object.assign(selectedOptions, {
        [ele.orderInput.includes("QUANTITY") ? "QUANTITY" : ele.orderInput]:
          selectedInput[index],
      }))
  );
  // console.log(dataProInfo, "dataproinfo");
  let proInfo = {
    id: dataProInfo.id,
    name: dataProInfo.name,
    // marketPrice:
    //   (dataProInfo.unitPrice * (100 - dataProInfo.discountRate)) / 100,

    marketPrice: dataProInfo.unitPrice,
    unitPrice: dataProInfo.unitPrice,
    seller: dataProInfo.seller,
    sellerId: dataProInfo.sellerId,
    srcImg: dataProInfo.images.length > 0 && dataProInfo.images[0].url,
    selectedOptions,
    configurationId: dataProInfo.configurationId,
    specificationId: dataProInfo.specificationId,
    priceBands: dataProInfo.priceBands,
    distributorId: dataProInfo.distributorId,

    bomId: dataProInfo.bomId,
  };
  let hasSelected = true;
  // console.log(dataProInfo, "dataproinfo", proInfo);

  dataProInfo.productInputs.forEach((ele, index) => {
    if (typeof selectedInput[index] === "undefined") hasSelected = false;
  });

  return (
    <div className={classes.root}>
      <Grid container justify="center">
        <Grid item lg={6} xs={12} md={6}>
          <ProductImages
            images={[{ url: dataProInfo.defaultImage }, ...dataProInfo.images]}
          />
        </Grid>
        <Grid
          item
          container
          justify="center"
          alignItems="flex-start"
          lg={6}
          xs={12}
          md={6}
          className={classes.mainsection}
          // style={{ backgroundColor: "red", height: "auto" }}
        >
          <Grid item lg={11} xs={12} md={12}>
            <ProductHeader
              description={dataProInfo.description}
              name={dataProInfo.name}
              unitPrice={dataProInfo.unitPrice}
              marketPrice={dataProInfo.marketPrice}
              pricebands={dataProInfo.priceBands}
              quantity={dataProInfo.quantity}
              selected={
                Object.keys(selectedOptions).length > 0
                  ? selectedOptions.QUANTITY
                  : 0
              }
              returnable={dataProInfo.returnable}
            />
          </Grid>
          <Grid item lg={11} xs={12} md={12}>
            {dataProInfo.productInputs.length > 0 &&
              dataProInfo.productInputs.map((ele, index) => {
                return (
                  <ProductInput
                    key={index}
                    header={ele.orderInput}
                    dataInputs={ele.inputOptions}
                    moq={dataProInfo.moq}
                    selectedInput={selectedInput}
                    inputIndex={index}
                    handleSelectInput={handleSelectInput}
                  />
                );
              })}
          </Grid>

          <Grid item lg={11} xs={12} md={12}>
            <ProductDetail
              dataDes={dataProInfo.description}
              canAddToCart={canAddToCart}
              hasSelected={hasSelected}
              handleAddToCart={props.handleAddToCart}
              handleAddOnceToCart={handleAddOnceToCart}
              proInfo={proInfo}
              attributes={dataProInfo.attributeList}
              seller={dataProInfo.seller}
              openEnquiry={props.openEnquiry}
            />
          </Grid>

          <Grid
            item
            lg={11}
            xs={12}
            md={12}
            className={classes.priceBandsContainer}
          >
            {dataProInfo.priceBands && dataProInfo.priceBands.length > 0 && (
              <ProductPriceBands
                pricebands={dataProInfo.priceBands}
                selected={
                  Object.keys(selectedOptions).length > 0
                    ? selectedOptions.QUANTITY
                    : 0
                }
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductInfo;
