import React from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import PT from "prop-types";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
const propTypes = {
  dataDes: PT.string,
  proInfo: PT.object,
  canAddToCart: PT.bool,
  hasSelected: PT.bool,
  handleAddOnceToCart: PT.func,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Delivery = (props) => (
  <div>
    Delivery: Shipping is available to residential address or other address in
    Mainland China, in accordance with your selected delivery option.
    Unfortunately we are unable to deliver to Hong Kong, Macau or Taiwan.
    Payment: We accept card payments via MasterCard and Visa. You can also
    select to pay by Alipay, Bank transfer, China Union Pay via Online or
    Telephone or H&M giftcard.
  </div>
);

const useStyles = makeStyles((theme) => ({
  TabPanel: {
    fontSize: 20,
  },
  favouriteBtn: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  main: {
    fontSize: theme.typography.h3,
    fontSize: 20,
  },
  title: {
    fontWeight: 600,
    fontSize: 20,
    // fontSize: 20,
    // [theme.breakpoints.down("md")]: {
    //   // fontSize: 20,
    //   fontWeight: 400,
    // },
  },
  delivers: {
    color: theme.palette.text.secondary,
  },
  root: {
    paddingTop: theme.spacing(2),
    // paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(2),
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  paperCOntainer: {
    height: "60vh",
    overflow: "auto",
  },
}));
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 15,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: theme.palette.primary.dark,
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: theme.palette.primary.light,
    },
  },

  selected: {},
}))((props) => <Tab disableRipple {...props} />);
const ProductDetail = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const Specification = ({ attributes }) => (
    <div>
      {attributes && (
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography as="h3">Product Specification</Typography>
                </TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {attributes.map((att, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {att.productAttribute}
                  </TableCell>
                  <TableCell align="right">
                    {att.value} {" " + att.uom}{" "}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const panes = [
    {
      menuItem: "DESCRIPTION",
      component: (
        <TabPanel value={value} index={0} key={0}>
          {props.description}
        </TabPanel>
      ),
    },
    {
      menuItem: "DETAILS",
      component: (
        <TabPanel value={value} index={1} key={1}>
          {props.details}
        </TabPanel>
      ),
    },
    {
      menuItem: "OVERVIEW",
      component: (
        <TabPanel value={value} index={2} key={2}>
          {props.overview}
        </TabPanel>
      ),
    },
    {
      menuItem: "DELIVERY",
      component: (
        <TabPanel value={value} index={3} key={3}>
          {/* <Delivery /> */}
          <Typography className={classes.main}>
            <Typography
              className={classes.title}
              color="primary"
              display="inline"
            >
              Length
            </Typography>{" "}
            : {props.length} {props.uom}
          </Typography>
          <Typography className={classes.main}>
            <Typography
              className={classes.title}
              color="primary"
              display="inline"
            >
              Height
            </Typography>{" "}
            : {props.height} {props.uom}
          </Typography>
          <Typography className={classes.main}>
            <Typography
              className={classes.title}
              color="primary"
              display="inline"
            >
              Breadth
            </Typography>{" "}
            : {props.breadth} {props.uom}
          </Typography>
          <Typography className={classes.main}>
            <Typography
              className={classes.title}
              color="primary"
              display="inline"
            >
              Weight
            </Typography>{" "}
            : {props.weight} {"kg"}
          </Typography>
        </TabPanel>
      ),
    },

    {
      menuItem: "SPECIFICATION",
      component: (
        <TabPanel value={value} index={4} key={4}>
          <Specification attributes={props.attributes} />
        </TabPanel>
      ),
    },
  ];

  return (
    <div className={classes.root}>
      <Paper variant="outlined" className={classes.paperCOntainer}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
        >
          {panes.map((key, index) => (
            <AntTab key={index} label={key.menuItem} {...a11yProps(index)} />
          ))}
        </Tabs>
        {panes.map((key, index) => {
          return key.component;
        })}
      </Paper>
    </div>
  );
};

ProductDetail.propTypes = propTypes;

export default connect(null, null)(ProductDetail);
