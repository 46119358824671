import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  makeStyles,
  Divider,
  ListItemIcon,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ChevronRight";
import BackIcon from "@material-ui/icons/ArrowBack";
const useStyles = makeStyles((theme) => ({
  list: {
    width: "100vw",
    height: "100%",
    // overflow: "auto",

    // backgroundColor: theme.palette.background.default,
  },
  // root: {
  //   backgroundColor: theme.palette.background.default,
  // },
  // titleContainer: {},
  // ListSubheader: {
  //   border: "1px solid green",
  // },
  // categories: {
  //   color: theme.palette.text.primary,

  //   fontSize: 16,
  //   fontWeight: 500,
  // },
  // link: {
  //   fontSize: 15,
  //   color: theme.palette.text.primary,
  //   textTransform: "Capitalize",
  // },
}));
const TypeMenu = (props) => {
  const { categoryMenu, handleCategoryClick, onClose } = props;

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <List className={classes.list}>
        {props.showback && (
          <>
            <ListItem button onClick={() => onClose()}>
              <ListItemIcon>
                <BackIcon />
              </ListItemIcon>
              <ListItemText primary={"Go back"} />
            </ListItem>
            <Divider />
          </>
        )}

        {categoryMenu &&
          categoryMenu.categoryList.map((category, index) => {
            return (
              <>
                <ListItem
                  button
                  // className={classes.listItem}
                  onClick={(e) => handleCategoryClick(category, index)}
                >
                  <ListItemText primary={category.name} />

                  <ListItemSecondaryAction>
                    <IconButton>
                      <ExpandMoreIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </>
            );
          })}
      </List>
    </div>
  );
};

export default TypeMenu;
