import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  Card,
  CardHeader,
  Divider,
  CardContent,
  List,
  ListItem,
  Radio,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "50%",
  },
  Card: {
    width: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: "#fff",
  },
}));
const options = [
  { text: "Best Match", value: "Best Match" },
  { text: "Lowest Price", value: "Lowest Price" },
  { text: "Highest Price", value: "Highest Price" },
];
const SortBarMobile = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Drawer
        keepMounted={false}
        open={props.open}
        anchor="bottom"
        onClose={() => props.close()}
      >
        <Card className={classes.Card}>
          <CardHeader title="Sort By" />
          <Divider />
          <CardContent>
            <List>
              {options.map((data, index) => {
                return (
                  <ListItem key={index} button>
                    <ListItemText primary={data.text} />
                    <ListItemSecondaryAction>
                      <Radio
                        edge="end"
                        checked={props.filter === data.value}
                        onChange={() => props.handleSort(data.value)}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </CardContent>
        </Card>
      </Drawer>
    </div>
  );
};
export default React.memo(SortBarMobile);
