// import React from "react";

// import { useSelector } from "react-redux";

const CalculateTotal = (usercart) => {
  let cart = usercart !== undefined ? [...usercart] : [];
  cart.totalNetAmount = 0;
  cart.totalTaxAmount = 0;
  cart.totalDiscAmount = 0;
  cart.itemsQuantity = 0;
  cart.charges = 0;

  cart.forEach((item) => {
    item.charges = 0;
    item.taxAmount =
      item.unitPrice * (item.taxRate / 100) * item.selectedOptions.QUANTITY;

    item.discountAmount =
      ((item.unitPrice * item.discountRate) / 100) *
      item.selectedOptions.QUANTITY;
    item.netAmount =
      item.unitPrice * item.selectedOptions.QUANTITY - item.discountAmount;
    item.total = item.netAmount + item.taxAmount;
    cart.totalNetAmount += item.netAmount;
    cart.totalTaxAmount += item.taxAmount;
    cart.totalDiscAmount += item.discountAmount;
    cart.itemsQuantity += item.selectedOptions.QUANTITY;
    cart.charges += item.charges;

    // cart.grandTotal += item.total;
  });

  return cart;
};
export default CalculateTotal;
