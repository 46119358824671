import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ProductItem from "./ProductItem";
import PT from "prop-types";
import { Grid, Paper } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { toggleLoginModal } from "../../redux/action/utilActions";

const propTypes = {
  dataProducts: PT.arrayOf(PT.object),
};

const useStyles = makeStyles((theme) => ({}));
const ProductList = (props) => {
  const classes = useStyles();
  const favourites = useSelector((state) => state.shop.favourites);

  const itemsProduct = props.dataProducts.map((item, index) => {
    // console.log("item", item);
    return (
      <Grid item lg={3} sm={6} md={3} xs={6}>
        <ProductItem
          item={item}
          {...item}
          isFavourite={favourites.some((data) => data.id === item.id)}
        />
      </Grid>
    );
  });

  return (
    <div>
      <Grid container>{itemsProduct}</Grid>
    </div>
  );
};

ProductList.propTypes = propTypes;

export default ProductList;
