import React from "react";

import {
  Container,
  makeStyles,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  signUp: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1),
  },
}));
const AlreadLogged = () => {
  const classes = useStyles();

  return (
    <Card elevation={0}>
      <CardHeader
        title="You are already logged in"
        style={{ textAlign: "center" }}
      />
      <CardContent>
        <Button color="primary" variant="text">
          Logout
        </Button>
      </CardContent>
    </Card>
  );
};

export default AlreadLogged;
