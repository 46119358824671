import React from "react";

import LoginForm from "../user/LoginForm";
import NewCustomer from "../user/NewCustomer";
import OrderBar from "./OrderBar";
import {
  Grid,
  Container,
  Hidden,
  Card,
  CardHeader,
  Divider,
  CardContent,
} from "@material-ui/core";

const SigninStep = (props) => {
  return (
    <Grid container spacing={5}>
      <Grid item lg={12}>
        <OrderBar />
      </Grid>
    </Grid>
  );
};

export default SigninStep;
