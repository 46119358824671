import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Card,
  CardContent,
} from "@material-ui/core";

import { states } from "../../../../data/stateList";

const useStyles = makeStyles((theme) => ({
  normalBg: {
    backgroundColor: theme.palette.grayBackground,
  },
  root: {
    // height: "80%",
    // overflow: "auto",
    // backgroundColor: theme.palette.grayBackground,
    borderRadius: 4,
    border: "1px solid gray",
    padding: theme.spacing(1),
    // margin: theme.spacing(10),

    // overflow: "auto",

    // marginBottom: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(1),
    // width: "100%",
    // padding: theme.spacing(5),
    // paddingTop: theme.spacing(10),
    // paddingBottom: theme.spacing(10),
  },
  title: {
    fontSize: "1.3rem",
    fontWeight: 500,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
  },
  TextField: {
    backgroundColor: theme.palette.background.paper,
  },
}));
const AddNewAddress = ({ array, add, close, open }) => {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [line1, setline1] = React.useState();
  const [line2, setline2] = React.useState();
  const [line3, setline3] = React.useState();
  const [city, setCity] = React.useState();
  const [state, setState] = React.useState();
  const [country, setCountry] = React.useState();
  const [zipCode, setzipCode] = React.useState();
  const [addressName, setaddressName] = React.useState();

  //empty every field

  const Empty = () => {
    setFirstName();
    setLastName();
    setzipCode();
    setline1();
    setline2();
    setCity();
    setState();
    setline3();
    setCountry();

    setaddressName();
  };

  //save btn

  const saveNewAddress = () => {
    let newArray = [];
    newArray.push({
      firstName,
      lastName,
      zipCode,
      line1,
      line2,
      line3,
      city,
      state,
      country,
      addressName,
      defaultAddress: false,
    });
    add(newArray);
    close();
    Empty();
    console.log(newArray, "new address");
  };

  //cancel btn action

  const cancelAdding = () => {
    Empty();
    close();
  };

  return (
    <Grid item lg={12} className={classes.container}>
      <Card className={classes.normalBg}>
        {/* <CardHeader title="Add New Address" /> */}
        {/* <Divider /> */}
        <CardContent>
          <Grid container spacing={3}>
            <Grid item lg={12} sm={12} xs={12} md={12}>
              <Typography className={classes.title}>Add New Address</Typography>
            </Grid>
            <Grid item lg={6} sm={12} xs={12} md={12}>
              <TextField
                variant="outlined"
                fullWidth
                size="medium"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className={classes.TextField}
              />
            </Grid>
            <Grid item lg={6} sm={12} xs={12} md={12}>
              <TextField
                variant="outlined"
                fullWidth
                size="medium"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className={classes.TextField}
              />
            </Grid>
            <Grid item lg={12} sm={12} xs={12} md={12}>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                rowsMax={2}
                size="medium"
                placeholder="Address Line 1"
                value={line1}
                onChange={(e) => setline1(e.target.value)}
                className={classes.TextField}
              />
            </Grid>
            <Grid item lg={12} sm={12} xs={12} md={12}>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                rowsMax={2}
                size="medium"
                placeholder="Address Line 2"
                value={line2}
                onChange={(e) => setline2(e.target.value)}
                className={classes.TextField}
              />
            </Grid>
            <Grid item lg={12} sm={12} xs={12} md={12}>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                rowsMax={2}
                size="medium"
                placeholder="Address Line 3"
                value={line3}
                onChange={(e) => setline3(e.target.value)}
                className={classes.TextField}
              />
            </Grid>
            <Grid item lg={6} sm={12} xs={12} md={12}>
              <TextField
                variant="outlined"
                fullWidth
                size="medium"
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className={classes.TextField}
              />
            </Grid>
            <Grid item lg={6} sm={12} xs={12} md={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel id="demo-simple-select-filled-label">
                  State
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={state}
                  placeholder="State"
                  label="State"
                  className={classes.TextField}
                  onChange={(e) => setState(e.target.value)}
                >
                  {states.map((value, index) => {
                    return (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={6} sm={12} xs={12} md={12}>
              <TextField
                variant="outlined"
                fullWidth
                size="medium"
                placeholder="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                className={classes.TextField}
              />
            </Grid>
            <Grid item lg={6} sm={12} xs={12} md={12}>
              <TextField
                variant="outlined"
                fullWidth
                size="medium"
                placeholder="Zip Code"
                value={zipCode}
                onChange={(e) => setzipCode(e.target.value)}
                className={classes.TextField}
              />
            </Grid>{" "}
            <Grid item lg={12} sm={12} xs={12} md={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Address Type</FormLabel>
                <RadioGroup
                  row
                  aria-label="Address Type"
                  name="Address Type"
                  value={addressName}
                  onChange={(e) => setaddressName(e.target.value)}
                >
                  <FormControlLabel
                    value="Home"
                    control={<Radio />}
                    label="Home"
                  />
                  <FormControlLabel
                    value="Work"
                    control={<Radio />}
                    label="Work"
                  />
                  <FormControlLabel
                    value="Other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item lg={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                elevation={0}
                onClick={() => saveNewAddress()}
              >
                Save
              </Button>
            </Grid>
            <Grid item lg={2}>
              <Button
                fullWidth
                variant="text"
                color="secondary"
                size="large"
                onClick={() => cancelAdding()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default AddNewAddress;
