import React from "react";

import {
  Divider,
  Card,
  CardHeader,
  CardContent,
  Container,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
const useStyles = makeStyles((theme) => ({
  container: {
    // border: "1px solid #e5e5e5",
    borderRadius: 10,
  },
  root: {
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(2),
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  title: {
    fontSize: 18,
  },
  heading: {
    fontSize: 15,
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const ProductAvisory = (props) => {
  const classes = useStyles();
  const { dataAvisory } = props;
  const Panels = () => {};
  return (
    <div className={classes.root}>
      <Grid container justify="center">
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          lg={12}
          xs={12}
          md={12}
          spacing={2}
          className={classes.mainsection}
          component={Card}
          variant="outlined"
        >
          <CardHeader title="Product Advisory"></CardHeader>
          <Grid item lg={12} xs={12}>
            <Divider />
          </Grid>
          <Grid item lg={12} xs={12} md={12}>
            {dataAvisory.map((data, index) => {
              return (
                <ExpansionPanel elevation={0} key={index}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      {data.title}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>{data.content}</Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductAvisory;
