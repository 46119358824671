import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel, withStyles } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(5),
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
  },
}));
const PurpleSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.primary.light,
    "&$checked": {
      color: theme.palette.primary.main,
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
  track: {},
}))(Switch);

const SwitchInput = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControlLabel
        id={props.name}
        // disabled={!props.selected}
        checked={props.verified}
        control={<PurpleSwitch {...props} color="primary" />}
      />
    </div>
  );
};
export default SwitchInput;
