import React from "react";

import ConfirmHeader from "./ConfirmOrderHeader";

import PT from "prop-types";
import {
  Grid,
  Typography,
  Divider,
  Radio,
  Button,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import ConfirmOrderProdcuts from "./ConfirmOrderProdcuts";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Axios from "axios";
import { ROOT_URL } from "../../redux/utils/utils";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const propTypes = {
  handleBillDone: PT.func,
};
const useStyles = makeStyles((theme) => ({
  table: {
    borderWidth: 1,
    borderColor: theme.palette.gray.light,
    borderStyle: "solid",
  },
  Card: {
    overflow: "auto",
  },
  completeBtn: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      width: "100%",
    },
  },
}));

const ConfirmStep = (props) => {
  const classes = useStyles();
  const [snackOpen, setSnackOpen] = React.useState({
    open: false,
    severnity: "",
    message: "",
  });
  //address
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const [quotebtndisable, setquotebtndisable] = React.useState(false);

  const addresses = useSelector((state) => state.user.addresses);
  const addressId = useSelector((state) => state.shop.addressId);
  const shippingInfo = useSelector((state) => state.shop.shippingMethods);
  const getAddress = () => {
    let newAddress = null;
    if (addresses.length !== 0) {
      newAddress = addresses.find((item) => item.id === addressId);
    }

    setSelectedAddress(newAddress);
  };

  //shipping
  const [selectedShippingMethod, setSelectedShippingMethod] = React.useState(
    null
  );
  const shippingData = shippingInfo;
  const shippingId = useSelector((state) => state.shop.shipmentMethodId);

  const getShippingMethod = () => {
    let newShippingMethod = null;
    if (shippingData.length !== 0) {
      newShippingMethod = shippingData.find(
        (item) => item.id === parseInt(shippingId)
      );
    }

    setSelectedShippingMethod(newShippingMethod);
  };

  //email

  const { email, mobile } = useSelector((state) => state.user);

  //user order items

  const userCart = useSelector((state) => state.shop.cart);
  //

  const handleQuote = () => {
    const token = localStorage.getItem("tkn");

    const header = {
      Authorization: `Bearer ${token}`,
    };
    Axios.post(`${ROOT_URL}SFA/Query/Quote`, props.prepareOrderData(), {
      headers: header,
    })
      .then((res) => {
        setSnackOpen({
          open: true,
          severnity: "success",
          message: "Quote Request  Submitted Successfully",
        });
        setquotebtndisable(true);
      })
      .catch((err) =>
        setSnackOpen({
          open: true,
          severnity: "error",
          message: "There was a problem in submitting quote, please retry.",
        })
      );
  };
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen({ ...snackOpen, open: false });
  };

  React.useEffect(() => {
    getAddress();
    getShippingMethod();
  }, []);
  return (
    <Grid container spacing={5}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Card className={classes.Card}>
          <CardHeader title="Confirm Order" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item lg={12} xs={12}>
                {selectedAddress !== null &&
                  selectedShippingMethod !== null && (
                    <ConfirmHeader
                      address={selectedAddress}
                      shippingMethod={selectedShippingMethod.name}
                      shippingCharges={selectedShippingMethod.charges}
                      email={email}
                      mobile={mobile}
                    />
                  )}
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            {selectedAddress !== null && selectedShippingMethod !== null && (
              <ConfirmOrderProdcuts
                cart={userCart}
                shippingCharges={selectedShippingMethod.charges}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        container
        justify="space-between"
        lg={12}
        md={12}
        xs={12}
        lg={12}
      >
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => props.back()}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleQuote()}
            disabled={quotebtndisable}
          >
            GET QUOTE
          </Button>
        </Grid>
        <Grid item lg="auto" md={4} xs={12} sm={12}>
          <Button
            className={classes.completeBtn}
            color="primary"
            size="large"
            variant="contained"
            onClick={() => props.handleBillDone()}
          >
            COMPLETE PURCHASE
          </Button>
        </Grid>
      </Grid>
      {/* <Grid item lg={5} xs={12}>
        <HeaderBar />
      </Grid> */}
    </Grid>
  );
};

ConfirmStep.propTypes = propTypes;

export default ConfirmStep;
