import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid, Hidden, Button, Box } from "@material-ui/core";
import OrderItem from "./OrderItem";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function SimpleExpansionPanel({
  data,
  addComment,
  orderIndex,
  returnRequest,
  getTrackingInfo,
}) {
  const classes = useStyles();

  const { orderDate, grandTotal, orderNumber, seller, status } = data;

  return (
    <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          aria-label="Expand"
        >
          <Grid container>
            <Grid
              item
              container
              lg={5}
              md={5}
              sm={12}
              xs={12}
              justify="space-between"
            >
              <Grid item lg="auto" md="auto" sm="auto" xs="auto">
                <Typography color="primary">ORDER PLACED ON</Typography>
                <Typography color="textSecondary">{orderDate}</Typography>
              </Grid>
              <Grid item lg="auto" md="auto" sm="auto" xs="auto">
                <Typography color="primary">TOTAL</Typography>
                <Typography color="textSecondary">{grandTotal}</Typography>
              </Grid>
            </Grid>
            <Hidden mdDown>
              <Grid item container lg={3} sm={12} xs={12} justify="flex-end">
                <Grid item>
                  <Typography color="primary">ORDER NUMBER</Typography>
                  <Typography color="textSecondary">{orderNumber}</Typography>
                </Grid>
              </Grid>
            </Hidden>
            <Hidden mdDown>
              <Grid item container lg={4} sm={12} xs={6} justify="center">
                <Grid item>
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      getTrackingInfo(orderNumber);
                      // console.log(data, "add product");
                      // addComment(data);
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    variant="outlined"
                    color="primary"
                    fullWidth
                  >
                    Track Order
                  </Button>
                </Grid>
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid item xs={6}>
                <Typography color="primary">ORDER NUMBER</Typography>
                <Typography color="textSecondary">{orderNumber}</Typography>
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid item container lg={4} sm={12} xs={6} justify="center">
                <Grid item>
                  <Button
                    onClick={(event) => {
                      event.stopPropagation();
                      getTrackingInfo(orderNumber);
                      // console.log(data, "add product");
                      // addComment(data);
                    }}
                    onFocus={(event) => event.stopPropagation()}
                    variant="outlined"
                    color="primary"
                    // fullWidth
                  >
                    Track Order
                  </Button>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container>
            {data.detailList &&
              data.detailList.length > 0 &&
              data.detailList.map((item, index) => {
                return (
                  <OrderItem
                    data={item}
                    key={index}
                    status={status}
                    seller={seller}
                    addComment={addComment}
                    orderIndex={orderIndex}
                    orderDetailIndex={index}
                    // getTrackingInfo={getTrackingInfo}
                    returnRequest={returnRequest}
                  />
                );
              })}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
