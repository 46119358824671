import { combineReducers } from "redux";
import shop from "./shop.reducer";
import { brandFilterReducer } from "./brand.filter.reducer";
import { orderByPriceReducer } from "./orderByPrice.filter.reducer";
import { paginationReducer } from "./pagination.reducer";
import { reducer as formReducer } from "redux-form";
import userReducer from "./userReducer";
import activeProductReducer from "./activeProductReducer";
import utilReducer from "./utilReducers/utilReducers";

import spinner from "./spinnerReducer";
const initialState = {
  values: {},
};

export default combineReducers({
  form: formReducer.plugin({
    searchAttributes: (state = initialState, action) => {
      switch (action.type) {
        case "SUBMIT_SEARCH":
          console.log("SUBMIT_SEARCH PLUGIN REDUCED SUCCESS");
          return { ...state, values: action.payload.data };
        case "SELECT_TYPE":
          console.log("SELECT_TYPE PLUGIN REDUCED SUCCESS");
          return { ...state, values: {} };
        default:
          return state;
      }
    },
  }),
  shop,
  brandFilter: brandFilterReducer,
  orderBy: orderByPriceReducer,
  pagination: paginationReducer,
  user: userReducer,
  activeProduct: activeProductReducer,
  utilReducer,

  spinner,
});
