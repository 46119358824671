import React from "react";
import BannerCarousel from "../carousel/BannerCarousel";
import HomeProductSection from "./HomeProductSection";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { pushtohistory } from "../../redux/action/historyActions";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
  },
}));
const ComponentName = (props) => {
  const classes = useStyles();
  //stub

  const { stub } = useParams();
  const location = useLocation().pathname;
  const dispatch = useDispatch();

  React.useEffect(() => {
    let newlocation = location === "/" ? location : location.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));
    if (stub) {
      props.history.push(location);
    }
  }, []);

  return (
    <div className={classes.root}>
      <BannerCarousel />
      <HomeProductSection />
    </div>
  );
};
export default ComponentName;
