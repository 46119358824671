import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const StepProgress = () => {
  const classes = useStyles();

  return <div className={classes.root}>step progress</div>;
};
export default StepProgress;

// import React from "react";

// import PT from "prop-types";
// import { Container, Paper, Grid } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// import Stepper from "@material-ui/core/Stepper";
// import Stepe from "@material-ui/core/Step";
// import { Step } from "semantic-ui-react";
// import StepLabel from "@material-ui/core/StepLabel";
// import Button from "@material-ui/core/Button";
// import Typography from "@material-ui/core/Typography";
// import SigninStep from "./SigninStep";

// const propTypes = {
//   steps: PT.arrayOf(PT.object),
// };
// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//   },
//   backButton: {
//     marginRight: theme.spacing(1),
//   },
//   instructions: {
//     marginTop: theme.spacing(1),
//     marginBottom: theme.spacing(1),
//   },
//   completed: {
//     height: "50vh",
//     width: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
// }));
// function getSteps() {
//   return [
//     "Sign In",
//     "Review Products",
//     "Address",
//     "Shipping",
//     "Payment",
//     "Confirm Order",
//     "Thank You",
//   ];
// }
// function getStepContent(stepIndex) {
//   switch (stepIndex) {
//     case 0:
//       return <SigninStep />;
//     case 1:
//       return "What is an ad group anyways?";
//     case 2:
//       return "This is the bit I really care about!";
//     case 3:
//       return "Unknown stepIndex";
//     case 4:
//       return "Unknown stepIndex";
//     case 5:
//       return "Unknown stepIndex";
//     case 6:
//       return "Unknown stepIndex";
//   }
// }
// const StepProgress = (props) => {
//   function getSteps() {
//     return props.steps;
//   }
//   const classes = useStyles();
//   const [activeStep, setActiveStep] = React.useState(0);
//   const steps = getSteps();
//   console.log(props.steps, "checkout");

//   const handleNext = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//   };

//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };

//   const handleReset = () => {
//     setActiveStep(0);
//   };
//   return (
//     <Container style={{ marginTop: 100 }}>
//       <Grid container justify="center" alignItems="center">
//         <Grid item>
//           <Typography as="h2">CHECKOUT</Typography>
//         </Grid>
//         <Grid item lg={12} xs={11}>
//           <Stepper activeStep={activeStep} alternativeLabel>
//             {steps.map((label) => (
//               <Stepe key={label}>
//                 <StepLabel>{label.title}</StepLabel>
//               </Stepe>
//             ))}
//           </Stepper>
//           <div>
//             {activeStep === steps.length ? (
//               <div className={classes.completed}>
//                 <Typography className={classes.instructions}>
//                   All steps completed
//                 </Typography>
//                 <Button onClick={handleReset}>Reset</Button>
//               </div>
//             ) : (
//               <div>
//                 {getStepContent(activeStep)}

//                 <div>
//                   <Button
//                     disabled={activeStep === 0}
//                     onClick={handleBack}
//                     className={classes.backButton}
//                   >
//                     Back
//                   </Button>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleNext}
//                   >
//                     {activeStep === steps.length - 1 ? "Finish" : "Next"}
//                   </Button>
//                 </div>
//               </div>
//             )}
//           </div>
//         </Grid>
//       </Grid>

//       <Step.Group items={props.steps} />
//     </Container>
//   );
// };

// StepProgress.propTypes = propTypes;

// export default StepProgress;
