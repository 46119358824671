import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Divider,
  Radio,
  FormControlLabel,
  IconButton,
  Button,
  ExpansionPanelActions,
} from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditAddress from "./EditAddress";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  summary: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  column: {
    flex: 1,
    display: "flex",
    alignItems: "center",
  },

  heading: {
    fontWeight: 400,
    fontSize: 17,
  },
  nameContainer: {
    width: "100%",
  },
}));

const SingleAddress = (props) => {
  const classes = useStyles();
  const { data, deleteaddress, edit } = props;
  const {
    firstName,
    lastName,
    zipCode,
    line1,
    line2,
    line3,
    city,
    state,
    country,
    userId,

    addressName,
  } = data;
  const getListedAddress = (
    zipCode,
    line1,
    line2,
    line3,
    city,
    state,
    country
  ) => {
    let address = "";
    let space = ", ";
    let newadd = address.concat(
      line1,
      space,
      line2,
      space,
      line3,
      space,
      city,
      space,
      state,
      space,
      country,

      " - ",
      zipCode
    );
    return newadd;
  };
  const editExistingAddress = () => {
    // setAnchorEl(null);
    edit();
  };

  //delete address

  const DeleteAddress = () => {
    // setAnchorEl(null);
    deleteaddress();
    //make permemnant changes here
  };
  return (
    <Grid item lg={12} className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.summary}
        >
          {/* <div className={classes.column}>
            <FormControlLabel
              aria-label="Acknowledge"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={<Radio />}
              label="Default Address"
              name="defaultAddress"
              color="red"
            />
          </div> */}
          <div className={classes.column}>
            <Typography className={classes.heading}>{addressName}</Typography>
          </div>

          {/* {defaultAddress === true ? (
            <Button variant="outlined" color="secondary">
              Default Address
            </Button>
          ) : null} */}
        </ExpansionPanelSummary>
        {/* <Divider /> */}
        <ExpansionPanelDetails>
          <Grid container>
            <Grid item lg={12}>
              <Typography variant="subtitle1">
                {firstName} {lastName}
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.subTitle}
              >
                {getListedAddress(
                  zipCode,
                  line1,
                  line2,
                  line3,
                  city,
                  state,
                  country
                )}
              </Typography>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions>
          <Button
            size="large"
            startIcon={<EditIcon />}
            color="primary"
            onClick={() => editExistingAddress()}
          >
            Edit
          </Button>
          <Button
            size="large"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={() => DeleteAddress()}
          >
            Delete
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    </Grid>
  );
};
export default SingleAddress;
