import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, IconButton, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const useStyles = makeStyles((theme) => ({
  numberInputContainer: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  removeIcon: {
    flex: 1,
  },
  addIcon: {
    flex: 1,
  },
  TextField: {
    flex: 2,

    textAlign: "center",

    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
      margin: 80,
    },
  },
}));

const NumberInput = ({ dataInputs, handleSelect, moq }) => {
  const classes = useStyles();
  //   console.log("dataInputs", dataInputs);

  const [value, setValue] = React.useState(moq);

  const Increment = () => {
    if (value < dataInputs.maximum) {
      handleSelect(value + moq);
      setValue(value + moq);
    } else {
      alert("Can not select more");
    }
  };
  const Decrement = () => {
    if (value > dataInputs.minimum) {
      handleSelect(value - moq);
      setValue(value - moq);
    } else {
      alert("Can not select less");
    }
  };
  React.useEffect(() => {
    setValue(moq);
  }, [moq]);

  // const handleChange = (e) => {
  //   let value = e;
  //   if (value > dataInputs.maximum) {
  //     alert(`Can not select more than ${dataInputs.maximum} items`);
  //     return null;
  //   }

  //   // if (value >= dataInputs.minimum) {
  //   //   return null;
  //   //   alert("minimum quantity reached");
  //   // }
  //   // if (value <= dataInputs.maximum) {
  //   // alert("maximum quantity reached");
  //   // return null;
  //   // }
  //   handleSelect(value - 1);
  //   setValue(value);
  // };
  return (
    <Box className={classes.numberInputContainer}>
      <IconButton
        className={classes.removeIcon}
        color="secondary"
        disabled={value <= moq}
        onClick={() => Decrement()}
      >
        <RemoveIcon />
      </IconButton>
      <TextField
        className={classes.TextField}
        variant="outlined"
        color="primary"
        size="small"
        type="number"
        inputProps={{
          min: dataInputs.minimum,
          max: dataInputs.maximum,
          style: { textAlign: "center" },
        }}
        value={value}
        // onChange={(e) => handleChange(e.target.value)}
      />
      <IconButton
        className={classes.addIcon}
        color="primary"
        disabled={value >= dataInputs.maximum}
        onClick={() => Increment()}
      >
        <AddIcon />
      </IconButton>
    </Box>
  );
};
export default NumberInput;
