//material

import React from "react";

import AttributeFilter from "../AttributeFilter/AttributeFilter";
import OrderFilter from "../OrderFilter/OrderFilter";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Divider, CardContent } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  container: {
    borderWidth: 1,
    borderColor: "red",
    borderRadius: 4,
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  CardHeader: {
    // fontSize: theme.typography.pxToRem(18),
    // color: "#565656",
  },
  CardHeaderContainer: {
    display: "flex",

    height: 50,

    flexDirection: "row",
    paddingLeft: theme.spacing(2),
    alignItems: "center",
  },
}));
const FilterBar = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState({
    searchAttributes: true,
    priceAttributes: true,
  });

  const handleExpansion = (att) => {
    if (att === "search") {
      setExpanded({
        ...expanded,
        searchAttributes: !expanded.searchAttributes,
      });
    }
    if (att === "price") {
      setExpanded({
        ...expanded,
        priceAttributes: !expanded.priceAttributes,
      });
    }
  };

  return (
    <Card className={classes.container} elevation={0}>
      <CardContent>
        <Typography variant="h6" className={classes.CardHeader}>
          Filter By
        </Typography>
      </CardContent>

      <Divider />
      <div className={classes.root}>
        <ExpansionPanel expanded={expanded.priceAttributes}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
            onClick={() => handleExpansion("price")}
          >
            <Typography className={classes.heading}>Price</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <OrderFilter />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel expanded={expanded.searchAttributes}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
            onClick={() => handleExpansion("search")}
          >
            <Typography className={classes.heading}>
              Search Attributes
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <AttributeFilter />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </Card>
  );
};

export default React.memo(FilterBar);
