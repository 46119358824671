import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  singleInfoContainer: {
    marginBottom: theme.spacing(1),
  },
}));

const ConfirmOrder = (props) => {
  const classes = useStyles();
  const getListedAddress = (useraddress) => {
    const { line1, line2, line3, city, state, country, zipCode } = useraddress;
    let address = "";
    let space = ", ";
    let newadd = address.concat(
      line1,
      space,
      line2,
      space,
      line3,
      space,
      city,
      space,
      state,
      space,
      country,

      " - ",
      zipCode
    );
    return newadd;
  };
  return (
    <div className={classes.root}>
      <Grid
        container
        className={classes.userInfocontainer}
        justify="space-between"
      >
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <div className={classes.singleInfoContainer}>
            <Typography className={classes.title} variant="body1">
              Name
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {props.address.firstName} {props.address.lastName}
            </Typography>
          </div>
          <div className={classes.singleInfoContainer}>
            <Typography className={classes.title} variant="body1">
              Address
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {" "}
              {getListedAddress(props.address)}
            </Typography>
          </div>
          <div className={classes.singleInfoContainer}>
            <Typography className={classes.title} variant="body1">
              Shipping Method
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {" "}
              {props.shippingMethod}
            </Typography>
          </div>
          <div className={classes.singleInfoContainer}>
            <Typography className={classes.title} variant="body1">
              Shipping Charges
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {" "}
              {props.shippingCharges}
            </Typography>
          </div>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <div className={classes.singleInfoContainer}>
            <Typography className={classes.title} variant="body1">
              Email
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {props.email}
            </Typography>
          </div>
          <div className={classes.singleInfoContainer}>
            <Typography className={classes.title} variant="body1">
              Mobile
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {props.mobile}
            </Typography>
          </div>
          <div className={classes.singleInfoContainer}>
            <Typography className={classes.title} variant="body1">
              ETA
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {" "}
              Approx 7 Days
            </Typography>
          </div>
          <div className={classes.singleInfoContainer}>
            <Typography className={classes.title} variant="body1">
              Payment Method
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {" "}
              Card
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default ConfirmOrder;
// import React from "react";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import Paper from "@material-ui/core/Paper";
// import { useSelector } from "react-redux";
// const ConfirmOrder = () => (
//   <TableContainer component={Paper} elevation={0}>
//     <Table aria-label="simple table">
//       <TableBody>
//         <TableRow>
//           <TableCell>PERSONAL DETAILS</TableCell>
//           <TableCell>JohoshaphatTse</TableCell>
//         </TableRow>
//         <TableRow>
//           <TableCell>Email</TableCell>
//           <TableCell>tsejx@foxmail.com</TableCell>
//         </TableRow>
//         <TableRow>
//           <TableCell>Home Address</TableCell>
//           <TableCell>San Francisco Street 5 No.101</TableCell>
//         </TableRow>
//         <TableRow>
//           <TableCell>DELIVERY COMPANY</TableCell>
//           <TableCell>SF DELIVERY</TableCell>
//         </TableRow>
//         <TableRow>
//           <TableCell>DELIVERY NOTIFICATION</TableCell>
//           <TableCell>952768</TableCell>
//         </TableRow>
//         <TableRow>
//           <TableCell>ORDER VALUE</TableCell>
//           <TableCell>$1000</TableCell>
//         </TableRow>
//       </TableBody>
//     </Table>
//   </TableContainer>
// );

// export default ConfirmOrder;
