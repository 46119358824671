import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  gridItemContainer: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: "1.3rem",
    fontWeight: 500,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
  },
}));
const TitleContainer = ({ open, setOpen }) => {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      lg={12}
      sm={12}
      xs={12}
      md={12}
      justify="space-between"
      alignItems="center"
      className={classes.gridItemContainer}
    >
      <Grid item>
        <Typography className={classes.title}>Manage Addresses</Typography>
      </Grid>
      <Grid item>
        {!open && (
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            onClick={() => setOpen()}
          >
            Add New Address
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
export default TitleContainer;
