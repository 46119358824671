import React, { useState } from "react";

import PT from "prop-types";
import {
  Typography,
  Divider,
  Grid,
  makeStyles,
  Radio,
  Button,
  Card,
  CardHeader,
  CardContent,
  Hidden,
  Box,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useSelector } from "react-redux";
const propTypes = {
  handleShippingConfirm: PT.func,
};
const useStyles = makeStyles((theme) => ({
  table: {
    border: "1px solid red",
  },
  Card: {
    maxHeight: "80vh",
    overflow: "auto",
  },
  shippingbtnmobile: {
    marginTop: theme.spacing(2),
  },
}));

const ConfirmStep = (props) => {
  const [shippingId, setShipping] = useState(
    useSelector((state) => state.shop.shipmentMethodId)
  );
  const handleChange = (e) => setShipping(e.target.value);
  const shippingData = useSelector((state) => state.shop.shippingMethods);
  const classes = useStyles();
  return (
    <Grid container spacing={5}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Card className={classes.Card}>
          <CardHeader title="Choose Shipping Method" />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item lg={12} xs={12}>
                <TableContainer component={Paper} elevation={0}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {" "}
                          <Typography>Shipping Method</Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography>Type</Typography>
                        </TableCell>

                        <TableCell>Charges</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {shippingData.map((ele, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              <Radio
                                color="primary"
                                checked={ele.id == shippingId}
                                onChange={(e) => handleChange(e)}
                                value={ele.id}
                              />
                              {ele.name}
                            </TableCell>
                            <TableCell align="left">
                              <Typography>{ele.description}</Typography>
                            </TableCell>

                            <TableCell>{ele.charges}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        container
        justify="space-between"
      >
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => props.back()}
          >
            Back
          </Button>
        </Grid>

        <Hidden mdDown>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              disabled={typeof shippingId == "undefined"}
              onClick={() => props.handleShippingConfirm(shippingId)}
            >
              Confirm Shipping Method
            </Button>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item xs={12} sm={12}>
            <Box className={classes.shippingbtnmobile}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                disabled={typeof shippingId == "undefined"}
                onClick={() => props.handleShippingConfirm(shippingId)}
              >
                Confirm Shipping Method
              </Button>
            </Box>
          </Grid>
        </Hidden>
      </Grid>
      {/* <Hidden mdDown>
        <Grid item lg={5}>
          <OrderBar />
        </Grid>
      </Hidden> */}
    </Grid>
  );
};

ConfirmStep.propTypes = propTypes;

export default ConfirmStep;
