import React from "react";
import PT from "prop-types";
import {
  incrementCartQuantity,
  decrementCartQuantity,
  removeProductFromCart,
  openPreferenceModal,
  openConfigurationModal,
} from "../../redux/selector";
import { connect, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  Grid,
  IconButton,
  Divider,
  Hidden,
  Container,
  Link,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteForeverOutlined";
import PlusIcon from "@material-ui/icons/AddCircle";
import MinusIcon from "@material-ui/icons/RemoveCircle";
import { withRouter } from "react-router-dom";
import ConfigurationModal from "../Modals/ConfigurationModal";
import PreferenceModal from "../Modals/PreferenceModal";
const useStyles = makeStyles((theme) => ({
  quantity: {
    borderColor: theme.palette.secondary,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  cover: {
    width: 100,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: 150,
    },
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  imgContainer: {},
  productInfoContainer: {
    paddingLeft: theme.spacing(1),
  },
  container: {},
  productName: {
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
    },
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  removeIcon: {
    position: "relative",
    top: -63,
    left: 300,
  },
  removeContainer: {
    padding: "1vh 0vw",
  },
  btnContainer: {
    height: "6vh",
  },
}));

const propTypes = {
  id: PT.number,
  name: PT.string,
  marketPrice: PT.number,
  unitPrice: PT.number,
  srcImg: PT.string,
  selectedOptions: PT.object,
  quantity: PT.number,
  configurationId: PT.number,
  specificationId: PT.number,
  bomId: PT.number,
};

const OrderItem = (props) => {
  const dispatch = useDispatch();
  const [openConfigModal, setConfigModal] = React.useState(false);
  const [openPrefModal, setPrefModal] = React.useState(false);

  const classes = useStyles();
  const {
    id,
    name,
    unitPrice,
    srcImg,
    selectedOptions,

    quantity,
    configurationId,
    specificationId,
    bomId,
    type,
    category,
    taxRate,
    subcategory,
    browsingName,
    defaultImage,
    itemIndex,
    item,
    priceBands,
    productId,
  } = props;

  const optionKeys = Object.keys(selectedOptions);
  const totalprice = quantity * unitPrice;

  const handleNavigate = () => {
    props.history.push(`/product/${browsingName}/${productId}`);
  };
  return (
    <Container>
      <Grid container className={classes.container}>
        <Grid
          item
          lg={3}
          xs={12}
          container
          justify="center"
          alignItems="center"
          className={classes.imgContainer}
        >
          <img
            onClick={() => handleNavigate()}
            className={classes.cover}
            src={defaultImage}
          />
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="flex-start"
          justify="space-evenly"
          lg={7}
          xs={12}
          className={classes.productInfoContainer}
        >
          <Grid item>
            <Typography
              variant="h5"
              className={classes.productName}
              component={Link}
              onClick={() => handleNavigate()}
            >
              {name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" color="textPrimary">
              {"UnitPrice : ₹"}
              {unitPrice}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" color="textPrimary">
              {"TAX : "}
              {taxRate} %
            </Typography>
          </Grid>
          {optionKeys.map((ele, index) =>
            optionKeys[index] === "QUANTITY" ? null : (
              <Grid item key={index}>
                <Typography variant="subtitle1" color="textPrimary">
                  {optionKeys[index]}: {selectedOptions[optionKeys[index]]}
                </Typography>
              </Grid>
            )
          )}
          {optionKeys.map((ele, index) =>
            optionKeys[index] === "QUANTITY" ? (
              <Grid item key={index}>
                <Grid container alignItems="center" direction="row">
                  <Grid item>
                    <Typography variant="subtitle1" color="textPrimary">
                      {optionKeys[index]} :
                    </Typography>
                  </Grid>
                  <Grid item>
                    {priceBands ? (
                      <Typography>
                        {" "}
                        {selectedOptions[optionKeys[index]]}
                      </Typography>
                    ) : (
                      <Grid container>
                        <Grid item>
                          <IconButton
                            size="small"
                            color="secondary"
                            disabled={selectedOptions.QUANTITY < 2}
                            onClick={() => {
                              dispatch(
                                decrementCartQuantity({ id, selectedOptions })
                              );
                            }}
                          >
                            <MinusIcon />
                          </IconButton>
                        </Grid>
                        <Grid
                          item
                          className={classes.quantity}
                          // alignItems="center"
                          // justify="center"
                        >
                          <Typography
                            align="center"
                            variant="subtitle1"
                            color="textPrimary"
                          >
                            {selectedOptions[optionKeys[index]]}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => {
                              dispatch(
                                incrementCartQuantity({ id, selectedOptions })
                              );
                            }}
                          >
                            <PlusIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : null
          )}
          <Grid item className={classes.removeContainer}>
            <Button
              color="primary"
              variant="text"
              startIcon={<DeleteIcon />}
              onClick={() => {
                dispatch(removeProductFromCart(id, item));
                props.removeItem();
              }}
            >
              Remove
            </Button>
          </Grid>
        </Grid>
        <Grid item container direction="column" lg={2}>
          <Grid item className={classes.btnContainer}>
            {specificationId && (
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                disabled={!specificationId}
                // onClick={() => dispatch(openPreferenceModal())}
                onClick={() => setPrefModal(true)}
              >
                Preference
              </Button>
            )}
          </Grid>

          <Grid item className={classes.btnContainer}>
            {configurationId && (
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                disabled={!configurationId}
                //onClick={() => dispatch(openConfigurationModal())}
                onClick={() => setConfigModal(true)}
              >
                Configuration
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      {openConfigModal && (
        <ConfigurationModal
          id={id}
          productId={productId}
          selectedOptions={selectedOptions}
          configurationId={configurationId}
          open={true}
          closeModal={() => setConfigModal(false)}
        />
      )}
      {openPrefModal && (
        <PreferenceModal
          id={id}
          productId={productId}
          selectedOptions={selectedOptions}
          specificationId={specificationId}
          open={openPrefModal}
          closeModal={() => setPrefModal(false)}
        />
      )}
    </Container>
  );
};

OrderItem.propTypes = propTypes;

export default withRouter(OrderItem);
