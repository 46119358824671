import React from "react";
import { withStyles, useTheme } from "@material-ui/core/styles";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useDispatch } from "react-redux";
import { consentaction } from "../../redux/action/historyActions";

const styles = (theme) => ({
  root: {
    background: "#2B373B",
    height: "10vh",
    [theme.breakpoints.down("md")]: {
      height: "40vh",
      width: "100vw",
    },
  },
  buttonStyle: { color: "#4e503b", fontSize: "13px" },
});
const CookieDialog = (props) => {
  const { classes } = props;
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="CookieConsent"
        style={styles(theme).root}
        buttonStyle={styles(theme).buttonStyle}
        expires={150}
        enableDeclineButton
        onAccept={() => {
          dispatch(consentaction(true));
        }}
        onDecline={() => dispatch(consentaction(false))}
      >
        This website uses cookies to enhance the user experience.{" "}
      </CookieConsent>
    </div>
  );
};

export default withStyles(styles)(CookieDialog);

// import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import CookieConsent, { Cookies } from "react-cookie-consent";
// import { useDispatch } from "react-redux";
// import { consentaction } from "../../redux/action/historyActions";
// const useStyles = makeStyles((theme) => ({
//   root: {},
// }));
// const CookieDialog = (props) => {
//   const classes = useStyles();
//   const dispatch = useDispatch();
//   const width = window.innerWidth;

//   return (
//     <div>
//       <CookieConsent
//         location="bottom"
//         buttonText="Accept"
//         cookieName="CookieConsent"
//         style={{
//           background: "#2B373B",
//           height: width < 768 ? "18vh" : "10vh",
//           fontFamily: "Roboto",
//           position: "fixed",
//           bottom: 0,
//         }}
//         contentStyle={{ fontFamily: "Roboto", fontSize: "1rem" }}
//         buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
//         expires={150}
//         enableDeclineButton
//         onAccept={() => {
//           dispatch(consentaction(true));
//         }}
//         onDecline={() => dispatch(consentaction(false))}
//       >
//         This website uses cookies to enhance the user experience.{" "}
//       </CookieConsent>
//     </div>
//   );
// };

// export default CookieDialog;
