import React from "react";

import {
  Container,
  makeStyles,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { resetUserPassword } from "../../redux/action/useractions";
const useStyles = makeStyles((theme) => ({
  signUp: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1),
  },
  Card: {
    width: "100%",
    borderRadius: 0,
  },
}));
const RegisterSite = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.user.userId);
  const [current, setCurrent] = React.useState("");
  const [newpass, setNewpass] = React.useState("");
  const [renewpass, setRenewPass] = React.useState("");

  const handlePassReset = () => {
    newpass === renewpass
      ? dispatch(resetUserPassword(current, newpass, userId))
      : alert("both new passwords do not match");
  };
  return (
    <Card elevation={0} className={classes.Card}>
      <CardHeader
        title="Reset Password"
        subheader="Enter Following details here"
        style={{ textAlign: "center" }}
      />
      <CardContent>
        <Grid container spacing={2} justify="center">
          <Grid item lg={10} sm={12} md={10} xs={12}>
            <TextField
              id="outlined-basic"
              label="Current Password"
              variant="outlined"
              fullWidth
              value={current}
              onChange={(e) => setCurrent(e.target.value)}
            />
          </Grid>
          <Grid item lg={10} sm={12} md={10} xs={12}>
            <TextField
              id="outlined-basic"
              label="New Password"
              variant="outlined"
              type="password"
              fullWidth
              value={newpass}
              onChange={(e) => setNewpass(e.target.value)}
            />
          </Grid>
          <Grid item lg={10} sm={12} md={10} xs={12}>
            <TextField
              id="outlined-basic"
              label="Re-enter New Password"
              variant="outlined"
              type="password"
              fullWidth
              value={renewpass}
              onChange={(e) => setRenewPass(e.target.value)}
            />
          </Grid>
          {/* <Grid item lg={10} sm={12} md={10} xs={12}>
            <TextField
              id="outlined-basic"
              label="Confirm Password"
              variant="outlined"
              fullWidth
            />
          </Grid> */}
          <Grid item lg={6} sm={6} md={6} xs={6}>
            <Button
              fullWidth
              className={classes.signUp}
              size="large"
              variant="contained"
              color="primary"
              onClick={() => handlePassReset()}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RegisterSite;
