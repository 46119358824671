import { activeProductTypes, shopTypes } from "../types";
import { ROOT_URL } from "../utils/utils";

const RootUrl = ROOT_URL;
const subcategoryproducturl = (id) => {
  return `${RootUrl}ECOM/Product/products/${id}`;
};

export function addProductToCart(product) {
  console.log("add product to cart");
  return {
    type: shopTypes.ADD_PRODUCT_TO_CART,
    payload: {
      request: {
        method: "post",
        url: `${RootUrl}ECOM/ShoppingCart/save`,
        data: product,
      },
    },
  };
}
export function loadAllProducts() {
  return {
    type: shopTypes.LOAD_ALL_PRODUCTS,
  };
}

// export function removeProductFromCart(productId, item) {
//   return {
//     type: shopTypes.REMOVE_PRODUCT_FROM_CART,
//     payload: {
//       request: {
//         method: "delete",
//         url: `${RootUrl}ECOM/ShoppingCart/del/${productId}`,
//         data: item,
//       },
//     },
//   };
// }
export function removeProductFromCart(productId, item) {
  return {
    type: shopTypes.REMOVE_PRODUCT_FROM_CART,
    payload: {
      request: {
        method: "delete",
        url: `${RootUrl}ECOM/ShoppingCart/del/${productId}`,
        data: item,
      },
    },
  };
}
export function incrementCartQuantity(product) {
  return {
    type: "INCREMENT_CART_ITEM_QUANTITY",
    payload: product,
  };
}

export function decrementCartQuantity(product) {
  return {
    type: "DECREMENT_CART_ITEM_QUANTITY",
    payload: product,
  };
}

// export function addProductToFavourites(product) {
//   return {
//     type: "ADD_TO_FAVOURITES",
//     payload: product,
//   };
// }

export const addProductToFavourites = (data) => {
  return {
    type: shopTypes.ADD_PRODUCT_TO_FAVORITES,
    payload: {
      request: {
        method: "post",
        url: `${RootUrl}ECOM/Wishlist/save`,
        data: data,
      },
    },
  };
};

export function removeProductFromFavourites(product) {
  return {
    type: shopTypes.REMOVE_PRODUCT_FROM_FAVORITES,
    payload: {
      request: {
        method: "delete",
        url: `${RootUrl}ECOM/Wishlist/del/${product.id}`,
        data: product,
      },
    },
  };
}

export function moveProductToCart(product) {
  return {
    type: "MOVE_TO_CART",
    payload: product,
  };
}

export function addBrandToFilter(brand) {
  return {
    type: "ADD_BRAND_TO_FILTER",
    brand,
  };
}

export function removeBrandFromFilter(brand) {
  return {
    type: "REMOVE_BRAND_FROM_FILTER",
    brand,
  };
}

export function orderByAsc() {
  return {
    type: "ORDER_BY_ASC",
  };
}

export function orderByDesc() {
  return {
    type: "ORDER_BY_DESC",
  };
}

export function clearOrderBy() {
  return {
    type: "CLEAR_ORDER_BY_PRICE",
  };
}

export function nextPage() {
  return {
    type: "NEXT_PAGE",
  };
}

export function prevPage() {
  return {
    type: "PREV_PAGE",
  };
}

export function goPage(n) {
  return {
    type: "GO_PAGE",
    currentPage: n,
  };
}

export function countItem(n) {
  return {
    type: "COUNT_ITEM",
    totalItemsCount: n,
  };
}

export function selectType(type, index) {
  return {
    type: "SELECT_TYPE",
    payload: { type, index },
  };
}

export function selectMainCategory(mainCategory, index) {
  return {
    type: "SELECT_MAINCAT",
    payload: { mainCategory, index },
  };
}

export function selectSubCategory(subcategory, id, index) {
  return {
    type: "SELECT_SUBCAT",
    payload: { subcategory, id, index },
  };
}
// export function selectSubCategory(subcategory, index, products) {
//   console.log("SELECT_SUBCAT DISPATHED", subcategory, index, products);
//   return {
//     type: "SELECT_SUBCAT",
//     payload: { subcategory, index, products }
//   };
// }

export function getSubCategoryProducts(id) {
  return {
    type: "GET_SUBCATEGORY_PRODUCTS",
    payload: {
      request: {
        url: subcategoryproducturl(id),
        method: "GET",
        // headers: {
        //   "Access-Control-Allow-Origin": "*",
        // },
      },
    },
  };
}
// export function selectSubCategory(subcategory, index, products) {
//   console.log("SELECT_SUBCAT DISPATHED", subcategory, index, products);
//   return {
//     type: "SELECT_SUBCAT",
//     payload: { subcategory, index, products }
//   };
// }

export function filterProducts(data) {
  return {
    type: shopTypes.FILTER_PRODUCTS,
    payload: { data },
  };
}

export function openPreferenceModal() {
  return {
    type: "OPEN_PREFERENCE_MODEL",
  };
}

export function closePreferenceModal() {
  return {
    type: "CLOSE_PREFERENCE_MODEL",
  };
}

export function openConfigurationModal() {
  return {
    type: "OPEN_CONFIGURATION_MODEL",
  };
}

export function closeConfigurationModal() {
  return {
    type: "CLOSE_CONFIGURATION_MODEL",
  };
}

export function setProductPreferences(preferences) {
  return {
    type: "SET_PRODUCT_PREFERENCE",
    payload: preferences,
  };
}

export function setProductConfiguration(configuration) {
  return {
    type: "SET_PRODUCT_CONFIGURATION",
    payload: configuration,
  };
}

export function loadCartFavourites() {
  return {
    type: "LOAD_CART_FAVOURITES",
  };
}

export function setShippingAddresss(addressId) {
  return {
    type: "SET_SHIPPING_ADDRESS",
    payload: { addressId },
  };
}

export function setPaymentDetails(paymentMethodId, paymentId, verificationCode) {
  return {
    type: "SET_PAYMENT_DETAILS",
    payload: {
      paymentMethodId: paymentMethodId,
      paymentId: paymentId,
      verificationCode: verificationCode,
    },
  };
}

export function setShipmentMethod(shipmentMethodId) {
  return {
    type: "SET_SHIPMENT_METHOD",
    payload: { shipmentMethodId },
  };
}

export function submitConfirmOrder(data) {
  return {
    type: "SUBMIT_CONFIRM_ORDER",
    payload: {
      request: {
        method: "post",
        url: `${RootUrl}ECOM/EcomOrder/create`,
        data: data,
        ...{ headers: { "Content-Type": "application/json" } },
      },
    },
  };
}

export function getSingleProduct(id) {
  return {
    type: activeProductTypes.GET_ACTIVE_PRODUCT,
    payload: {
      request: {
        method: "GET",
        url: `${RootUrl}ECOM/Product/${id}`,
      },
    },
  };
}
export const getUserCart = () => {
  return {
    type: shopTypes.GET_USER_CART,
    payload: {
      request: {
        method: "GET",
        url: `${RootUrl}ECOM/ShoppingCart/myCart`,
      },
    },
  };
};

export const getUserFavourites = () => {
  return {
    type: shopTypes.GET_USER_FAVORITES,
    payload: {
      request: {
        method: "GET",
        url: `${RootUrl}ECOM/Wishlist/myWishlist`,
      },
    },
  };
};
export const searchProducts = (query) => {
  return {
    type: shopTypes.SEARCH_PRODUCT,
    payload: {
      request: {
        method: "get",
        url: `${RootUrl}ECOM/Product/multisearch?searchText=${query}`,
      },
    },
  };
};
export const addCommentToProduct = (data) => {
  return {
    type: shopTypes.ADD_COMMENT_TO_PRODUCT,
    payload: {
      request: {
        method: "post",
        url: `${RootUrl}ECOM/ProductReview/save`,
        data: data,
      },
    },
  };
};

export const requestReturnProduct = (data) => {
  return {
    type: shopTypes.RETURN_PRODUCT,
    payload: {
      request: {
        method: "post",
        url: `${RootUrl}ECOM/OrderReturn/save`,
        data: data,
      },
    },
  };
};
export const getShippingMethods = () => {
  return {
    type: shopTypes.GET_SHIPPING_METHODS,
    payload: {
      request: {
        method: "GET",
        url: `${RootUrl}SHIPPING/ShippingMethod/all`,
      },
    },
  };
};
