import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Popper,
  Button,
  Card,
  Paper,
  Grid,
  TextField,
  IconButton,
  Divider,
  CardContent,
  CardActions,
  Checkbox,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1000,
  },
  searchPaper: {
    width: 500,
  },
  gridItem: {
    padding: theme.spacing(3),
  },
  card: {
    minWidth: "40vw",

    width: "auto",
    maxWidth: "70vw",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",

    width: "100%",
    flexWrap: "wrap",
    height: "50vh",
    overflowX: "auto",
  },
  differentBg: {
    backgroundColor: theme.palette.grayBackground,
  },
  ListItem: {
    // maxHeight: "5vh",
    maxWidth: "15vw",
    // padding: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
  },
}));

const ComplexMenu = (props) => {
  const classes = useStyles();
  const { complexLabels, labels, handleToggle, checked, input } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState("");
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const [listLabel, setListLabel] = React.useState([]);
  React.useEffect(() => {
    setListLabel(labels);
  }, [labels]);

  const handleSearch = (value) => {
    if (value === "") {
      setListLabel(labels);
      setSearchValue("");
    } else {
      //   console.log(value, "sarchvalue");
      setSearchValue(value);
      let newArray = listLabel.filter((data) =>
        data.toLowerCase().includes(value.toLowerCase())
      );
      //   console.log(value, "sarchvalue");
      //   console.log(newArray, "New Array");
      setListLabel(newArray);
    }
  };
  return (
    <div>
      <Button
        aria-describedby="searchcheckbox"
        color="secondary"
        variant="text"
        onClick={(e) => handleClick(e)}
      >
        {labels.length - 5} More
      </Button>
      <Popper
        id="searcheckbox"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="right"
        transition
        className={classes.root}
      >
        <Card className={classes.card}>
          <CardContent className={classes.differentBg}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Search"
                  label="Search"
                  value={searchValue}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </Grid>
              <Grid item>
                <IconButton onClick={() => setAnchorEl(null)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </CardContent>

          <Divider />
          <CardContent>
            <div className={classes.contentContainer}>
              {listLabel.length > 0 &&
                listLabel.map((data, index) => {
                  return (
                    <Paper
                      key={index}
                      elevation={0}
                      className={classes.ListItem}
                      component={Button}
                      onClick={handleToggle(data)}
                    >
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(data) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": data }}
                        value={input.value}
                        name={input.name}
                      />
                      <Typography variant="body2">{data}</Typography>
                    </Paper>
                  );
                })}
            </div>
          </CardContent>
          <Divider />
          <CardActions className={classes.differentBg}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setAnchorEl(null)}
            >
              Apply Filter
            </Button>
            <Button variant="text" onClick={() => setAnchorEl(null)}>
              Close
            </Button>
          </CardActions>
        </Card>
      </Popper>
    </div>
  );
};
export default ComplexMenu;
