import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Input,
  FormControlLabel,
  Typography,
  InputLabel,
  FormHelperText,
  OutlinedInput,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      backgroundColor: "white",
    },
  },
}));

const Text = (props) => {
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      fullWidth
      // size="small"
    >
      <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
      <OutlinedInput
        {...props}
        inputProps={{
          min: props.minimumValue || 0,
          max: props.maximumValue || 1000000,

          pattern: props.mask,
          step: 1,
        }}
      />
      {/* <FormHelperText id="my-helper-text">
        We'll never share your email.
      </FormHelperText> */}
    </FormControl>
    // <FormControl variant="outlined" >
    // <FormControlLabel>Text</FormControlLabel>
    // <Input />
    // </FormControl>
  );
};
export default Text;
