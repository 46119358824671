import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListSubheader,
  ListItem,
  Divider,
  ListItemText,
  ListItemIcon,
  IconButton,
  ListItemSecondaryAction,
  Slide,
} from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import ShopIcon from "@material-ui/icons/ShoppingCart";
import sidebarMenu from "../../data/productData/categoryInfo.json";
import SubcategorySidebar from "./SubcategorySidebar";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ChevronRight";
import {
  selectMainCategory,
  selectType,
  getSubCategoryProducts,
  selectSubCategory,
} from "../../redux/selector";
import { useDispatch } from "react-redux";
import TypeMenu from "./MobileMenuList/TypeMenu";
import CategoryMenu from "./MobileMenuList/CategoryMenu";
import SubCategoryMenu from "./MobileMenuList/SubCategoryMenu";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    height: "100vh",
  },
  list: {
    width: "100%",

    backgroundColor: theme.palette.background.default,
  },
  titleContainer: {},
  ListSubheader: {
    border: "1px solid green",
  },
  categories: {
    color: theme.palette.text.primary,

    fontSize: 16,
    fontWeight: 500,
  },
  link: {
    fontSize: 15,
    color: theme.palette.text.primary,
    textTransform: "Capitalize",
  },
}));
const menuItems = [
  {
    link: "ladies",
    path: "/ladies",
  },
  {
    link: "men",
    path: "/men",
  },
  {
    link: "kids",
    path: "/kids",
  },
  {
    link: "home",
    path: "/",
  },
];

const SidebarMenu = (props) => {
  const classes = useStyles();
  const [subcatOpen, setSubcatOpen] = React.useState(false);
  // const [activeItem, setActiveItem] = React.useState(null);
  const [selectedType, setSelectedType] = React.useState(null);
  const [selectedCat, setSelectedCat] = React.useState(null);
  const [selectedSubCat, setSelectedSubcat] = React.useState(null);

  const [menuOpen, setMenuOpen] = React.useState({
    typemenu: true,
    categorymenu: false,
    subcategorymenu: false,
  });

  const dispatch = useDispatch();

  const handleTypeClick = (type, index) => {
    setMenuOpen({
      typemenu: false,
      categorymenu: true,
      subcategorymenu: false,
    });
    setSelectedType(type);
    dispatch(selectType(type.name, index));
  };
  const handleCategoryClick = (category, index) => {
    setMenuOpen({
      typemenu: false,
      categorymenu: false,
      subcategorymenu: true,
    });
    setSelectedCat(category);
    dispatch(selectMainCategory(category, index));
  };
  const handleCategortBack = () => {
    setMenuOpen({
      typemenu: true,
      categorymenu: false,
      subcategorymenu: false,
    });
    setSelectedCat(null);
  };

  const handleSubCategoryClick = (subcat, index, id) => {
    // setMenuOpen({
    //   typemenu: true,
    //   categorymenu: false,
    //   subcategorymenu: false,
    // });
    props.history.push(`/category/${selectedCat.name}/${subcat.name}`);
    exitDrawer();

    // setSelectedSubcat(subcat);
    dispatch(selectSubCategory(subcat, id, index));
    dispatch(getSubCategoryProducts(id));
  };
  const handleSubCategortBack = () => {
    setMenuOpen({
      typemenu: false,
      categorymenu: true,
      subcategorymenu: false,
    });
    setSelectedSubcat(null);
  };
  const exitDrawer = () => {
    setMenuOpen({
      typemenu: true,
      categorymenu: false,
      subcategorymenu: false,
    });
    setSelectedType(null);
    setSelectedSubcat(null);
    setSelectedCat(null);
    props.close();
  };
  // const handleMainCategory = (category, index) => {
  //   dispatch(selectMainCategory(category, index));
  // };

  // const handleSubbarOpen = (item, index) => {
  //   setSubcatOpen(true);
  //   setActiveItem(item);
  //   // dispatch(selectType(item.name, index));
  // };

  // const handleSelectType = (item, index) => {
  //   dispatch(selectType(item.name, index));
  // };

  // const handleMainCategoryyClick = (e, category, index) => {
  //   dispatch(selectMainCategory(category, index));
  //   props.close(e);

  //   props.history.push(`/category/${category.productType}/${category.name}`);
  // };

  return (
    <div className={classes.root}>
      {menuOpen.typemenu && (
        <Slide
          in={menuOpen.typemenu}
          direction="left"
          mountOnEnter
          unmountOnExit
        >
          <List className={classes.titleContainer}>
            <ListItem>
              <ListItemIcon>
                <ShopIcon />
              </ListItemIcon>
              <ListItemText>Shop By Categories</ListItemText>
            </ListItem>
          </List>
        </Slide>
      )}
      <Divider />
      <div>
        {menuOpen.typemenu && (
          <Slide
            unmountOnExit
            mountOnEnter
            in={menuOpen.typemenu}
            direction="left"
          >
            <div>
              <TypeMenu
                typeMenu={sidebarMenu}
                handleTypeClick={(type, index) => handleTypeClick(type, index)}
              />
            </div>
          </Slide>
        )}
        {menuOpen.categorymenu && (
          <Slide
            unmountOnExit
            mountOnEnter
            in={menuOpen.categorymenu && selectedType}
            direction="left"
          >
            <div>
              <CategoryMenu
                showback={true}
                onClose={() => handleCategortBack()}
                categoryMenu={selectedType}
                handleCategoryClick={(category, index) =>
                  handleCategoryClick(category, index)
                }
              />
            </div>
          </Slide>
        )}
        {menuOpen.subcategorymenu && (
          <Slide
            unmountOnExit
            mountOnEnter
            in={menuOpen.subcategorymenu && selectedCat}
            direction="left"
          >
            <div>
              <SubCategoryMenu
                handleSubCategoryClick={(category, index, id) =>
                  handleSubCategoryClick(category, index, id)
                }
                onClose={() => handleSubCategortBack()}
                subcategorymenu={selectedCat}
              />
            </div>
          </Slide>
        )}
      </div>
    </div>
  );
};
export default withRouter(SidebarMenu);

// import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import {
//   List,
//   ListSubheader,
//   ListItem,
//   Divider,
//   ListItemText,
//   ListItemIcon,
// } from "@material-ui/core";
// import { Link, withRouter } from "react-router-dom";
// import ShopIcon from "@material-ui/icons/ShoppingCart";
// import sidebarMenu from "../../data/productData/categoryInfo.json";
// import SubcategorySidebar from "./SubcategorySidebar";
// import Accordion from "@material-ui/core/Accordion";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
// import Typography from "@material-ui/core/Typography";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import { selectMainCategory, selectType } from "../../redux/selector";
// import { useDispatch } from "react-redux";
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.paper,
//     borderRadius: 4,
//   },
//   list: {
//     width: "100%",

//     backgroundColor: theme.palette.background.default,
//   },
//   titleContainer: {},
//   ListSubheader: {
//     border: "1px solid green",
//   },
//   categories: {
//     color: theme.palette.text.primary,

//     fontSize: 16,
//     fontWeight: 500,
//   },
//   link: {
//     fontSize: 15,
//     color: theme.palette.text.primary,
//     textTransform: "Capitalize",
//   },
// }));
// const menuItems = [
//   {
//     link: "ladies",
//     path: "/ladies",
//   },
//   {
//     link: "men",
//     path: "/men",
//   },
//   {
//     link: "kids",
//     path: "/kids",
//   },
//   {
//     link: "home",
//     path: "/",
//   },
// ];

// const SidebarMenu = (props) => {
//   const classes = useStyles();
//   const [subcatOpen, setSubcatOpen] = React.useState(false);
//   const [activeItem, setActiveItem] = React.useState(null);

//   const dispatch = useDispatch();
//   const handleMainCategory = (category, index) => {
//     dispatch(selectMainCategory(category, index));
//   };

//   const handleSubbarOpen = (item, index) => {
//     setSubcatOpen(true);
//     setActiveItem(item);
//     // dispatch(selectType(item.name, index));
//   };

//   const handleSelectType = (item, index) => {
//     dispatch(selectType(item.name, index));
//   };

//   const handleMainCategoryyClick = (e, category, index) => {
//     dispatch(selectMainCategory(category, index));
//     props.close(e);

//     props.history.push(`/category/${category.productType}/${category.name}`);
//   };
//   let listMenu = sidebarMenu.map(function (item, typeIndex) {
//     return (
//       <div key={typeIndex}>
//         <Accordion>
//           <AccordionSummary
//             expandIcon={<ExpandMoreIcon />}
//             aria-controls="panel1a-content"
//             id="panel1a-header"
//             onClick={() => handleSelectType(item, typeIndex)}
//           >
//             <Typography className={classes.heading}>{item.name}</Typography>
//           </AccordionSummary>
//           <AccordionDetails>
//             <List disablePadding className={classes.list}>
//               {item.categoryList.map((category, index) => {
//                 return (
//                   <>
//                     <ListItem
//                       button
//                       className={classes.listItem}
//                       onClick={(e) =>
//                         handleMainCategoryyClick(e, category, index)
//                       }
//                     >
//                       <ListItemText primary={category.name} />
//                     </ListItem>
//                     <Divider />
//                   </>
//                 );
//               })}
//             </List>
//           </AccordionDetails>
//         </Accordion>
//         {/* <ListItem
//         key={typeIndex}
//         button
//         onClick={() => handleSubbarOpen(item, typeIndex)}
//       >

//         <ListItemText primary={item.name} />
//       </ListItem> */}
//       </div>
//     );
//   });
//   return (
//     <div className={classes.root}>
//       {/* <SubcategorySidebar
//         open={subcatOpen}
//         close={() => setSubcatOpen(false)}
//       /> */}

//       <List className={classes.titleContainer}>
//         <ListItem>
//           <ListItemIcon>
//             <ShopIcon />
//           </ListItemIcon>
//           <ListItemText>Shop By Categories</ListItemText>
//         </ListItem>
//       </List>
//       <Divider />
//       {listMenu}
//     </div>
//   );
// };
// export default withRouter(SidebarMenu);
