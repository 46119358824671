export const userTypes = {
  LOGIN_USER: "LOGIN_USER",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAIL: "LOGIN_USER_FAIL",

  REGISTER_USER: "REGISTER_USER",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  REGISTER_USER_FAIL: "REGISTER_USER_FAIL",

  LOGOUT_USER: "LOGOUT_USER",
  LOGOUT_USER_SUCCESS: "LOGOUT_USER_SUCCESS",
  LOGOUT_USER_FAIL: "LOGOUT_USER_FAIL",

  RESET_USER_PASSWORD: "RESET_USER_PASSWORD",
  RESET_USER_PASSWORD_SUCCESS: "RESET_USER_PASSWORD_SUCCESS",
  RESET_USER_PASSWORD_FAIL: "RESET_USER_PASSWORD_FAIL",

  GET_USER_ACCOUNT: "GET_USER_ACCOUNT",
  GET_USER_ACCOUNT_SUCCESS: "GET_USER_ACCOUNT_SUCCESS",
  GET_USER_ACCOUNT_FAIL: "GET_USER_ACCOUNT_FAIL",

  GET_USER_CART: "GET_USER_CART",
  GET_USER_CART_SUCCESS: "GET_USER_CART_SUCCESS",
  GET_USER_CART_FAIL: "GET_USER_CART_FAIL",

  SAVE_USER_CART: "SAVE_USER_CART",
  SAVE_USER_CART_SUCCESS: "SAVE_USER_CART_SUCCESS",
  SAVE_USER_CART_FAIL: "SAVE_USER_CART_FAIL",

  GET_USER_ADDRESS: "GET_USER_ADDRESS",
  GET_USER_ADDRESS_SUCCESS: "GET_USER_ADDRESS_SUCCESS",
  GET_USER_ADDRESS_FAIL: "GET_USER_ADDRESS_FAIL",

  SAVE_USER_ADDRESS: "SAVE_USER_ADDRESS",
  SAVE_USER_ADDRESS_SUCCESS: "SAVE_USER_ADDRESS_SUCCESS",
  SAVE_USER_ADDRESS_FAIL: "SAVE_USER_ADDRESS_FAIL",

  EDIT_USER_ADDRESS: "EDIT_USER_ADDRESS",
  EDIT_USER_ADDRESS_SUCCESS: "EDIT_USER_ADDRESS_SUCCESS",
  EDIT_USER_ADDRESS_FAIL: "EDIT_USER_ADDRESS_FAIL",

  DELETE_USER_ADDRESS: "DELETE_USER_ADDRESS",
  DELETE_USER_ADDRESS_SUCCESS: "DELETE_USER_ADDRESS_SUCCESS",
  DELETE_USER_ADDRESS_FAIL: "DELETE_USER_ADDRESS_FAIL",

  GET_USER_CARD: "GET_USER_CARD",
  GET_USER_CARD_SUCCESS: "GET_USER_CARD_SUCCESS",
  GET_USER_CARD_FAIL: "GET_USER_CARD_FAIL",

  SAVE_USER_CARD: "SAVE_USER_CARD",
  SAVE_USER_CARD_SUCCESS: "SAVE_USER_CARD_SUCCESS",
  SAVE_USER_CARD_FAIL: "SAVE_USER_CARD_FAIL",

  DELETE_USER_CARD: "DELETE_USER_CARD",
  DELETE_USER_CARD_SUCCESS: "DELETE_USER_CARD_SUCCESS",
  DELETE_USER_CARD_FAIL: "DELETE_USER_CARD_FAIL",

  GET_USER_FAVOURITES: "GET_USER_FAVOURITES",
  GET_USER_FAVOURITES_SUCCESS: "GET_USER_FAVOURITES_SUCCESS",
  GET_USER_FAVOURITES_FAIL: "GET_USER_FAVOURITES_FAIL",

  SAVE_USER_FAVOURITE: "SAVE_USER_FAVOURITE",
  SAVE_USER_FAVOURITE_SUCCESS: "SAVE_USER_FAVOURITE_SUCCESS",
  SAVE_USER_FAVOURITE_FAIL: "SAVE_USER_FAVOURITE_FAIL",

  GET_USER_ORDERS: "GET_USER_ORDERS",
  GET_USER_ORDERS_SUCCESS: "GET_USER_ORDERS_SUCCESS",
  GET_USER_ORDERS_FAIL: "GET_USER_ORDERS_FAIL",

  SAVE_USER_ORDER: "SAVE_USER_ORDER",
  SAVE_USER_ORDER_SUCCESS: "SAVE_USER_ORDER_SUCCESS",
  SAVE_USER_ORDER_FAIL: "SAVE_USER_ORDER_FAIL",

  SAVE_BASIC_INFO: "SAVE_BASIC_INFO",
  SAVE_ADDRESSES: "SAVE_ADDRESSES",
  SAVE_SAVED_CARDS: "SAVE_SAVED_CARDS",
  RESET_PASSWORD: "RESET_PASSWORD",

  REGISTER_USER: "REGISTER_USER",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  REGISTER_USER_FAIL: "REGISTER_USER_FAIL",

  SAVE_USER_TOKEN: "SAVE_USER_TOKEN",

  LOCAL_LOGIN_USER: "LOCAL_LOGIN_USER",

  GET_TRACKING_DETAILS: "GET_TRACKING_DETAILS",
  GET_TRACKING_DETAILS_SUCCESS: "GET_TRACKING_DETAILS_SUCCESS",
  GET_TRACKING_DETAILS_FAIL: "GET_TRACKING_DETAILS_FAIL",

  REMOVE_TRACKING_DETAILS: "REMOVE_TRACKING_DETAILS",
};
export const activeProductTypes = {
  GET_ACTIVE_PRODUCT: "GET_ACTIVE_PRODUCT",
  GET_ACTIVE_PRODUCT_SUCCESS: "GET_ACTIVE_PRODUCT_SUCCESS",
  GET_ACTIVE_PRODUCT_FAIL: "GET_ACTIVE_PRODUCT_FAIL",

  GET_ACTIVE_PRODUCT_COMMENTS: "GET_ACTIVE_PRODUCT_COMMENTS",
  GET_ACTIVE_PRODUCT_COMMENTS_SUCCESS: "GET_ACTIVE_PRODUCT_COMMENTS_SUCCESS",
  GET_ACTIVE_PRODUCT_COMMENTS_FAIL: "GET_ACTIVE_PRODUCT_COMMENTS_FAIL",
};

export const shopTypes = {
  LOAD_ALL_PRODUCTS: "LOAD_ALL_PRODUCTS",
  GET_USER_CART: "GET_USER_CART",
  GET_USER_CART_SUCCESS: "GET_USER_CART_SUCCESS",
  GET_USER_CART_FAIL: "GET_USER_CART_FAIL",

  ADD_PRODUCT_TO_CART: "ADD_PRODUCT_TO_CART",
  ADD_PRODUCT_TO_CART_SUCCESS: "ADD_PRODUCT_TO_CART_SUCCESS",
  ADD_PRODUCT_TO_CART_FAIL: "ADD_PRODUCT_TO_CART_FAIL",

  REMOVE_PRODUCT_FROM_CART: "REMOVE_PRODUCT_FROM_CART",
  REMOVE_PRODUCT_FROM_CART_SUCCESS: "REMOVE_PRODUCT_FROM_CART_SUCCESS",
  REMOVE_PRODUCT_FROM_CART_FAIL: "REMOVE_PRODUCT_FROM_CART_FAIL",

  GET_USER_FAVORITES: "GET_USER_FAVORITES",
  GET_USER_FAVORITES_SUCCESS: "GET_USER_FAVORITES_SUCCESS",
  GET_USER_FAVORITES_FAIL: "GET_USER_FAVORITES_FAIL",

  ADD_PRODUCT_TO_FAVORITES: "ADD_PRODUCT_TO_FAVORITES",
  ADD_PRODUCT_TO_FAVORITES_SUCCESS: "ADD_PRODUCT_TO_FAVORITES_SUCCESS",
  ADD_PRODUCT_TO_FAVORITES_FAIL: "ADD_PRODUCT_TO_FAVORITES_FAIL",

  REMOVE_PRODUCT_FROM_FAVORITES: "REMOVE_PRODUCT_FROM_FAVORITES",
  REMOVE_PRODUCT_FROM_FAVORITES_SUCCESS:
    "REMOVE_PRODUCT_FROM_FAVORITES_SUCCESS",
  REMOVE_PRODUCT_FROM_FAVORITES_FAIL: "REMOVE_PRODUCT_FROM_FAVORITES_FAIL",

  SEARCH_PRODUCT: "SEARCH_PRODUCT",
  SEARCH_PRODUCT_SUCCESS: "SEARCH_PRODUCT_SUCCESS",
  SEARCH_PRODUCT_FAIL: "SEARCH_PRODUCT_FAIL",

  FILTER_PRODUCTS: "FILTER_PRODUCTS",

  ADD_COMMENT_TO_PRODUCT: "ADD_COMMENT_TO_PRODUCT",
  ADD_COMMENT_TO_PRODUCT_SUCCESS: "ADD_COMMENT_TO_PRODUCT_SUCCESS",
  ADD_COMMENT_TO_PRODUCT_FAIL: "ADD_COMMENT_TO_PRODUCT_FAIL",

  RETURN_PRODUCT: "RETURN_PRODUCT",
  RETURN_PRODUCT_SUCCESS: "RETURN_PRODUCT_SUCCESS",
  RETURN_PRODUCT_FAIL: "RETURN_PRODUCT_FAIL",
  GET_SHIPPING_METHODS: "GET_SHIPPING_METHODS",
  GET_SHIPPING_METHODS_SUCCESS: "GET_SHIPPING_METHODS_SUCCESS",
  GET_SHIPPING_METHODS_FAIL: "GET_SHIPPING_METHODS_FAIL",
};
export const utilTypes = {
  TOGGLE_LOGIN_MODAL: "TOGGLE_LOGIN_MODAL",
  CLOSE_LOGIN_MODAL: "CLOSE_LOGIN_MODAL",

  GET_USER_LOCATION: "GET_USER_LOCATION",
  GET_USER_LOCATION_SUCCESS: "GET_USER_LOCATION_SUCCESS",
  GET_USER_LOCATION_FAIL: "GET_USER_LOCATION_FAIL",

  PUSH_USER_HISTORY: "PUSH_USER_HISTORY",
  PUSH_USER_HISTORY_SUCCESS: "PUSH_USER_HISTORY_SUCCESS",
  PUSH_USER_HISTORY_FAIL: "PUSH_USER_HISTORY_FAIL",

  GET_VISITOR_TOKEN: "GET_VISITOR_TOKEN",
  GET_VISITOR_TOKEN_SUCCESS: "GET_VISITOR_TOKEN_SUCCESS",
  GET_VISITOR_TOKEN_FAIL: "GET_VISITOR_TOKEN_FAIL",

  CONSENT_ACTION: "CONSENT_ACTION",
  PUSH_TO_HISTORY: " PUSH_TO_HISTORY",
  UPLOAD_HISTORY: "UPLOAD_HISTORY",
  UPLOAD_HISTORY_SUCCESS: "UPLOAD_HISTORY_SUCCESS",
  UPLOAD_HISTORY_FAIL: "UPLOAD_HISTORY_FAIL",

  SET_NEWSLETTER: "SET_NEWSLETTER",
};
