import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ProductContainer from "./ProductContainer";
import axios from "axios";
import {
  Container,
  Grid,
  Hidden,
  AppBar,
  Toolbar,
  Button,
} from "@material-ui/core";
import { Route, Switch, useParams, useLocation } from "react-router-dom";
import SidebarMenu from "../nav/SidebarMenu";
import { connect, useSelector, useDispatch } from "react-redux";

import SortBarMobile from "./SortBarMobile";
import FilterBar from "../FilterBar/FilterBar";
import { pushtohistory } from "../../redux/action/historyActions";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.grayBackground,
  },
  Container: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  img: {
    width: "100%",
    height: "40vh",
    objectFit: "cover",
    borderRadius: 4,
    [theme.breakpoints.down("md")]: {
      height: "30vh",
    },
  },
}));

const FilterSite = (props) => {
  const classes = useStyles();

  // const [dataProducts, setdataProducts] = useState([]);
  const [nameProList, setnameProList] = useState("Category Name");

  const currentPath = props.match.url;
  const dataHotSaleProduct = useSelector((state) => state.shop.products);
  // function handleGetData(path) {
  //   console.log("handelgetdatat runnig");
  //   setdataProducts(dataHotSaleProduct);
  // }

  // function handleProList(path, nameProList) {
  //   handleGetData(path);

  //   setnameProList(nameProList.toUpperCase());
  // }
  //stub

  const { stub } = useParams();
  const location = useLocation().pathname;
  const dispatch = useDispatch();

  React.useEffect(() => {
    let newlocation = location === "/" ? location : location.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));

    const arrSitePath = props.location.pathname;
    console.log(arrSitePath, "arrSitePath");

    if (arrSitePath[0]) {
      // handleGetData(arrSitePath.join(""));
      // let string = arrSitePath[0];
      setnameProList(arrSitePath.replace("/", ""));
    }
  }, []);

  const FilterSiteDesktop = () => {
    return (
      <div className={classes.root}>
        <Container maxWidth="xl" className={classes.Container}>
          <Grid container spacing={5}>
            <Hidden smDown>
              <Grid item lg={12} sm={12} md={12} xs={12}>
                <img
                  src="https://source.unsplash.com/random"
                  className={classes.img}
                />
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Grid item lg={3} sm={3} md={3} xs={3}>
                <FilterBar />
                {/* <Route
                children={({ match, location }) => {
                  return (
                    <SidebarMenu
                      match={match}
                      location={location}
                      handleProList={handleProList}
                    />
                  );
                }}
              /> */}
              </Grid>
            </Hidden>
            <Grid item lg={9} sm={12} md={9} xs={12}>
              <Switch>
                <Route
                  exact
                  path={currentPath}
                  children={({ match, location }) => {
                    return (
                      <ProductContainer
                        location={location}
                        dataProducts={dataHotSaleProduct}
                        showcaseHeader={nameProList}
                      />
                    );
                  }}
                />
              </Switch>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  };

  const FilterSiteMobile = () => {
    return (
      <div>
        <Route
          exact
          path={currentPath}
          children={({ match, location }) => {
            // console.log("dataProducts", dataHotSaleProduct);
            return (
              <ProductContainer
                location={location}
                dataProducts={dataHotSaleProduct}
                showcaseHeader={nameProList}
              />
            );
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <Hidden mdDown>
        <FilterSiteDesktop />
      </Hidden>
      <Hidden smUp>
        <FilterSiteMobile />
      </Hidden>
    </div>
  );
};

export default FilterSite;
