//material
import React from "react";
import { Link } from "react-router-dom";
import { List, Typography, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import gray from "@material-ui/core/colors/grey";
import { useDispatch } from "react-redux";
import { toggleLoginModal } from "../../redux/action/utilActions";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: 15,
    fontWeight: 600,
    textTransform: "uppercase",
  },
  link: {
    color: theme.palette.text.secondary,
    fontSize: 15,
  },
}));

const SubscribeMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleSignUp = () => {
    dispatch(toggleLoginModal("register"));
  };

  return (
    <List disablePadding>
      <ListItem>
        <Typography className={classes.title}>Sign up for newletter</Typography>
      </ListItem>
      <ListItem button onClick={() => handleSignUp()}>
        <Typography className={classes.link}>
          Sign up now and get 15% off one item.
        </Typography>
      </ListItem>

      <ListItem button onClick={() => handleSignUp()}>
        <Typography className={classes.link}> SIGN UP</Typography>
      </ListItem>
    </List>
  );
};

export default SubscribeMenu;
