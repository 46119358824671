import React from "react";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#ffffff",

    "&:hover": {},
  },
  popper: {
    border: "1px solid red",
  },
}))(IconButton);

const NewBadge = withStyles((theme) => ({
  badge: {
    // border: `2px solid black`,
    padding: "5px",
    background: theme.palette.background.paper,
    color: theme.palette.primary.main,
  },
}))(Badge);

export default function StyledBadge(props) {
  return <NewBadge {...props}>{props.children}</NewBadge>;
}
