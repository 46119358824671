import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
  List,
  ListItem,
  Grid,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import SingleListItem from "./SingleListItem";
import { useDispatch } from "react-redux";
import {
  getSubCategoryProducts,
  selectType,
  selectSubCategory,
  selectMainCategory,
} from "../../redux/selector/index";
import { withRouter } from "react-router-dom";
import Menu from "material-ui-popup-state/HoverMenu";
import {
  usePopupState,
  bindHover,
  bindMenu,
} from "material-ui-popup-state/hooks";
const useStyles = makeStyles((theme) => ({
  categoryContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",

    padding: theme.spacing(1),
  },
  category: {
    flex: 1,
    // backgroundColor: "red",
    // border: "1px solid green",

    minWidth: "10vw",
    maxWidth: "10vw",
  },
  categoryBtn: {
    textAlign: "left",
  },
}));

function SimplePopover(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { item, index } = props;
  const handleClick = (event, type) => {
    setAnchorEl(event.currentTarget);

    dispatch(selectType(type, index));
  };
  const popupref = React.useRef();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleMainCategory = (category, index) => {
    dispatch(selectMainCategory(category, index));
  };
  const handleSubCategory = (subCategory, id, index) => {
    dispatch(selectSubCategory(subCategory, id, index));
    dispatch(getSubCategoryProducts(id));

    // props.history.push();
  };

  const handleSubCategoryClick = (
    maincategory,
    maincatIndex,
    subcat,
    subcatid,
    subcatIndex
  ) => {
    dispatch(selectMainCategory(maincategory, maincatIndex));
    dispatch(selectSubCategory(subcat, subcatid, subcatIndex));
    dispatch(getSubCategoryProducts(subcatid));
    setAnchorEl(null);
    props.history.push("/");
  };

  React.useEffect(() => {
    open === true
      ? window.addEventListener("scroll", handleClose)
      : window.removeEventListener("scroll", handleClose);
    return () => {
      window.removeEventListener("scroll", handleClose);
    };
  }, [open]);
  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });
  return (
    <div>
      <Button
        variant="text"
        onMouseEnter={(e) => handleClick(e, props.item.name)}
        // onClick={(e) => handleClick(e, props.item.name)}
        {...bindHover(popupState)}
      >
        {props.item.name}
      </Button>
      <Menu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        transitionDuration={0}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        disableScrollLock
      >
        <div className={classes.categoryContainer}>
          {item.categoryList.map((ele, catIndex) => {
            return (
              <div className={classes.category}>
                <Button
                  className={classes.categoryBtn}
                  color="primary"
                  variant="text"
                  onClick={() => handleMainCategory(ele.name, catIndex)}
                >
                  {ele.name}
                </Button>
                <div className={classes.subCategory}>
                  <List dense disablePadding>
                    {ele.subcategoryList.map((nele, subIndex) => {
                      return (
                        <ListItem
                          key={subIndex}
                          dense
                          button
                          onClick={() =>
                            handleSubCategoryClick(
                              ele.name,
                              catIndex,
                              nele.name,
                              nele.id,
                              subIndex
                            )
                          }
                        >
                          <ListItemText primary={nele.name} />
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              </div>
            );
          })}
        </div>
      </Menu>
      {/* <Popover
        id={id}
        open={open}
        ref={popupref}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableScrollLock
      >
        <div className={classes.categoryContainer}>
          {item.categoryList.map((ele, catIndex) => {
            return (
              <div className={classes.category}>
                <Button
                  className={classes.categoryBtn}
                  color="primary"
                  variant="text"
                  onClick={() => handleMainCategory(ele.category, catIndex)}
                >
                  {ele.category}
                </Button>
                <div className={classes.subCategory}>
                  <List dense disablePadding>
                    {ele.subcategoryList.map((nele, subIndex) => {
                      return (
                        <ListItem
                          key={subIndex}
                          button
                          onClick={() =>
                            handleSubCategoryClick(
                              ele.category,
                              catIndex,
                              nele.subcategory,
                              nele.id,
                              subIndex
                            )
                          }
                        >
                          <ListItemText primary={nele.subcategory} />
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              </div>
            );
          })}
        </div>
      </Popover> */}
    </div>
  );
}
export default withRouter(SimplePopover);
// import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import Popover from "@material-ui/core/Popover";
// import Typography from "@material-ui/core/Typography";
// import Button from "@material-ui/core/Button";
// import { List, ListItem } from "@material-ui/core";
// import SingleListItem from "./SingleListItem";
// import { useDispatch } from "react-redux";
// import {
//   getSubCategoryProducts,
//   selectType,
//   selectSubCategory,
//   selectMainCategory,
// } from "../../redux/selector/index";
// const useStyles = makeStyles((theme) => ({}));

// export default function SimplePopover(props) {
//   const classes = useStyles();
//   const dispatch = useDispatch();
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const { item, index } = props;
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//     let categoryName = event.target.textContent;

//     dispatch(selectType(categoryName, index));
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? "simple-popover" : undefined;

//   const handleMainCategory = (category, index) => {
//     dispatch(selectMainCategory(category, index));
//   };
//   const handleSubCategory = (subCategory, id, index) => {
//     dispatch(selectSubCategory(subCategory, id, index));
//     dispatch(getSubCategoryProducts(id));

//     // props.history.push();
//   };
//   return (
//     <div>
//       <Button variant="text" onClick={handleClick}>
//         {props.item.type}
//       </Button>
//       <Popover
//         id={id}
//         open={open}
//         anchorEl={anchorEl}
//         onClose={handleClose}
//         anchorOrigin={{
//           vertical: "bottom",
//           horizontal: "center",
//         }}
//         transformOrigin={{
//           vertical: "top",
//           horizontal: "center",
//         }}
//         disableScrollLock
//       >
//         <List dense={true} disablePadding>
//           {item.categoryList.map((ele, catIndex) => (
//             <SingleListItem
//               key={catIndex}
//               close={() => handleClose()}
//               item={ele}
//               itemindex={catIndex}
//               selectMainCategory={(category, index) =>
//                 handleMainCategory(category, index)
//               }
//               selectSubCategory={(a, b, c) => handleSubCategory(a, b, c)}
//             />
//           ))}
//         </List>
//       </Popover>
//     </div>
//   );
// }
