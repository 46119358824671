import React, { useState } from "react";
import Carousel from "@brainhubeu/react-carousel";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import "@brainhubeu/react-carousel/lib/style.css";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),

    maxHeigth: "50vh",
  },
  img: {
    height: "70vh",
    width: "100%",
    objectFit: "cover",
    borderRadius: 4,
    [theme.breakpoints.down("md")]: {
      height: "30vh",
      width: "98%",
    },
  },
}));
const BannerCarousel = () => {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const onChange = (e) => {
    setValue(e);
  };

  const slides = [
    <img
      key={1}
      src="/carousel/techpack.jpg"
      className={classes.img}
      alt="117_years_of_expertise"
    />,
    <img
      key={2}
      src="/carousel/patterndrafting.jpg"
      className={classes.img}
      alt="BMW_1400x788"
    />,
    <img
      key={3}
      src="/carousel/patterngrading.jpg"
      className={classes.img}
      alt="every-ride-matters"
    />,

  ];
  return (
    <div className={classes.root}>
      <Container maxWidth="xl">
        <Carousel
          slidesPerPage={1}
          animationSpeed={1500}
          autoPlay={3000}
          stopAutoPlayOnHover
          infinite
          slides={slides}
          clickToChange
          value={value}
          onChange={(e) => onChange(e)}
        />
      </Container>
    </div>
  );
};
export default BannerCarousel;
