import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardHeader, CardContent, Divider } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  addCommentToProduct,
  requestReturnProduct,
} from "../../../../redux/selector/index";
import SingleOrder from "./SingleOrder";
import AddComment from "./AddComment";
import ReturnModal from "./ReturnModal";
import { useParams, useLocation } from "react-router-dom";
import { pushtohistory } from "../../../../redux/action/historyActions";
import {
  getTrackingDetails,
  removeTrackingDetails,
} from "../../../../redux/action/useractions";
import TrackModal from "./TrackModal";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    color: theme.palette.background.main,
    [theme.breakpoints.down("md")]: {
      // paddingTop: theme.spacing(3),
    },
  },
}));
const TrackingDetails = [
  {
    status: "INTRANSIT",
    location: "Bangalore 456064",
    details: "shipment is in transit",
    date: "26-04-2020",
  },
  {
    status: "INTRANSIT",
    location: "Bangalore 456064",
    details: "shipment is in transit",
    date: "26-04-2020",
  },
  {
    status: "INTRANSIT",
    location: "Bangalore 456064",
    details: "shipment is in transit",
    date: "26-04-2020",
  },
  {
    status: "INTRANSIT",
    location: "Bangalore 456064",
    details: "shipment is in transit",
    date: "26-04-2020",
  },
  {
    status: "INTRANSIT",
    location: "Bangalore 456064",
    details: "shipment is in transit",
    date: "26-04-2020",
  },
  {
    status: "INTRANSIT",
    location: "Bangalore 456064",
    details:
      "shipment is in transit ,shipment is in transitshipment is in transitshipment is in transit ,shipment is in transitshipment is in transitshipment is in transit",
    date: "26-04-2020",
  },
];
const UserOrders = () => {
  const [commentModalOpen, setCommentModalOpen] = React.useState(false);
  const [returnModalOpen, setreturnModalOpen] = React.useState(false);
  const [trackModalOpen, settrackModalOpen] = React.useState(false);
  const [commentProduct, setCommentProduct] = React.useState(null);

  const origUserOrders = useSelector((state) => state.user.orders);

  const UserOrders = origUserOrders.sort(function (a, b) {
    return (
      moment(b.orderDate, "DD-MM-YYYY") - moment(a.orderDate, "DD-MM-YYYY")
    );
  });

  // const TrackingDetails = useSelector((state) => state.user.trackingDetails);

  const dispatch = useDispatch();

  //stub

  const { stub } = useParams();
  const location = useLocation().pathname;

  React.useEffect(() => {
    let newlocation = location === "/" ? location : location.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));
  }, []);

  const handleCommentModalOpen = (data) => {
    setCommentModalOpen(true);
    setCommentProduct(data);
  };
  const handleCommentModalClose = (e) => {
    setCommentModalOpen(false);
    setCommentProduct();
  };

  const handleCommentSave = (e) => {
    setCommentModalOpen(false);

    dispatch(
      addCommentToProduct({
        ...e,
        productId: commentProduct.productId,
        orderDetailId: commentProduct.id,
      })
    );
  };
  const handleReturnModalOpen = (data) => {
    setreturnModalOpen(true);
    setCommentProduct(data);
  };
  const handleReturnModalClose = (e) => {
    setreturnModalOpen(false);
    setCommentProduct();
  };

  const handleReturnSave = (e) => {
    setreturnModalOpen(false);

    dispatch(
      requestReturnProduct({
        ...e,

        orderDetailId: commentProduct.id,
      })
    );
  };

  const getTrackingInfo = (number) => {
    dispatch(getTrackingDetails(number));
    settrackModalOpen(true);
  };

  const openTrackModal = () => {
    settrackModalOpen(true);
  };
  const closeTrackModal = () => {
    settrackModalOpen(false);
    dispatch(removeTrackingDetails());
  };
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {trackModalOpen && (
        <TrackModal
          open={trackModalOpen}
          details={TrackingDetails}
          close={() => closeTrackModal()}
        />
      )}
      <AddComment
        save={(e) => {
          console.log("comment save", e);
          handleCommentSave(e);
        }}
        open={commentModalOpen}
        product={commentProduct}
        close={() => handleCommentModalClose()}
      />
      <ReturnModal
        save={(e) => {
          handleReturnSave(e);
        }}
        open={returnModalOpen}
        product={commentProduct}
        close={() => handleReturnModalClose()}
      />

      <Card className={classes.card}>
        <CardHeader title="My Orders" />
        <Divider />

        <CardContent>
          {UserOrders &&
            UserOrders.map((data, index) => {
              return (
                <SingleOrder
                  key={index}
                  orderIndex={index}
                  data={data}
                  addComment={handleCommentModalOpen}
                  returnRequest={handleReturnModalOpen}
                  getTrackingInfo={getTrackingInfo}
                />
              );
            })}
        </CardContent>
      </Card>
    </div>
  );
};
export default UserOrders;
