import { userTypes } from "../types";
import { ROOT_URL } from "../utils/utils";
export const loginuser = (user) => {
  const newUser = {
    username: user.email,
    password: user.password,
    deviceInfo: {
      deviceId: "123123",
      deviceType: "DEVICE_TYPE_ANDROID",
      notificationToken: "eaeqewqewq",
    },
  };
  return {
    type: userTypes.LOGIN_USER,
    payload: {
      request: {
        method: "POST",
        url: `${ROOT_URL}IDM/AUTH/login`,
        data: newUser,
        headers: {},
      },
    },
  };
};
export const logInOnLoad = (token) => {
  console.log("loginonload", token);
  return {
    type: userTypes.LOCAL_LOGIN_USER,
    // payload: {
    //   request: {
    //     method: "PUT",
    //     url: `${ROOT_URL}IDM/LoginUser/updateUserRole/3291`,
    //     headers: {
    //       Authorization: token ? `Bearer ${token}` : "",
    //     },
    //   },
    //   data: { accessToken: token },
    // },
  };
};

export const saveBasicInfo = (data) => {
  return {
    type: userTypes.SAVE_BASIC_INFO,
    payload: data,
  };
};
export const saveUserAddresses = (addresses) => {
  console.log(addresses, "addresses from action");
  return {
    type: userTypes.SAVE_USER_ADDRESS,
    payload: {
      request: {
        method: "POST",
        url: `${ROOT_URL}ECOM/UserAddress/save`,
        data: addresses[0],
      },
    },
  };
};
export const editUserAddress = (data, id) => {
  return {
    type: userTypes.EDIT_USER_ADDRESS,
    payload: {
      request: {
        method: "put",
        url: `${ROOT_URL}ECOM/UserAddress/${id}`,
        data: data[0],
      },
    },
  };
};
export const deleteUserAddress = (id, data) => {
  return {
    type: userTypes.DELETE_USER_ADDRESS,
    payload: {
      request: {
        method: "delete",
        url: `${ROOT_URL}ECOM/UserAddress/del/${id}`,
        data: data,
      },
    },
  };
};

export const addUserCard = (data) => {
  return {
    type: userTypes.SAVE_USER_CARD,
    payload: {
      request: {
        method: "post",
        url: `${ROOT_URL}ECOM/UserCard/save`,
        data: data,
      },
    },
  };
};
export const deleteUserCard = (id, data) => {
  return {
    type: userTypes.DELETE_USER_CARD,
    payload: {
      request: {
        method: "delete",
        url: `${ROOT_URL}ECOM/UserCard/del/${id}`,
        data: data,
      },
    },
  };
};

export const resetUserPassword = (old, newpass, userId) => {
  return {
    type: userTypes.RESET_USER_PASSWORD,
    payload: {
      request: {
        method: "put",
        url: `${ROOT_URL}IDM/ChangePassword/${userId}`,
        data: {
          oldPassword: old,
          newPassword: newpass,
        },
      },
    },
  };
};
// export const saveSavedCards = (cards) => {
//   return {
//     type: userTypes.SAVE_SAVED_CARDS,
//     payload: cards,
//   };
// };

export const getUserAccount = () => {
  return {
    type: userTypes.GET_USER_ACCOUNT,
    payload: {
      request: {
        method: "GET",
        url: `${ROOT_URL}ECOM/Customer/myAccount`,
      },
    },
  };
};

export const getUserOrders = (token) => {
  return {
    type: userTypes.GET_USER_ORDERS,
    payload: {
      request: {
        method: "GET",
        url: `${ROOT_URL}ECOM/EcomOrder/myOrders`,
        data: { accessToken: token },
      },
    },
  };
};

export const getUserAddresses = (token) => {
  return {
    type: userTypes.GET_USER_ADDRESS,
    payload: {
      request: {
        method: "GET",
        url: `${ROOT_URL}ECOM/UserAddress/myAddresses`,
        data: { accessToken: token },
      },
    },
  };
};

export const getUserCards = (token) => {
  return {
    type: userTypes.GET_USER_CARD,
    payload: {
      request: {
        method: "GET",
        url: `${ROOT_URL}UserCard/myCards`,
        data: { accessToken: token },
      },
    },
  };
};

export const logoutUser = () => {
  return {
    type: userTypes.LOGOUT_USER,
  };
};
export const registerUser = (data) => {
  return {
    type: userTypes.REGISTER_USER,
    payload: {
      request: {
        method: "post",
        url: `${ROOT_URL}IDM/AUTH/ecomregister`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      },
    },
  };
};
export const svaeUserToken = (token) => {
  return {
    type: userTypes.SAVE_USER_TOKEN,
    payload: token,
  };
};
export const getTrackingDetails = (trackingNumber) => {
  return {
    type: userTypes.GET_TRACKING_DETAILS,
    payload: {
      request: {
        method: "get",
        url: `${ROOT_URL}Shipping/ShipmentTracking/${trackingNumber}`,
      },
    },
  };
};
export const removeTrackingDetails = () => {
  return {
    type: userTypes.REMOVE_TRACKING_DETAILS,
  };
};
