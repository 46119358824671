import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Container, Hidden } from "@material-ui/core";
import Sidemenu from "./SideMenu";
import ProfileInformation from "./ProfileInformation";
import ManageAddresses from "./ManageAddresses/index";
import SavedCards from "./SavedCards/SavedCards";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import ResetPassword from "./ResetPassword";
import UserOrders from "./Orders/UserOrders";
import Logout from "./Logout";
import { useDispatch, useSelector } from "react-redux";
import { getUserAccount } from "../../../redux/action/useractions";
import LoadingComponent from "../../Spinners/LoadingComponent";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    minHeight: "100vh",
    padding: "5vh 0vw",
    [theme.breakpoints.down("md")]: {
      minHeight: "auto",
      padding: "0vh 0vw 5vh 0vw",
    },
  },
  sidecontainer: {
    minHeight: "20vh",
  },
}));
const Useraccount = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const [loading, setLoading] = React.useState(false);
  const { location } = props;
  const loading = useSelector((state) => state.user.isLoding);

  // React.useEffect(() => {
  //   setLoading(isloading);
  // }, [isloading]);
  // React.useEffect(() => {
  //   dispatch(getUserAccount());
  // }, []);
  return (
    <div className={classes.root}>
      <Hidden mdDown>
        <Redirect to="/useraccount/profile" />
      </Hidden>
      <Container maxWidth="xl">
        <Grid container spacing={5}>
          <Grid item lg={3} xs={12} md={12} sm={12}>
            <Sidemenu />
          </Grid>

          <Hidden smDown>
            <Grid item lg={9} xs={12} sm={12} md={12}>
              <Route
                exact
                path="/useraccount/myorders"
                component={UserOrders}
              />
              <Route
                exact
                path="/useraccount/addresses"
                component={ManageAddresses}
              />
              <Route
                exact
                path="/useraccount/profile"
                component={ProfileInformation}
              />
              <Route
                exact
                path="/useraccount/savedcards"
                component={SavedCards}
              />
              <Route
                exact
                path="/useraccount/resetpassword"
                component={ResetPassword}
              />

              <Route exact path="/useraccount/logout" component={Logout} />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};
export default withRouter(Useraccount);
