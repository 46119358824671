import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Card,
  CardHeader,
  Grid,
  Typography,
  Button,
  TextField,
  CardContent,
  Divider,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { saveBasicInfo } from "../../../redux/action/useractions";
import { useParams, useLocation } from "react-router-dom";
import { pushtohistory } from "../../../redux/action/historyActions";
const useStyles = makeStyles((theme) => ({
  gridContainer: {
    padding: "3vh",
  },
  gridItem: {
    marginBottom: theme.spacing(3),
  },
  titleContainer: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(3),
    },
  },
  lastNameContainer: {
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginTop: theme.spacing(3),
    },
  },
  title: {
    fontSize: "1rem",
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
}));
const ProfileInformation = () => {
  const classes = useStyles();

  //save function
  const userInfo = useSelector((state) => state.user);

  const { firstName, lastName, email, mobile } = userInfo;

  //stub

  const { stub } = useParams();
  const location = useLocation().pathname;
  const dispatch = useDispatch();

  React.useEffect(() => {
    let newlocation = location === "/" ? location : location.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));
  }, []);
  return (
    <Card>
      <CardHeader title="Personal Information" />
      <Divider />
      <CardContent>
        <Grid container className={classes.gridContainer}>
          <Grid item container lg={12} xs={12} md={12} sm={12}>
            {/* first item */}
            <Grid
              item
              container
              lg={12}
              xs={12}
              md={12}
              sm={12}
              className={classes.gridItem}
            >
              <Grid
                item
                container
                alignItems="center"
                justify="space-between"
                lg={5}
                className={classes.titleContainer}
              >
                <Grid item>
                  <Typography className={classes.title}>Name</Typography>
                </Grid>
              </Grid>
              <Grid item lg={10} container alignItems="center">
                <Grid item lg={5} xs={12} md={12} sm={12}>
                  <Typography className={classes.subItem} color="body2">
                    {firstName} {lastName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* second item */}
            <Grid
              item
              container
              lg={12}
              xs={12}
              md={12}
              sm={12}
              className={classes.gridItem}
            >
              <Grid
                item
                container
                alignItems="center"
                justify="space-between"
                lg={5}
                xs={12}
                md={12}
                sm={12}
                className={classes.titleContainer}
              >
                <Grid item>
                  <Typography className={classes.title}>
                    Email Address
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                lg={10}
                xs={12}
                md={12}
                sm={12}
                container
                alignItems="center"
              >
                <Grid item lg={5} xs={12} md={12} sm={12}>
                  <Typography className={classes.subItem} color="body2">
                    {email}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* third item */}
            <Grid
              item
              container
              lg={12}
              xs={12}
              md={12}
              sm={12}
              className={classes.gridItem}
            >
              <Grid
                item
                container
                alignItems="center"
                justify="space-between"
                lg={5}
                xs={12}
                md={12}
                sm={12}
                className={classes.titleContainer}
              >
                <Grid item>
                  <Typography className={classes.title}>
                    Mobile Number
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                lg={10}
                xs={12}
                md={12}
                sm={12}
                container
                alignItems="center"
              >
                <Grid item lg={5} xs={12} md={12} sm={12}>
                  <Typography className={classes.subItem} color="body2">
                    {mobile}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default ProfileInformation;

// import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import {
//   Paper,
//   Card,
//   CardHeader,
//   Grid,
//   Typography,
//   Button,
//   TextField,
// } from "@material-ui/core";
// import { useSelector, useDispatch } from "react-redux";
// import { saveBasicInfo } from "../../../redux/action/useractions";
// const useStyles = makeStyles((theme) => ({
//   gridContainer: {
//     padding: "3vh",
//   },
//   gridItem: {
//     marginBottom: theme.spacing(3),
//   },
//   titleContainer: {
//     marginBottom: theme.spacing(1),
//     [theme.breakpoints.down("md")]: {
//       marginBottom: theme.spacing(3),
//     },
//   },
//   lastNameContainer: {
//     marginLeft: theme.spacing(3),
//     [theme.breakpoints.down("md")]: {
//       marginLeft: 0,
//       marginTop: theme.spacing(3),
//     },
//   },
//   title: {
//     fontSize: "1rem",
//     fontWeight: 500,
//   },
// }));
// const ProfileInformation = () => {
//   const classes = useStyles();
//   const [edit, setEdit] = useState(false);
//   const [firstName, setFirstName] = useState("Robin");
//   const [lastName, setLastName] = useState("Hood");
//   const [email, setEmail] = useState("robinhood@gmail.com");
//   const [mobile, setMobile] = useState(9898989898);
//   const dispatch = useDispatch();
//   //save function
//   const userInfo = useSelector((state) => state.user);

//   const saveInfo = () => {
//     setEdit(false);
//     const data = {
//       ...userInfo,
//       firstName,
//       lastName,
//       email,
//       mobile,
//     };
//     dispatch(saveBasicInfo(data));
//     //do something here
//   };
//   React.useEffect(() => {
//     setFirstName(userInfo.firstName);
//     setLastName(userInfo.lastName);
//     setEmail(userInfo.email);
//     setMobile(userInfo.mobile);
//   }, [userInfo]);
//   return (
//     <Paper elevation={1}>
//       <Grid container className={classes.gridContainer}>
//         <Grid item container lg={12} xs={12} md={12} sm={12}>
//           {/* first item */}
//           <Grid
//             item
//             container
//             lg={12}
//             xs={12}
//             md={12}
//             sm={12}
//             className={classes.gridItem}
//           >
//             <Grid
//               item
//               container
//               alignItems="center"
//               justify="space-between"
//               lg={5}
//               className={classes.titleContainer}
//             >
//               <Grid item>
//                 <Typography className={classes.title}>
//                   Personal Information
//                 </Typography>
//               </Grid>
//               <Grid item>
//                 <Button
//                   variant="text"
//                   color="secondary"
//                   onClick={() => setEdit(true)}
//                 >
//                   Edit
//                 </Button>
//               </Grid>
//             </Grid>
//             <Grid item lg={10} container alignItems="center">
//               <Grid item lg={5} xs={12} md={12} sm={12}>
//                 <TextField
//                   variant="outlined"
//                   placeholder="First Name"
//                   fullWidth
//                   disabled={!edit}
//                   value={firstName}
//                   onChange={(e) => setFirstName(e.target.value)}
//                   size="medium"
//                 />
//               </Grid>
//               <Grid
//                 item
//                 lg={5}
//                 xs={12}
//                 md={12}
//                 sm={12}
//                 className={classes.lastNameContainer}
//               >
//                 <TextField
//                   variant="outlined"
//                   placeholder="Last Name"
//                   fullWidth
//                   disabled={!edit}
//                   size="medium"
//                   value={lastName}
//                   onChange={(e) => setLastName(e.target.value)}
//                 />
//               </Grid>
//             </Grid>
//           </Grid>
//           {/* second item */}
//           <Grid
//             item
//             container
//             lg={12}
//             xs={12}
//             md={12}
//             sm={12}
//             className={classes.gridItem}
//           >
//             <Grid
//               item
//               container
//               alignItems="center"
//               justify="space-between"
//               lg={5}
//               xs={12}
//               md={12}
//               sm={12}
//               className={classes.titleContainer}
//             >
//               <Grid item>
//                 <Typography className={classes.title}>Email Address</Typography>
//               </Grid>
//             </Grid>
//             <Grid
//               item
//               lg={10}
//               xs={12}
//               md={12}
//               sm={12}
//               container
//               alignItems="center"
//             >
//               <Grid item lg={5} xs={12} md={12} sm={12}>
//                 <TextField
//                   variant="outlined"
//                   placeholder="Email"
//                   fullWidth
//                   disabled={!edit}
//                   size="medium"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                 />
//               </Grid>
//             </Grid>
//           </Grid>
//           {/* third item */}
//           <Grid
//             item
//             container
//             lg={12}
//             xs={12}
//             md={12}
//             sm={12}
//             className={classes.gridItem}
//           >
//             <Grid
//               item
//               container
//               alignItems="center"
//               justify="space-between"
//               lg={5}
//               xs={12}
//               md={12}
//               sm={12}
//               className={classes.titleContainer}
//             >
//               <Grid item>
//                 <Typography className={classes.title}>Mobile Number</Typography>
//               </Grid>
//             </Grid>
//             <Grid
//               item
//               lg={10}
//               xs={12}
//               md={12}
//               sm={12}
//               container
//               alignItems="center"
//             >
//               <Grid item lg={5} xs={12} md={12} sm={12}>
//                 <TextField
//                   variant="outlined"
//                   placeholder="Mobile Number"
//                   fullWidth
//                   disabled={!edit}
//                   size="medium"
//                   value={mobile}
//                   onChange={(e) => setMobile(e.target.value)}
//                 />
//               </Grid>
//             </Grid>
//           </Grid>
//           {/* save btn */}
//           <Grid
//             item
//             container
//             xs={12}
//             md={12}
//             sm={12}
//             lg={12}
//             className={classes.gridItem}
//           >
//             <Grid
//               item
//               container
//               alignItems="center"
//               justify="space-between"
//               lg={5}
//               xs={12}
//               md={12}
//               sm={12}
//               className={classes.titleContainer}
//             >
//               <Grid item>
//                 <Button
//                   disabled={!edit}
//                   variant="contained"
//                   color="secondary"
//                   size="large"
//                   onClick={() => saveInfo()}
//                 >
//                   Save
//                 </Button>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Grid>
//     </Paper>
//   );
// };
// export default ProfileInformation;
