import { utilTypes, userTypes } from "../../types/index";

const initialState = {
  loginmodal: false,
  component: "login",
  location: {},
  cookiedialog: false,
  consent: false,
  history: [],
  userId: null,
  email: null,
  name: null,
  error: false,
  message: "",
};

const utilReducer = (state = initialState, action) => {
  switch (action.type) {
    case utilTypes.TOGGLE_LOGIN_MODAL:
      return {
        ...state,
        loginmodal: true,
        component: action.payload,
        error: false,
        message: "",
      };
    case utilTypes.CLOSE_LOGIN_MODAL:
      return {
        ...state,
        loginmodal: false,
        component: "login",
        error: false,
        message: "",
      };

    case userTypes.LOGIN_USER_SUCCESS:
      return {
        ...state,
        loginmodal: false,
        component: "login",
        error: false,
        message: "",
      };

    case userTypes.REGISTER_USER_SUCCESS:
      return {
        ...state,
        loginmodal: false,
        component: "login",
        error: false,
        message: "",
      };
    case userTypes.LOGIN_USER_FAIL:
      let errormsg;
      if (action.error.response && action.error.response.data.message) {
        errormsg = action.error.response.data.message;
      } else {
        errormsg = "Login failed , please try after some time";
      }
      return {
        ...state,

        error: true,
        message: errormsg,
      };
	  
     case userTypes.REGISTER_USER_FAIL:
      let myerrormsg;
      if (action.error.response && action.error.response.data.message) {
        myerrormsg = action.error.response.data.message;
      } else {
        myerrormsg = "Registration failed , please try after some time";
      }

      return {
        ...state,

        error: true,
        message: myerrormsg,
      };

    case utilTypes.GET_USER_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case utilTypes.GET_VISITOR_TOKEN_SUCCESS:
      return {
        ...state,
        id: action.payload.data.id,
        consent: action.payload.data.consent,
        logs: action.payload.data.logs,
      };
    case utilTypes.CONSENT_ACTION:
      return {
        ...state,
        consent: action.payload,
      };
    case utilTypes.PUSH_TO_HISTORY:
      let newhisoty = state.history.push(action.payload);
      // console.log(action.payload, "push history data");
      return {
        ...state,
        newhisoty,
      };

    case utilTypes.UPLOAD_HISTORY_SUCCESS:
      return {
        ...state,
        history: [],
      };

    case utilTypes.SET_NEWSLETTER:
      return {
        ...state,
        email: action.payload.email,
        name: action.payload.name,
      };

    default:
      return state;
  }
};
export default utilReducer;
