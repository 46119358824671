import { activeProductTypes } from "../types";

const initialState = {
  id: "",
  name: "",
  type: "",
  category: "",
  subcategory: "",
  subCategoryId: 20,
  seller: "",
  sellerId: null,
  brand: "",
  brandId: null,
  customizable: false,
  discontinued: false,
  browsingName: "",
  salePrice: null,
  listPrice: null,
  defaultImage: "",
  overview: "",
  quantity: null,
  isStockControlled: false,
  status: "",
  description: "",
  rank: null,
  sku: "",
  rating: null,
  discount: null,
  memberDelivery: true,
  freeDelivery: false,
  expressDelivery: false,
  overnightDelivery: true,
  configurationId: null,
  specificationId: null,
  bomId: null,
  images: [],
  productInputs: [],
  normalDelivery: false,
  attributeList: [],
  tags: [],
  relatedProducts: [],
  isLoading: false,
  comments: [],
};

const activeProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case activeProductTypes.GET_ACTIVE_PRODUCT:
      return {
        ...state,
        isLoading: true,
      };
    case activeProductTypes.GET_ACTIVE_PRODUCT_SUCCESS:
      let data = action.payload.data;

      return {
        ...state,
        isLoading: false,
        ...data,
        // images: dummyImages,
      };
    case activeProductTypes.GET_ACTIVE_PRODUCT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: "true",
      };

    case activeProductTypes.GET_ACTIVE_PRODUCT_COMMENTS:
      console.log("get active prod runnig", action.payload);
      return {
        ...state,
        isLoading: true,
      };
    case activeProductTypes.GET_ACTIVE_PRODUCT_COMMENTS_SUCCESS:
      console.log("get active prod succes", action.payload);
      let comments = JSON.parse(action.payload.data);

      return {
        ...state,
        isLoading: false,
        comments: comments.data,
      };
    case activeProductTypes.GET_ACTIVE_PRODUCT_COMMENTS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: "true",
      };
    default:
      return state;
  }
};
export default activeProductReducer;
