import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { Paper, Grid, Typography, AppBar, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import SidebarMenu from "../nav/SidebarMenu";
import LeftArrow from "@material-ui/icons/KeyboardArrowLeft";
import { DISPLAY_PRODUCT_TYPE } from "../../redux/utils/utils";
import NoTypeSidebarMenu from "../nav/NoTypeSidebarMenu";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    width: "100vw",
  },
  icon: {
    color: theme.palette.text.primary,
    fontSize: "1.2em",
    marginLeft: -10,
  },
  gridContainer: {
    height: "100vh",
    backgroundColor: theme.palette.background.dark,
  },
  logoContainer: {
    height: "10vh",

    // backgroundColor: theme.palette.primary.main,
  },
  linksContainer: {
    height: "90vh",
  },
  LeftArrowBtn: {
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
  },
  LeftArrow: {
    fontSize: 20,
  },
  AppBar: {
    backgroundColor: theme.palette.background.lighter,
  },
  logo: {
    height: "10vh",
    padding: theme.spacing(0.5),
    [theme.breakpoints.down("md")]: {
      height: "7vh",
    },
  },
}));

export default function MobileSidebar(props) {
  const { logo } = props;
  const classes = useStyles();
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event) {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
    }

    setState(!state);
  };

  return (
    <div>
      <React.Fragment>
        <IconButton onClick={toggleDrawer(true)}>
          <MenuIcon className={classes.icon} />
        </IconButton>
        <Drawer anchor="left" open={state} onClose={toggleDrawer(false)}>
          <AppBar position="sticky" className={classes.AppBar}>
            <Toolbar>
              <Grid
                container
                lg={12}
                xs={12}
                alignItems="center"
                justify="space-between"
                className={classes.logoContainer}
              >
                <Grid item>
                  <IconButton
                    className={classes.LeftArrowBtn}
                    onClick={toggleDrawer(false)}
                  >
                    <LeftArrow className={classes.LeftArrow} fontSize="large" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <img src={logo} alt="logo" className={classes.logo} />
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <div className={classes.sidebar}>
            <Grid
              container
              className={classes.gridContainer}
              alignItems="flex-start"
              justify="flex-start"
            >
              <Grid item xs={12} className={classes.linksContainer}>
                {DISPLAY_PRODUCT_TYPE ? (
                  <SidebarMenu close={toggleDrawer(false)} />
                ) : (
                  <NoTypeSidebarMenu close={toggleDrawer(false)} />
                )}
                {/* <SidebarMenu close={toggleDrawer(false)} /> */}
              </Grid>
            </Grid>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
