import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LoginForm from "./LoginForm";
import NewCustomer from "./NewCustomer";

import CartIcon from "@material-ui/icons/ShoppingCartOutlined";
import SecurityIcon from "@material-ui/icons/VerifiedUserOutlined";
import CreditCart from "@material-ui/icons/CreditCard";
import FaceIcon from "@material-ui/icons/InsertEmoticon";
import LoyalyIcon from "@material-ui/icons/LoyaltyOutlined";
import ResetPassword from "./ResetPassword";
import { Container, Grid, Typography, Hidden } from "@material-ui/core";
import RegisterForm from "./RegisterForm";
import Existingcustomer from "./ExistingUser";
import ForgotPassword from "./ForgotPassword";
import AlreadyLogged from "./AlreadyLogged";
import { useSelector } from "react-redux";
import EnquiryProduct from "../Modals/Enquiry/EnquiryModal";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      minHeight: "auto",
    },
  },
  loginInfo: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.light,
    minHeight: "100%",
  },
  loginContainer: {
    // borderColor: theme.palette.primary.dark,
    // borderWidth: "1px",
    // borderStyle: "solid",
    borderRadius: 0,
    backgroundColor: "white",
    [theme.breakpoints.down("md")]: {
      border: "none",
    },
  },
  title: {
    color: "white",
    fontSize: 25,
  },
  otherText: {
    color: theme.palette.background.light,
    fontSize: 20,
  },
  icon: {
    // color: "white",
    color: theme.palette.background.light,
    fontSize: 30,
  },
}));

const LoginFormWrap = (props) => {
  const classes = useStyles();

  const logged = useSelector((state) => state.user.isLogged);
  const [isLogged, setislogged] = React.useState(false);
  React.useEffect(() => {
    setislogged(logged);
    console.log("logged", isLogged);
  }, [logged]);
  const getComponent = (path) => {
    if (path === "login") {
      return (
        <Grid item lg={12} container justify="center">
          <LoginForm elevation={0} />
          <NewCustomer />
        </Grid>
      );
    }
    if (path === "register") {
      return (
        <Grid item lg={12} container justify="center">
          <RegisterForm />
          <Existingcustomer />
        </Grid>
      );
    }
    if (path === "forgot") {
      return (
        <Grid item lg={12} container justify="center">
          <ForgotPassword />
          {/* <Grid item lg={12} container justify="center">
            <Existingcustomer />
          </Grid> */}
          <Grid item lg={12} container justify="center">
            <NewCustomer />
          </Grid>
        </Grid>
      );
    }
    if (path === "resetpassword")
      return (
        <Grid item lg={12}>
          <ResetPassword />
        </Grid>
      );
    if (path === "enquiry")
      return (
        <Grid item lg={12}>
          <EnquiryProduct />
        </Grid>
      );
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      <Grid container>
        <Hidden smDown>
          <Grid item lg={5}>
            <Grid
              container
              direction="column"
              justify="space-around"
              alignItems="center"
              className={classes.loginInfo}
            >
              <Grid item lg={10}>
                <Typography align="center" className={classes.otherText}>
                  Get access to your Orders, Wishlist and Recommendations
                </Typography>
              </Grid>
              <Grid
                item
                container
                lg={10}
                alignItems="center"
                justify="space-evenly"
              >
                <Grid item>
                  <CartIcon className={classes.icon} />
                </Grid>
                <Grid item>
                  <LoyalyIcon className={classes.icon} />
                </Grid>
                <Grid item>
                  <SecurityIcon className={classes.icon} />
                </Grid>
                <Grid item>
                  <CreditCart className={classes.icon} />
                </Grid>
                <Grid item>
                  <FaceIcon className={classes.icon} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Grid
          item
          container
          alignItems="center"
          justify="center"
          lg={7}
          xs={12}
          className={classes.loginContainer}
        >
          {props.path === "enquiry" && isLogged === true ? (
            getComponent(props.path)
          ) : props.path !== "resetpassword" && isLogged === true ? (
            <AlreadyLogged />
          ) : (
            getComponent(props.path)
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
export default LoginFormWrap;
