import React from "react";

import { Grid, Typography, Box, Paper, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  name: {
    // color: theme.palette.text.primary,
    textTransform: "uppercase",
  },
  title: {
    [theme.breakpoints.down("md")]: {},
  },
  actualValue: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {},
  },
  marketValue: {
    fontWeight: 600,
    color: theme.palette.action.disabled,
    textDecoration: "line-through",
    [theme.breakpoints.down("md")]: {},
  },
  discountValue: {
    fontWeight: 600,
    color: theme.palette.green.main[400],
    [theme.breakpoints.down("md")]: {},
  },
  priceBandsContainer: {
    overflowX: "auto",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  //   singlePriceBandContainer:{
  // // maxWidth:'100vw',
  // // display:'flex',
  // // flexDirection:'column',
  // // padding:'3vh',
  // // margin:'1vh',
  // border:'1px solid red'

  //   },
  priceBandsContainerTop: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      overflow: "scroll",
    },
  },
  pricebandItem: {
    flex: 1,

    width: "10vw",
    [theme.breakpoints.down("md")]: {
      width: "35vw",
    },
  },
  pricebandItemdiscount: {
    flex: 1,
    width: "10vw",
    border: "1px solid grey",
    borderRadius: 4,
  },
  singleband: {
    border: "1px solid lightgray",
    borderRadius: "4px",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  selectedband: {
    borderWidth: 4,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    //   borderWidth:1,
    //   borderStyle:'solid',
    //   borderColor:theme.palette.primary,
    // backgroundColor: theme.palette.secondary.main,
    // color: "white",
    // fontWeight: 800,
  },
}));

const ProductPriceBands = (props) => {
  const classes = useStyles();
  const { pricebands } = props;
  const [selectedPriceBand, setSelectedPriceBand] = React.useState(null);

  // console.log("pricebands", pricebands);
  const selectPriceBand = (index) => {
    setSelectedPriceBand(index);
  };
  React.useEffect(() => {
    let selectedIndex = pricebands.findIndex(
      (ele) =>
        ele.minimumQuantity <= parseInt(props.selected) &&
        ele.maximumQuantity >= parseInt(props.selected)
    );
    if (selectedIndex === -1) {
      selectedIndex = pricebands.length - 1;
    }

    // console.log(selectedIndex, props.selected);
    setSelectedPriceBand(selectedIndex);
  }, [props.selected]);
  return (
    <Grid
      container
      spacing={4}
      alignItems="center"
      justify="center"
      className={classes.root}
    >
      <Grid item container lg={12} xs={12} sm={12} md={12} alignItems="center">
        <Grid item lg={12} sm={12} md={1} xs={12}>
          <Typography variant="h6" className={classes.title}>
            Price bands
          </Typography>
        </Grid>

        <Grid item lg={12} sm={12} md={12} xs={12}>
          <div className={classes.priceBandsContainer}>
            {pricebands
              .sort((a, b) => a.minimumQuantity - b.minimumQuantity)
              .map((item, index) => {
                return (
                  <div
                    className={clsx(
                      classes.singleband,
                      selectedPriceBand === index ? classes.selectedband : null
                    )}
                  >
                    <div className={classes.priceBandsContainerTop}>
                      {" "}
                      <Typography
                        className={classes.pricebandItem}
                        color="textPrimary"
                        align="center"
                      >
                        {" "}
                        {item.minimumQuantity} - {item.maximumQuantity}
                      </Typography>
                    </div>
                    {/* <div className={classes.priceBandsContainerTop}>
                      {" "}
                      <Typography
                        className={classes.pricebandItem}
                        align="center"
                        color="primary"
                      >
                        Rs : {item.salePrice}
                      </Typography>
                    </div> */}
                    <div className={classes.priceBandsContainerTop}>
                      <Typography
                        className={classes.pricebandItemdiscount}
                        align="center"
                        color="textPrimary"
                      >
                        {item.discount}% discount
                      </Typography>
                    </div>
                  </div>
                  //         <Grid container >
                  //             <Grid item lg={1} md={6} sm={6}xs={6}>
                  // <Typography>Minimum Quantity : {item.minimumQuantity}</Typography>
                  //             </Grid>
                  //             <Grid item lg={6}>
                  // <Typography>Maximum Quantity : {item.minimumQuantity}</Typography>
                  //             </Grid>
                  //             <Grid item lg={6}>
                  // <Typography>Sale Price : {item.salePrice}</Typography>
                  //             </Grid>
                  //             <Grid item lg={6}>
                  // <Typography>Discount : {item.discount}</Typography>
                  //             </Grid>
                  //         </Grid>
                );
              })}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductPriceBands;

// import React from "react";

// import { Grid, Typography, Box, Paper, Hidden, TableContainer, Table, TableBody, TableRow, TableCell } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// const useStyles = makeStyles((theme) => ({
//   root: {
//     paddingTop: theme.spacing(3),
//     paddingBottom: theme.spacing(2),
//   },
//   name: {
//     // color: theme.palette.text.primary,
//     textTransform: "uppercase",
//   },
//   title: {
//     [theme.breakpoints.down("md")]: {},
//   },
//   actualValue: {
//     fontWeight: 600,
//     color: theme.palette.primary.main,
//     textTransform: "uppercase",
//     [theme.breakpoints.down("md")]: {},
//   },
//   marketValue: {
//     fontWeight: 600,
//     color: theme.palette.action.disabled,
//     textDecoration: "line-through",
//     [theme.breakpoints.down("md")]: {},
//   },
//   discountValue: {
//     fontWeight: 600,
//     color: theme.palette.green.main[400],
//     [theme.breakpoints.down("md")]: {},
//   },
//   priceBandsContainer:{
//       overflow:'auto',

//       display:'flex',
//       flexDirection:'row',
//   },
//   singlePriceBandContainer:{
// // width:'50vw'
// margin:10
//   },
// //   priceBandsContainerTop:{
// //     display:'flex',
// //     flexDirection:'row',
// //   },
// //   pricebandItem:{
// // flex:1
// //   }
// }));

// const ProductPriceBands = (props) => {
//   const classes = useStyles();
//   const {pricebands } = props;

//   console.log('pricebands',pricebands)

//   return (
//     <Grid
//       container
//       spacing={4}
//       alignItems="center"
//       justify="center"
//       className={classes.root}
//     >

//       <Grid item container lg={12} xs={12} sm={12} md={12} alignItems="center">
//         <Grid item lg={12} sm={12} md={1} xs={12}>
//           <Typography variant="h6" className={classes.title}>
//           Price bands
//           </Typography>
//         </Grid>

//         <Grid item lg={12} sm={12} md={12} xs={12} >
//       <div className={classes.priceBandsContainer}>

// {pricebands.map((item,index)=>{
//     return(
//       <TableContainer >
//           <Table className={classes.singlePriceBandContainer}>
//               <TableBody>
//                   <TableRow>
//                       <TableCell>
//                       Minimum Quantity : {item.minimumQuantity}
//                       </TableCell>
//                       <TableCell>
//                       Maximum Quantity : {item.minimumQuantity}
//                       </TableCell>
//                   </TableRow>
//                   <TableRow>
//                       <TableCell>
//                       Sale Price : {item.salePrice}
//                       </TableCell>
//                       <TableCell>
//                       Discount : {item.discount}
//                       </TableCell>
//                   </TableRow>
//               </TableBody>
//           </Table>
//       </TableContainer>
//     //         <Grid container >
//     //             <Grid item lg={1} md={6} sm={6}xs={6}>
//     // <Typography>Minimum Quantity : {item.minimumQuantity}</Typography>
//     //             </Grid>
//     //             <Grid item lg={6}>
//     // <Typography>Maximum Quantity : {item.minimumQuantity}</Typography>
//     //             </Grid>
//     //             <Grid item lg={6}>
//     // <Typography>Sale Price : {item.salePrice}</Typography>
//     //             </Grid>
//     //             <Grid item lg={6}>
//     // <Typography>Discount : {item.discount}</Typography>
//     //             </Grid>
//     //         </Grid>

//     )
// })}
//   </div>

//         </Grid>
//       </Grid>

//     </Grid>
//   );
// };

// export default ProductPriceBands;

// import React from "react";

// import { Grid, Typography, Box, Paper, Hidden } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// const useStyles = makeStyles((theme) => ({
//   root: {
//     paddingTop: theme.spacing(3),
//     paddingBottom: theme.spacing(2),
//   },
//   name: {
//     // color: theme.palette.text.primary,
//     textTransform: "uppercase",
//   },
//   title: {
//     [theme.breakpoints.down("md")]: {},
//   },
//   actualValue: {
//     fontWeight: 600,
//     color: theme.palette.primary.main,
//     textTransform: "uppercase",
//     [theme.breakpoints.down("md")]: {},
//   },
//   marketValue: {
//     fontWeight: 600,
//     color: theme.palette.action.disabled,
//     textDecoration: "line-through",
//     [theme.breakpoints.down("md")]: {},
//   },
//   discountValue: {
//     fontWeight: 600,
//     color: theme.palette.green.main[400],
//     [theme.breakpoints.down("md")]: {},
//   },
//   priceBandsContainer:{
//       overflowX:'auto',
//       width:'100%',
//       display:'flex',
//       flexDirection:'row',
//   },
//   singlePriceBandContainer:{
// maxWidth:'100vw',
// display:'flex',
// flexDirection:'column'
//   },
//   priceBandsContainerTop:{
//     display:'flex',
//     flexDirection:'row',
//   },
//   pricebandItem:{
// flex:1
//   }
// }));

// const ProductPriceBands = (props) => {
//   const classes = useStyles();
//   const {pricebands } = props;

//   console.log('pricebands',pricebands)

//   return (
//     <Grid
//       container
//       spacing={4}
//       alignItems="center"
//       justify="center"
//       className={classes.root}
//     >

//       <Grid item container lg={12} xs={12} sm={12} md={12} alignItems="center">
//         <Grid item lg={12} sm={12} md={1} xs={12}>
//           <Typography variant="h6" className={classes.title}>
//           Price bands
//           </Typography>
//         </Grid>

//         <Grid item lg={12} sm={12} md={12} xs={12} >
//       <div className={classes.priceBandsContainer}>

// {pricebands.map((item,index)=>{
//     return(
//        <Box className={classes.singlepriceBandsContainer}>
// <div className={classes.priceBandsContainerTop}> <Typography className={classes.pricebandItem}>Minimum Quantity : {item.minimumQuantity}</Typography><Typography className={classes.pricebandItem}>Maximum Quantity : {item.minimumQuantity}</Typography></div>
// <div className={classes.priceBandsContainerTop}> <Typography className={classes.pricebandItem}>Sale Price : {item.salePrice}</Typography><Typography className={classes.pricebandItem}>Discount : {item.discount}</Typography></div>

//        </Box>
//     //         <Grid container >
//     //             <Grid item lg={1} md={6} sm={6}xs={6}>
//     // <Typography>Minimum Quantity : {item.minimumQuantity}</Typography>
//     //             </Grid>
//     //             <Grid item lg={6}>
//     // <Typography>Maximum Quantity : {item.minimumQuantity}</Typography>
//     //             </Grid>
//     //             <Grid item lg={6}>
//     // <Typography>Sale Price : {item.salePrice}</Typography>
//     //             </Grid>
//     //             <Grid item lg={6}>
//     // <Typography>Discount : {item.discount}</Typography>
//     //             </Grid>
//     //         </Grid>

//     )
// })}
//   </div>

//         </Grid>
//       </Grid>

//     </Grid>
//   );
// };

// export default ProductPriceBands;

// import React from "react";

// import { Grid, Typography, Box, Paper, Hidden } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// const useStyles = makeStyles((theme) => ({
//   root: {
//     paddingTop: theme.spacing(3),
//     paddingBottom: theme.spacing(2),
//   },
//   name: {
//     // color: theme.palette.text.primary,
//     textTransform: "uppercase",
//   },
//   title: {
//     [theme.breakpoints.down("md")]: {},
//   },
//   actualValue: {
//     fontWeight: 600,
//     color: theme.palette.primary.main,
//     textTransform: "uppercase",
//     [theme.breakpoints.down("md")]: {},
//   },
//   marketValue: {
//     fontWeight: 600,
//     color: theme.palette.action.disabled,
//     textDecoration: "line-through",
//     [theme.breakpoints.down("md")]: {},
//   },
//   discountValue: {
//     fontWeight: 600,
//     color: theme.palette.green.main[400],
//     [theme.breakpoints.down("md")]: {},
//   },
// }));

// const ProductPriceBands = (props) => {
//   const classes = useStyles();
//   const {pricebands } = props;

//   console.log('pricebands',pricebands)

//   return (
//     <Grid
//       container
//       spacing={4}
//       alignItems="center"
//       justify="center"
//       className={classes.root}
//     >

//       <Grid item container lg={12} xs={12} sm={12} md={12} alignItems="center">
//         <Grid item lg={1} sm={4} md={1} xs={12}>
//           <Typography variant="h6" className={classes.title}>
//           Price bands
//           </Typography>
//         </Grid>
//         <Hidden smDown>

//         <Grid item lg={1} sm={1} md={1} xs={1}>
//           <Typography>:</Typography>
//         </Grid>
//         </Hidden>
//         <Grid item lg={10} sm={12} md={10} xs={12}>

// {pricebands.map((item,index)=>{
//     return(
//         <Box>
//             <Grid container className={classes.singlePriceBandContainer}>
//                 <Grid item lg={6}>
//     <Typography>Minimum Quantity : {item.minimumQuantity}</Typography>
//                 </Grid>
//                 <Grid item lg={6}>
//     <Typography>Maximum Quantity : {item.minimumQuantity}</Typography>
//                 </Grid>
//                 <Grid item lg={6}>
//     <Typography>Sale Price : {item.salePrice}</Typography>
//                 </Grid>
//                 <Grid item lg={6}>
//     <Typography>Discount : {item.discount}</Typography>
//                 </Grid>
//             </Grid>
//         </Box>
//     )
// })}

//         </Grid>
//       </Grid>

//     </Grid>
//   );
// };

// export default ProductPriceBands;

// import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import { Grid, Typography } from "@material-ui/core";

// const useStyles = makeStyles((theme) => ({
//   item: {
//     marginTop: theme.spacing(1),
//     marginBottom: theme.spacing(1),
//   },
// }));

// const ProductPriceBands = ({ name, description, salePrice, marketPrice }) => {
//   const classes = useStyles();

//   return (
//     <div className={classes.root}>
//       <Grid container>
//         <Grid item lg={12} sm={12} md={12} xs={12} className={classes.item}>
//           <Typography variant="h6">{name}</Typography>
//         </Grid>
//         <Grid item lg={12} sm={12} md={12} xs={12} className={classes.item}>
//           <Typography variant="h6" color="textSecondary">
//             {description}
//           </Typography>
//         </Grid>

//         <Grid item lg={12} sm={12} md={12} xs={12} className={classes.item}>
//           <Typography variant="h6">Deal Price : {salePrice}</Typography>
//         </Grid>
//         <Grid item lg={12} sm={12} md={12} xs={12} className={classes.item}>
//           <Typography variant="h6">Market Price : {marketPrice}</Typography>
//         </Grid>
//         <Grid item lg={12} sm={12} md={12} xs={12} className={classes.item}>
//           <Typography variant="h6">
//             Discount : {100 - (100 * salePrice) / marketPrice} % off
//           </Typography>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };
// export default ProductPriceBands;
