import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import {
  AppBar,
  Toolbar,
  Grid,
  Breadcrumbs,
  Container,
} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { useParams } from "react-router-dom";
import MainCateogryItem from "./MainCategoryItem";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    backgroundColor: theme.palette.background.paper,
  },
  productList: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));
const MainCategoryListing = (props) => {
  const { type, category } = useParams();
  const classes = useStyles();
  // const maincat = useSelector((state) => state.shop.mainCategory);
  return (
    <div className={classes.root}>
      <div>
        <AppBar position="static" color="default" elevation={1}>
          <Toolbar>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link>{type}</Link>
                  <Link>{category}</Link>
                </Breadcrumbs>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container className={classes.productList}>
            {/* {maincat.subcategoryList &&
            maincat.subcategoryList.length > 0 &&
            maincat.subcategoryList.map((subcat, index) => {
              return (
                <Grid item lg={6} md={6} xs={6} sm={6} key={index}>
                  <MainCateogryItem data={subcat} />
                </Grid>
              );
            })} */}
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default MainCategoryListing;
