import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Divider,
  CardContent,
  Typography,
  TextField,
  Grid,
  IconButton,
  Button,
  CardHeader,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import ModalComponent from "../../../Modals/ModalComponent";
import moment from "moment";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "auto",
  },
  CloseIcon: {
    color: "white",
  },
  card: {
    width: "60%",
    maxHeight: "98vh",
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
  rateContainer: {
    display: "flex",
    padding: "1vh 0vh",
    alignItems: "center",
  },
  input: {
    margin: "2vh 0vh",
  },
  btnCOntainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const AddComment = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    title: "",
    comment: "",
    commentDate: moment().format("DD-MM-YYYY"),
    rating: null,
  });

  const [hover, setHover] = React.useState(-1);
  const [error, setError] = React.useState();

  const handleChange = (e) => {
    setError();
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleRatingChange = (value) => {
    // console.log("value", value);
    setState({
      ...state,
      rating: value,
    });
  };
  const handleCommentSave = (e) => {
    state.comment === "" ? setError("Description") : props.save(state);
    // setState({
    //   title: "",
    //   comment: "",
    //   commentDate: moment().format("DD-MM-YYYY"),
    //   rating: null,
    // });
  };
  return (
    <ModalComponent className={classes.root} open={props.open}>
      <div className={classes.card}>
        <Grid container justify="flex-end">
          <IconButton size={30}>
            <CloseIcon className={classes.CloseIcon} />
          </IconButton>
        </Grid>
        <Card>
          <CardHeader title={`Rate & Review`} />
          <Divider />

          <CardContent>
            <Typography variant="body1">Rate This Product</Typography>
            <div className={classes.rateContainer}>
              <Rating
                name="hover-feedback"
                value={state.rating}
                precision={0.5}
                onChange={(event, newValue) => {
                  handleRatingChange(newValue);
                }}
                onChangeActive={(event, newHover) => {
                  setHover(newHover);
                }}
              />
              {state.rating !== null && (
                <Box ml={2}>
                  {labels[hover !== -1 ? hover : state.rating]}
                  {state.rating}
                </Box>
              )}
            </div>
          </CardContent>

          <Divider />
          <CardContent>
            <Typography variant="body1">Review This Product</Typography>
            <TextField
              className={classes.input}
              size="small"
              fullWidth
              variant="outlined"
              label="Title"
              placeholder="Title"
              value={state.title}
              name="title"
              onChange={(e) => handleChange(e)}
            />
            <TextField
              className={classes.input}
              size="small"
              fullWidth
              variant="outlined"
              label="Description"
              multiline
              error={error === "Description"}
              rows={10}
              placeholder="Description"
              value={state.comment}
              name="comment"
              onChange={(e) => handleChange(e)}
            />
          </CardContent>
          <Divider />
          <CardContent>
            <div className={classes.btnCOntainer}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => props.close()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => handleCommentSave(e)}
              >
                Save
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </ModalComponent>
  );
};
export default AddComment;
