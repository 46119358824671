import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PopupItem from "./PopupItem";
import { Grid } from "@material-ui/core";
import { DISPLAY_PRODUCT_TYPE } from "../../redux/utils/utils";
import PopupNoType from "./PopupItemnotypes";
const useStyles = makeStyles((theme) => ({}));
const ListMenu = ({ data }) => {
  const classes = useStyles();
  console.log("list menu data", data);
  return DISPLAY_PRODUCT_TYPE
    ? data.map((data, index) => (
        <Grid item key={index}>
          <PopupItem index={index} item={data} />
        </Grid>
      ))
    : data[0].categoryList.map((data, index) => {
        return (
          <Grid item>
            <PopupNoType item={data} />
          </Grid>
        );
      });
};
export default ListMenu;
