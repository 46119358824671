import React from "react";

import PT from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Divider } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "30vh",
    maxHeight: "30vh",
    overflow: "none",
  },

  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  comment: {
    // width: "100%",
    // height: "15vh",
    // whiteSpace: "nowrap",
    overflow: "hidden",
    // textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
  },
}));

const propTypes = {
  urlAvatar: PT.string,
  username: PT.string,
  date: PT.string,
  rating: PT.number,
  content: PT.string,
};

const ProductCommentItem = (props) => {
  const { urlAvatar, username, title, commentDate, rating, comment } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const stars = new Array();

  return (
    <div>
      <Card className={classes.root} elevation={1} variant="outlined">
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              {username.slice(0, 1).toUpperCase()}
            </Avatar>
          }
          action={<Rating name="read-only" value={rating} readOnly />}
          title={username}
          subheader={commentDate}
        />
        <CardContent>
          <Typography variant="body1" color="textPrimary" noWrap>
            {title}
          </Typography>

          <Typography
            variant="subtitle1"
            color="textSecondary"
            component="p"
            className={classes.comment}
          >
            {comment}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

ProductCommentItem.propTypes = propTypes;

export default ProductCommentItem;
