import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";

//components
// Publick
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

// Home
import Home from "../components/home/HomeContainer";

// Classification
import FilterSite from "../components/main/FilterSite";

// Detail
import ProductDetailSite from "../components/product/ProductDetailSite";

// Cart
import CartSite from "../components/cart/CartSite";

import FavouritesSite from "../components/favourites/FavouritesSite";

// Register & Login
import LoginSite from "../components/user/LoginSite";
import RegisterSite from "../components/user/RegisterSite";
import FooterPage from "../components/footer/FooterPages";
import SubCategoryProductsList from "../components/main/SubCategoryProducts";
import UserAccount from "../components/user/UserAccount";
import LoginModal from "../components/Modals/LoginModal";
import SpinnerComponent from "../components/Spinners/SpinnerComponent";
import UtilComponent from "../components/Util/UtilComponent";
import {
  loginuser,
  logInOnLoad,
  logoutUser,
  getUserAccount,
} from "../redux/action/useractions";
import { getUserCart, getUserFavourites } from "../redux/selector";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { Hidden } from "@material-ui/core";

import ProfileInformation from "../components/user/UserAccount/ProfileInformation";
import ManageAddresses from "../components/user/UserAccount/ManageAddresses/index";
import SavedCards from "../components/user/UserAccount/SavedCards/SavedCards";
import ResetPassword from "../components/user/UserAccount/ResetPassword";
import UserOrders from "../components/user/UserAccount/Orders/UserOrders";
import Logout from "../components/user/UserAccount/Logout";
import MessagesSite from "../components/messages/MessagesSite";
import getExpireTime from "../utils/getExpireTime";

import {
  getvisitortoken,
  setUserLocation,
  pushtohistory,
  uploadhistory,
} from "../redux/action/historyActions";
import Axios from "axios";
import MainCategoryListing from "../components/ProductListing/MainCateogyList";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../components/Errorboundary/Errorboundary";
import SubCateogryListing from "../components/ProductListing/SubCateogryListing";
const useStyles = makeStyles((theme) => ({}));
const RouteApp = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [login, setLogin] = React.useState(false);
  const userlogin = useSelector((state) => state.user.isLogged);
  // React.useEffect(() => {
  //   // setLogin(userlogin);
  //   getExpireTime().then((res) =>
  //     // res === undefined
  //     // ? dispatch(logoutUser())
  //     {
  //       console.log("get expire time ", res);
  //       res !== undefined
  //         ? res
  //           ? dispatch(logoutUser())
  //           : setLogin(userlogin)
  //         : dispatch(logoutUser());
  //     }
  //   );
  // }, []);

  const handleLogin = (email, password) => {
    dispatch(loginuser({ email, password }));
  };

  const token = localStorage.getItem("tkn");

  const effect = () => {
    setLogin(true);
    dispatch(logInOnLoad());
    dispatch(getUserCart());
    dispatch(getUserFavourites());
    dispatch(getUserAccount());
  };
  const checktokenchange = async () => {
    if (token === "undefined" || token === null) {
      dispatch(logoutUser());
    }
    if (token !== null) {
      getExpireTime().then((res) =>
        // res === undefined
        // ? dispatch(logoutUser())
        {
          console.log("get expire time ", res);
          res !== undefined
            ? res
              ? dispatch(logoutUser())
              : effect()
            : dispatch(logoutUser());
        }
      );
    }
  };
  React.useLayoutEffect(() => {
    checktokenchange();
  }, [token]);

  const loginModalOpen = useSelector((state) => state.utilReducer.loginmodal);
  const spinner = useSelector((state) => state.spinner.spinner);

  return (
    <div>
      <UtilComponent token={token} />
      {spinner && <SpinnerComponent />}
      {loginModalOpen && <LoginModal />}
      <Route
        children={({ location }) => {
          return <Header location={location} />;
        }}
      />
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/campaign/:stub?" component={Home} />
          {/* <Route
            exact
            path="/category/:type/:category/:stub?"
            component={MainCategoryListing}
          /> */}
          <Route
            exact
            path="/category/:type/:category/:stub?"
            component={SubCateogryListing}
          />
          <Route
            exact
            path="/cart/:stub?"
            render={(props) => {
              return (
                <CartSite
                  isLogin={login}
                  handleLogin={handleLogin}
                  {...props}
                />
              );
            }}
          />
          <Route
            exact
            path="/favourites/:stub?"
            children={() => {
              return (
                <FavouritesSite isLogin={login} handleLogin={handleLogin} />
              );
            }}
          />{" "}
          <Route
            exact
            path="/messages/:stub?"
            children={() => {
              return <MessagesSite isLogin={login} handleLogin={handleLogin} />;
            }}
          />
          <Route
            exact
            path="/login/:stub?"
            children={(props) => {
              return (
                <LoginSite
                  isLogin={login}
                  handleLogin={handleLogin}
                  {...props}
                />
              );
            }}
          />
          <Route
            exact
            path="/product/:browsingname/:id/:stub?"
            component={ProductDetailSite}
          />
          <Route
            exact={isWidthUp("md", props.width) ? false : true}
            path="/useraccount"
            component={UserAccount}
          />
          <Route exact path="/register/:stub?" component={RegisterSite} />
          <Route
            exact
            path="/products/:subcategory/:stub?"
            component={FilterSite}
          />
          <Route
            exact
            path={`/footer/:topicId/:stub?`}
            component={FooterPage}
          />
          <Hidden smUp>
            <Route exact path="/useraccount/myorders" component={UserOrders} />
            <Route
              exact
              path="/useraccount/addresses"
              component={ManageAddresses}
            />
            <Route
              exact
              path="/useraccount/profile"
              component={ProfileInformation}
            />
            <Route
              exact
              path="/useraccount/savedcards"
              component={SavedCards}
            />
            <Route
              exact
              path="/useraccount/resetpassword"
              component={ResetPassword}
            />
            <Route exact path="/useraccount/orders" component={SavedCards} />
            <Route exact path="/useraccount/logout" component={Logout} />
          </Hidden>
        </Switch>
      </ErrorBoundary>
      <Route component={Footer} />
    </div>
  );
};
export default withWidth()(RouteApp);
