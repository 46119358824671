import React from "react";
import PT from "prop-types";
import {
  incrementCartQuantity,
  decrementCartQuantity,
  removeProductFromCart,
  openPreferenceModal,
  openConfigurationModal,
} from "../../redux/selector";
import { connect, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  Grid,
  IconButton,
  Divider,
  Hidden,
  Container,
  Paper,
  Button,
  Link,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteForeverOutlined";
import PlusIcon from "@material-ui/icons/AddCircle";
import MinusIcon from "@material-ui/icons/RemoveCircle";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  quantity: {
    borderColor: theme.palette.secondary,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  cover: {
    width: 100,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: 150,
    },
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  imgContainer: {},
  productInfoContainer: {
    paddingLeft: theme.spacing(1),
  },
  container: {},
  productName: {
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const propTypes = {
  id: PT.number,
  name: PT.string,
  marketPrice: PT.number,
  salePrice: PT.number,
  srcImg: PT.string,
  selectedOptions: PT.object,
  quantity: PT.number,
  configurationId: PT.number,
  specificationId: PT.number,
  bomId: PT.number,
};

const OrderItem = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    id,
    name,
    salePrice,
    srcImg,
    selectedOptions,

    quantity,
    configurationId,
    specificationId,

    type,
    category,
    subcategory,
    browsingName,

    itemIndex,
    item,
  } = props;

  const optionKeys = Object.keys(selectedOptions);
  const totalprice = quantity * salePrice;
  const handleNavigate = () => {
    props.history.push(`/product/${browsingName}/${id}`);
  };
  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <Grid
          item
          lg={3}
          xs={12}
          container
          justify="center"
          alignItems="center"
          className={classes.imgContainer}
        >
          <img
            onClick={() => handleNavigate()}
            className={classes.cover}
            src={srcImg}
          />
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems="flex-start"
          justify="space-evenly"
          lg={8}
          xs={12}
          className={classes.productInfoContainer}
        >
          <Grid item>
            <Typography
              variant="h5"
              className={classes.productName}
              component={Link}
              onClick={() => handleNavigate()}
            >
              {name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" color="textSecondary">
              {"UnitPrice : $"}
              {salePrice}
            </Typography>
          </Grid>
          {optionKeys.map((ele, index) =>
            optionKeys[index] === "QUANTITY" ? null : (
              <Grid item key={index}>
                <Typography variant="subtitle1" color="textSecondary">
                  {optionKeys[index]}: {selectedOptions[optionKeys[index]]}
                </Typography>
              </Grid>
            )
          )}
          {optionKeys.map((ele, index) =>
            optionKeys[index] === "QUANTITY" ? (
              <Grid item key={index}>
                <Grid container alignItems="center" direction="row">
                  <Grid item>
                    <Typography variant="subtitle1" color="textSecondary">
                      {optionKeys[index]} :
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <Grid item>
                        <IconButton
                          size="small"
                          color="primary"
                          // disabled={
                          //   selectedOptions[optionKeys[index]].selectedOptions
                          //     .QUANTITY < 2
                          // }
                          onClick={(e) => {
                            console.log("Quantity change");
                            // console.log(
                            //   selectedOptions[optionKeys[index]],
                            //   "quantity cvhanhe"
                            // );
                            dispatch(
                              decrementCartQuantity({ id, selectedOptions })
                            );
                          }}
                        >
                          <MinusIcon />
                        </IconButton>
                      </Grid>
                      <Grid
                        item
                        className={classes.quantity}
                        // alignItems="center"
                        // justify="center"
                      >
                        <Typography
                          align="center"
                          variant="subtitle1"
                          color="textSecondary"
                        >
                          {selectedOptions[optionKeys[index]]}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          size="small"
                          color="secondary"
                          onClick={() => {
                            dispatch(
                              incrementCartQuantity({ id, selectedOptions })
                            );
                          }}
                        >
                          <PlusIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Button
                      color="primary"
                      variant="text"
                      onClick={() => {
                        dispatch(removeProductFromCart(id, item));
                      }}
                    >
                      Remove
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : null
          )}
        </Grid>
      </Grid>
      <Divider />
    </div>
  );
};

OrderItem.propTypes = propTypes;

export default withRouter(OrderItem);
