import React from "react";
import RouteApp from "./router/Route"; //路由配置
import { Provider } from "react-redux";
import Store from "./redux/store";
import axios from "axios";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { orange, blue, green } from "@material-ui/core/colors";
import Gray from "@material-ui/core/colors/grey";
import {
  BrowserRouter as Router,
  Route,
  useLocation,
  Redirect,
  useParams,
} from "react-router-dom";
const theme = createMuiTheme({
  palette: {
    type: "dark",
    background: {
      lighter: "rgba(0,0,0,0.6)",
      light: "#3B3B3B",
      main: "#000",
      dark: "#212121",
      paper: "#3B3B3B",
    },
    primary: {
      main: "#FFDD00",
      // contrastText: "#fff",
    },
    // secondary: {
    //   main: "#DB1C25",
    // },

    // blue: {
    //   main: blue[100],
    // },

    gray: { main: Gray[800], light: Gray[100] },
    green: {
      main: green,
    },
  },
  shape: {
    borderRadius: 0,
  },

  //   grayBackground: "#f7f7f7",

  //   secondary: {
  //     main: "#D31D24",
  //   },

  //   text: {
  //     // primary: "#fff",
  //     // secondary: "#fff",
  //   },
  //   divider: "rgba(255, 255, 255, 0.12)",

  // },
  // typography: {
  //   // In Chinese and Japanese the characters are usually larger,
  //   // so a smaller fontsize may be appropriate.
  //   primary: "red",
  //   fontSize: 12,
  // },
});

const App = () => {
  return (
    <Router>
      <Provider store={Store()}>
        <ThemeProvider theme={theme}>
          <RouteApp />
        </ThemeProvider>
      </Provider>
    </Router>
  );
};

export default App;
