import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Card,
  CardContent,
} from "@material-ui/core";

import { states } from "../../../../data/stateList";

const useStyles = makeStyles((theme) => ({
  normalBg: {
    backgroundColor: theme.palette.grayBackground,
  },
  root: {
    // height: "80%",
    // overflow: "auto",
    // backgroundColor: theme.palette.grayBackground,
    borderRadius: 4,
    border: "1px solid gray",
    padding: theme.spacing(1),
    // margin: theme.spacing(10),

    // overflow: "auto",

    // marginBottom: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(1),
    // width: "100%",
    // padding: theme.spacing(5),
    // paddingTop: theme.spacing(10),
    // paddingBottom: theme.spacing(10),
  },
  title: {
    fontSize: "1.3rem",
    fontWeight: 500,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
  },
  TextField: {
    backgroundColor: theme.palette.background.paper,
  },
}));
const AddNewAddress = ({ array, edit, close, open, index }) => {
  const classes = useStyles();
  const [firstNamestate, setFirstName] = React.useState("");
  const [lastNamestate, setLastName] = React.useState("");
  const [line1state, setline1] = React.useState();
  const [line2state, setline2] = React.useState();
  const [line3state, setline3] = React.useState();
  const [citystate, setCity] = React.useState();
  const [statestate, setState] = React.useState();
  const [countrystate, setCountry] = React.useState();
  const [zipCodestate, setzipCode] = React.useState();
  const [addressNamestate, setaddressName] = React.useState();
  // const [defaultAddressstate, setDefaultAddressState] = React.useState(false);

  //empty every field

  const Empty = () => {
    setFirstName();
    setLastName();
    setzipCode();
    setline1();
    setline2();
    setCity();
    setState();
    setline3();
    setCountry();

    setaddressName();
  };

  //save btn

  const saveNewAddress = () => {
    let newArray = [];
    newArray.push({
      id: id,
      firstName: firstNamestate,
      lastName: lastNamestate,
      zipCode: zipCodestate,
      line1: line1state,
      line2: line2state,
      line3: line3state,
      city: citystate,
      state: statestate,
      country: countrystate,
      addressName: addressNamestate,
    });
    edit(newArray, id);
    close();
    Empty();
    // console.log(newArray, "new address");
  };

  //cancel btn action

  const cancelAdding = () => {
    Empty();
    close();
  };
  let item = array[index];
  const {
    id,
    firstName,
    lastName,
    zipCode,
    line1,
    line2,
    line3,
    city,
    state,
    country,
    addressName,
  } = item;

  React.useEffect(() => {
    window.scrollTo({
      top: 20,

      behavior: "smooth",
    });
  }, [
    firstName,
    lastName,
    zipCode,
    line1,
    line2,
    line3,
    city,
    state,
    country,
    addressName,
  ]);

  return (
    <Grid item lg={12} className={classes.container}>
      <Card className={classes.normalBg}>
        {/* <CardHeader title="Add New Address" /> */}
        {/* <Divider /> */}
        <CardContent>
          <Grid container spacing={3}>
            <Grid
              item
              lg={12}
              container
              alignItems="center"
              justify="space-between"
              sm={12}
              xs={12}
              md={12}
            >
              <Typography className={classes.title}>
                Edit New Address
              </Typography>
            </Grid>
            <Grid item lg={6} sm={12} xs={12} md={12}>
              <TextField
                variant="outlined"
                fullWidth
                size="medium"
                label="First Name"
                placeholder="First Name"
                defaultValue={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className={classes.TextField}
              />
            </Grid>
            <Grid item lg={6} sm={12} xs={12} md={12}>
              <TextField
                variant="outlined"
                fullWidth
                size="medium"
                label="Last Name"
                placeholder="Last Name"
                defaultValue={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className={classes.TextField}
              />
            </Grid>
            <Grid item lg={12} sm={12} xs={12} md={12}>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                rowsMax={2}
                size="medium"
                label="Line 1"
                placeholder="Address Line 1"
                defaultValue={line1}
                onChange={(e) => setline1(e.target.value)}
                className={classes.TextField}
              />
            </Grid>
            <Grid item lg={12} sm={12} xs={12} md={12}>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                rowsMax={2}
                size="medium"
                label="Line 2"
                placeholder="Address Line 2"
                defaultValue={line2}
                onChange={(e) => setline2(e.target.value)}
                className={classes.TextField}
              />
            </Grid>
            <Grid item lg={12} sm={12} xs={12} md={12}>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                rowsMax={2}
                size="medium"
                label="Line 3"
                placeholder="Address Line 3"
                defaultValue={line3}
                onChange={(e) => setline3(e.target.value)}
                className={classes.TextField}
              />
            </Grid>
            <Grid item lg={6} sm={12} xs={12} md={12}>
              <TextField
                variant="outlined"
                fullWidth
                size="medium"
                label="City"
                placeholder="City"
                defaultValue={city}
                onChange={(e) => setCity(e.target.value)}
                className={classes.TextField}
              />
            </Grid>
            <Grid item lg={6} sm={12} xs={12} md={12}>
              <FormControl
                variant="outlined"
                label="State"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel id="demo-simple-select-filled-label">
                  State
                </InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  defaultValue={state}
                  // value={statestate}
                  placeholder="State"
                  label="State"
                  className={classes.TextField}
                  onChange={(e) => setState(e.target.value)}
                >
                  {states.map((value, index) => {
                    return (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={6} sm={12} xs={12} md={12}>
              <TextField
                variant="outlined"
                fullWidth
                size="medium"
                label="Country"
                placeholder="Country"
                defaultValue={country}
                onChange={(e) => setCountry(e.target.value)}
                className={classes.TextField}
              />
            </Grid>
            <Grid item lg={6} sm={12} xs={12} md={12}>
              <TextField
                variant="outlined"
                fullWidth
                size="medium"
                label="Zip Code"
                placeholder="Zip Code"
                defaultValue={zipCode}
                onChange={(e) => setzipCode(e.target.value)}
                className={classes.TextField}
              />
            </Grid>{" "}
            <Grid item lg={12} sm={12} xs={12} md={12}>
              <FormControl component="fieldset" color="primary">
                <FormLabel component="legend">Address Type</FormLabel>
                <RadioGroup
                  row
                  aria-label="Address Type"
                  name="Address Type"
                  onChange={(e) => setaddressName(e.target.value)}
                >
                  <FormControlLabel
                    color="primary"
                    value="Residence"
                    control={<Radio color="primary" />}
                    label="Residence"
                    checked={
                      addressNamestate === "Residence" ||
                      addressName === "Residence"
                    }
                  />
                  <FormControlLabel
                    color="primary"
                    value="Work"
                    control={<Radio color="primary" />}
                    label="Work"
                    checked={
                      addressName === "Work" || addressNamestate === "Work"
                    }
                  />
                  <FormControlLabel
                    color="primary"
                    value="Office"
                    control={<Radio color="primary" />}
                    label="Office"
                    checked={
                      addressName === "Office" || addressNamestate === "Office"
                    }
                  />

                  <FormControlLabel
                    color="primary"
                    value="Other"
                    control={<Radio color="primary" />}
                    label="Other"
                    checked={
                      addressName === "Other" || addressNamestate === "Other"
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item lg={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                elevation={0}
                onClick={() => saveNewAddress()}
              >
                Save
              </Button>
            </Grid>
            <Grid item lg={2}>
              <Button
                fullWidth
                variant="text"
                color="secondary"
                size="large"
                onClick={() => cancelAdding()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default React.memo(AddNewAddress);
