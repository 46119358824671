const initialState = {
  spinner: false,
};

export const spinnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SPINNER_START":
      //   SpinnerService.showSpinner("nunchospinner");
      return { ...state, spinner: true };
    case "SPINNER_STOP":
      //   SpinnerService.hideSpinner("nunchospinner");
      return { ...state, spinner: false };
    default:
      return state;
  }
};
export default spinnerReducer;
