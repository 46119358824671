import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Grid, Paper, Typography } from "@material-ui/core";

class BillDoneStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timerId: "",
      seconds: 3,
    };
  }
  componentDidMount() {
    let time = 3;
    this.timerinId = setInterval(() => {
      this.setState({ seconds: time-- });
    }, 1000);
    this.timeoutId = setTimeout(() => {
      window.location.href = "/";
    }, 1000000);
  }
  componentWillMount() {
    clearTimeout(this.timeoutId);
    clearInterval(this.timeinId);
  }
  render() {
    const { seconds } = this.state;

    return (
      <Grid container alignItems="center" justify="center">
        <Grid item>
          <Paper style={{ padding: 10 }} elevation={0}>
            <Typography>
              This page will jump to home page in {seconds} seconds,or click{" "}
              <Link to="/">HERE</Link> to jump.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default BillDoneStep;
