import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grayBackground,
    borderRadius: 4,
    border: "1px solid gray",
    marginBottom: theme.spacing(2),
  },
  container: {
    width: "100%",
    padding: theme.spacing(2),
  },
  title: {
    fontSize: "1.3rem",
    fontWeight: 500,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
  },
  TextField: {
    backgroundColor: "white",
  },
}));

let CardTypes = ["Visa", "Master", "American Express"];

const AddNewCreditCard = ({ array, add, close }) => {
  const classes = useStyles();
  const [cardNumber, setCardNumber] = React.useState("");
  const [cardType, setCardType] = React.useState("");
  const [cvv, setCvv] = React.useState();
  const [expiryDate, setExpirydate] = React.useState();

  //empty every field

  const Empty = () => {
    setCardNumber();
    setCardType();
    setCvv();
    setExpirydate();
  };

  //save btn

  const SaveNewCard = () => {
    let newArray = [];
    newArray.push({
      cardNumber,
      cardType,
      cvv,
      expiryDate,
    });
    const newObj = {
      cardNumber,
      cardType,
      cvv,
      expiryDate,
    };
    add(newObj);
    close();
    Empty();
    // console.log(newArray, "new address");
  };

  //cancel btn action

  const cancelAdding = () => {
    Empty();
    close();
  };

  return (
    <Grid
      item
      container
      lg={12}
      sm={12}
      xs={12}
      md={12}
      justify="space-between"
      alignItems="center"
      className={classes.root}
    >
      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} xs={12} md={12}>
            <Typography className={classes.title}>
              Add new credit/debit card
            </Typography>
          </Grid>
          <Grid item lg={4} sm={12} xs={12} md={12}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <InputLabel id="demo-simple-select-filled-label">
                Card Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={cardType}
                placeholder="Card Type"
                label="Card Type"
                className={classes.TextField}
                onChange={(e) => setCardType(e.target.value)}
              >
                {CardTypes.map((value) => {
                  return <MenuItem value={value}>{value}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={8} sm={12} xs={12} md={12}>
            <TextField
              variant="outlined"
              fullWidth
              size="medium"
              placeholder="Card Number"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
              className={classes.TextField}
            />
          </Grid>

          <Grid item lg={6} sm={12} xs={12} md={12}>
            <TextField
              variant="outlined"
              fullWidth
              size="medium"
              placeholder="Cvv"
              value={cvv}
              onChange={(e) => setCvv(e.target.value)}
              className={classes.TextField}
            />
          </Grid>

          <Grid item lg={6} sm={12} xs={12} md={12}>
            <TextField
              variant="outlined"
              fullWidth
              size="medium"
              placeholder="Expiry Date"
              value={expiryDate}
              onChange={(e) => setExpirydate(e.target.value)}
              className={classes.TextField}
            />
          </Grid>

          <Grid item lg={2}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              size="large"
              elevation={0}
              onClick={() => SaveNewCard()}
            >
              Save
            </Button>
          </Grid>
          <Grid item lg={2}>
            <Button
              fullWidth
              variant="text"
              color="secondary"
              size="large"
              onClick={() => cancelAdding()}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};
export default AddNewCreditCard;
