import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import { Route, withRouter, useParams, useLocation } from "react-router-dom";

import ProductInfo from "./ProductInfo";
import ProductComment from "./ProductComment";
import ProductAvisory from "./ProductAvisory";
import RecommendCarousel from "../carousel/RecommendCarousel";

import axios from "axios";
import PT from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";
import { addProductToCart, getSingleProduct } from "../../redux/selector";

import clsx from "clsx";
import ProductsTabs from "./ProductsTabs";
import EnquiryProduct from "../Modals/Enquiry/EnquiryModal";
import { pushtohistory } from "../../redux/action/historyActions";
import { red } from "@material-ui/core/colors";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  Container: {
    // backgroundColor: ,
    minHeight: "90vh",
    padding: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
const ProductDetailsSite = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [enqiryModal, setEnquiryModal] = React.useState(false);
  // const id = props.location.pathname.split("/")[2];

  let { id } = useParams();

  const reducerProduct = useSelector((state) => state.activeProduct);

  const [product, setProduct] = React.useState({ id: null });

  React.useEffect(() => {
    dispatch(getSingleProduct(id));
  }, [id]);
  React.useEffect(() => {
    setProduct(reducerProduct);
  }, [reducerProduct]);

  //stub

  const { stub } = useParams();
  const location = useLocation().pathname;

  React.useEffect(() => {
    let newlocation = location === "/" ? location : location.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));
  }, []);
  // console.log("reducer Product", product);
  return (
    <div className={classes.root}>
      {enqiryModal && (
        <EnquiryProduct
          open={enqiryModal}
          productId={reducerProduct.id}
          productName={reducerProduct.name}
          close={() => setEnquiryModal(false)}
        />
      )}
      <Container maxWidth="xl" className={clsx(classes.Container)}>
        {product !== null && (
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ProductInfo
                product={product}
                openEnquiry={() => setEnquiryModal(true)}
              />
            </Grid>
            <Grid item lg={12} sm={12} xs={12} md={12}>
              <ProductsTabs
                description={reducerProduct.description}
                overview={reducerProduct.overview}
                details={reducerProduct.details}
                specifications={reducerProduct.specifications}
                length={reducerProduct.length}
                weight={reducerProduct.weight}
                breadth={reducerProduct.breadth}
                height={reducerProduct.height}
                uom={reducerProduct.uom}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ProductComment data={reducerProduct.reviews} />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <RecommendCarousel data={reducerProduct.relatedProducts} />
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
};
export default withRouter(ProductDetailsSite);
