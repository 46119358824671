import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import OrderBar from "./OrderBar";
import OrderEdit from "./OrderEdit";
import OrderReview from "./OrderReview";
import ReviewBill from "./ReviewBill";
import { useSelector } from "react-redux";
import {
  Grid,
  Typography,
  Divider,
  Button,
  Container,
  Card,
  CardHeader,
  CardContent,
  Hidden,
  Box,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {},
  Card: {
    maxHeight: "80vh",
    overflow: "auto",
  },
  bottomButtonContainer: {
    marginTop: theme.spacing(2),
  },
  noProductContainer: {
    height: "100%",
    width: "100%",
  },
  noProduct: {
    textAlign: "center",
    color: theme.palette.text.primary,
  },
}));

const ProductReview = (props) => {
  const classes = useStyles();
  const [currentProduct, setCurrentProduct] = React.useState(0);
  const cart = useSelector((state) => state.shop.cart);

  const increment = () => {
    const nextProduct =
      cart && cart.length > 0 && currentProduct < cart.length - 1
        ? currentProduct + 1
        : currentProduct;
    setCurrentProduct(nextProduct);
  };

  const decrement = () => {
    const nextProduct =
      cart && cart.length > 0 && currentProduct > 0
        ? currentProduct - 1
        : currentProduct;
    setCurrentProduct(nextProduct);
  };

  return (
    <Grid container spacing={5}>
      <Grid item lg={8} md={8} sm={12} xs={12}>
        <Card className={classes.Card}>
          <CardHeader title="Confirm Order" />
          <CardContent>
            <Grid container className={classes.root}>
              <Grid item container lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={12}>
                  <OrderReview
                    key={cart[currentProduct].id}
                    {...cart[currentProduct]}
                    item={cart[currentProduct]}
                    quantity={parseInt(
                      cart[currentProduct].selectedOptions.QUANTITY
                    )}
                    removeItem={() => decrement()}
                  />
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  container
                  className={classes.bottomButtonContainer}
                  justify="space-between"
                >
                  <Grid item>
                    <Button
                      color="primary"
                      variant="outlined"
                      disabled={currentProduct === 0}
                      onClick={() => decrement()}
                    >
                      Previous
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="outlined"
                      disabled={currentProduct === cart.length - 1}
                      title="Next Product"
                      onClick={() => increment()}
                    >
                      Next Product
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <ReviewBill />
      </Grid>
      <Grid
        item
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        justify="flex-end"
        alignItems="flex-end"
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => props.onConfirm()}
        >
          Confirm Review
        </Button>
      </Grid>
    </Grid>
  );
};
export default ProductReview;
