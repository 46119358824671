import React from "react";
import PT from "prop-types";

import inputType from "../../data/productinputs.json";
import {
  ButtonGroup,
  Button,
  Grid,
  Typography,
  Hidden,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import NumberInput from "./Inputs/NumberInput";

const useStyles = makeStyles((theme) => ({
  name: {
    fontWeight: 600,
    color: theme.palette.text.primary,
    textTransform: "uppercase",
  },
  actualValue: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  marketValue: {
    fontWeight: 700,
    color: theme.palette.grey[400],
    textDecoration: "line-through",
  },
  discountValue: {
    fontWeight: 500,
    color: theme.palette.green.main[400],
    [theme.breakpoints.down("md")]: {
      fontSize: 25,
      fontWeight: 700,
      color: theme.palette.green.main[500],
    },
  },
  input: {
    marginTop: 0,
  },
  colorInputContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  colorInput: {
    height: "4vh",
    width: "4vh",
    borderRadius: 100,
  },
  selectedColor: {
    borderColor: "blue",
    borderWidth: 5,
    borderStyle: "solid",
  },

  quantityBtnActive: {
    color: "white",
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      color: "white",
      backgroundColor: theme.palette.secondary.main,
    },
  },
  quantityBtnNotActive: {
    color: theme.palette.secondary,
    backgroundColor: "none",
  },
  mobileTitle: {
    marginBottom: theme.spacing(3),
  },
}));
const propTypes = {
  header: PT.string,
  dataInputs: PT.array,
  selectedInput: PT.string,
  inputIndex: PT.number,
  handleSelectInput: PT.func,
};
const ProductInput = (props) => {
  const classes = useStyles();
  const [clsActive, setclsActive] = React.useState("");
  function activeInput(inputValue) {
    setclsActive(inputValue);
  }
  const {
    header,

    selectedInput,
    inputIndex,
    handleSelectInput,
  } = props;

  const dataInputs =
    header === "Loading"
      ? { type: "NUMBER", minimum: 3.5, maximum: 500.0, inputOptions: [] }
      : inputType[header];

  React.useEffect(() => {
    if (dataInputs.type !== "LIST") {
      handleSelectInput(dataInputs.minimum, inputIndex);
    }
  }, [dataInputs]);
  const itemsInputBtn = (dataInputs) => {
    return (
      <ButtonGroup color="primary" aria-label="text primary button group">
        {dataInputs.inputOptions.map(function (item, index) {
          return (
            <Button
              key={index}
              className={
                clsActive === item
                  ? classes.quantityBtnActive
                  : classes.quantityBtnNotActive
              }
              onClick={() => {
                handleSelectInput(item, inputIndex);
                activeInput(item);
              }}
            >
              {item}
            </Button>
          );
        })}
      </ButtonGroup>
      // <ButtonGroup
      //   variant="text"
      //   color="primary"
      //   aria-label="text primary button group"
      // >
      //   {dataInputs.inputOptions.map(function (item, index) {
      //     return (
      //       <Button
      //         key={index}
      //         color={clsActive === item ? "secondary" : "primary"}
      //         onClick={() => {
      //           handleSelectInput(item, inputIndex);
      //           activeInput(item);
      //         }}
      //       >
      //         {item}
      //       </Button>
      //     );
      //   })}
      // </ButtonGroup>
    );
  };

  // https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5/Constraint_validation

  const itemsInputField = (item) => {
    return (
      <input
        style={{ pattern: "^[0-9]{0,2}" }}
        size="large"
        label={header}
        name={header}
        min={item.minimum}
        max={item.maximum}
        labelPosition="right"
        type="number"
        onChange={(e, { value }) => handleSelectInput(value, inputIndex)}
      />
    );
  };
  // const mobileMenu = (dataInputs) => {
  //   return (
  //     <div>
  //       <Typography variant="h6" className={classes.mobileTitle}>
  //         {/* {header.substring(0, 1).toUpperCase() +
  //           header.substring(1).toLowerCase()}{" "} */}
  //       </Typography>

  //       <ButtonGroup color="primary" aria-label="text primary button group">
  //         {dataInputs.inputOptions &&
  //           dataInputs.inputOptions.map(function (item, index) {
  //             return (
  //               <Button
  //                 key={index}
  //                 className={
  //                   clsActive === item
  //                     ? classes.quantityBtnActive
  //                     : classes.quantityBtnNotActive
  //                 }
  //                 onClick={() => {
  //                   handleSelectInput(item, inputIndex);
  //                   activeInput(item);
  //                 }}
  //               >
  //                 {item}
  //               </Button>
  //             );
  //           })}
  //       </ButtonGroup>
  //     </div>

  //     // <Grid item xs={12} className={classes.input}>
  //     //   <FormControl fullWidth variant="outlined">
  //     //     <InputLabel>{header}</InputLabel>
  //     //     <Select
  //     //       value={clsActive}
  //     //       onChange={(data, index) => {
  //     //         handleSelectInput(data.target.value, index.key);
  //     //         activeInput(data.target.value);
  //     //       }}
  //     //       label={header}
  //     //     >
  //     //       {dataInputs.inputOptions.map(function (item, index) {
  //     //         return (
  //     //           <MenuItem value={item} key={index}>
  //     //             {item}
  //     //           </MenuItem>
  //     //         );
  //     //       })}
  //     //     </Select>
  //     //   </FormControl>
  //     // </Grid>
  //   );
  // };

  const ColorInput = () => {
    return (
      <div className={classes.colorInputContainer}>
        {dataInputs.inputOptions.map(function (item, index) {
          return (
            <div
              key={index}
              className={clsx(
                classes.colorInput,
                clsActive === item ? classes.selectedColor : null
              )}
              style={{ backgroundColor: item }}
              onClick={() => {
                handleSelectInput(item, inputIndex);
                activeInput(item);
              }}
            />
          );
        })}
      </div>
    );
  };

  const QuantityInput = () => {
    return (
      <ButtonGroup color="primary" aria-label="text primary button group">
        {dataInputs.inputOptions.map(function (item, index) {
          return (
            <Button
              key={index}
              className={
                clsActive === item
                  ? classes.quantityBtnActive
                  : classes.quantityBtnNotActive
              }
              onClick={() => {
                handleSelectInput(item, inputIndex);
                activeInput(item);
              }}
            >
              {item}
            </Button>
          );
        })}
      </ButtonGroup>
    );
  };

  const chooseInput = (key) => {
    switch (key) {
      case "LIST":
        return itemsInputBtn(dataInputs);

      case "NUMBER":
        let defaultvalue = dataInputs.minimum;
        return (
          <NumberInput
            moq={props.moq}
            // value={selectedInput.QUANTITY}
            dataInputs={dataInputs}
            handleSelect={(value) => handleSelectInput(value, inputIndex)}
          />
        );

      case "QUANTITY":
        return <QuantityInput />;
        break;
      case "COLOR":
        return <ColorInput />;

      case "SIZE":
        return;
      default:
        break;
    }
  };

  return (
    <Grid container alignItems="center">
      <Grid item container lg={12} md={12} sm={12} alignItems="center">
        <Grid item lg={3} sm={4} md={4} xs={4}>
          <Typography variant="h6" color="textPrimary">
            Quantity
          </Typography>
        </Grid>

        <Grid item lg={1} sm={1} md={1} xs={1}>
          <Typography>:</Typography>
        </Grid>

        {/* <Hidden smUp>{mobileMenu(dataInputs)}</Hidden> */}

        <Grid item lg={8} md={8}>
          {/* {header !== "Loading" &&
              inputType[header].type !== undefined &&
              chooseInput(header)} */}

          {/* {header !== "Loading" && inputType[header].type === "COLOR"
              ? itemsInputBtn(dataInputs)
              : itemsInputField(dataInputs)} */}
          {/* {inputType[header].type !== undefined &&
              console.log("header type", inputType[header].type)} */}
          {header !== "Loading" && chooseInput(inputType[header].type)}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default React.memo(ProductInput);

// import React from "react";
// import PT from "prop-types";

// import inputType from "../../data/productinputs.json";
// import {
//   ButtonGroup,
//   Button,
//   Grid,
//   Typography,
//   Hidden,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Box,
//   IconButton,
//   Input,
//   TextField,
// } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
// import AddIcon from "@material-ui/icons/Add";
// import RemoveIcon from "@material-ui/icons/Remove";
// import clsx from "clsx";
// import NumberInput from "./Inputs/NumberInput";
// const useStyles = makeStyles((theme) => ({
//   name: {
//     fontWeight: 600,
//     color: theme.palette.text.primary,
//     textTransform: "uppercase",
//   },
//   actualValue: {
//     fontWeight: 700,
//     color: theme.palette.primary.main,
//     textTransform: "uppercase",
//   },
//   marketValue: {
//     fontWeight: 700,
//     color: theme.palette.grey[400],
//     textDecoration: "line-through",
//   },
//   discountValue: {
//     fontWeight: 500,
//     color: theme.palette.green.main[400],
//     [theme.breakpoints.down("md")]: {
//       fontSize: 25,
//       fontWeight: 700,
//       color: theme.palette.green.main[500],
//     },
//   },
//   input: {
//     marginTop: 10,
//   },
//   colorInputContainer: {
//     width: "100%",
//     display: "flex",
//     justifyContent: "space-between",
//   },
//   colorInput: {
//     height: "4vh",
//     width: "4vh",
//     borderRadius: 100,
//   },
//   selectedColor: {
//     borderColor: "blue",
//     borderWidth: 5,
//     borderStyle: "solid",
//   },

//   quantityBtnActive: {
//     color: theme.palette.background.main,
//     backgroundColor: theme.palette.primary.main,
//     "&:hover": {
//       color: theme.palette.background.light,
//       backgroundColor: theme.palette.primary.light,
//     },
//   },
//   quantityBtnNotActive: {
//     color: theme.palette.primary,
//     backgroundColor: "none",
//   },
//   mobileTitle: {
//     marginBottom: theme.spacing(3),
//   },
//   numberInputContainer: {
//     width: "50%",
//     display: "flex",
//     alignItems: "center",
//     flexDirection: "row",
//   },
//   removeIcon: {
//     flex: 1,
//   },
//   addIcon: {
//     flex: 1,
//   },
//   TextField: {
//     flex: 2,
//   },
// }));
// const propTypes = {
//   header: PT.string,
//   dataInputs: PT.array,
//   selectedInput: PT.string,
//   inputIndex: PT.number,
//   handleSelectInput: PT.func,
// };
// const ProductInput = (props) => {
//   const classes = useStyles();
//   const [clsActive, setclsActive] = React.useState("");
//   function activeInput(inputValue) {
//     setclsActive(inputValue);
//   }
//   const {
//     header,

//     selectedInput,
//     inputIndex,
//     handleSelectInput,
//   } = props;

//   const dataInputs =
//     header === "Loading"
//       ? { type: "NUMBER", minimum: 3.5, maximum: 500.0, inputOptions: [] }
//       : inputType[header];

//   const itemsInputBtn = (dataInputs) => {
//     return (
//       <ButtonGroup color="primary" aria-label="text primary button group">
//         {dataInputs.inputOptions.map(function (item, index) {
//           return (
//             <Button
//               key={index}
//               className={
//                 clsActive === item
//                   ? classes.quantityBtnActive
//                   : classes.quantityBtnNotActive
//               }
//               onClick={() => {
//                 handleSelectInput(item, inputIndex);
//                 activeInput(item);
//               }}
//             >
//               {item}
//             </Button>
//           );
//         })}
//       </ButtonGroup>
//     );
//   };
//   const itemInputNumber = (dataInputs) => {
//     console.log("data Inpues", dataInputs);
//     return (
//       <Box className={classes.numberInputContainer}>
//         <IconButton className={classes.removeIcon} color="secondary">
//           <RemoveIcon />
//         </IconButton>
//         <TextField
//           className={classes.TextField}
//           variant="outlined"
//           color="primary"
//           size="small"
//           value={dataInputs.minimum}
//         >
//           0
//         </TextField>
//         <IconButton className={classes.addIcon} color="primary">
//           <AddIcon />
//         </IconButton>
//       </Box>
//       // <ButtonGroup color="primary" aria-label="text primary button group">
//       //   {dataInputs.inputOptions.map(function (item, index) {
//       //     return (
//       //       <Button
//       //         key={index}
//       //         className={
//       //           clsActive === item
//       //             ? classes.quantityBtnActive
//       //             : classes.quantityBtnNotActive
//       //         }
//       //         onClick={() => {
//       //           handleSelectInput(item, inputIndex);
//       //           activeInput(item);
//       //         }}
//       //       >
//       //         {item}
//       //       </Button>
//       //     );
//       //   })}
//       // </ButtonGroup>
//     );
//   };
//   // https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5/Constraint_validation

//   const itemsInputField = (item) => {
//     return (
//       <input
//         style={{ pattern: "^[0-9]{0,2}" }}
//         size="large"
//         label={header}
//         name={header}
//         min={item.minimum}
//         max={item.maximum}
//         labelPosition="right"
//         type="number"
//         onChange={(e, { value }) => handleSelectInput(value, inputIndex)}
//       />
//     );
//   };
//   const mobileMenu = (dataInputs) => {
//     return (
//       <div>
//         <Typography
//           variant="h6"
//           className={classes.mobileTitle}
//           color="textPrimary"
//         >
//           {header.substring(0, 1).toUpperCase() +
//             header.substring(1).toLowerCase()}{" "}
//         </Typography>

//         <ButtonGroup color="primary" aria-label="text primary button group">
//           {dataInputs.inputOptions.map(function (item, index) {
//             return (
//               <Button
//                 key={index}
//                 className={
//                   clsActive === item
//                     ? classes.quantityBtnActive
//                     : classes.quantityBtnNotActive
//                 }
//                 onClick={() => {
//                   handleSelectInput(item, inputIndex);
//                   activeInput(item);
//                 }}
//               >
//                 {item}
//               </Button>
//             );
//           })}
//         </ButtonGroup>
//       </div>

//       // <Grid item xs={12} className={classes.input}>
//       //   <FormControl fullWidth variant="outlined">
//       //     <InputLabel>{header}</InputLabel>
//       //     <Select
//       //       value={clsActive}
//       //       onChange={(data, index) => {
//       //         handleSelectInput(data.target.value, index.key);
//       //         activeInput(data.target.value);
//       //       }}
//       //       label={header}
//       //     >
//       //       {dataInputs.inputOptions.map(function (item, index) {
//       //         return (
//       //           <MenuItem value={item} key={index}>
//       //             {item}
//       //           </MenuItem>
//       //         );
//       //       })}
//       //     </Select>
//       //   </FormControl>
//       // </Grid>
//     );
//   };

//   const ColorInput = () => {
//     return (
//       <div className={classes.colorInputContainer}>
//         {dataInputs.inputOptions.map(function (item, index) {
//           return (
//             <div
//               key={index}
//               className={clsx(
//                 classes.colorInput,
//                 clsActive === item ? classes.selectedColor : null
//               )}
//               style={{ backgroundColor: item }}
//               onClick={() => {
//                 handleSelectInput(item, inputIndex);
//                 activeInput(item);
//               }}
//             />
//           );
//         })}
//       </div>
//     );
//   };

//   const QuantityInput = () => {
//     return (
//       <ButtonGroup color="primary" aria-label="text primary button group">
//         {dataInputs.inputOptions.map(function (item, index) {
//           return (
//             <Button
//               key={index}
//               className={
//                 clsActive === item
//                   ? classes.quantityBtnActive
//                   : classes.quantityBtnNotActive
//               }
//               onClick={() => {
//                 handleSelectInput(item, inputIndex);
//                 activeInput(item);
//               }}
//             >
//               {item}
//             </Button>
//           );
//         })}
//       </ButtonGroup>
//     );
//   };

// const chooseInput = (key) => {
//   switch (key) {
//     case "LIST":
//       return itemsInputBtn(dataInputs);

//     case "NUMBER":
//       let defaultvalue = dataInputs.minimum;
//       return (
//         <NumberInput
//           dataInputs={dataInputs}
//           handleSelect={(value) => handleSelectInput(value, inputIndex)}
//         />
//       );

//     case "QUANTITY":
//       return <QuantityInput />;
//       break;
//     case "COLOR":
//       return <ColorInput />;

//     case "SIZE":
//       return;
//     default:
//       break;
//   }
// };

//   return (
//     <Grid container spacing={5} alignItems="center">
//       <Grid item container lg={12} md={12} sm={12} alignItems="center">
//         <Hidden smDown>
//           <Grid item lg={3}>
//             <Typography variant="h6" color="textPrimary">
//               {header.substring(0, 1).toUpperCase() +
//                 header.substring(1).toLowerCase()}{" "}
//             </Typography>
//           </Grid>
//         </Hidden>
//         <Hidden smDown>
//           <Grid item lg={1}>
//             <Typography>:</Typography>
//           </Grid>
//         </Hidden>
//         {/* <Hidden smUp>{mobileMenu(dataInputs)}</Hidden> */}
//         <Hidden smDown>
//           <Grid item lg={8} md={8}>
//             {/* {header !== "Loading" &&
//               inputType[header].type !== undefined &&
//               chooseInput(header)} */}

//             {/* {header !== "Loading" && inputType[header].type === "COLOR"
//               ? itemsInputBtn(dataInputs)
//               : itemsInputField(dataInputs)} */}
//             {/* {inputType[header].type !== undefined &&
//               console.log("header type", inputType[header].type)} */}
//             {/* {header !== "Loading" && inputType[header].type === "LIST"
//               ? itemsInputBtn(dataInputs)
//               : itemsInputField(dataInputs)} */}

//             {header !== "Loading" && chooseInput(inputType[header].type)}
//           </Grid>
//         </Hidden>
//       </Grid>
//     </Grid>
//   );
// };
// export default React.memo(ProductInput);
