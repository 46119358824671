import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography } from "@material-ui/core";
import PT from "prop-types";
import { Link } from "react-router-dom";

const propTypes = {
  id: PT.number,
  name: PT.string,
  marketPrice: PT.number,
  salePrice: PT.number,
  srcImg: PT.string,
  selectedOptions: PT.object,
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
  },
  container: {
    // padding: theme.spacing(1),
    textDecoration: "none",
  },
  mini: {
    objectFit: "contain",

    height: 60,
    overflow: "hidden",
  },
  innerContainer: {
    height: "100%",
  },
  marketPrice: {
    textDecoration: "line-through",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
}));
const FavouriteItem = (props) => {
  const {
    name,
    salePrice,
    marketPrice,
    defaultImage,

    selectedOptions,
    type,
    category,
    subcategory,
    itemIndex,
    browsingName,
    productId,
    id,
  } = props;

  const classes = useStyles();
  return (
    <Link className={classes.link} to={`/product/${browsingName}/${productId}`}>
      <div className={classes.root}>
        <Paper variant="outlined" className={classes.container}>
          <Grid container justify="space-evenly" className={classes.container}>
            <Grid item lg={3} container justify="center">
              <img src={defaultImage} className={classes.mini} />
            </Grid>
            <Grid item lg={8}>
              <Grid
                container
                className={classes.innerContainer}
                alignItems="center"
              >
                <Grid item lg={12}>
                  <Typography variant="body1" color="primary">
                    {name}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="body2">₹{salePrice}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </Link>
  );
};

FavouriteItem.propTypes = propTypes;

export default FavouriteItem;
