import React from "react";

import PT from "prop-types";
import PropTypes from "prop-types";
import { addProductToCart, addProductToFavourites } from "../../redux/selector";
import { connect, useSelector } from "react-redux";
import { Grid, Button, Typography, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CardPlus from "@material-ui/icons/AddShoppingCartOutlined";
import Favourite from "@material-ui/icons/FavoriteBorderOutlined";
import EnquiryIcon from "@material-ui/icons/QuestionAnswerOutlined";
import { toggleLoginModal } from "../../redux/action/utilActions";
import calculatePricefromPribands from "../../functions/calculatePriceFromPricabands";
const propTypes = {
  dataDes: PT.string,
  proInfo: PT.object,
  canAddToCart: PT.bool,
  hasSelected: PT.bool,
  handleAddOnceToCart: PT.func,
};

const useStyles = makeStyles((theme) => ({
  favouriteBtn: {
    backgroundColor: theme.palette.primary.main,
    // color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  title: {
    color: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
      fontWeight: 500,
    },
  },

  delivers: {
    color: theme.palette.text.primary,
  },
  root: {
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginTop: 20,
    },
  },
}));

const ProductDetail = (props) => {
  const classes = useStyles();
  const isLogged = useSelector((state) => state.user.isLogged);
  let {
    proInfo,
    hasSelected,
    canAddToCart,

    dispatch,
  } = props;

  const handleFavourite = () => {
    if (isLogged) {
      let favouriteItem = {
        ...proInfo,
        id: null,
        productId: proInfo.id,
      };

      dispatch(addProductToFavourites(favouriteItem));
    } else {
      dispatch(toggleLoginModal("login"));
    }
  };

  const handleAddtoCart = () => {
    let cartItem;
    if (isLogged) {
      if (proInfo.priceBands.length > 0) {
        cartItem = {
          ...proInfo,

          salePrice: calculatePricefromPribands(
            proInfo.priceBands,
            proInfo.selectedOptions.QUANTITY
          ),
          priceBands: true,

          id: null,
          productId: proInfo.id,
        };
      } else {
        cartItem = {
          ...proInfo,
          priceBands: false,
          id: null,
          productId: proInfo.id,
        };
      }

      if (!hasSelected || !canAddToCart) return;

      dispatch(addProductToCart(cartItem));
    } else {
      dispatch(toggleLoginModal("login"));
    }

    // console.log(proInfo, "proinfo");
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid
          item
          container
          lg={12}
          xs={12}
          alignItems="center"
          className={classes.gridItemContainer}
        >
          <Grid item lg={3}>
            <Typography variant="h6" className={classes.title}>
              Delivers in:
            </Typography>
          </Grid>
          <Grid item lg={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" className={classes.delivers}>
              {/* <strong>1-7 working days</strong> */}
              1-7 working days
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          lg={12}
          xs={12}
          alignItems="center"
          className={classes.gridItemContainer}
        >
          <Grid item lg={3}>
            <Typography variant="h6" className={classes.title}>
              Seller
            </Typography>
          </Grid>
          <Grid item lg={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" className={classes.delivers}>
              {props.seller}
            </Typography>
          </Grid>
        </Grid>

        <Grid item lg={6} xs={12} sm={12} md={12}>
          <Button
            className={classes.favouriteBtn}
            startIcon={<Favourite />}
            fullWidth
            size="large"
            variant="contained"
            onClick={() => handleFavourite()}
          >
            SAVE AS FAVOURITE
          </Button>
        </Grid>
        <Grid item lg={6} xs={12} md={12} sm={12}>
          {console.log("selected quantity", proInfo.selectedOptions.QUANTITY)}
          <Button
            variant="contained"
            // disabled={!canAddToCart || !!proInfo.selectedOptions.QUANTITY}

            // disabled={!!proInfo.selectedOptions.QUANTITY}
            className={classes.button}
            startIcon={<CardPlus />}
            color="primary"
            fullWidth
            size="large"
            onClick={() => handleAddtoCart()}
          >
            ADD TO CART
          </Button>
        </Grid>
        <Grid item lg={6} xs={12} md={12} sm={12}>
          <Button
            variant="contained"
            className={classes.button}
            startIcon={<EnquiryIcon />}
            color="primary"
            fullWidth
            size="large"
            onClick={() => {
              props.openEnquiry();

              // console.log(proInfo, "proinfo");
            }}
          >
            Enquiry
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

ProductDetail.propTypes = propTypes;

export default connect(null, null)(ProductDetail);
