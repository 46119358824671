import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  MenuItem,
  Button,
  ListItemText,
  Menu,
  ListItem,
} from "@material-ui/core";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  // menu: {
  //   top: theme.spacing(-1),
  // },
  title: {
    flexGrow: 1,
  },
  moreArrow: {
    marginRight: theme.spacing(-1),
  },
}));
const Submenu = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { item, itemindex, close } = props;

  const ChooseMainCategory = (e, index) => {
    setAnchorEl(e.currentTarget);
    let text = e.target.innerText;
    props.selectMainCategory(text, index);
    console.log("choosemaincat", text, index);
  };
  const ChooseSubCategory = (e, id, subIndex) => {
    // let text = e.target.innerText;
    // console.log(products, "products");
    props.selectSubCategory(e, id, subIndex);
    props.history.push("/");
    setAnchorEl(null);
    close();
  };

  return (
    <ListItem button disableGutters>
      <Button onClick={(e) => ChooseMainCategory(e, itemindex)}>
        <ListItemText className={classes.title}>{item.category}</ListItemText>
        <ChevronRight className={classes.moreArrow} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
        {...props}
      >
        {item.subcategoryList.map((nele, subIndex) => {
          return (
            <MenuItem
              onClick={(e) =>
                ChooseSubCategory(nele.subcategory, nele.id, subIndex)
              }
            >
              {nele.subcategory}
            </MenuItem>
          );
        })}
      </Menu>
    </ListItem>
  );
};
export default withRouter(Submenu);
