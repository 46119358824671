import React from "react";
import Typography from "@material-ui/core/Typography";

import { Link, withRouter } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { bindHover } from "material-ui-popup-state/hooks";
import PopupState, { bindPopper } from "material-ui-popup-state";

import IconButton from "@material-ui/core/IconButton";
import MessageIcon from "@material-ui/icons/EmailOutlined";
import { Popper, Fade, Paper, Hidden, Badge } from "@material-ui/core";
import StyledBadge from "./StyledBadge";
import { toggleLoginModal } from "../../redux/action/utilActions";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  paper: {
    border: "1px solid gray",
    borderRadius: 3,
    padding: theme.spacing(1),

    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  userIcon: {
    marginRight: 10,
  },
  Popper: {
    zIndex: 100001,
  },
  Paper: {
    padding: theme.spacing(1),
  },
}));
const ColorButton = withStyles((theme) => ({
  root: {
    color: "#ffffff",

    "&:hover": {},
  },
  popper: {
    border: "1px solid red",
  },
}))(IconButton);

const HoverPopoverPopupState = ({ classes, messages, history }) => {
  const dashes = useStyles();
  const isLogged = useSelector((state) => state.user.isLogged);
  const dispatch = useDispatch();

  const handleNavigate = () => {
    history.push("/messages");
  };

  const handleLogin = () => {
    dispatch(toggleLoginModal("login"));
  };
  return (
    <div>
      <Hidden smUp>
        <ColorButton
          onClick={() => {
            isLogged ? handleNavigate() : handleLogin();
          }}
        >
          <StyledBadge
            color="secondary"
            badgeContent={messages && messages.length > 0 ? messages.length : 0}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            showZero
          >
            <MessageIcon />
          </StyledBadge>
        </ColorButton>
      </Hidden>
      <Hidden mdDown>
        <PopupState variant="popper" popupId="demo-popup-popper">
          {(popupState) => (
            <div>
              <ColorButton
                {...bindHover(popupState)}
                onClick={() => {
                  isLogged ? handleNavigate() : handleLogin();
                }}
              >
                <StyledBadge
                  color="secondary"
                  badgeContent={
                    messages && messages.length > 0 ? messages.length : 0
                  }
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  showZero
                >
                  <MessageIcon />
                </StyledBadge>
              </ColorButton>

              <Popper
                className={dashes.Popper}
                {...bindPopper(popupState)}
                transition
                disablePortal={false}
                modifiers={{
                  flip: {
                    enabled: true,
                  },
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: "scrollParent",
                  },
                }}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper
                      className={dashes.Paper}
                      onMouseLeave={(e) => popupState.onMouseLeave(e)}
                    >
                      <Link to="/messages">
                        <Typography color="textPrimary">
                          Click here see your messages.
                        </Typography>
                      </Link>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </div>
          )}
        </PopupState>
      </Hidden>
    </div>
  );
};

export default withRouter(HoverPopoverPopupState);
