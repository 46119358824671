import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ModalComponent from "../Modals/ModalComponent";
import LoadingComponent from "./LoadingComponent";
import { useSelector } from "react-redux";

const SpinnerComponent = () => {
  const { spinner } = useSelector((state) => state.spinner);
  return (
    <ModalComponent open={spinner}>
      <LoadingComponent />
    </ModalComponent>
  );
};
export default SpinnerComponent;
