import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ModalComponent = (props) => {
  const classes = useStyles();

  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      disableScrollLock
      className={classes.root}
      {...props}
    >
      {props.children}
    </Modal>
  );
};
export default ModalComponent;
