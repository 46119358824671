import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Paper,
  Typography,
  Grid,
} from "@material-ui/core";
import { Redirect, useParams, useLocation } from "react-router-dom";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import ContactUs from "../ContactUs";
import { useDispatch } from "react-redux";
import { pushtohistory } from "../../../redux/action/historyActions";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  Container: {
    backgroundColor: theme.palette.background.light,

    minHeight: "90vh",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  titleContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}));
const FooterPage = (props) => {
  const classes = useStyles();
  const { location } = props;
  const { item } = location;

  //stub

  const { stub } = useParams();
  const stublocation = useLocation().pathname;
  const dispatch = useDispatch();

  React.useEffect(() => {
    let newlocation =
      stublocation === "/" ? stublocation : stublocation.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));
  }, []);

  console.log(props, "from page");

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.Container}>
        {item === undefined ? (
          <Redirect to="/" />
        ) : (
          <Grid container spacing={2}>
            <Grid
              item
              container
              lg={12}
              sm={12}
              md={12}
              xs={12}
              className={classes.titleContainer}
              justify="center"
              alignItems="center"
            >
              <Typography
                variant="h4"
                className={classes.title}
                color="textPrimary"
              >
                {item.name}
              </Typography>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <Divider />
            </Grid>
            <Grid item container spacing={2} lg={12} sm={12} md={12} xs={12}>
              <Grid item lg={12} sm={12} md={12} xs={12}>
                {item.name === "Contact us" ? (
                  <ContactUs />
                ) : (
                  ReactHtmlParser(item.description)
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Container>
    </div>
  );
};
export default FooterPage;
