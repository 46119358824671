import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LoginForm from "./LoginForm";
import NewCustomer from "./NewCustomer";
import Scroll from "../scroll/Scroll";

import CartIcon from "@material-ui/icons/ShoppingCartOutlined";
import SecurityIcon from "@material-ui/icons/VerifiedUserOutlined";
import CreditCart from "@material-ui/icons/CreditCard";
import FaceIcon from "@material-ui/icons/InsertEmoticon";
import LoyalyIcon from "@material-ui/icons/LoyaltyOutlined";
import Useraccount from "./UserAccount";
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Hidden,
  IconButton,
} from "@material-ui/core";
import {
  Switch,
  Route,
  Redirect,
  withRouter,
  useParams,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoginModal } from "../../redux/action/utilActions";
import LoginFormWrap from "./LoginFormWrap";
import { pushtohistory } from "../../redux/action/historyActions";
const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "white",
    minHeight: "80vh",
    [theme.breakpoints.down("md")]: {
      minHeight: "90vh",
    },
  },
  loginInfo: {
    borderRadius: 4,
    backgroundColor: theme.palette.secondary.light,
    minHeight: "78vh",
  },
  loginContainer: {
    borderColor: theme.palette.secondary.light,
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: 4,
    backgroundColor: "white",
    [theme.breakpoints.down("md")]: {
      border: "none",
    },
  },
  title: {
    color: "white",
    fontSize: 25,
  },
  otherText: {
    color: "white",
    fontSize: 20,
  },
  icon: {
    color: "white",
    fontSize: 30,
  },
}));
const LoginSite = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const redirectandclose = () => {
    dispatch(toggleLoginModal());
  };
  const path = useSelector((state) => state.utilReducer.component);

  //stub

  const { stub } = useParams();
  const location = useLocation().pathname;

  React.useEffect(() => {
    let newlocation = location === "/" ? location : location.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));
  }, []);

  return (
    <div>
      {props.isLogin ? (
        redirectandclose()
      ) : (
        <LoginFormWrap
          isLogin={props.isLogin}
          path={path}
          handleLogin={props.handleLogin}
        />
      )}{" "}
    </div>
  );
};
export default withRouter(LoginSite);
