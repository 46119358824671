import React from "react";
import { Route, Link } from "react-router-dom";
import HeaderToolWrapper from "./HeaderToolWrapper";
import PropTypes from "prop-types";
import HeaderNavigation from "../nav/HeaderMenu.js";

import PT from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Hidden, Grid, Slide } from "@material-ui/core";

import SearchBar from "./SearchBar";
import MobileSidebar from "./MobileSidebar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";
import { useDispatch } from "react-redux";
import { loadAllProducts } from "../../redux/selector";
// import logo from "/logo.png";
const logo = "/logo.png";

const useStyles = makeStyles((theme) => ({
  // Container: {
  //   display: "flex",
  //   alignItems: "center",

  // },
  btnscrolltop: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 100,
  },
  spacefillup: {
    backgroundColor: theme.palette.background.main,
    height: "12vh",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      height: "20vh",
    },
  },
  AppBar: {
    backgroundColor: theme.palette.background.lighter,
    height: "12vh",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: "18vh",
    },
  },
  Toolbar: {
    flex: 1,
    width: "100%",
    // backgroundColor: "red",
  },
  root: {
    flexGrow: 1,
    // paddingBottom: "12vh",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,

    fontSize: 18,
    fontWeight: 600,
    color: "white",
  },
  mobiletitle: {
    fontSize: 18,
    fontWeight: 600,
    color: "white",
  },
  mobile: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  icon: {
    color: "white",
    fontSize: "1.2em",
  },
  logo: {
    height: "10vh",
    padding: theme.spacing(0.5),
    [theme.breakpoints.down("md")]: {
      height: "7vh",
    },
  },
  titleContainer: {
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(1),
    },
  },
}));
function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.btnscrolltop}
      >
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
const propTypes = {
  cart: PT.arrayOf(PT.object),
};

const Header = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { children, window } = props;
  let { cart } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });
  const handleHomeNavigate = () => {
    dispatch(loadAllProducts());
  };
  return (
    <div>
      <header className={classes.root}>
        <Hidden smDown>
          <div className={classes.spacefillup} id="back-to-top-anchor" />
          <AppBar position="fixed" className={classes.AppBar} elevation={1}>
            <Toolbar className={classes.Toolbar}>
              <Link
                to="/"
                className={classes.title}
                onClick={() => handleHomeNavigate()}
              >
                <img className={classes.logo} src={logo} />
              </Link>
              <HeaderToolWrapper cart={cart} />
            </Toolbar>
          </AppBar>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.spacefillup} id="back-to-top-anchor" />
          <Slide in={!trigger}>
            <AppBar position="fixed" className={classes.AppBar} elevation={1}>
              <Toolbar>
                <Grid container direction="column">
                  <Grid
                    item
                    container
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    alignItems="center"
                    justify="space-between"
                    direction="row"
                    className={classes.titleContainer}
                  >
                    <Grid
                      item
                      xs={6}
                      md={6}
                      sm={6}
                      container
                      direction="row"
                      alignItems="center"
                      justify="flex-start"
                    >
                      <Grid item>
                        <MobileSidebar logo={logo} />
                      </Grid>
                      <Grid item>
                        <Link
                          to="/"
                          className={classes.mobiletitle}
                          onClick={() => handleHomeNavigate()}
                        >
                          <img className={classes.logo} src={logo} />
                        </Link>
                      </Grid>
                    </Grid>

                    <Grid item md={6} sm={6} xs={6}>
                      <HeaderToolWrapper cart={cart} />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xs={12}>
                    <SearchBar />
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Slide>
        </Hidden>
      </header>
      <Hidden smDown>
        <HeaderNavigation />
      </Hidden>
      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </div>
  );
};

Header.propTypes = propTypes;

export default React.memo(Header);
