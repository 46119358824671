import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect, useSelector } from "react-redux";

import {
  AppBar,
  Toolbar,
  Container,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
import ListMenu from "./Listmenu";
import dataHeaderMenu from "../../data/productData/categoryInfo.json";
const useStyles = makeStyles((theme) => ({
  root: {},
  AppBar: {
    backgroundColor: theme.palette.background.dark,
    // boxShadow: "0px 1px 1px rgba(255,255,255,1)",
  },
  gridContainer: {
    width: "100%",
  },
  line: {
    flexGrow: 1,
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.secondary,
  },
  Btn: {
    fontWeight: 500,
    "&:hover": {
      color: "blue",
    },
  },
}));
const HeaderMenu = (props) => {
  const classes = useStyles();
  const { type, mainCategory, subCategory } = useSelector(
    (state) => state.shop
  );
  return (
    <div className={classes.root}>
      <Divider />
      <AppBar
        position="static"
        className={classes.AppBar}
        elevation={1}
        // color="transparent"
      >
        {/* <Toolbar variant="dense"> */}
        <Grid container className={classes.gridContainer} alignItems="center">
          {/* <Grid item container lg={6} md={6} sm={6} justify="flex-start">
              <Typography className={classes.line}>
                The best ecommerece platform
              </Typography>
            </Grid> */}
          <Grid
            item
            container
            spacing={1}
            lg={12}
            md={12}
            sm={12}
            justify="center"
          >
            <ListMenu data={dataHeaderMenu} />
          </Grid>
        </Grid>
        {/* </Toolbar> */}
      </AppBar>
    </div>
  );
};

export default HeaderMenu;
