import React from "react";

import {
  Container,
  makeStyles,
  Grid,
  Box,
  Typography,
  Button,
  Link,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import Axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { ROOT_URL } from "../../redux/utils/utils";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import CallIcon from "@material-ui/icons/Call";
import MailIcon from "@material-ui/icons/Mail";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
// import { registerUser } from "../../redux/action/useractions";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    // height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: theme.palette.background.main,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  signUp: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1),
  },
  closeBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  closeBtn: {
    color: "white",
  },
  gridContainer: {
    // width: "60vw",
    // [theme.breakpoints.down("md")]: {
    //   width: "90vw",
    // },
  },
  frame: {
    [theme.breakpoints.down("md")]: {
      height: "50vh",
    },
  },
  contactDescription: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  companyName: {
    fontSize: "2.1rem",
    fontWeight: 800,
  },
  link: {
    textDecoration: "none",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
const mapLink =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.3319685746824!2d77.57239721482178!3d12.950596890869642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15ee144f5003%3A0x222ebff8a6f5fbea!2sJust%20Signs%20India%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1598881497002!5m2!1sen!2sin";
const ContactUs = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [snackOpen, setSnackOpen] = React.useState({
    open: false,
    severnity: "",
    message: "",
  });
  const [state, setState] = React.useState({
    name: "",
    email: "",
    mobile: "",
    place: "",
    message: "",
  });
  const [error, setError] = React.useState();
  const [submitted, setSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleEnquiry = (e) => {
    if (state.name === "") {
      setError("name");
    }
    if (state.email === "") {
      setError("email");
    }
    if (state.mobile === "") {
      setError("mobile");
    }
    if (state.place === "") {
      setError("place");
    } else {
      const token = localStorage.getItem("tkn");
      const sendData = {
        ...state,
        source: "yourwebsite.com",
      };
      setLoading(true);
      const header = {
        "Content-Type": "application/json",
      };
      setSubmitted(true);
      Axios.post(`${ROOT_URL}SFA/Query/Contactus`, sendData, {
        headers: header,
      })
        .then((res) => {
          setLoading(false);
          setSubmitted(true);
          setSnackOpen({
            open: true,
            severnity: "success",
            message: "You Contact Request is Submitted Successfully",
          });
        })
        .catch((err) => {
          setLoading(false);
          setLoading(false);
          setSnackOpen({
            open: true,
            severnity: "error",
            message: "There was a problem, please retry.",
          });
        });
    }

    //   dispatch(registerUser(JSON.stringify(signupobj)));
  };
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen({ ...snackOpen, open: false });
  };

  //id;
  //companyName;
  //   levelType; // Data List Name contactType; designation; firstName; lastName; officialEmail; countryCode; phone; faxCountryCode; faxPhone; code; type; line1; line2; line3; country; state; city; postalCode; lattitude; longitude; altitude;
  return (
    <div className={classes.root}>
      <Snackbar
        open={snackOpen.open}
        autoHideDuration={5000}
        onClose={() => {
          setSnackOpen({
            open: false,
            severnity: "",
            message: "",
          });
        }}
      >
        <MuiAlert severity={snackOpen.severnity}>{snackOpen.message}</MuiAlert>
      </Snackbar>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <iframe
              title="map"
              src={mapLink}
              width="100%"
              height="100%"
              className={classes.frame}
            ></iframe>
          </Grid>
          <Grid itel lg={6} className={classes.gridItemContainer}>
            <Box m={3}>
              <div className={classes.contactContainer}>
                {/* <div className={classes.contactDescription}>
                  <Link
                    className={classes.link}
                    href="https://wa.me/919880091911"
                    target="_blank"
                  >
                    <Button
                      color="primary"
                      startIcon={<WhatsAppIcon />}
                      variant="contained"
                      size="large"
                    >
                      Message us on Whatsapp
                    </Button>
                  </Link>
                </div> */}
                <div className={classes.contactDescription}>
                  <Typography
                    color="textPrimary"
                    className={classes.companyName}
                  >
                    Just Signs India Pvt Ltd
                  </Typography>
                </div>
                <div className={classes.contactDescription}>
                  <Typography variant="body1" color="textPrimary">
                    14, Jain Temple Cross Rd,
                    <br /> Parvathipuram, Vishweshwarapura,
                    <br /> Shankarapura, Bengaluru,
                    <br />
                    Karnataka 560004
                  </Typography>
                </div>
                <div className={classes.contactDescription}>
                  <Link
                    className={classes.link}
                    href="tel:+9199303 59454"
                    target="_blank"
                  >
                    <Button
                      color="primary"
                      startIcon={<CallIcon />}
                      variant="text"
                      size="large"
                    >
                      Phone : +91 99303 59454 ,+91 93531 98743
                    </Button>
                  </Link>
                </div>
                <div className={classes.contactDescription}>
                  <Link
                    className={classes.link}
                    href="mailto:sales@justsigns.co.in"
                    target="_blank"
                  >
                    <Button
                      color="primary"
                      startIcon={<MailIcon />}
                      variant="text"
                      size="large"
                    >
                      Email :sales@justsigns.co.in
                    </Button>
                  </Link>
                </div>
              </div>
            </Box>
            <Box m={2}>
              <form>
                <Grid
                  container
                  spacing={2}
                  justify="center"
                  className={classes.gridContainer}
                >
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Enter Your Name"
                      variant="outlined"
                      fullWidth
                      disabled={submitted}
                      error={error === "name"}
                      value={state.name}
                      name="name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Enter Your Email"
                      variant="outlined"
                      fullWidth
                      disabled={submitted}
                      error={error === "email"}
                      type="email"
                      value={state.email}
                      name="email"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Enter Your Mobile Number"
                      variant="outlined"
                      fullWidth
                      disabled={submitted}
                      value={state.mobile}
                      error={error === "mobile"}
                      name="mobile"
                      type="number"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      id="outlined-basic"
                      // label="Enter Place"
                      placeholder="City, State , Country - Pincode"
                      variant="outlined"
                      fullWidth
                      disabled={submitted}
                      error={error === "place"}
                      value={state.place}
                      name="place"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      multiline
                      rows="8"
                      id="outlined-basic"
                      label="Message"
                      variant="outlined"
                      fullWidth
                      disabled={submitted}
                      value={state.message}
                      name="message"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>

                  <Grid item lg={6} sm={6} md={6} xs={6}>
                    <Button
                      fullWidth
                      className={classes.signUp}
                      size="large"
                      disabled={submitted}
                      variant="contained"
                      color="primary"
                      onClick={() => handleEnquiry()}
                    >
                      Enquire Now
                      {loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ContactUs;

// import React from "react";

// import {
//   Container,
//   makeStyles,
//   Grid,
//   Card,
//   CardHeader,
//   CardContent,
//   Typography,
//   TextField,
//   Button,
//   Modal,
//   IconButton,
// } from "@material-ui/core";
// import { useDispatch } from "react-redux";
// import Axios from "axios";
// import Snackbar from "@material-ui/core/Snackbar";
// import MuiAlert from "@material-ui/lab/Alert";
// import CloseIcon from "@material-ui/icons/Close";
// import { ROOT_URL } from "../../redux/utils/utils";
// // import { registerUser } from "../../redux/action/useractions";

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }
// const useStyles = makeStyles((theme) => ({
//   root: {
//     // height: "100vh",
//     width: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     marginTop: theme.spacing(5),
//     [theme.breakpoints.down("md")]: {
//       marginTop: theme.spacing(2),
//     },
//   },
//   signUp: {
//     marginTop: theme.spacing(3),
//     padding: theme.spacing(1),
//   },
//   closeBtnContainer: {
//     display: "flex",
//     justifyContent: "flex-end",
//   },
//   closeBtn: {
//     color: "white",
//   },
//   gridContainer: {
//     width: "60vw",
//     [theme.breakpoints.down("md")]: {
//       width: "90vw",
//     },
//   },
// }));
// const ContactUs = (props) => {
//   const classes = useStyles();
//   const dispatch = useDispatch();
//   const [snackOpen, setSnackOpen] = React.useState({
//     open: false,
//     severnity: "",
//     message: "",
//   });
//   const [state, setState] = React.useState({
//     name: "",
//     email: "",
//     mobile: "",
//     place: "",
//     message: "",
//   });
//   const [error, setError] = React.useState();

//   const handleChange = (e) => {
//     setState({ ...state, [e.target.name]: e.target.value });
//   };
//   const handleEnquiry = (e) => {
//     if (state.name === "") {
//       setError("name");
//     }
//     if (state.email === "") {
//       setError("email");
//     }
//     if (state.mobile === "") {
//       setError("mobile");
//     }
//     if (state.place === "") {
//       setError("place");
//     } else {
//       const token = localStorage.getItem("tkn");
//       const sendData = {
//         ...state,
//         source: "yourwebsite.com",
//       };
//       const header = {
//         "Content-Type": "application/json",
//       };
//       Axios.post(`${ROOT_URL}SFA/Query/Contactus`, sendData, {
//         headers: header,
//       })
//         .then((res) =>
//           setSnackOpen({
//             open: true,
//             severnity: "success",
//             message: "You Contact Request is Submitted Successfully",
//           })
//         )
//         .catch((err) =>
//           setSnackOpen({
//             open: true,
//             severnity: "error",
//             message: "There was a problem, please retry.",
//           })
//         );
//     }

//     //   dispatch(registerUser(JSON.stringify(signupobj)));
//   };
//   const handleSnackClose = (event, reason) => {
//     if (reason === "clickaway") {
//       return;
//     }

//     setSnackOpen({ ...snackOpen, open: false });
//   };

//   //id;
//   //companyName;
//   //   levelType; // Data List Name contactType; designation; firstName; lastName; officialEmail; countryCode; phone; faxCountryCode; faxPhone; code; type; line1; line2; line3; country; state; city; postalCode; lattitude; longitude; altitude;
//   return (
//     <div className={classes.root}>
// <form>
//   <Grid
//     container
//     spacing={2}
//     justify="center"
//     className={classes.gridContainer}
//   >
//     <Grid item lg={10} sm={12} md={10} xs={11}>
//       <TextField
//         id="outlined-basic"
//         label="Enter Name"
//         variant="outlined"
//         fullWidth
//         error={error === "name"}
//         value={state.name}
//         name="name"
//         onChange={(e) => handleChange(e)}
//       />
//     </Grid>
//     <Grid item lg={10} sm={12} md={10} xs={11}>
//       <TextField
//         id="outlined-basic"
//         label="Enter Email"
//         variant="outlined"
//         fullWidth
//         error={error === "email"}
//         type="email"
//         value={state.email}
//         name="email"
//         onChange={(e) => handleChange(e)}
//       />
//     </Grid>
//     <Grid item lg={10} sm={12} md={10} xs={11}>
//       <TextField
//         id="outlined-basic"
//         label="Enter Mobile Number"
//         variant="outlined"
//         fullWidth
//         value={state.mobile}
//         error={error === "mobile"}
//         name="mobile"
//         type="number"
//         onChange={(e) => handleChange(e)}
//       />
//     </Grid>
//     <Grid item lg={10} sm={12} md={10} xs={11}>
//       <TextField
//         id="outlined-basic"
//         // label="Enter Place"
//         placeholder="City, State , Country - Pincode"
//         variant="outlined"
//         fullWidth
//         error={error === "place"}
//         value={state.place}
//         name="place"
//         onChange={(e) => handleChange(e)}
//       />
//     </Grid>
//     <Grid item lg={10} sm={12} md={10} xs={11}>
//       <TextField
//         multiline
//         rows="8"
//         id="outlined-basic"
//         label="Message"
//         variant="outlined"
//         fullWidth
//         value={state.message}
//         name="message"
//         onChange={(e) => handleChange(e)}
//       />
//     </Grid>

//     <Grid item lg={6} sm={6} md={6} xs={6}>
//       <Button
//         fullWidth
//         className={classes.signUp}
//         size="large"
//         variant="contained"
//         color="primary"
//         onClick={() => handleEnquiry()}
//       >
//         Enquire Now
//       </Button>
//     </Grid>
//   </Grid>
// </form>
//     </div>
//   );
// };

// export default ContactUs;
