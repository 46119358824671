import { utilTypes } from "../types";
import { ROOT_URL } from "../utils/utils";
export const setUserLocation = (location) => {
  return {
    type: utilTypes.GET_USER_LOCATION,
    payload: location,
  };
};

export const pushtohistory = (location) => {
  console.log("push to history");
  return {
    type: utilTypes.PUSH_TO_HISTORY,

    payload: location,
  };
};
export const uploadhistory = (history) => {
  return {
    type: utilTypes.UPLOAD_HISTORY,
    payload: {
      request: {
        method: "post",
        url: `${ROOT_URL}MA/WebsiteVisitor/history`,

        data: history,
      },
    },
  };
};
export const uploadhistorysuccess = () => {
  return {
    type: utilTypes.UPLOAD_HISTORY_SUCCESS,
  };
};
export const getvisitortoken = (data) => {
  let preparedata = {
    countryCode: data.country_code,
    countryName: data.country_name,
    city: data.city,
    postal: data.postal,
    ip: data.IPv4,
    state: data.state,
    latitude: data.latitude,
    longitude: data.longitude,
    domain: "ecom",
  };
  console.log("visitor data", data);
  return {
    type: utilTypes.GET_VISITOR_TOKEN,
    payload: {
      request: {
        method: "post",
        url: `${ROOT_URL}MA/WebsiteVisitor/log`,
        data: preparedata,
      },
    },
  };
};
export const getvisitortokensuccess = (data) => {
  return {
    type: utilTypes.GET_VISITOR_TOKEN_SUCCESS,
    payload: {
      data: data,
    },
  };
};
export const consentaction = (value) => {
  return {
    type: utilTypes.CONSENT_ACTION,
    payload: value,
  };
};
