import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core";
import FilterBar from "../FilterBar/FilterBar";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "60vw",
  },
  filterContainer: {
    width: "60vw",
  },
}));

const FilterBarMobile = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Drawer open={props.open} onClose={() => props.close()} anchor="right">
        <div className={classes.filterContainer}>
          <FilterBar />
        </div>
      </Drawer>
    </div>
  );
};
export default FilterBarMobile;
