import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Github from "@material-ui/icons/GitHub";
import Facebook from "@material-ui/icons/Facebook";
import Instagram from "@material-ui/icons/Instagram";
import LinkedIn from "@material-ui/icons/LinkedIn";
import PinterestIcon from "@material-ui/icons/Pinterest";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));
const FooterSocialInfo = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container alignItems="center" justify="space-evenly">
        <Grid item>
          <PinterestIcon color="action" />
        </Grid>
        <Grid item>
          <Facebook color="action" />
        </Grid>
        <Grid item>
          <Instagram color="action" />
        </Grid>
        <Grid item>
          <LinkedIn color="action" />
        </Grid>
      </Grid>
    </div>
  );
};
export default FooterSocialInfo;
