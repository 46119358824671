import React from "react";

import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import calculatePricefromPribands from "../../functions/calculatePriceFromPricabands";
const useStyles = makeStyles((theme) => ({
  root: {
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
  name: {
    // color: theme.palette.text.primary,
    // textTransform: "uppercase",
    fontSize: "1.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
  title: {
    color: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {},
  },
  actualValue: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {},
  },
  marketValue: {
    fontWeight: 600,
    color: theme.palette.text.secondary,
    // textDecoration: "line-through",
    [theme.breakpoints.down("md")]: {},
  },
  discountValue: {
    fontWeight: 600,
    color: theme.palette.green.main[400],
    [theme.breakpoints.down("md")]: {},
  },
  returntext: {
    color: theme.palette.text.primary,
  },
}));

const ProductHeader = (props) => {
  const classes = useStyles();
  const { name, unitPrice, marketPrice, pricebands, selected, quantity } =
    props;
  const [selectedPriceBand, setSelectedPriceBand] = React.useState(null);
  let clsPrice = "",
    originValue,
    actualValue;

  if (!marketPrice) {
    //原价
    actualValue = unitPrice;
    originValue = null;
    clsPrice = "common";
  } else {
    // 折扣价
    if (!props.pricebands.length > 0) {
      actualValue = unitPrice.toFixed(2);
    } else {
      actualValue = calculatePricefromPribands(pricebands, props.selected);

      // const listPrice = pricebands.sort(
      //   (a, b) => a.minimumQuantity - b.minimumQuantity
      // )[selectedPriceBand].listPrice;
      // const discount = pricebands.sort(
      //   (a, b) => a.minimumQuantity - b.minimumQuantity
      // )[selectedPriceBand].discount;
      // actualValue = (listPrice * 100) / discount;
      // actualValue = pricebands
      //   .sort((a, b) => a.minimumQuantity - b.minimumQuantity)
      // [selectedPriceBand].unitPrice.toFixed(2);
    }
    originValue = marketPrice.toFixed(2);
    clsPrice = "discount";
  }
  React.useEffect(() => {
    const selectedIndex =
      props.pricebands &&
      pricebands.findIndex(
        (ele) =>
          ele.minimumQuantity <= parseInt(props.selected) &&
          ele.maximumQuantity >= parseInt(props.selected)
      );

    console.log(selectedIndex, props.selected);
    setSelectedPriceBand(selectedIndex);
  }, [props.selected]);
  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      justify="center"
      className={classes.root}
    >
      <Grid item lg={12} xs="auto" md="auto" sm="auto">
        <Typography color="primary" className={classes.name}>
          {name}
        </Typography>
      </Grid>
      <Grid item container lg={12} xs={12} sm={12} md={12} alignItems="center">
        <Grid item lg={5} sm={5} md={5} xs={4}>
          <Typography variant="h6" className={classes.title}>
            Your Price
          </Typography>
        </Grid>

        <Grid item lg={5} sm={5} md={5} xs={12}>
          <Typography variant="h6" className={classes.actualValue}>
            ₹{actualValue}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        lg={12}
        container
        alignItems="center"
        xs="auto"
        md="auto"
        sm="auto"
      >
        <Grid item lg={5} sm={5} md={5} xs={4}>
          <Typography variant="h6" className={classes.title}>
            M.R.P.
          </Typography>
        </Grid>

        <Grid item lg={5} sm={5} md={5} xs={12}>
          <Typography variant="h6" className={classes.marketValue}>
            ₹{originValue}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container lg={12} xs={12} sm={12} md={12} alignItems="center">
        <Grid item lg={5} sm={5} md={5} xs={5}>
          <Typography variant="h6" className={classes.title}>
            Available Quantity
          </Typography>
        </Grid>

        <Grid item lg={5} sm={5} md={5} xs={5}>
          <Typography variant="h6" className={classes.actualValue}>
            {quantity}
          </Typography>
        </Grid>
      </Grid>
      {/* <Grid
        item
        container
        lg={12}
        alignItems="center"
        xs="auto"
        md="auto"
        sm="auto"
      >
        <Grid item lg={3} sm={4} md={4} xs={4}>
          <Typography variant="h6" className={classes.title}>
            Discount
          </Typography>
        </Grid>
        <Grid item lg={1} sm={1} md={1} xs={1}>
          <Typography>:</Typography>
        </Grid>
        <Grid item lg={8} sm={7} md={7} xs={7}>
          <Typography variant="h6" className={classes.discountValue}>
            {(100 - (100 * unitPrice) / marketPrice).toFixed(2)} % off
          </Typography>
        </Grid>
      </Grid> */}
      {/* {props.returnable === 0 ? (
        <Grid
          item
          container
          lg={12}
          alignItems="center"
          xs="auto"
          md="auto"
          sm="auto"
        >
          <Grid item lg={3} sm={4} md={4} xs={4}>
            <Typography variant="h6" className={classes.title}>
              Return
            </Typography>
          </Grid>
          <Grid item lg={1} sm={1} md={1} xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid item lg={8} sm={7} md={7} xs={7}>
            <Typography className={classes.discountValue}>
              Item cannot be returned
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          container
          lg={12}
          alignItems="center"
          xs="auto"
          md="auto"
          sm="auto"
        >
          <Grid item lg={3} sm={4} md={4} xs={4}>
            <Typography variant="h6" className={classes.title}>
              Return
            </Typography>
          </Grid>
          <Grid item lg={1} sm={1} md={1} xs={1}>
            <Typography>:</Typography>
          </Grid>
          <Grid item lg={8} sm={7} md={7} xs={7}>
            <Typography variant="h6" className={classes.returntext}>
              Item can be returned within {props.returnable} days.
            </Typography>
          </Grid>
        </Grid>
      )} */}
    </Grid>
  );
};

export default ProductHeader;

// import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import { Grid, Typography } from "@material-ui/core";

// const useStyles = makeStyles((theme) => ({
//   item: {
//     marginTop: theme.spacing(1),
//     marginBottom: theme.spacing(1),
//   },
// }));

// const ProductHeader = ({ name, description, unitPrice, marketPrice }) => {
//   const classes = useStyles();

//   return (
//     <div className={classes.root}>
//       <Grid container>
//         <Grid item lg={12} sm={12} md={12} xs={12} className={classes.item}>
//           <Typography variant="h6">{name}</Typography>
//         </Grid>
//         <Grid item lg={12} sm={12} md={12} xs={12} className={classes.item}>
//           <Typography variant="h6" color="textSecondary">
//             {description}
//           </Typography>
//         </Grid>

//         <Grid item lg={12} sm={12} md={12} xs={12} className={classes.item}>
//           <Typography variant="h6">Deal Price : {unitPrice}</Typography>
//         </Grid>
//         <Grid item lg={12} sm={12} md={12} xs={12} className={classes.item}>
//           <Typography variant="h6">Market Price : {marketPrice}</Typography>
//         </Grid>
//         <Grid item lg={12} sm={12} md={12} xs={12} className={classes.item}>
//           <Typography variant="h6">
//             Discount : {100 - (100 * unitPrice) / marketPrice} % off
//           </Typography>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };
// export default ProductHeader;
