import momemt from "moment";
const getExpireTime = async () => {
  try {
    const value = await localStorage.getItem("expireTime");
    if (value !== null) {
      // value previously stored
      let isExpired = false;
      const expireTime = momemt(value).toDate();

      const currentTime = momemt().toDate();
      console.log(
        "from get expire time",
        // value,
        expireTime,
        currentTime,
        currentTime > expireTime ? true : false
      );

      // 'before',
      // currentTime.isBefore(expireTime),
      // 'after',
      // currentTime.isAfter(expireTime),
      // );
      currentTime > expireTime ? (isExpired = true) : (isExpired = false);

      return isExpired;
    }
  } catch (e) {
    return true;
  }
};
export default getExpireTime;
