import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import {
  AppBar,
  Toolbar,
  Grid,
  Breadcrumbs,
  Container,
} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { useParams } from "react-router-dom";
import MainCateogryItem from "./MainCategoryItem";
import ProductItem from "../main/ProductItem";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    backgroundColor: theme.palette.background.paper,
  },
  productList: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));
const SubCateogryListing = (props) => {
  const { type, category } = useParams();
  const classes = useStyles();
  const products = useSelector((state) => state.shop.products);
  const favourites = useSelector((state) => state.shop.favourites);
  return (
    <div className={classes.root}>
      <div>
        <AppBar position="static" color="default" elevation={1}>
          <Toolbar>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link>{type}</Link>
                  <Link>{category}</Link>
                </Breadcrumbs>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container className={classes.productList}>
            {products &&
              products.length > 0 &&
              products.map((subcat, index) => {
                return (
                  <Grid item lg={3} sm={6} md={3} xs={6} key={index}>
                    <ProductItem
                      item={subcat}
                      {...subcat}
                      isFavourite={favourites.some(
                        (data) => data.id === subcat.id
                      )}
                    />
                    {/* <MainCateogryItem data={subcat} /> */}
                  </Grid>
                );
              })}
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default SubCateogryListing;
