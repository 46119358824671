import React from "react";

import FootMenu from "./FooterMenu";
import FooterSocialInfo from "./FooterSocialInfo";
import FooterCopyright from "./FooterCopyright";

import { Container, Divider, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 35,

    [theme.breakpoints.down("md")]: {
      backgroundColor: theme.palette.background.dark,
      paddingBottom: theme.spacing(5),
    },
  },
  Divider: {
    // backgroundColor: theme.palette.primary.gray,
    marginTop: 25,
    marginBottom: 0,
  },
  FooterCopyright: {
    height: "10vh",
    [theme.breakpoints.down("md")]: {
      marginTop: "2vh",
      paddingBottom: "5vh",
    },
  },
  FooterCopyrightContainer: {
    [theme.breakpoints.down("md")]: {
      paddingBottom: "0vh",
    },
  },
  textContainer: {
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(2),
    },
  },
}));
const Footer = () => {
  const classes = useStyles();
  return (
    <div className="footer">
      <div id="footer" className={classes.footer}>
        <Container maxWidth="xl">
          <FootMenu />
          <Divider className={classes.Divider} />
          <Grid
            container
            alignItems="center"
            className={classes.FooterCopyright}
          >
            <Grid
              item
              container
              lg={3}
              md={3}
              sm={12}
              xs={12}
              className={classes.FooterCopyrightContainer}
            >
              <FooterSocialInfo />
            </Grid>
            <Grid
              item
              container
              lg={9}
              md={9}
              sm={12}
              xs={12}
              className={classes.textContainer}
            >
              <FooterCopyright />
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
