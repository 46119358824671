import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Carousel, { Dots } from "@brainhubeu/react-carousel";
import { Grid, Hidden } from "@material-ui/core";
import LeftIcon from "@material-ui/icons/ChevronLeft";
import RightIcon from "@material-ui/icons/ChevronRight";
import "@brainhubeu/react-carousel/lib/style.css";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  img: {
    height: 400,
    width: "auto",
    [theme.breakpoints.down("md")]: {
      height: 400,
      width: "auto",
    },
  },

  mini: {
    height: 100,
    padding: 10,
    width: "auto",
    borderWidth: 0.5,
    borderStyle: "solid",
    // borderColor: theme.palette.background.paper,
    borderRadius: 0,
    [theme.breakpoints.down("md")]: {
      height: 400,
      width: "auto",
    },
  },
  mainImageContainer: {
    borderWidth: 0.5,
    borderStyle: "solid",
    borderColor: theme.palette.background.paper,
    borderRadius: 0,
  },
  disabled: {
    color: "lightgray",
  },
}));
const ProductImages = ({ images }) => {
  const classes = useStyles();
  const [imgIndex, setImgageIndex] = useState(0);
  // const [productImages, setProductImages] = React.useState([]);
  // React.useEffect(() => {
  //   setProductImages(images);
  // }, []);
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);
  const handleClick = (imgIndex) => {
    setImgageIndex(imgIndex);
    imgIndex < 0
      ? setCurrentImageIndex(images.length + imgIndex)
      : setCurrentImageIndex(imgIndex);
  };

  // const handleSelectedImage = (index) => {
  //   setImgageIndex(index);
  //   setCurrentImageIndex(index);
  // };

  return (
    <div className={classes.root}>
      {images.length > 0 && (
        <Grid container spacing={3} className={classes.container}>
          <Hidden smDown>
            <Grid
              item
              className={classes.mainImageContainer}
              container
              justify="center"
              alignItems="center"
              lg={12}
            >
              <img
                src={images[currentImageIndex].url}
                className={classes.img}
              />
            </Grid>
          </Hidden>
          <Grid item lg={12} xs={12}>
            <Carousel
              arrows
              slidesPerPage={5}
              slidesPerScroll={1}
              clickToChange
              arrowLeft={<LeftIcon className={classes.icon} />}
              arrowLeftDisabled={<LeftIcon className={classes.disabled} />}
              arrowRightDisabled={<RightIcon className={classes.disabled} />}
              arrowRight={<RightIcon className={classes.icon} />}
              addArrowClickHandler
              value={imgIndex}
              onChange={(e) => handleClick(e)}
              breakpoints={{
                1000: {
                  // these props will be applied when screen width is less than 1000px
                  slidesPerPage: 1,
                  slidesPerScroll: 1,
                  arrowLeft: <LeftIcon className={classes.icon} />,
                  arrowRight: <RightIcon className={classes.icon} />,

                  clickToChange: false,
                  centered: true,
                  arrows: true,
                  infinite: false,
                },
              }}
            >
              {images.map((img, index) => (
                <img
                  key={index}
                  alt={index}
                  onClick={() => handleClick(index)}
                  className={classes.mini}
                  src={img.url}
                />
              ))}
            </Carousel>
            <Hidden smUp>
              <Dots
                value={imgIndex}
                onChange={(e) => handleClick(e)}
                number={images.length}
              />
            </Hidden>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
export default ProductImages;
