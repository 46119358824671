import React from "react";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Typography,
  Hidden,
  Box,
  Divider,
} from "@material-ui/core";
import CalculateTotal from "../../utils/calculateTotal";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "100%",
  },
  totalContainer: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0),
    },
  },
  mobileTotalContainer: {
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(2),
    },
  },
  total: {
    fontSize: 15,
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
      display: "inline",
    },
  },
  container: {
    maxHeight: 200,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      maxHeight: "50vh",
    },
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const ConfirmOrderProdcuts = (props) => {
  const theme = useTheme();
  const cartsite = useSelector((state) => state.shop.cart);
  // let cart = [...props.cart];
  // cart.totalNetAmount = 0;
  // cart.totalTaxAmount = 0;
  // cart.forEach((item) => {
  //   item.taxAmount =
  //     item.unitPrice * (item.taxRate / 100) * item.selectedOptions.QUANTITY;
  //   item.total =
  //     item.unitPrice * item.selectedOptions.QUANTITY + item.taxAmount;
  //   item.netAmount = item.unitPrice * item.selectedOptions.QUANTITY;
  //   cart.totalNetAmount += item.netAmount;
  //   cart.totalTaxAmount += item.taxAmount;
  //   // cart.grandTotal += item.total;
  // });
  const classes = useStyles();

  let cart = CalculateTotal(cartsite);

  return (
    <div className={classes.root}>
      <TableContainer className={classes.container}>
        <Table
          size="small"
          stickyHeader={theme.breakpoints.down("md") ? false : true}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell colSpan={1} align="left">
                {" "}
                Code
              </StyledTableCell>
              <StyledTableCell colSpan={3} align="left">
                {" "}
                Item
              </StyledTableCell>
              <StyledTableCell colSpan={1} align="center">
                {" "}
                M.R.P
              </StyledTableCell>
              <StyledTableCell colSpan={1} align="center">
                {" "}
                Unit Price
              </StyledTableCell>
              <StyledTableCell colSpan={1} align="center">
                {" "}
                Quantity
              </StyledTableCell>
              <StyledTableCell colSpan={1} align="center">
                {" "}
                Dis. Amount
              </StyledTableCell>
              <StyledTableCell colSpan={1} align="center">
                {" "}
                Net Amount
              </StyledTableCell>
              <StyledTableCell colSpan={1} align="center">
                {" "}
                Tax Rate
              </StyledTableCell>
              <StyledTableCell colSpan={1} align="center">
                {" "}
                Tax Amount
              </StyledTableCell>
              <StyledTableCell colSpan={1} align="right">
                {" "}
                Total
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cart &&
              cart.length > 0 &&
              cart.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell colSpan={1} align="left">
                      {item.sku}
                    </TableCell>
                    <TableCell colSpan={3} align="left">
                      {item.name}
                    </TableCell>
                    <TableCell colSpan={1} align="center">
                      {/* ₹ {item?.marketPrice?.toFixed(2)} */}₹{" "}
                      {item?.unitPrice?.toFixed(2)}
                    </TableCell>
                    <TableCell colSpan={1} align="center">
                      ₹ {item.unitPrice.toFixed(2)}
                    </TableCell>
                    <TableCell colSpan={1} align="center">
                      {item.selectedOptions.QUANTITY}
                    </TableCell>
                    <TableCell colSpan={1} align="center">
                      ₹ {item.discountAmount.toFixed(2)}
                    </TableCell>
                    <TableCell colSpan={1} align="center">
                      ₹ {item.netAmount.toFixed(2)}
                    </TableCell>
                    <TableCell colSpan={1} align="center">
                      {item.taxRate} %
                    </TableCell>
                    <TableCell colSpan={1} align="center">
                      {item.taxAmount.toFixed(2)}
                    </TableCell>
                    <TableCell colSpan={1} align="right">
                      ₹ {item.total.toFixed(2)}
                      {/* {(
                        item.unitPrice *
                        (1 + item.taxRate / 100) *
                        item.selectedOptions.QUANTITY
                      ).toFixed(2)} */}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container justify="flex-end" className={classes.totalContainer}>
        <Grid item xs={12}>
          <Hidden mdDown>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell align="right">
                      <Typography className={classes.total} variant="body1">
                        Total Net Amount
                      </Typography>
                    </TableCell>
                    <TableCell>:</TableCell>
                    <TableCell align="right">
                      <Typography className={classes.total} variant="body1">
                        ₹ {cart.totalNetAmount.toFixed(2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">
                      <Typography className={classes.total} variant="body1">
                        Total Tax
                      </Typography>
                    </TableCell>
                    <TableCell>:</TableCell>
                    <TableCell align="right">
                      <Typography className={classes.total} variant="body1">
                        ₹ {cart.totalTaxAmount.toFixed(2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">
                      <Typography className={classes.total} variant="body1">
                        Total Discount Amount
                      </Typography>
                    </TableCell>
                    <TableCell>:</TableCell>
                    <TableCell align="right">
                      <Typography className={classes.total} variant="body1">
                        ₹ {cart.totalDiscAmount.toFixed(2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">
                      <Typography className={classes.total} variant="body1">
                        Shipping Charges
                      </Typography>
                    </TableCell>
                    <TableCell>:</TableCell>
                    <TableCell align="right">
                      <Typography className={classes.total} variant="body1">
                        ₹ {props.shippingCharges.toFixed(2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">
                      <Typography
                        className={classes.total}
                        color="primary"
                        variant="body1"
                      >
                        Grand Total
                      </Typography>
                    </TableCell>
                    <TableCell>:</TableCell>
                    <TableCell align="right">
                      <Typography
                        className={classes.total}
                        color="primary"
                        variant="body1"
                      >
                        ₹{" "}
                        {(
                          cart.totalNetAmount +
                          cart.totalTaxAmount +
                          props.shippingCharges
                        ).toFixed(2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Hidden>
          <Hidden smUp>
            <Box className={classes.mobileTotalContainer}>
              <Box m={1}>
                <Typography className={classes.total} variant="body1">
                  Total Net Amount :
                </Typography>
                <Typography className={classes.total} variant="body1">
                  ₹ {cart.totalNetAmount.toFixed(2)}
                </Typography>
              </Box>
              <Divider />
              <Box m={1}>
                <Typography className={classes.total} variant="body1">
                  Total Tax :
                </Typography>
                <Typography className={classes.total} variant="body1">
                  ₹ {cart.totalTaxAmount.toFixed(2)}
                </Typography>
              </Box>
              <Divider />
              <Box m={1}>
                <Typography className={classes.total} variant="body1">
                  Shipping Charges :
                </Typography>
                <Typography className={classes.total} variant="body1">
                  ₹ {props.shippingCharges.toFixed(2)}
                </Typography>
              </Box>
              <Divider />
              <Box m={1}>
                <Typography className={classes.total} variant="body1">
                  Grand Total :
                </Typography>
                <Typography className={classes.total} variant="body1">
                  ₹{" "}
                  {(
                    cart.totalNetAmount +
                    cart.totalTaxAmount +
                    props.shippingCharges
                  ).toFixed(2)}
                </Typography>
              </Box>
              <Divider />
            </Box>
          </Hidden>
          {/* <Grid
        container
        direction="column"
        alignItems="flex-end"
        className={classes.totalContainer}
      >
        <Grid item>
          <Typography className={classes.total} color="primary" variant="body1">
            Total : {total}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.total} color="primary" variant="body1">
            Shipping Charges : {props.shippingCharges}
          </Typography>
        </Grid>
      </Grid> */}
        </Grid>
      </Grid>
    </div>
  );
};
export default ConfirmOrderProdcuts;
