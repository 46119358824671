import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { toggleLoginModal } from "../../redux/action/utilActions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    background: theme.palette.background.paper,
  },
  innerdiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const NewCustomer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <Grid item lg={12} sm={12} md={12} xs={12} className={classes.root}>
      <div className={classes.innerdiv}>
        <Button
          color="primary"
          variant="text"
          onClick={() => {
            dispatch(toggleLoginModal("register"));
          }}
        >
          New Customer ? Register
        </Button>
      </div>
    </Grid>
  );
};
export default NewCustomer;
