import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Useravatar from "./UserAvatar";
import UserActions from "./UserActions";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({}));
const SideMenu = () => {
  const { firstName, lastName } = useSelector((state) => state.user);
  return (
    <div>
      <Grid container direction="column" spacing={5}>
        <Grid item lg={12} xs={12} md={12} sm={12}>
          <Useravatar firstName={firstName} lastName={lastName} />
        </Grid>
        <Grid item lg={12} xs={12} md={12} sm={12}>
          <UserActions />
        </Grid>
      </Grid>
    </div>
  );
};
export default React.memo(SideMenu);
