import React from "react";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grayBackground,
    minHeight: "100vh",
  },
}));
const SubCategoryProductsList = () => {
  const classes = useStyles();
  return <div className={classes.root}></div>;
};
export default SubCategoryProductsList;
