import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PT from "prop-types";
import { removeProductFromFavourites } from "../../redux/selector";
import { connect, useDispatch } from "react-redux";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import FavouriteFilled from "@material-ui/icons/Favorite";
import FavouriteOutlined from "@material-ui/icons/FavoriteBorderOutlined";

import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  container: {
    textDecoration: "none",
  },
  media: {
    objectFit: "contain",
    height: 200,
    width: "100%",

    [theme.breakpoints.down("md")]: {
      height: 100,
      border: "none",
    },
  },
  link: {
    height: "7vh",
    textTransform: "uppercase",
    marginTop: theme.spacing(2),
    fontSize: "1rem",
    fontWeight: 400,
    overflow: "hidden",
    // whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    WebkitLineClamp: 2,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    width: "100%",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      height: "7vh",
      display: "flex",
      alignItems: "center",
    },
  },
  marketPrice: {
    color: theme.palette.text.secondary,

    textDecoration: "line-through",
  },
  discount: {
    color: theme.palette.primary.light,
  },
  Card: {
    "&:hover": {
      boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
    },
  },
  favouritebutton: {
    color: theme.palette.primary.main,
  },
}));

const propTypes = {
  id: PT.number,
  name: PT.string,
  marketPrice: PT.number,
  salePrice: PT.number,
  srcImg: PT.string,
  selectedOptions: PT.object,
  quantity: PT.number,
};

const FavouritesEdit = (props) => {
  const classes = useStyles();
  const [favourite, setfavourite] = React.useState(true);
  const dispatch = useDispatch();
  const changeFavourite = () => {
    if (favourite === true) {
      dispatch(removeProductFromFavourites(props.item));
    }
    setfavourite(!favourite);
  };

  function percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }

  const {
    id,
    name,
    salePrice,
    marketPrice,
    srcImg,
    selectedOptions,
    defaultImage,
    quantity,
    type,
    category,
    subcategory,
    browsingName,
    itemIndex,
  } = props.item;

  return (
    <Grid item lg={3} sm={4} md={4} xs={6} className={classes.container}>
      <Card variant="outlined" className={classes.Card}>
        <CardContent>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            spacing={1}
          >
            <Grid
              item
              container
              sm={12}
              md={12}
              xs={12}
              lg={12}
              justify="flex-end"
            >
              <IconButton
                onClick={() => changeFavourite()}
                className={classes.favouritebutton}
              >
                {favourite === true ? (
                  <FavouriteFilled />
                ) : (
                  <FavouriteOutlined />
                )}
              </IconButton>
            </Grid>
            <Grid
              item
              lg={12}
              sm={12}
              md={12}
              xs={12}
              component={Link}
              to={`/product/${browsingName}/${id}`}
            >
              <img
                alt="No image Found"
                className={classes.media}
                src={defaultImage}
              />
            </Grid>
            <Grid
              item
              lg={12}
              sm={12}
              md={12}
              xs={12}
              component={Link}
              to={`/product/${browsingName}/${id}`}
            >
              <Typography variant="h5" className={classes.link}>
                {name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="left" display="inline">
                ₹{salePrice}
              </Typography>
            </Grid>
            {/* <Grid item>
              <Typography display="inline" className={classes.marketPrice}>
                ₹{marketPrice}
              </Typography>
            </Grid> */}
            {/* <Grid item>
              <Typography display="block" className={classes.discount}>
                {100 - percentage(salePrice, marketPrice)}% off
              </Typography>
            </Grid> */}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default FavouritesEdit;
