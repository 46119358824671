import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import {
  // getvisitortoken,
  setUserLocation,
  pushtohistory,
  // uploadhistory,
  getvisitortokensuccess,
  uploadhistorysuccess,
} from "../../redux/action/historyActions";
import CookieComponent from "../Cookie/CookieDialog";
import ReactInterval from "react-interval";
import NewsLetter from "../Modals/NewsLetter";
import getvisitortoken from "../../functions/getVisitorToken";
import uploadhistory from "../../functions/uploadHistory";
const useStyles = makeStyles((theme) => ({
  root: {},
}));
const ComponentName = (props) => {
  const { token } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation().pathname;
  const history = useSelector((state) => state.utilReducer.history);
  const consent = useSelector((state) => state.utilReducer.consent);
  const visitorid = useSelector((state) => state.utilReducer.id);
  const userId = useSelector((state) => state.user.userId);
  const email = useSelector((state) => state.utilReducer.email);
  const name = useSelector((state) => state.utilReducer.name);
  React.useEffect(() => {
    Axios.get(
      "https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572"
    ).then((res) => {
      if (token === "undefined" || token === null) {
        getvisitortoken(res.data).then((data) =>
          dispatch(getvisitortokensuccess(data.data))
        );
        dispatch(setUserLocation(res.data));
      }
      // dispatch(getvisitortoken(res.data));
    });
  }, []);

  const uploadhistorytoserver = (visitorid, consent, history) => {
    if (consent && history.length > 0) {
      uploadhistory({
        id: visitorid,
        userId: userId,
        consent: consent,
        logs: history,
        email,
        name,
      }).then((data) => {
        dispatch(uploadhistorysuccess());
      });
      // dispatch(
      // uploadhistory({
      //   id: visitorid,
      //   userId: userId,
      //   consent: consent,
      //   logs: history,
      //   email,
      //   name,
      // })
      // );
    }
  };
  //   React.useEffect(() => {
  //     console.log("history length", history.length);
  //     if (consent && history.length > 0) {
  //       setInterval(uploadhistorytoserver, 10000, token, visitorid, consent);
  //     }
  //   }, [consent]);

  //   function uploadhistorytoserver(token, visitorid, consent, history) {
  //     dispatch(
  //       uploadhistory({
  //         id: token === "undefined" || token === null ? visitorid : userId,
  //         consent: consent,
  //         logs: history,
  //       })
  //     );
  //   }

  return (
    <div>
      <NewsLetter />
      <ReactInterval
        timeout={10000}
        enabled={true}
        callback={() => uploadhistorytoserver(visitorid, consent, history)}
      />
      <CookieComponent />
    </div>
  );
};

export default ComponentName;
