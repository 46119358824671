import React from "react";
import ToolBar from "./ToolBar";
import SearchBar from "./SearchBar";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden, Grid } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "flex-end",

    width: "74%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));
const Wrapper = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Grid container alignItems="center" justify="space-between">
        <Hidden smDown>
          <Grid item>
            <SearchBar />
          </Grid>
        </Hidden>
        <Grid item lg={4} md={4} sm={8} xs={12}>
          <ToolBar />
        </Grid>
      </Grid>
    </div>
  );
};
export default Wrapper;
