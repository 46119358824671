import React, { useState } from "react";
import ProductInputs from "../../data/productinputs.json";
import ProductItem from "../main/ProductItem.js";

import FilterBar from "../FilterBar/FilterBar";
import { connect, useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Container,
  Card,
  CardContent,
  Divider,
  Typography,
  Hidden,
  Button,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import Pagination from "@material-ui/lab/Pagination";
import { toggleLoginModal } from "../../redux/action/utilActions.js";
import { addProductToCart } from "../../redux/selector/index.js";
import FlippingCard from "../main/FlippingCard";
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  card: {
    borderRadius: 4,
    [theme.breakpoints.down("md")]: {
      backgroundColor: theme.palette.background.paper,
    },
  },
  CardHeader: {
    // color: "#565656",

    [theme.breakpoints.down("md")]: {
      fontWeight: 400,

      // color: "white"
    },
  },
  Container: {
    [theme.breakpoints.down("md")]: {},
  },
}));
const HomeProductSection = (props) => {
  const { dataHotSaleProduct } = props;
  const classes = useStyles();
  //   React.useEffect(()=>{
  //     setTotalProducts(dataHotSaleProduct)
  //     },[dataHotSaleProduct])
  // const [totalProducts,setTotalProducts] =React.useState([])
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(16);

  const indexOfLastitem = currentPage * itemsPerPage;
  const indexOfFirstitem = indexOfLastitem - itemsPerPage;
  let currentProdcuts = dataHotSaleProduct.slice(
    indexOfFirstitem,
    indexOfLastitem
  );
  React.useEffect(() => {
    setCurrentPage(1);
  }, [dataHotSaleProduct]);
  const isLogged = useSelector((state) => state.user.isLogged);
  const dispatch = useDispatch();
  const handleLogin = () => {
    dispatch(toggleLoginModal("login"));
  };

  const handleAddtoCart = (item) => {
    const proInfo = {
      id: item.id,
      name: item.name,
      marketPrice: item.listPrice,
      salePrice: item.salePrice,
      seller: item.seller,
      sellerId: item.sellerId,
      srcImg: item.images.length > 0 && item.images[0].url,
      selectedOptions: item.selectedOptions,
      configurationId: item.configurationId,
      specificationId: item.specificationId,
      priceBands: item.priceBands.length > 0 ? true : false,
      distributorId: item.distributorId,

      bomId: item.bomId,
    };
    let inputtype = ProductInputs[item.productInputs[0].orderInput];
    console.log("inputtype", item.selectedOptions);
    // let selectedOptions = {
    //   QUANTITY: item.moq,
    // };
    let cartItem = {
      ...proInfo,
      // selectedOptions,
      id: null,
      productId: proInfo.id,
    };
    dispatch(addProductToCart(cartItem));
  };
  const renderProducts = currentProdcuts.map(function (item, index) {
    return (
      <Grid item lg={3} xs={6} key={index}>
        <FlippingCard
          isLogged={isLogged}
          handleLogin={() => handleLogin()}
          handleCart={(item) => handleAddtoCart(item)}
          key={item.id}
          item={item}
          {...item}
          index={index}
        />
      </Grid>
    );
  });

  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(dataHotSaleProduct.length / itemsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  function handleClick(event, value) {
    console.log(event, "pages");
    setCurrentPage(value);
  }

  // const btnReadMore = (
  //   <CardContent>
  //     <Hidden smDown>
  //       <Grid container justify="flex-end">
  //         <Grid item>
  //           <Button
  //             color="secondary"
  //             size="large"
  //             variant="contained"
  //             onClick={handleReadMoreProduct}
  //           >
  //             View more
  //           </Button>
  //         </Grid>
  //       </Grid>
  //     </Hidden>
  //     <Hidden smUp>
  //       <Button
  //         color="primary"
  //         fullWidth
  //         size="large"
  //         variant="contained"
  //         onClick={handleReadMoreProduct}
  //       >
  //         View more
  //       </Button>
  //     </Hidden>
  //   </CardContent>
  // );

  let itemsProductCard = (
    <Card className={classes.card} elevation={0}>
      <CardContent>
        <Typography variant="h6" className={classes.CardHeader}>
          Featured Products
        </Typography>
      </CardContent>
      <Hidden smDown>
        <Divider />
      </Hidden>
      <CardContent>
        <Grid container spacing={2}>
          {renderProducts}
        </Grid>
      </CardContent>
      <CardContent>
        <Grid container justify="center">
          <Grid item>
            <Pagination
              page={currentPage}
              shape="rounded"
              color="primary"
              count={pageNumbers.length}
              onChange={handleClick}
            />
          </Grid>
        </Grid>
      </CardContent>
      {/* {!isReadMore ? btnReadMore : null} */}
    </Card>
  );

  return (
    <div className={classes.root}>
      <Container maxWidth="xl" className={classes.Container}>
        <Grid container spacing={5}>
          <Hidden smDown>
            <Grid item lg={3} md={3} sm={3}>
              <FilterBar />
            </Grid>
          </Hidden>
          <Grid item lg={9} xs={12} sm={12} md={9}>
            {itemsProductCard}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataHotSaleProduct: state.shop.products,
  };
};

export default connect(mapStateToProps)(HomeProductSection);
