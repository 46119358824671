import React from "react";

import {
  Container,
  makeStyles,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  Button,
  Modal,
  IconButton,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { ROOT_URL } from "../../../redux/utils/utils";
// import { registerUser } from "../../redux/action/useractions";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  signUp: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
    },
  },
  closeBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  closeBtn: {
    color: "white",
  },
  card: {
    height: "95vh",
    width: "70vw",
    [theme.breakpoints.down("md")]: {
      width: "95vw",
    },
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  Alertcontainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alert: {
    maxWidth: "80%",
  },
}));
const EnquiryProduct = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState({
    open: false,
    severnity: "",
    message: "",
  });
  const [state, setState] = React.useState({
    name: "",
    email: "",
    mobile: "",
    place: "",
    message: "",
    productId: props.productId,
    source: "yourwebsite.com",
  });
  const [error, setError] = React.useState();

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleEnquiry = (e) => {
    if (state.name === "") {
      setError("name");
    }
    if (state.email === "") {
      setError("email");
    }
    if (state.mobile === "") {
      setError("mobile");
    }
    if (state.place === "") {
      setError("place");
    } else {
      const token = localStorage.getItem("tkn");
      setLoading(true);
      const header = {
        Authorization: `Bearer ${token}`,
      };
      Axios.post(`${ROOT_URL}SFA/Query/Enquiry`, state, {
        headers: header,
      })
        .then((res) => {
          setSnackOpen({
            open: true,
            severnity: "success",
            message: "You Enquiry is Submitted Successfully",
          });
          setLoading(false);
          setTimeout(() => {
            setState({
              name: "",
              email: "",
              mobile: "",
              place: "",
              message: "",
              productId: props.productId,
              source: "yourwebsite.com",
            });
            handleClose();
          }, 5000);
        })
        .catch((err) => {
          setLoading(false);
          setSnackOpen({
            open: true,
            severnity: "error",
            message: "There was a problem, please retry.",
          });
        });
    }

    //   dispatch(registerUser(JSON.stringify(signupobj)));
  };
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen({ ...snackOpen, open: false });
  };
  const handleClose = () => {
    setState({
      name: "",
      email: "",
      mobile: "",
      place: "",
      message: "",
      productId: props.productId,
      source: "yourwebsite.com",
    });
    props.close();
  };
  const user = useSelector((state) => state.user);
  React.useEffect(() => {
    if (user.isLogged) {
      setState({
        ...state,
        name: `${user.firstName} ${user.firstName}`,
        email: user.email,
        mobile: user.mobile,
      });
    }
  }, [props.open]);
  return (
    <div>
      {/* <Snackbar
        open={snackOpen.open}
        autoHideDuration={2000}
        onClose={handleSnackClose}
      >
        <Alert onClose={handleSnackClose} severity={snackOpen.severnity}>
          {snackOpen.message}
        </Alert>
      </Snackbar> */}

      <Modal open={props.open} onClose={props.close} className={classes.root}>
        <div>
          <Card elevation={0} className={classes.card}>
            <CardHeader
              title="Enquiry"
              style={{ textAlign: "center" }}
              action={
                <IconButton onClick={() => handleClose()}>
                  <CloseIcon />
                </IconButton>
              }
            />

            {!snackOpen.open ? (
              <Typography align="center">Enter Your details here</Typography>
            ) : (
              <Box className={classes.Alertcontainer}>
                <Alert
                  className={classes.alert}
                  onClose={handleSnackClose}
                  severity={snackOpen.severnity}
                >
                  {snackOpen.message}
                </Alert>
              </Box>
            )}

            <CardContent>
              <Grid container spacing={2} justify="center">
                <Grid item lg={10} sm={12} md={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="Product Name"
                    variant="outlined"
                    fullWidth
                    disabled={true}
                    error={error === "name"}
                    value={props.productName}
                    // name="name"
                    // onChange={(e) => handleChange(e)}
                  />
                </Grid>

                <Grid item lg={10} sm={12} md={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="Enter Your Name"
                    variant="outlined"
                    fullWidth
                    disabled={loading}
                    error={error === "name"}
                    value={state.name}
                    name="name"
                    onChange={(e) => handleChange(e)}
                  />
                </Grid>
                <Grid item lg={10} sm={12} md={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="Enter Your Email"
                    variant="outlined"
                    fullWidth
                    disabled={loading}
                    error={error === "email"}
                    type="email"
                    value={state.email}
                    name="email"
                    onChange={(e) => handleChange(e)}
                  />
                </Grid>
                <Grid item lg={10} sm={12} md={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="Enter Your Mobile Number"
                    variant="outlined"
                    fullWidth
                    disabled={loading}
                    value={state.mobile}
                    error={error === "mobile"}
                    name="mobile"
                    onChange={(e) => handleChange(e)}
                  />
                </Grid>
                <Grid item lg={10} sm={12} md={10} xs={12}>
                  <TextField
                    id="outlined-basic"
                    // label="Enter Place"
                    placeholder="City, State , Country - Pincode"
                    variant="outlined"
                    fullWidth
                    disabled={loading}
                    error={error === "place"}
                    value={state.place}
                    name="place"
                    onChange={(e) => handleChange(e)}
                  />
                </Grid>
                <Grid item lg={10} sm={12} md={10} xs={12}>
                  <TextField
                    multiline
                    rows="6"
                    id="outlined-basic"
                    label="Message"
                    variant="outlined"
                    fullWidth
                    disabled={loading}
                    value={state.message}
                    name="message"
                    onChange={(e) => handleChange(e)}
                  />
                </Grid>

                <Grid item lg={6} sm={6} md={6} xs={6}>
                  <Button
                    fullWidth
                    className={classes.signUp}
                    disabled={loading}
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => handleEnquiry()}
                  >
                    Enquire Now
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

export default EnquiryProduct;
