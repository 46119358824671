import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SwipeableDrawer } from "@material-ui/core";
import {
  selectMainCategory,
  selectSubCategory,
  getSubCategoryProducts,
} from "../../redux/selector";
import { useDispatch } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
  },
}));
const SubcategorySidebar = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleSubCategoryClick = (
    maincategory,
    maincatIndex,
    subcat,
    subcatid,
    subcatIndex
  ) => {
    dispatch(selectMainCategory(maincategory, maincatIndex));
    dispatch(selectSubCategory(subcat, subcatid, subcatIndex));
    dispatch(getSubCategoryProducts(subcatid));
    props.close();
    // setAnchorEl(null);
    // props.history.push("/");
  };

  return (
    <div>
      <SwipeableDrawer
        open={props.open}
        onClose={props.close}
        className={classes.root}
      >
        sub category sidebar
      </SwipeableDrawer>
    </div>
  );
};

export default SubcategorySidebar;
