import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
} from "@material-ui/core";
import ProductCommentItem from "./ProductCommentItem";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
}));

const ProductComment = ({ data }) => {
  const classes = useStyles();
  const itemsComment =
    data &&
    data.slice(0, 9).map(function (item, index) {
      return (
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <ProductCommentItem key={item.id} {...item} />
        </Grid>
      );
    });

  return (
    <div className={classes.root}>
      <Card variant="outlined">
        <CardHeader title="Product Reviews" />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            {itemsComment}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};
export default ProductComment;
