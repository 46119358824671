import React from "react";
import PT from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import PlusIcon from "@material-ui/icons/AddCircle";
import MinusIcon from "@material-ui/icons/RemoveCircle";

import inputType from "../../data/productinputs.json";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  IconButton,
  CardHeader,
  Hidden,
  Divider,
  CardActionArea,
  Button,
  Box,
} from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import FavouriteFilled from "@material-ui/icons/Favorite";
import FavouriteOutlined from "@material-ui/icons/FavoriteBorderOutlined";
import { useDispatch } from "react-redux";
import {
  addProductToFavourites,
  removeProductFromFavourites,
  incrementCartQuantity,
  decrementCartQuantity,
} from "../../redux/selector";
import CartIcon from "@material-ui/icons/ShoppingCartOutlined";
// import ReactCardFlip from "react-card-flip";
import useHover from "@react-hook/hover";
import scrollToTop from "../../utils/scollToTop";

const useStyles = makeStyles((theme) => ({
  media: {
    objectFit: "contain",
    height: 200,

    width: "100%",

    [theme.breakpoints.down("md")]: {
      height: 100,
      // backgroundColor: "red",
      border: "none",
    },
  },
  productContainer: {
    padding: 0,
    // height: "50vh",
    // margin: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  },
  link: {
    color: theme.palette.text.primary,
  },
  marketPrice: {
    color: theme.palette.text.secondary,

    textDecoration: "line-through",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
    },
  },
  salePrice: {
    // color: theme.palette.text.secondary,

    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
    },
  },
  discount: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
    },
  },
  Card: {
    height: "77vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // backgroundColor: "red",
    padding: 0,
    margin: 0,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
    "&:hover": {
      boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
    },
  },
  favouritebutton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
  },
  // favouritebutton: {
  //   color: theme.palette.primary.main,

  // },
  IconButtonIncrement: {
    height: "6vh",

    width: "6vh",
    // backgroundColor: "red",
    // fontSize: "11px",
  },
  flippedCard: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    backgroundColor: theme.palette.action.disabledBackground,
  },
  flippedDescription: {
    flex: 1,
  },
  backSide: {
    boxShadow: "none",
    padding: 0,
  },
  btnContainer: {
    backgroundColor: theme.palette.background.lighter,
  },
  quantity: {
    borderColor: theme.palette.secondary,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  proName: {
    height: "7vh",
    textTransform: "uppercase",
    marginTop: theme.spacing(2),
    fontSize: "1rem",
    fontWeight: 400,
    overflow: "hidden",
    // whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    WebkitLineClamp: 2,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
      height: "10vh",
      display: "flex",
      alignItems: "center",
    },
  },
}));

let propTypes = {
  id: PT.string,
  category: PT.string,
  type: PT.string,
  name: PT.string,
  listPrice: PT.number,
  salePrice: PT.number,
  images: PT.arrayOf(PT.object),
};

const ProductItem = (props) => {
  const classes = useStyles();
  let selectedOptions = {
    QUANTITY: props.moq,
  };
  let product = { ...props, selectedOptions };
  const [favourite, setfavourite] = React.useState(false);
  const { flipped, setIsFlipped, handleLogin } = props;
  const dispatch = useDispatch();
  let {
    id,

    name,
    listPrice: marketPrice,
    salePrice: salePrice,
    images,

    item,
    isFavourite,
    defaultImage,
    type,
    category,
    subcategory,
    browsingName,
    productInputs,
    priceBands,
    isLogged,
    moq,
  } = product;

  const changeFavourite = () => {
    let favouriteItem = { ...item, id: null, productId: item.id };
    if (!isLogged) {
      // console.log("handlelogin", props, handleLogin);
      props.handleLogin();
    } else if (!isFavourite) {
      dispatch(addProductToFavourites(favouriteItem));
    } else {
      dispatch(removeProductFromFavourites(item));
    }
  };

  const handleCart = () => {
    if (!isLogged) {
      props.handleLogin();
    } else {
      let newProduct = {
        ...product,
        selectedOptions: {
          QUANTITY: value,
        },
      };
      props.handleCart(newProduct);
    }
  };
  const handleClick = () => {
    scrollToTop();
  };

  React.useEffect(() => {
    product.selectedOptions.QUANTITY = product.moq;
  }, []);
  const [value, setValue] = React.useState(moq);

  const handleSelect = (datavalue) => {
    product.selectedOptions.QUANTITY = datavalue;
    // alert(product.selectedOptions.QUANTITY);
  };

  let header = product.productInputs[0].orderInput;
  const dataInputs =
    header === "Loading"
      ? { type: "NUMBER", minimum: 3.5, maximum: 500.0, inputOptions: [] }
      : inputType[header];
  const Increment = () => {
    if (value < dataInputs.maximum) {
      handleSelect(value + moq);
      setValue(value + moq);
    } else {
      alert("Can not select more");
    }
  };
  const Decrement = () => {
    if (value > dataInputs.minimum && value > moq) {
      handleSelect(value - moq);
      setValue(value - moq);
    } else {
      alert("Can not select less");
    }
  };
  return (
    <div className={classes.root}>
      <Card variant="outlined" className={classes.Card}>
        <CardContent>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            spacing={1}
          >
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <Link
                className={classes.link}
                to={`/product/${browsingName}/${id}`}
                onClick={() => handleClick()}
              >
                <img
                  ref={props.innerRef}
                  alt="Image Url Error"
                  className={classes.media}
                  src={defaultImage}
                  // onMouseOver={() => setIsFlipped(true)}
                />
              </Link>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <Link
                className={classes.link}
                to={`/product/${browsingName}/${id}`}
                onClick={() => handleClick()}
              >
                <Typography className={classes.proName}>{name}</Typography>
              </Link>
            </Grid>
            <Grid item sm={6} md={6} sm={6} xs={6}>
              <Typography
                align="left"
                variant="h6"
                display="inline"
                className={classes.salePrice}
              >
                ₹{salePrice}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>

        <Grid item container sm={12} md={12} xs={12} lg={12} justify="center">
          <Grid
            item
            className={classes.btnContainer}
            container
            alignItems="center"
            justify="space-evenly"
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <Grid
              item
              sm={4}
              md={4}
              xs={4}
              lg={4}
              alignItems="center"
              justify="center"
              container
            >
              <IconButton
                // size="medium"
                color="secondary"
                // disabled={selectedOptions.QUANTITY < 2}
                onClick={() => {
                  Decrement();
                }}
              >
                <MinusIcon className={classes.IconButtonIncrement} />
              </IconButton>
            </Grid>
            <Grid
              item
              container
              className={classes.quantity}
              sm={4}
              md={4}
              xs={4}
              lg={4}
              alignItems="center"
              justify="center"
              // alignItems="center"
              // justify="center"
            >
              <Typography
                align="center"
                variant="subtitle1"
                color="textPrimary"
              >
                {value}
                {/* {product.selectedOptions.QUANTITY} */}
                {/* {selectedOptions[optionKeys[index]]} */}
              </Typography>
            </Grid>
            <Grid
              item
              sm={4}
              md={4}
              xs={4}
              lg={4}
              alignItems="center"
              justify="center"
              container
            >
              <IconButton
                // size="medium"
                color="primary"
                onClick={() => {
                  Increment();
                }}
              >
                <PlusIcon className={classes.IconButtonIncrement} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            item
            sm={4}
            md={4}
            xs={4}
            lg={4}
            container
            alignItems="center"
            justify="center"
            className={classes.btnContainer}
          >
            <IconButton
              size="medium"
              onClick={() => changeFavourite()}
              className={classes.favouritebutton}
            >
              {isFavourite === true ? (
                <FavouriteFilled />
              ) : (
                <FavouriteOutlined />
              )}
            </IconButton>
          </Grid>
          <Grid
            item
            sm={4}
            md={4}
            xs={4}
            lg={4}
            alignItems="center"
            justify="center"
            className={classes.btnContainer}
          />
          <Grid
            item
            sm={4}
            md={4}
            xs={4}
            lg={4}
            container
            alignItems="center"
            justify="center"
            className={classes.btnContainer}
          >
            {productInputs.length === 1 && (
              <IconButton
                onClick={() => handleCart()}
                className={classes.favouritebutton}
              >
                <CartIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default ProductItem;

// import React from "react";
// import PT from "prop-types";
// import { makeStyles } from "@material-ui/core/styles";
// import PlusIcon from "@material-ui/icons/AddCircle";
// import MinusIcon from "@material-ui/icons/RemoveCircle";
// import {
//   Card,
//   CardContent,
//   Grid,
//   Typography,
//   IconButton,
//   CardHeader,
//   Hidden,
//   Divider,
//   CardActionArea,
//   Button,
//   Box,
// } from "@material-ui/core";
// import { Link, withRouter } from "react-router-dom";
// import FavouriteFilled from "@material-ui/icons/Favorite";
// import FavouriteOutlined from "@material-ui/icons/FavoriteBorderOutlined";
// import { useDispatch } from "react-redux";
// import {
//   addProductToFavourites,
//   removeProductFromFavourites,
//   incrementCartQuantity,
//   decrementCartQuantity,
// } from "../../redux/selector";
// import Flippy, { FrontSide, BackSide } from "react-flippy";
// import CartIcon from "@material-ui/icons/ShoppingCartOutlined";
// // import ReactCardFlip from "react-card-flip";
// import useHover from "@react-hook/hover";
// import scrollToTop from "../../utils/scollToTop";
// const useStyles = makeStyles((theme) => ({
//   media: {
//     objectFit: "contain",
//     height: 200,

//     width: "100%",

//     [theme.breakpoints.down("md")]: {
//       height: 100,
//       // backgroundColor: "red",
//       border: "none",
//     },
//   },
//   productContainer: {
//     padding: 0,
//     // height: "50vh",
//     // margin: theme.spacing(1),
//     [theme.breakpoints.down("md")]: {
//       height: "auto",
//     },
//   },
//   link: {
//     color: theme.palette.text.primary,
//   },
//   marketPrice: {
//     color: theme.palette.text.secondary,

//     textDecoration: "line-through",
//     [theme.breakpoints.down("md")]: {
//       fontSize: "0.8rem",
//     },
//   },
//   salePrice: {
//     // color: theme.palette.text.secondary,

//     [theme.breakpoints.down("md")]: {
//       fontSize: "0.8rem",
//     },
//   },
//   discount: {
//     color: theme.palette.primary.main,
//     [theme.breakpoints.down("md")]: {
//       fontSize: "0.8rem",
//     },
//   },
//   Card: {
//     height: "65vh",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//     // backgroundColor: "red",
//     padding: 0,
//     margin: 0,
//     width: "100%",
//     [theme.breakpoints.down("md")]: {
//       height: "100%",
//     },
//     "&:hover": {
//       boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
//     },
//   },
//   favouritebutton: {
//     color: theme.palette.primary.main,
//     backgroundColor: theme.palette.background.paper,
//   },
//   flippedCard: {
//     display: "flex",
//     flexDirection: "column",
//     height: "100%",
//     backgroundColor: theme.palette.action.disabledBackground,
//   },
//   flippedDescription: {
//     flex: 1,
//   },
//   backSide: {
//     boxShadow: "none",
//     padding: 0,
//   },
//   btnContainer: {
//     backgroundColor: theme.palette.background.lighter,
//   },
//   quantity: {
//     borderColor: theme.palette.secondary,
//     borderWidth: 1,
//     borderStyle: "solid",
//     borderRadius: 4,
//     paddingLeft: theme.spacing(1),
//     paddingRight: theme.spacing(1),
//   },
//   proName: {
//     height: "7vh",
//     textTransform: "uppercase",
//     marginTop: theme.spacing(2),
//     fontSize: "1rem",
//     fontWeight: 400,
//     overflow: "hidden",
//     // whiteSpace: "nowrap",
//     textOverflow: "ellipsis",
//     WebkitLineClamp: 2,
//     display: "-webkit-box",
//     WebkitBoxOrient: "vertical",
//     width: "100%",
//     [theme.breakpoints.down("md")]: {
//       fontSize: "0.8rem",
//       height: "10vh",
//       display: "flex",
//       alignItems: "center",
//     },
//   },
// }));

// let propTypes = {
//   id: PT.string,
//   category: PT.string,
//   type: PT.string,
//   name: PT.string,
//   listPrice: PT.number,
//   salePrice: PT.number,
//   images: PT.arrayOf(PT.object),
// };

// const ProductItem = (props) => {
//   const classes = useStyles();
//   let selectedOptions = {
//     QUANTITY: props.moq,
//   };
//   let product = { ...props, selectedOptions };
//   const [favourite, setfavourite] = React.useState(false);
//   const { flipped, setIsFlipped, handleLogin } = props;
//   const dispatch = useDispatch();
//   let {
//     id,

//     name,
//     listPrice: marketPrice,
//     salePrice: salePrice,
//     images,

//     item,
//     isFavourite,
//     defaultImage,
//     type,
//     category,
//     subcategory,
//     browsingName,
//     productInputs,
//     priceBands,
//     isLogged,
//   } = product;

//   function percentage(partialValue, totalValue) {
//     return (100 * partialValue) / totalValue;
//   }

//   const changeFavourite = () => {
//     let favouriteItem = { ...item, id: null, productId: item.id };
//     if (!isLogged) {
//       // console.log("handlelogin", props, handleLogin);
//       props.handleLogin();
//     } else if (!isFavourite) {
//       dispatch(addProductToFavourites(favouriteItem));
//     } else {
//       dispatch(removeProductFromFavourites(item));
//     }
//   };

//   const handleCart = () => {
//     if (!isLogged) {
//       props.handleLogin();
//     } else {
//       props.handleCart();
//     }
//   };
//   const handleClick = () => {
//     scrollToTop();
//   };

//   React.useEffect(() => {
//     product.selectedOptions.QUANTITY = product.moq;
//   }, []);
//   const [value, setValue] = React.useState(moq);

//   const Increment = () => {
//     if (value < dataInputs.maximum) {
//       handleSelect(value + moq);
//       setValue(value + moq);
//     } else {
//       alert("Can not select more");
//     }
//   };
//   const Decrement = () => {
//     if (value > dataInputs.minimum) {
//       handleSelect(value - moq);
//       setValue(value - moq);
//     } else {
//       alert("Can not select less");
//     }
//   };
//   return (
//     <div className={classes.root}>
//       <Card variant="outlined" className={classes.Card}>
//         <CardContent>
//           <Grid
//             container
//             alignItems="center"
//             justify="space-between"
//             spacing={1}
//           >
//             <Grid item lg={12} sm={12} md={12} xs={12}>
//               <Link
//                 className={classes.link}
//                 to={`/product/${browsingName}/${id}`}
//                 onClick={() => handleClick()}
//               >
//                 <img
//                   ref={props.innerRef}
//                   alt="Image Url Error"
//                   className={classes.media}
//                   src={defaultImage}
//                   // onMouseOver={() => setIsFlipped(true)}
//                 />
//               </Link>
//             </Grid>
//             <Grid item lg={12} sm={12} md={12} xs={12}>
//               <Link
//                 className={classes.link}
//                 to={`/product/${browsingName}/${id}`}
//                 onClick={() => handleClick()}
//               >
//                 <Typography className={classes.proName}>{name}</Typography>
//               </Link>
//             </Grid>
//             <Grid item sm={6} md={6} sm={6} xs={6}>
//               <Typography
//                 align="left"
//                 variant="h6"
//                 display="inline"
//                 className={classes.salePrice}
//               >
//                 ₹{salePrice}
//               </Typography>
//             </Grid>
//           </Grid>
//         </CardContent>

//         <Grid item container sm={12} md={12} xs={12} lg={12} justify="center">
//           <Grid
//             item
//             className={classes.btnContainer}
//             container
//             alignItems="center"
//             justify="space-evenly"
//             lg={12}
//             md={12}
//             sm={12}
//             xs={12}
//           >
//             <Grid item>
//               <IconButton
//                 size="small"
//                 color="secondary"
//                 // disabled={selectedOptions.QUANTITY < 2}
//                 // onClick={() => {
//                 //   dispatch(
//                 //     decrementCartQuantity({ id, selectedOptions })
//                 //   );
//                 // }}
//               >
//                 <MinusIcon />
//               </IconButton>
//             </Grid>
//             <Grid
//               item
//               className={classes.quantity}
//               // alignItems="center"
//               // justify="center"
//             >
//               <Typography
//                 align="center"
//                 variant="subtitle1"
//                 color="textPrimary"
//               >
//                 {product.selectedOptions.QUANTITY}
//                 {/* {selectedOptions[optionKeys[index]]} */}
//               </Typography>
//             </Grid>
//             <Grid item>
//               <IconButton
//                 size="small"
//                 color="primary"
//                 // onClick={() => {
//                 //   dispatch(
//                 //     incrementCartQuantity({ id, selectedOptions })
//                 //   );
//                 // }}
//               >
//                 <PlusIcon />
//               </IconButton>
//             </Grid>
//           </Grid>
//           <Grid
//             item
//             container
//             className={classes.btnContainer}
//             alignItems="center"
//             justify="center"
//             sm={6}
//             md={6}
//             xs={6}
//             lg={6}
//           >
//             <IconButton
//               size="medium"
//               onClick={() => changeFavourite()}
//               className={classes.favouritebutton}
//             >
//               {isFavourite === true ? (
//                 <FavouriteFilled />
//               ) : (
//                 <FavouriteOutlined />
//               )}
//             </IconButton>
//           </Grid>
//           <Grid
//             item
//             container
//             className={classes.btnContainer}
//             alignItems="center"
//             justify="center"
//             sm={6}
//             md={6}
//             xs={6}
//             lg={6}
//           >
//             {productInputs.length === 1 && (
//               <IconButton
//                 onClick={() => handleCart()}
//                 className={classes.favouritebutton}
//               >
//                 <CartIcon />
//               </IconButton>
//             )}
//           </Grid>
//         </Grid>
//       </Card>
//     </div>
//   );
// };

// const FlippingCard = (props) => {
//   const cardRef = React.useRef();
//   const isFlipped = useHover(cardRef, { enterDelay: 2000, leaveDelay: 0 });

//   const classes = useStyles();
//   const handleNavigate = () => {
//     props.history.push(`/product/${props.browsingName}/${props.id}`);
//   };
//   return (
//     <div ref={cardRef} className={classes.productContainer}>
//       <Hidden smDown>
//         <Flippy
//           // flipOnHover={false} // default false
//           // flipOnClick={true} // default false
//           flipDirection="horizontal" // horizontal or vertical
//           ref={cardRef}
//           // isFlipped={isFlipped}
//           // to use toggle method like this.flippy.toggle()
//           // if you pass isFlipped prop component will be controlled component.
//           // and other props, which will go to div
//           style={{ width: "100%", height: "100%" }} /// these are optional style, it is not necessary
//         >
//           <FrontSide
//             elementType="div"
//             style={{ boxShadow: "none", padding: 0 }}
//           >
//             <ProductItem {...props} isFlipped={isFlipped} innerRef={cardRef} />
//           </FrontSide>
//           <BackSide className={classes.backSide}>
//             <Card
//               ref={cardRef}
//               variant="outlined"
//               className={classes.flippedCard}
//               onMouse
//             >
//               <CardContent className={classes.flippedDescription}>
//                 <Typography variant="h6" color="textPrimary">
//                   Description
//                 </Typography>
//                 <Typography>{props.description}</Typography>
//               </CardContent>
//               {/* <CardActionArea>

//               </CardActionArea> */}
//               <CardContent>
//                 <Box m={1}>
//                   <Button
//                     color="primary"
//                     variant="contained"
//                     size="large"
//                     fullWidth
//                     onClick={() => handleNavigate()}
//                   >
//                     Explore
//                   </Button>
//                 </Box>
//               </CardContent>
//             </Card>
//           </BackSide>
//         </Flippy>
//         {/* <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
//           <ProductItem {...props} isFlipped={isFlipped} innerRef={cardRef} />

//           <Card
//             ref={cardRef}
//             variant="outlined"
//             className={classes.flippedCard}
//             onMouse
//           >
//             <CardHeader title="Overview" />

//             <CardContent>
//               <Typography>{props.overview}</Typography>
//             </CardContent>
//           </Card>
//         </ReactCardFlip> */}
//       </Hidden>
//       <Hidden smUp>
//         <div>
//           <ProductItem {...props} isFlipped={isFlipped} />
//         </div>
//       </Hidden>
//     </div>
//   );
// };
// export default withRouter(FlippingCard);
