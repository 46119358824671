import React from "react";
import { Menu, Button, Icon } from "@material-ui/core";

import PopupLogin from "../popup/PopupLogin";
import PopupCart from "../popup/PopupCart";
import PopupFavourites from "../popup/PopupFavourites";
import PopUpMessage from "../popup/PopUpMessage";

import { connect } from "react-redux";
import { Grid, Hidden, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  iconButton: {},
  icon: {
    color: "white",
    fontSize: "1.2em",
    // padding:theme.spacing(2)
  },
}));
const dataToolBtnsRight = [
  {
    title: "sign-in",
    icon: "user",
  },
  {
    title: "favorite",
    icon: "heart",
  },
  {
    title: "message",
    icon: "mail",
  },
  {
    title: "cart",
    icon: "shop",
  },
];

// Tool Button 工具栏按钮
// const ToolBtn = (index, feature, icon) => (
//   <Button className="tool-btn" animated="fade" floated="right" tabIndex={index}>
//     <Button.Content as="span" hidden>
//       {feature}
//     </Button.Content>
//     <Button.Content as="span" visible>
//       <Icon name={icon} />
//     </Button.Content>
//   </Button>
// );

const ToolBar = (props) => {
  let itemsToolRight = dataToolBtnsRight.map(function (item, index) {
    let title = item.title
      .replace(/\-/g, " ")
      .replace(/^[a-z]?/, function ($0) {
        return $0.toUpperCase();
      });

    let popupWrap = null;

    // let btnTool = ToolBtn(index, title, item.icon);
    // console.log("Toolbar item.title : ", item.title);
    // console.log("Toolbar title : ", title);

    // console.log("Toolbar btnTool : ", btnTool);

    switch (item.title) {
      case "sign-in":
        popupWrap = <PopupLogin />;
        break;
      case "favorite":
        popupWrap = <PopupFavourites favourites={props.favourites} />;
        break;
      case "message":
        popupWrap = <PopUpMessage messages={props.messages} />;
        break;
      case "cart":
        popupWrap = <PopupCart cart={props.cart} />;
        break;
    }
    return (
      <Grid item key={index} as="li" lg={3} md={3} xs={3}>
        {popupWrap}
      </Grid>
    );
  });
  const classes = useStyles();

  return (
    <div>
      <Grid container alignItems="center">
        {itemsToolRight}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    messages: state.user.messages,
    cart: state.shop.cart,
    favourites: state.shop.favourites,
  };
};

export default connect(mapStateToProps, null)(ToolBar);
