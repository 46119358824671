import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TableRow, TableCell, IconButton, Hidden } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";

const useStyles = makeStyles((theme) => ({
  TableCell: {
    fontSize: "1rem",
  },
}));
const SingleCardRow = ({ data, index, deleteItem }) => {
  const { cardType, cardNumber, cvv, expiryDate } = data;

  const classes = useStyles();

  return (
    <TableRow key={index}>
      <TableCell>{cardType}</TableCell>

      <Hidden>
        <TableCell>{cardNumber}</TableCell>
      </Hidden>
      <TableCell className={classes.TableCell}>{cvv}</TableCell>
      <TableCell className={classes.TableCell}>{expiryDate}</TableCell>
      <TableCell className={classes.TableCell}>
        <IconButton color="secondary" onClick={() => deleteItem(index)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
export default SingleCardRow;
