import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  Divider,
  CardContent,
  Typography,
  TextField,
  Grid,
  IconButton,
  Button,
  CardHeader,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import ModalComponent from "../../../Modals/ModalComponent";
import moment from "moment";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "auto",
  },
  CloseIcon: {
    color: "white",
  },
  card: {
    width: "60%",
    maxHeight: "98vh",
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
  rateContainer: {
    display: "flex",
    padding: "1vh 0vh",
    alignItems: "center",
  },
  input: {
    margin: "2vh 0vh",
  },
  btnCOntainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const ReturnReasons = [
  { key: 1, value: "RES1", text: "Incorrect Product or Size Ordered " },
  { key: 2, value: "RES2", text: "Product No Longer Needed" },
  { key: 3, value: "RES3", text: "Product Does Not Match Description" },
  { key: 4, value: "RES4", text: "Product Did Not Meet Expectations" },
  { key: 5, value: "RES5", text: "Company Shipped Wrong Product or Size" },
  { key: 6, value: "RES6", text: "Customer Unfamiliar with Retail Interface" },
  { key: 7, value: "RES7", text: "Customer Unfamiliar with Product " },
];
const ReturnModal = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    reason: "",
    details: "",
  });

  const [hover, setHover] = React.useState(-1);
  const [error, setError] = React.useState();

  const handleChange = (e) => {
    setError();
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleReturnSave = (e) => {
    state.reason === "" ? setError("reason") : props.save(state);
    setState({
      reason: "",
      details: "",
    });
  };

  const returnreason = ReturnReasons.map((item, index) => {
    return (
      <MenuItem key={item.key} value={item.value}>
        {item.text}
      </MenuItem>
    );
  });

  return (
    <ModalComponent className={classes.root} open={props.open}>
      <div className={classes.card}>
        <Grid container justify="flex-end">
          <IconButton size={30}>
            <CloseIcon className={classes.CloseIcon} />
          </IconButton>
        </Grid>
        <Card>
          <CardHeader title={`Return Product`} />
          <Divider />

          <CardContent>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              fullWidth
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Reason
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={state.reason}
                onChange={handleChange}
                label="Reason"
                name="reason"
              >
                {returnreason}
              </Select>
            </FormControl>
          </CardContent>

          <CardContent>
            <TextField
              className={classes.input}
              size="small"
              fullWidth
              variant="outlined"
              label="Details"
              multiline
              rows={10}
              placeholder="Details"
              value={state.details}
              name="details"
              onChange={(e) => handleChange(e)}
            />
          </CardContent>
          <Divider />
          <CardContent>
            <div className={classes.btnCOntainer}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => props.close()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => handleReturnSave(e)}
              >
                Save
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </ModalComponent>
  );
};
export default ReturnModal;
