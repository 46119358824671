const calculatePricefromPribands = (pricebands, quantity) => {
  let actualValue = 0.0;
  let bandvalue = pricebands.find(
    (item) =>
      quantity >= item.minimumQuantity && quantity <= item.maximumQuantity
  );

  if (bandvalue) {
    const listPrice = bandvalue.listPrice;
    const discount = bandvalue.discount;
    actualValue = parseFloat(
      (listPrice - (listPrice * discount) / 100).toFixed(2)
    );

    // console.log("calculated", actualValue, listPrice, discount);
  } else if (quantity > pricebands[pricebands.length - 1].maximumQuantity) {
    const newListPrice = pricebands[pricebands.length - 1].listPrice;
    const discount = pricebands[pricebands.length - 1].discount;
    actualValue = parseFloat(
      (newListPrice - (newListPrice * discount) / 100).toFixed(2)
    );
  }

  return actualValue;
};
export default calculatePricefromPribands;
