import React from "react";
import Typography from "@material-ui/core/Typography";

import { Link, withRouter } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Menu from "material-ui-popup-state/HoverMenu";
import {
  usePopupState,
  bindHover,
  bindMenu,
} from "material-ui-popup-state/hooks";

import IconButton from "@material-ui/core/IconButton";
import UserIcon from "@material-ui/icons/AccountCircleOutlined";
import LogoutIcon from "@material-ui/icons/ExitToAppOutlined";
import {
  Popper,
  Fade,
  Paper,
  Hidden,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  MenuItem,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { toggleLoginModal } from "../../redux/action/utilActions";
const useStyles = makeStyles((theme) => ({
  typography: {
    // padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  paper: {
    border: "1px solid gray",
    borderRadius: 3,
    padding: theme.spacing(1),

    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  userIcon: {
    marginRight: 10,
  },
  Popper: {
    zIndex: 100001,
  },
  Paper: {
    // padding: theme.spacing(1),
  },
  btn: {
    cursor: "pointer",
  },
  // username: {
  //   marginRight: theme.spacing(2),
  // },
}));
const Icon = withStyles((theme) => ({
  root: {
    color: "#ffffff",

    "&:hover": {},
  },
  popper: {
    border: "1px solid red",
  },
}))(IconButton);
const ColorButton = withStyles((theme) => ({
  root: {
    color: "#ffffff",
    fontWeight: 700,

    "&:hover": {},
  },
  popper: {
    border: "1px solid red",
  },
}))(Button);

const HoverPopoverPopupState = (props) => {
  const { classes } = props;
  const dashes = useStyles();
  const dispathc = useDispatch();
  const isLogged = useSelector((state) => state.user.isLogged);
  const firstName = useSelector((state) => state.user.firstName);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event, type) => {
    setAnchorEl(event.currentTarget);

    // dispatch(selectType(type, index));
  };
  const handleLogin = () => {
    dispathc(toggleLoginModal("login"));
  };

  const handleAccount = () => {
    // console.log(props, "handleacc");
    props.history.push("/useraccount/");
  };
  const handleLogout = () => {
    props.history.push("/useraccount/logout");
  };

  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });

  const DeskTopMenu = (
    <div>
      {!isLogged ? (
        <ColorButton size="medium" onClick={() => handleLogin()}>
          Login
        </ColorButton>
      ) : (
        <div>
          <ColorButton
            size="medium"
            variant="text"
            // className={dashes.username}
            {...bindHover(popupState)}
            onClick={() => {
              bindHover(popupState);
            }}
          >
            {firstName}
          </ColorButton>
          {/* <Icon
        variant="text"
       
        {...bindHover(popupState)}
      >
        <UserIcon/>
     
      </Icon> */}
          <Menu
            {...bindMenu(popupState)}
            getContentAnchorEl={null}
            transitionDuration={0}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            disableScrollLock
          >
            <MenuItem button onClick={() => handleAccount()}>
              <ListItemIcon>
                <UserIcon />
              </ListItemIcon>
              <ListItemText primary="My Account" />
            </MenuItem>
            <MenuItem button onClick={() => handleLogout()}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );

  const mobileMenu = !isLogged ? (
    <ColorButton size="medium" onClick={() => handleLogin()}>
      <UserIcon />
    </ColorButton>
  ) : (
    <div>
      <ColorButton
        size="medium"
        variant="text"
        onClick={(e) => handleAccount()}
      >
        <UserIcon />
      </ColorButton>
      {/* <Icon
    variant="text"
   
    {...bindHover(popupState)}
  >
    <UserIcon/>
 
  </Icon> */}
    </div>
  );

  return (
    <div>
      <Hidden mdDown>{DeskTopMenu}</Hidden>
      <Hidden smUp>{mobileMenu}</Hidden>
    </div>
  );
};

export default withRouter(HoverPopoverPopupState);

// import React from "react";
// import Typography from "@material-ui/core/Typography";

// import { Link, withRouter } from "react-router-dom";
// import { makeStyles, withStyles } from "@material-ui/core/styles";

// import { bindHover } from "material-ui-popup-state/hooks";
// import PopupState, { bindPopper } from "material-ui-popup-state";

// import IconButton from "@material-ui/core/IconButton";
// import UserIcon from "@material-ui/icons/AccountCircleOutlined";
// import { Popper, Fade, Paper, Hidden } from "@material-ui/core";
// import { useSelector, useDispatch } from "react-redux";
// import { toggleLoginModal } from "../../redux/action/utilActions";
// const useStyles = makeStyles((theme) => ({
//   typography: {
//     padding: theme.spacing(2),
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "space-evenly",
//   },
//   paper: {
//     border: "1px solid gray",
//     borderRadius: 3,
//     padding: theme.spacing(1),

//     backgroundColor: theme.palette.background.paper,
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "space-evenly",
//   },
//   userIcon: {
//     marginRight: 10,
//   },
//   Popper: {
//     zIndex: 100001,
//   },
//   Paper: {
//     padding: theme.spacing(1),
//   },
//   btn: {
//     cursor: "pointer",
//   },
// }));
// const ColorButton = withStyles((theme) => ({
//   root: {
//     color: "#ffffff",

//     "&:hover": {},
//   },
//   popper: {
//     border: "1px solid red",
//   },
// }))(IconButton);

// const HoverPopoverPopupState = (props) => {
//   const { classes } = props;
//   const dashes = useStyles();
//   const dispathc = useDispatch();
//   const isLogged = useSelector((state) => state.user.isLogged);

// const handleLogin = () => {
//   dispathc(toggleLoginModal("login"));
// };

//   const handleAccount = () => {
//     // console.log(props, "handleacc");
//     props.history.push("/useraccount/profile");
//   };
//   return (
//     <div>
//       <Hidden smUp>
//         <ColorButton
//           onClick={() => (isLogged ? handleAccount() : handleLogin())}
//         >
//           <UserIcon />
//         </ColorButton>
//       </Hidden>
//       <Hidden mdDown>
// <PopupState variant="popper" popupId="demo-popup-popper">
//   {(popupState) => (
//     <div>
//       {/* <Link to="/login"> */}
//       <ColorButton {...bindHover(popupState)}>
//         <UserIcon />
//       </ColorButton>
//       {/* </Link> */}
//       <Popper
//         className={dashes.Popper}
//         {...bindPopper(popupState)}
//         transition
//         disablePortal={false}
//         modifiers={{
//           flip: {
//             enabled: true,
//           },
//           preventOverflow: {
//             enabled: true,
//             boundariesElement: "scrollParent",
//           },
//         }}
//       >
//         {({ TransitionProps }) => (
//           <Fade {...TransitionProps} timeout={350}>
//             <Paper
//               className={dashes.Paper}
//               onMouseLeave={(e) => popupState.onMouseLeave(e)}
//             >
//               {isLogged ? (
//                 <div onClick={() => handleAccount()}>
//                   <Typography className={dashes.btn}>
//                     Click here to access your account
//                   </Typography>
//                 </div>
//               ) : (
//                 <div onClick={() => handleLogin()}>
//                   <Typography className={dashes.btn}>
//                     Click here to login
//                   </Typography>
//                 </div>
//               )}
//               {/* <Typography>
//               <Link to="/login">Click here to sign in.</Link>
//             </Typography> */}
//             </Paper>
//           </Fade>
//         )}
//       </Popper>
//     </div>
//   )}
// </PopupState>
//       </Hidden>
//     </div>
//   );
// };

// export default withRouter(HoverPopoverPopupState);
