import { userTypes, shopTypes } from "../types";
import setExpireTime from "../../utils/setExpireTime";
import JWTDecode from "jwt-decode";
const initialState = {
  isLogged: false,

  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  username: "Guest",
  userDiscount: 0,
  distributorId: null,
  territoryUnitId: null,

  addresses: [],
  savedcards: [],
  orders: [],
  shippingMethods: [],
  messages: [],
  trackingDetails: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    //login
    case userTypes.LOGIN_USER:
      return {
        ...state,
      };
    case userTypes.LOCAL_LOGIN_USER:
      return {
        ...state,
        isLogged: true,
      };
    case userTypes.LOGIN_USER_SUCCESS:
      const token = action.payload.data.accessToken;
      localStorage.setItem("tkn", token);
      setExpireTime(action.payload.data.expiryDuration);
      const jwt_login = JWTDecode(token);
      return {
        ...state,
        isLogged: true,
        userDiscount: jwt_login.userDiscount,
        username: jwt_login.sub,
        distributorId: jwt_login.distributorId ? jwt_login.distributorId : null,
        territoryUnitId: jwt_login.territoryUnitId
          ? jwt_login.territoryUnitId
          : null,
      };
    // case userTypes.LOGIN_USER_SUCCESS:
    //   const token = action.payload.data.accessToken;

    //   localStorage.setItem("tkn", token);
    //   setExpireTime(action.payload.data.expiryDuration);

    //   return {
    //     ...state,
    //     isLogged: true,
    //   };

    case userTypes.LOGIN_USER_FAIL:
      return {
        ...state,
        isLogged: false,
      };

    //logout
    case userTypes.LOGOUT_USER:
      localStorage.removeItem("tkn");
      return {
        ...initialState,
      };

    //user account

    case userTypes.GET_USER_ACCOUNT:
      return {
        ...state,
      };

    case userTypes.GET_USER_ACCOUNT_SUCCESS:
      // console.log("user acc get success", action.payload);
      return {
        ...state,

        ...action.payload.data,
        // previousOrdersdata: action.payload.data.orders,
      };
    case userTypes.GET_USER_ACCOUNT_FAIL:
      return {
        ...state,
      };
    //save cards - - basicinfo
    case userTypes.SAVE_BASIC_INFO:
      return {
        ...state,
        ...action.payload,
      };

    //save address
    case userTypes.SAVE_USER_ADDRESS:
      console.log("save address", action.payload);
      return {
        ...state,
      };
    case userTypes.SAVE_USER_ADDRESS_SUCCESS:
      console.log("save address success", action.payload);
      let mynewaddresses = state.addresses.concat(action.payload.data);
      return {
        ...state,

        addresses: mynewaddresses,
      };
    case userTypes.SAVE_USER_ADDRESS_FAIL:
      console.log("save address fail", action.payload);
      return {
        ...state,
      };

    //edit address
    case userTypes.EDIT_USER_ADDRESS:
      return {
        ...state,
      };

    case userTypes.EDIT_USER_ADDRESS_SUCCESS:
      const newEditArray = state.addresses;
      let index = newEditArray.findIndex(
        (item) => item.id === action.payload.data.id
      );
      newEditArray[index] = action.payload.data;
      return {
        ...state,
        addresses: newEditArray,
      };
    case userTypes.EDIT_USER_ADDRESS_FAIL:
      return {
        ...state,
      };

    //Delete address
    case userTypes.DELETE_USER_ADDRESS:
      return {
        ...state,
      };

    case userTypes.DELETE_USER_ADDRESS_SUCCESS:
      return {
        ...state,
      };
    case userTypes.DELETE_USER_ADDRESS_FAIL:
      return {
        ...state,
      };

    case userTypes.SAVE_USER_CARD:
      return {
        ...state,
      };

    case userTypes.SAVE_USER_CARD_SUCCESS:
      return {
        ...state,

        savedcards: state.savedcards.concat(action.payload.data),
      };

    case userTypes.SAVE_USER_CARD_FAIL:
      return {
        ...state,
      };
    case shopTypes.ADD_COMMENT_TO_PRODUCT_SUCCESS:
      return {
        ...state,
      };

    case userTypes.GET_TRACKING_DETAILS_SUCCESS:
      return {
        ...state,
      };

    case userTypes.REMOVE_TRACKING_DETAILS:
      return {
        ...state,
        trackingDetails: [],
      };
    default:
      return state;
  }
};
export default userReducer;
