import React, { useState } from "react";
import { Slider } from "react-semantic-ui-range";

import { makeStyles, Typography, Button } from "@material-ui/core";
import NormalList from "./CheckBoxList/NormalList";
import ComplexList from "./CheckBoxList/ComplexList";
// https://www.codementor.io/blizzerand/building-forms-using-react-everything-you-need-to-know-iz3eyoq4y
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",

    backgroundColor: theme.palette.background.paper,
  },
  complexListText: {
    color: theme.palette.primary,
  },
}));
const App = (props) => {
  const classes = useStyles();

  const { input, meta, mode, initvalue, ...others } = props;
  //const labels = ['one','two','three','four','five'];
  const labels = others.options.map((ele) => ele.text);
  const [multipleValues, setMultipleValues] = useState([]);
  const [checked, setChecked] = React.useState([0]);
  // input.onChange(multipleValues);
  // input.onChange(multipleValues);
  // const handleCheckBox = (e, value) => {
  //   input.onChange(checked);
  //   let newSelectionArray = multipleValues;
  //   let checked = e.target.checked;
  //   console.log("chekded", e.target.checked, value);
  //   if (checked) {
  //     if (!newSelectionArray.includes(value)) {
  //       newSelectionArray.push(value);
  //       console.log("if statement", newSelectionArray);
  //     }
  //   } else {
  //     newSelectionArray = newSelectionArray.filter(e => e !== value);
  //   }
  //   setMultipleValues(newSelectionArray);
  //   console.log(multipleValues, "multiplevalue");
  // };
  input.onChange(checked);
  const handleToggle = (value) => () => {
    input.onChange(checked);
    const currentIndex = checked.indexOf(value);
    let newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked = newChecked.filter((e) => e !== value);
    }

    setChecked(newChecked);
  };

  let complexLabels = [];
  if (labels.length > 5) {
    for (let i = 0; i <= 4; i++) {
      complexLabels.push(labels[i]);
    }
  }

  return (
    <div>
      {labels.length < 5 ? (
        <NormalList
          input={input}
          labels={labels}
          checked={checked}
          handleToggle={handleToggle}
        />
      ) : (
        <ComplexList
          complexLabels={complexLabels}
          input={input}
          labels={labels}
          checked={checked}
          handleToggle={handleToggle}
        />
      )}
    </div>
  );
};

export default App;
