import React from "react";
import Typography from "@material-ui/core/Typography";

import { Link, withRouter } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { bindHover } from "material-ui-popup-state/hooks";
import PopupState, { bindPopper } from "material-ui-popup-state";

import IconButton from "@material-ui/core/IconButton";
import FavourtieIcon from "@material-ui/icons/FavoriteBorderRounded";
import FavouriteItem from "../favourites/FavouriteItem";
import {
  CardContent,
  Card,
  Popper,
  Fade,
  Paper,
  Button,
  Hidden,
  Badge,
} from "@material-ui/core";
import { toggleLoginModal } from "../../redux/action/utilActions";
import { useDispatch, useSelector } from "react-redux";
import StyledBadge from "./StyledBadge";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },

  userIcon: {
    marginRight: 10,
  },
  Popper: {
    zIndex: 100001,
  },
  empty: {
    padding: theme.spacing(1),
  },
  Link: {
    color: "black",
  },
  checkoutbtncontainer: {
    margin: 10,
  },
  CardContent: {
    marginTop: -theme.spacing(1),
    paddingBottom: theme.spacing(2),
    minWidth: "20vw",
    maxHeight: "40vh",
    overflow: "auto",
  },
  loginBtn: {
    textTransform: "none",
    color: "black",
  },
}));
const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,

    "&:hover": {},
  },
  popper: {
    border: "1px solid red",
  },
}))(IconButton);

const HoverPopoverPopupState = (props) => {
  const dashes = useStyles();
  const dispatch = useDispatch();

  const logged = useSelector((state) => state.user.isLogged);

  const handleLogin = () => {
    dispatch(toggleLoginModal("login"));
  };
  let { favourites } = props;

  const handleFavouriteClick = () => {
    if (logged) {
      props.history.push("/favourites");
    } else {
      dispatch(toggleLoginModal("login"));
    }
  };

  const itemsOrder = favourites.map(function (item, index) {
    return <FavouriteItem {...item} key={index} />;
  });

  const conHasPro = (
    <Card>
      <CardContent>
        <Link className={dashes.Link} to="/favourites">
          <Typography align="center" variant="h6" color="textPrimary">
            My Favourites
          </Typography>
        </Link>
        <div className={dashes.checkoutbtncontainer}>
          <Link className="checkout-btn" to="/favourites">
            <Button variant="contained" color="primary" fullWidth>
              Manage Favourites
            </Button>
          </Link>
        </div>
      </CardContent>
      <CardContent className={dashes.CardContent}>{itemsOrder}</CardContent>
    </Card>
  );

  const conNoPro = (
    <Paper>
      <Typography className={dashes.empty}>
        Your Favourite List bag is empty!
      </Typography>
    </Paper>
  );
  const showLoginLink = (
    <Paper className={dashes.noItem}>
      <Button
        variant="text"
        onClick={() => handleLogin()}
        className={dashes.loginBtn}
      >
        Login to access your Favourites
      </Button>
    </Paper>
  );

  const displayPopupfavourites = !favourites.length ? conNoPro : conHasPro;

  return (
    <div>
      <Hidden smUp>
        <ColorButton>
          <StyledBadge
            color="secondary"
            badgeContent={favourites.length}
            showZero
            onClick={() => handleFavouriteClick()}
          >
            <FavourtieIcon />
          </StyledBadge>
        </ColorButton>
      </Hidden>
      <Hidden mdDown>
        <PopupState variant="popper" popupId="demo-popup-popper">
          {(popupState) => (
            <div>
              <ColorButton {...bindHover(popupState)}>
                <StyledBadge
                  color="secondary"
                  badgeContent={favourites.length}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  showZero
                  onClick={() => handleFavouriteClick()}
                >
                  <FavourtieIcon />
                </StyledBadge>
              </ColorButton>

              <Popper
                className={dashes.Popper}
                {...bindPopper(popupState)}
                transition
                disablePortal={false}
                modifiers={{
                  flip: {
                    enabled: true,
                  },
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: "scrollParent",
                  },
                }}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <div onMouseLeave={(e) => popupState.onMouseLeave(e)}>
                      {logged ? displayPopupfavourites : showLoginLink}
                    </div>
                  </Fade>
                )}
              </Popper>
            </div>
          )}
        </PopupState>
      </Hidden>
    </div>
  );
};
export default withRouter(HoverPopoverPopupState);
