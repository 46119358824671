import React from "react";

import { connect, useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography, Divider } from "@material-ui/core";
import MessageItem from "./MessageItem";
import { toggleLoginModal } from "../../redux/action/utilActions";
import { useParams, useLocation } from "react-router-dom";
import { pushtohistory } from "../../redux/action/historyActions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    padding: "5vh 0vh",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  Container: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    minHeight: "90vh",
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  titleContainer: {
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
  },
  title: {
    color: theme.palette.text.primary,
  },
}));

const MessagesSite = (props) => {
  const classes = useStyles();

  const messages = useSelector((state) => state.user.messages);

  let valueTotal = 0;

  const itemsOrder = messages.map(function (item, index) {
    return <MessageItem key={item.id} item={item} />;
  });
  //stub

  const { stub } = useParams();
  const location = useLocation().pathname;
  const dispatch = useDispatch();

  React.useEffect(() => {
    let newlocation = location === "/" ? location : location.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));
  }, []);

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.Container}>
        <Grid container spacing={2}>
          <Grid
            item
            container
            lg={12}
            sm={12}
            md={12}
            xs={12}
            className={classes.titleContainer}
            justify="center"
            alignItems="center"
          >
            <Typography variant="h5" className={classes.title}>
              My Messages
            </Typography>
          </Grid>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <Divider />
          </Grid>
          <Grid item container spacing={2} lg={12} sm={12} md={12} xs={12}>
            {itemsOrder}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    favourites: state.shop.favourites,
  };
};

export default connect(mapStateToProps, null)(MessagesSite);
