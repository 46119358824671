const { default: Axios } = require("axios");
const { ROOT_URL } = require("../redux/utils/utils");

const getVisitorToken = (data) => {
  console.log("get visitor token");
  let preparedata = {
    countryCode: data.country_code,
    countryName: data.country_name,
    city: data.city,
    postal: data.postal,
    ip: data.IPv4,
    state: data.state,
    latitude: data.latitude,
    longitude: data.longitude,
    domain: "ecom",
  };
  return Axios.post(`${ROOT_URL}MA/WebsiteVisitor/log`, preparedata);
};
export default getVisitorToken;
