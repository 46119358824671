import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography, Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddNewCreditCard from "./AddNewCreditCard";
import SingleCardRow from "./SingleCardRow";
import { useSelector, useDispatch } from "react-redux";
import {
  addUserCard,
  deleteUserCard,
} from "../../../../redux/action/useractions";
import { useParams, useLocation } from "react-router-dom";
import { pushtohistory } from "../../../../redux/action/historyActions";
const useStyles = makeStyles((theme) => ({
  gridContainer: {
    padding: "3vh",
  },
  gridItem: {
    marginBottom: theme.spacing(3),
  },
  titleContainer: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(3),
    },
  },
  lastNameContainer: {
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginTop: theme.spacing(3),
    },
  },
  title: {
    fontSize: "1.4rem",
    fontWeight: 500,
  },
  table: {
    width: "100%",
    overflow: "none",
  },
  TableCell: {
    fontSize: "1rem",
  },
}));

const SavedCards = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  //add new card state
  const [addNew, setAddNew] = React.useState(false);

  //delete a card

  // const DeleteCard = (index) => {
  //   let newArray = cardsArray.filter((item, key) => key !== index);
  //   handleCardsChange(newArray);
  // };

  const [cardsArray, setCardsArray] = useState([]);

  //effect
  const Cards = useSelector((state) => state.user.savedcards);
  // const token = localStorage.getItem("tkn");
  // React.useEffect(() => {
  //   dispatch(getUserCards(token));
  // }, []);
  React.useEffect(() => {
    setCardsArray(Cards);
  }, [Cards]);
  //save function
  const handleCardsChange = (e) => {
    // setCardsArray(e);
    // dispatch(saveSavedCards(e));
  };

  const handleCardAdd = (data) => {
    dispatch(addUserCard(data));
  };

  const handleCardDelete = (id, data) => {
    dispatch(deleteUserCard(id, data));
  };

  //stub

  const { stub } = useParams();
  const location = useLocation().pathname;

  React.useEffect(() => {
    let newlocation = location === "/" ? location : location.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));
  }, []);
  return (
    <Paper elevation={1}>
      <Grid container className={classes.gridContainer}>
        <Grid item container lg={12} xs={12} md={12} sm={12}>
          {/* first item */}

          <Grid
            item
            container
            lg={12}
            xs={12}
            md={12}
            sm={12}
            className={classes.gridItem}
          >
            <Grid
              item
              container
              alignItems="center"
              justify="space-between"
              lg={12}
              className={classes.titleContainer}
            >
              <Grid item>
                <Typography className={classes.title}>Saved Cards</Typography>
              </Grid>
              <Grid item>
                {!addNew && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="medium"
                    onClick={() => setAddNew(true)}
                  >
                    Add New Card
                  </Button>
                )}
              </Grid>
            </Grid>
            {addNew && (
              <AddNewCreditCard
                array={cardsArray}
                add={(e) => handleCardAdd(e)}
                close={(e) => setAddNew(false)}
              />
            )}
            <Grid item lg={12} sm={12} xs={12} md={12}>
              {/* //cards list table */}
              {cardsArray.length > 0 ? (
                <TableContainer className={classes.table}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.TableCell}>
                          Card Type
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          Card Number
                        </TableCell>
                        <TableCell className={classes.TableCell}>cvv</TableCell>
                        <TableCell className={classes.TableCell}>
                          Expiry Date
                        </TableCell>
                        <TableCell className={classes.TableCell}>
                          Delete
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cardsArray.map((data, index) => {
                        return (
                          <SingleCardRow
                            key={index}
                            data={data}
                            index={index}
                            deleteItem={(e) => handleCardDelete(data.id, data)}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null}
            </Grid>
            {/* //single card */}

            {/* //single card */}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default SavedCards;
