import React from "react";
import Typography from "@material-ui/core/Typography";
import Popover from "material-ui-popup-state/HoverPopover";
import Button from "@material-ui/core/Button";
import { Link, withRouter } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  usePopupState,
  bindHover,
  bindPopover,
} from "material-ui-popup-state/hooks";
import PopupState, { bindPopper } from "material-ui-popup-state";
import OrderItem from "../cart/OrderItem";
import OrderBill from "../cart/OrderBill";
import PT from "prop-types";

import IconButton from "@material-ui/core/IconButton";
import CartIcon from "@material-ui/icons/ShoppingCartOutlined";
import LoadingComponent from "../Spinners/LoadingComponent";

import {
  CardContent,
  CardHeader,
  Card,
  Paper,
  Popper,
  Fade,
  Divider,
  Hidden,
  Badge,
  Box,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import FavouriteItem from "../favourites/FavouriteItem";
import { toggleLoginModal } from "../../redux/action/utilActions";
import CalculateTotal from "../../utils/calculateTotal";
import StyledBadge from "./StyledBadge";

const propTypes = {
  btnTool: PT.element,
};
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },

  userIcon: {
    marginRight: 10,
  },
  checkoutbtncontainer: {
    marginTop: 30,
  },
  Popper: {
    zIndex: 100000,
  },
  subheader: {
    marginBottom: -theme.spacing(3),
  },
  Divider: {
    marginBottom: theme.spacing(3),
  },
  noItem: {
    padding: theme.spacing(1),
  },
  CardContent: {
    // marginTop: -theme.spacing(3),
    minWidth: "20vw",
    maxHeight: "50vh",
    overflow: "auto",
  },
  loading: {
    minHeight: "20vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  Link: {
    color: "black",
  },
  loginBtn: {
    textTransform: "none",
    color: "black",
  },
  empty: {
    padding: theme.spacing(1),
  },
  totalItemcontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));
const ColorButton = withStyles((theme) => ({
  root: {
    color: "#ffffff",

    "&:hover": {},
  },
  popper: {
    border: "1px solid red",
  },
}))(IconButton);

const HoverPopoverPopupState = (props) => {
  const dashes = useStyles();
  const dispatch = useDispatch();

  let { cart } = props;

  const logged = useSelector((state) => state.user.isLogged);

  const handleLogin = () => {
    dispatch(toggleLoginModal("login"));
  };

  const handleCartPage = () => {
    // console.log(props, "from popup cart");
    if (logged) {
      props.history.push("/cart");
    } else {
      dispatch(toggleLoginModal("login"));
    }
  };
  let valueTotal = CalculateTotal(cart);

  const conHasPro = (
    <Card>
      <CardContent>
        <Link className={dashes.Link} to="/cart">
          <Typography
            align="center"
            variant="h6"
            className={dashes.subheader}
            color="textPrimary"
          >
            My Cart
          </Typography>
          <div className={dashes.checkoutbtncontainer}>
            <Link className="checkout-btn" to="/cart">
              <Button variant="contained" color="primary" fullWidth>
                Checkout
              </Button>
            </Link>
          </div>
        </Link>
      </CardContent>
      <CardContent>
        <Box className={dashes.totalItemcontainer}>
          <Typography>Total Net Amount :</Typography>
          <Typography>₹ {valueTotal.totalNetAmount.toFixed(2)} </Typography>
        </Box>
        <Box className={dashes.totalItemcontainer}>
          <Typography>Total Tax :</Typography>
          <Typography>₹ {valueTotal.totalTaxAmount.toFixed(2)} </Typography>
        </Box>
        <Box className={dashes.totalItemcontainer}>
          <Typography>Shipping Charges</Typography>
          <Typography> Extra</Typography>
        </Box>
        <Box className={dashes.totalItemcontainer}>
          <Typography>Grand Total :</Typography>
          <Typography>
            ₹{" "}
            {(valueTotal.totalNetAmount + valueTotal.totalTaxAmount).toFixed(2)}{" "}
          </Typography>
        </Box>
        {/* <OrderBill orderValue={valueTotal} /> */}
      </CardContent>
      <Divider className={dashes.Divider} />
      <CardContent className={dashes.CardContent}>
        {cart.map((item, index) => {
          return <FavouriteItem {...item} key={index} />;
        })}
      </CardContent>
    </Card>
    //
  );

  const conNoPro = (
    <Paper>
      <Typography className={dashes.empty}>
        Your Shopping cart is empty!
      </Typography>
    </Paper>
  );

  const displayPopupfavourites = !cart.length ? conNoPro : conHasPro;

  const showLoginLink = (
    <Paper>
      <Button
        variant="text"
        onClick={() => handleLogin()}
        className={dashes.loginBtn}
      >
        Login to access your Cart
      </Button>
    </Paper>
  );
  return (
    <div>
      <Hidden smUp>
        <ColorButton>
          <StyledBadge
            color="secondary"
            badgeContent={cart.length}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            showZero
            onClick={() => handleCartPage()}
          >
            <CartIcon />
          </StyledBadge>
        </ColorButton>
      </Hidden>
      <Hidden mdDown>
        <PopupState variant="popper" popupId="demo-popup-popper">
          {(popupState) => (
            <div>
              <ColorButton {...bindHover(popupState)}>
                <StyledBadge
                  color="secondary"
                  badgeContent={cart.length}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  showZero
                  onClick={() => handleCartPage()}
                >
                  <CartIcon />
                </StyledBadge>
              </ColorButton>

              <Popper
                className={dashes.Popper}
                {...bindPopper(popupState)}
                transition
                disablePortal={false}
                modifiers={{
                  flip: {
                    enabled: true,
                  },
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: "scrollParent",
                  },
                }}
              >
                <div onMouseLeave={(e) => popupState.onMouseLeave(e)}>
                  {logged ? displayPopupfavourites : showLoginLink}
                </div>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <div onMouseLeave={(e) => popupState.onMouseLeave(e)}>
                      {logged ? displayPopupfavourites : showLoginLink}
                    </div>
                  </Fade>
                )}
              </Popper>
            </div>
          )}
        </PopupState>
      </Hidden>
    </div>
  );
};
export default withRouter(HoverPopoverPopupState);
