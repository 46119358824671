import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography, Button } from "@material-ui/core";
import PT from "prop-types";
import { withRouter } from "react-router-dom";

const propTypes = {
  id: PT.number,
  name: PT.string,
  marketPrice: PT.number,
  salePrice: PT.number,
  srcImg: PT.string,
  selectedOptions: PT.object,
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  mini: {
    objectFit: "contain",

    height: 80,
  },
  innerContainer: {
    height: "100%",
  },
  marketPrice: {
    textDecoration: "line-through",
  },
}));
const FavouriteItem = (props) => {
  const {
    name,
    type,
    category,
    subcategory,
    id,
    itemIndex,
    salePrice,
    marketPrice,
    srcImg,
    selectedOptions,
    browsingName,
  } = props;
  const classes = useStyles();

  console.log(props, "orderItem");
  const handleNavigate = () => {
    props.history.push(`/product/${browsingName}/${id}`);
  };
  return (
    <div className={classes.root}>
      <Paper
        variant="outlined"
        className={classes.container}
        component={Button}
        onClick={() => handleNavigate()}
        fullWidth
      >
        <Grid container justify="space-evenly" className={classes.container}>
          <Grid item lg={3}>
            <img src={srcImg} className={classes.mini} />
          </Grid>
          <Grid item lg={8}>
            <Grid
              container
              className={classes.innerContainer}
              alignItems="center"
              justify="space-evenly"
            >
              <Grid item>
                <Typography variant="h6">${salePrice}</Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  className={classes.marketPrice}
                >
                  ${marketPrice}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

FavouriteItem.propTypes = propTypes;

export default withRouter(FavouriteItem);
