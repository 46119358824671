import React from "react";
import PT from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import PlusIcon from "@material-ui/icons/AddCircle";
import MinusIcon from "@material-ui/icons/RemoveCircle";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  IconButton,
  CardHeader,
  Hidden,
  Divider,
  CardActionArea,
  Button,
  Box,
} from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import FavouriteFilled from "@material-ui/icons/Favorite";
import FavouriteOutlined from "@material-ui/icons/FavoriteBorderOutlined";
import { useDispatch } from "react-redux";
import {
  addProductToFavourites,
  removeProductFromFavourites,
  incrementCartQuantity,
  decrementCartQuantity,
} from "../../redux/selector";
import Flippy, { FrontSide, BackSide } from "react-flippy";
import CartIcon from "@material-ui/icons/ShoppingCartOutlined";
// import ReactCardFlip from "react-card-flip";
import useHover from "@react-hook/hover";
import scrollToTop from "../../utils/scollToTop";
import ProductItem from "./ProductItem";

const useStyles = makeStyles((theme) => ({
  productContainer: {
    padding: 0,
    // height: "50vh",
    // margin: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  },

  favouritebutton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
  },
  flippedCard: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    backgroundColor: theme.palette.action.disabledBackground,
  },
  flippedDescription: {
    flex: 1,
  },
  backSide: {
    boxShadow: "none",
    padding: 0,
  },
}));
const FlippingCard = (props) => {
  const cardRef = React.useRef();
  const isFlipped = useHover(cardRef, { enterDelay: 2000, leaveDelay: 0 });

  const classes = useStyles();
  const handleNavigate = () => {
    props.history.push(`/product/${props.browsingName}/${props.id}`);
  };
  return (
    <div ref={cardRef} className={classes.productContainer}>
      <Hidden smDown>
        <Flippy
          // flipOnHover={false} // default false
          // flipOnClick={true} // default false
          flipDirection="horizontal" // horizontal or vertical
          ref={cardRef}
          isFlipped={false}
          // to use toggle method like this.flippy.toggle()
          // if you pass isFlipped prop component will be controlled component.
          // and other props, which will go to div
          style={{ width: "100%", height: "100%" }} /// these are optional style, it is not necessary
        >
          <FrontSide
            elementType="div"
            style={{ boxShadow: "none", padding: 0 }}
          >
            <ProductItem {...props} isFlipped={isFlipped} innerRef={cardRef} />
          </FrontSide>
          <BackSide className={classes.backSide}>
            <Card
              ref={cardRef}
              variant="outlined"
              className={classes.flippedCard}
              onMouse
            >
              <CardContent className={classes.flippedDescription}>
                <Typography variant="h6" color="textPrimary">
                  Description
                </Typography>
                <Typography>{props.description}</Typography>
              </CardContent>
              {/* <CardActionArea>
           
                </CardActionArea> */}
              <CardContent>
                <Box m={1}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={() => handleNavigate()}
                  >
                    Explore
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </BackSide>
        </Flippy>
        {/* <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
            <ProductItem {...props} isFlipped={isFlipped} innerRef={cardRef} />
  
            <Card
              ref={cardRef}
              variant="outlined"
              className={classes.flippedCard}
              onMouse
            >
              <CardHeader title="Overview" />
  
              <CardContent>
                <Typography>{props.overview}</Typography>
              </CardContent>
            </Card>
          </ReactCardFlip> */}
      </Hidden>
      <Hidden smUp>
        <div>
          <ProductItem {...props} isFlipped={isFlipped} />
        </div>
      </Hidden>
    </div>
  );
};
export default withRouter(FlippingCard);
