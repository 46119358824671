import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  Typography,
  Button,
  Paper,
  Hidden,
} from "@material-ui/core";
import RebuyIcon from "@material-ui/icons/DirectionsCarTwoTone";
import { withRouter } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {},
  imgContainer: {
    height: "20vh",
    [theme.breakpoints.down("md")]: {
      height: "10vh",
    },
  },
  img: {
    height: "100%",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
  },
  container: {
    // width: "100%",
    // border: "1px solid red",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
  btnContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
      margin: "1vh 0vh",
    },
  },
}));

const OrderItem = (props) => {
  const classes = useStyles();
  const {
    data,
    seller,
    addComment,
    returnRequest,
    status,
    // getTrackingInfo,
  } = props;
  const handleNavigate = () => {
    props.history.push(`/product/${data.browsingName}/${data.productId}`);
  };

  return (
    // <div className={classes.container}>
    <Grid
      item
      lg={12}
      md={12}
      sm={12}
      xs={12}
      container
      className={classes.container}
      component={Paper}
      variant="outlined"
    >
      <Grid
        item
        lg={3}
        md={3}
        sm={3}
        xs={3}
        container
        justify="center"
        alignItems="center"
        className={classes.imgContainer}
      >
        <img className={classes.img} src={data.imageUrl} />
      </Grid>
      <Grid item lg={9} md={9} sm={9} xs={9} container alignItems="stretch">
        <Grid
          item
          lg={9}
          md={9}
          sm={9}
          xs={9}
          className={classes.contentcontainer}
        >
          <Typography variant="body1" color="primary">
            {data.product}
          </Typography>
          <Typography variant="body2">SOLD BY : {seller}</Typography>
          <Typography variant="body2">STATUS : {status}</Typography>
          {data.orderInputs.map((input, index) => {
            return (
              <Typography variant="body2">
                {Object.keys(input)[0]} : {input[Object.keys(input)[0]]}
              </Typography>
            );
          })}
        </Grid>
        <Hidden mdDown>
          <Grid item lg={3} md={3} sm={3} xs={3}>
            <div className={classes.btnContainer}>
              <Button
                onClick={() => handleNavigate()}
                variant="outlined"
                color="primary"
                fullWidth
              >
                Buy It Again
              </Button>

              <Button
                onClick={() => {
                  // console.log(data, "add product");
                  addComment(data);
                }}
                variant="outlined"
                disabled={data.rating !== null}
                color="primary"
                fullWidth
              >
                Rate {`&`} Review
              </Button>
              {data.returnable && (
                <Button
                  onClick={() => returnRequest(data)}
                  variant="outlined"
                  color="primary"
                >
                  Return
                </Button>
              )}
            </div>
          </Grid>
        </Hidden>
      </Grid>
      <Hidden smUp>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className={classes.btnContainer}>
            <Button
              onClick={() => handleNavigate()}
              variant="outlined"
              color="primary"
            >
              Buy It Again
            </Button>

            {data.rating && (
              <Button
                onClick={() => addComment(data)}
                variant="outlined"
                color="primary"
                disabled={!!data.rating}
              >
                Rate {`&`} Review
              </Button>
            )}

            <Button
              onClick={() => returnRequest(data)}
              variant="outlined"
              color="primary"
              disabled={data.rating === null}
            >
              Return Product
            </Button>
          </div>
        </Grid>
      </Hidden>
    </Grid>
    // </div>
  );
};
export default withRouter(OrderItem);
