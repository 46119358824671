export const FooterData = [
  {
    title: "ABOUT",
    list: [
      {
        name: "Contact us",
        link: "contactus",
        description: `  <h1>HTML Ipsum Presents</h1>
  
                <p>
                  <strong>Pellentesque habitant morbi tristique</strong> senectus
                  et netus et malesuada fames ac turpis egestas. Vestibulum tortor
                  quam, feugiat vitae, ultricies eget, tempor sit amet, ante.
                  Donec eu libero sit amet quam egestas semper.{" "}
                  <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend
                  leo. Quisque sit amet est et sapien ullamcorper pharetra.
                  Vestibulum erat wisi, condimentum sed,{" "}
                  <code>commodo vitae</code>, ornare sit amet, wisi. Aenean
                  fermentum, elit eget tincidunt condimentum, eros ipsum rutrum
                  orci, sagittis tempus lacus enim ac dui.{" "}
                  <a href="#">Donec non enim</a> in turpis pulvinar facilisis. Ut
                  felis.
                </p>
  
                <h2>Header Level 2</h2>
  
                <ol>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ol>
  
                <blockquote>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vivamus magna. Cras in mi at felis aliquet congue. Ut a est
                    eget ligula molestie gravida. Curabitur massa. Donec eleifend,
                    libero at sagittis mollis, tellus est malesuada tellus, at
                    luctus turpis elit sit amet quam. Vivamus pretium ornare est.
                  </p>
                </blockquote>
  
                <h3>Header Level 3</h3>
  
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ul>
  
                <pre>
                  <code></code>
                </pre>`,
      },
      {
        name: "About us",
        link: "aboutus",
        description: `  <h1>HTML Ipsum Presents</h1>
  
                <p>
                  <strong>Pellentesque habitant morbi tristique</strong> senectus
                  et netus et malesuada fames ac turpis egestas. Vestibulum tortor
                  quam, feugiat vitae, ultricies eget, tempor sit amet, ante.
                  Donec eu libero sit amet quam egestas semper.{" "}
                  <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend
                  leo. Quisque sit amet est et sapien ullamcorper pharetra.
                  Vestibulum erat wisi, condimentum sed,{" "}
                  <code>commodo vitae</code>, ornare sit amet, wisi. Aenean
                  fermentum, elit eget tincidunt condimentum, eros ipsum rutrum
                  orci, sagittis tempus lacus enim ac dui.{" "}
                  <a href="#">Donec non enim</a> in turpis pulvinar facilisis. Ut
                  felis.
                </p>
  
                <h2>Header Level 2</h2>
  
                <ol>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ol>
  
                <blockquote>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vivamus magna. Cras in mi at felis aliquet congue. Ut a est
                    eget ligula molestie gravida. Curabitur massa. Donec eleifend,
                    libero at sagittis mollis, tellus est malesuada tellus, at
                    luctus turpis elit sit amet quam. Vivamus pretium ornare est.
                  </p>
                </blockquote>
  
                <h3>Header Level 3</h3>
  
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ul>
  
                <pre>
                  <code></code>
                </pre>`,
      },
      {
        name: "Careers",
        link: "careers",
        description: `  <h1>HTML Ipsum Presents</h1>
  
                <p>
                  <strong>Pellentesque habitant morbi tristique</strong> senectus
                  et netus et malesuada fames ac turpis egestas. Vestibulum tortor
                  quam, feugiat vitae, ultricies eget, tempor sit amet, ante.
                  Donec eu libero sit amet quam egestas semper.{" "}
                  <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend
                  leo. Quisque sit amet est et sapien ullamcorper pharetra.
                  Vestibulum erat wisi, condimentum sed,{" "}
                  <code>commodo vitae</code>, ornare sit amet, wisi. Aenean
                  fermentum, elit eget tincidunt condimentum, eros ipsum rutrum
                  orci, sagittis tempus lacus enim ac dui.{" "}
                  <a href="#">Donec non enim</a> in turpis pulvinar facilisis. Ut
                  felis.
                </p>
  
                <h2>Header Level 2</h2>
  
                <ol>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ol>
  
                <blockquote>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vivamus magna. Cras in mi at felis aliquet congue. Ut a est
                    eget ligula molestie gravida. Curabitur massa. Donec eleifend,
                    libero at sagittis mollis, tellus est malesuada tellus, at
                    luctus turpis elit sit amet quam. Vivamus pretium ornare est.
                  </p>
                </blockquote>
  
                <h3>Header Level 3</h3>
  
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ul>
  
                <pre>
                  <code></code>
                </pre>`,
      },
    ],
  },
  {
    title: "HELP",
    list: [
      {
        name: "Customer service",
        link: "customerservice",
        description: `  <h1>HTML Ipsum Presents</h1>
  
                <p>
                  <strong>Pellentesque habitant morbi tristique</strong> senectus
                  et netus et malesuada fames ac turpis egestas. Vestibulum tortor
                  quam, feugiat vitae, ultricies eget, tempor sit amet, ante.
                  Donec eu libero sit amet quam egestas semper.{" "}
                  <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend
                  leo. Quisque sit amet est et sapien ullamcorper pharetra.
                  Vestibulum erat wisi, condimentum sed,{" "}
                  <code>commodo vitae</code>, ornare sit amet, wisi. Aenean
                  fermentum, elit eget tincidunt condimentum, eros ipsum rutrum
                  orci, sagittis tempus lacus enim ac dui.{" "}
                  <a href="#">Donec non enim</a> in turpis pulvinar facilisis. Ut
                  felis.
                </p>
  
                <h2>Header Level 2</h2>
  
                <ol>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ol>
  
                <blockquote>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vivamus magna. Cras in mi at felis aliquet congue. Ut a est
                    eget ligula molestie gravida. Curabitur massa. Donec eleifend,
                    libero at sagittis mollis, tellus est malesuada tellus, at
                    luctus turpis elit sit amet quam. Vivamus pretium ornare est.
                  </p>
                </blockquote>
  
                <h3>Header Level 3</h3>
  
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ul>
  
                <pre>
                  <code></code>
                </pre>`,
      },
      {
        name: "Store-Locator",
        link: "storelocator",
        description: `  <h1>HTML Ipsum Presents</h1>
  
                <p>
                  <strong>Pellentesque habitant morbi tristique</strong> senectus
                  et netus et malesuada fames ac turpis egestas. Vestibulum tortor
                  quam, feugiat vitae, ultricies eget, tempor sit amet, ante.
                  Donec eu libero sit amet quam egestas semper.{" "}
                  <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend
                  leo. Quisque sit amet est et sapien ullamcorper pharetra.
                  Vestibulum erat wisi, condimentum sed,{" "}
                  <code>commodo vitae</code>, ornare sit amet, wisi. Aenean
                  fermentum, elit eget tincidunt condimentum, eros ipsum rutrum
                  orci, sagittis tempus lacus enim ac dui.{" "}
                  <a href="#">Donec non enim</a> in turpis pulvinar facilisis. Ut
                  felis.
                </p>
  
                <h2>Header Level 2</h2>
  
                <ol>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ol>
  
                <blockquote>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vivamus magna. Cras in mi at felis aliquet congue. Ut a est
                    eget ligula molestie gravida. Curabitur massa. Donec eleifend,
                    libero at sagittis mollis, tellus est malesuada tellus, at
                    luctus turpis elit sit amet quam. Vivamus pretium ornare est.
                  </p>
                </blockquote>
  
                <h3>Header Level 3</h3>
  
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ul>
  
                <pre>
                  <code></code>
                </pre>`,
      },
      {
        name: "Legal & Privacy",
        link: "legalandprivacy",
        description: `  <h1>HTML Ipsum Presents</h1>
  
                <p>
                  <strong>Pellentesque habitant morbi tristique</strong> senectus
                  et netus et malesuada fames ac turpis egestas. Vestibulum tortor
                  quam, feugiat vitae, ultricies eget, tempor sit amet, ante.
                  Donec eu libero sit amet quam egestas semper.{" "}
                  <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend
                  leo. Quisque sit amet est et sapien ullamcorper pharetra.
                  Vestibulum erat wisi, condimentum sed,{" "}
                  <code>commodo vitae</code>, ornare sit amet, wisi. Aenean
                  fermentum, elit eget tincidunt condimentum, eros ipsum rutrum
                  orci, sagittis tempus lacus enim ac dui.{" "}
                  <a href="#">Donec non enim</a> in turpis pulvinar facilisis. Ut
                  felis.
                </p>
  
                <h2>Header Level 2</h2>
  
                <ol>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ol>
  
                <blockquote>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vivamus magna. Cras in mi at felis aliquet congue. Ut a est
                    eget ligula molestie gravida. Curabitur massa. Donec eleifend,
                    libero at sagittis mollis, tellus est malesuada tellus, at
                    luctus turpis elit sit amet quam. Vivamus pretium ornare est.
                  </p>
                </blockquote>
  
                <h3>Header Level 3</h3>
  
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ul>
  
                <pre>
                  <code></code>
                </pre>`,
      },
    ],
  },
  {
    title: "POLICY",
    list: [
      {
        name: "Return Policy",
        link: "returnpolicy",
        description: `  <h1>HTML Ipsum Presents</h1>
  
                <p>
                  <strong>Pellentesque habitant morbi tristique</strong> senectus
                  et netus et malesuada fames ac turpis egestas. Vestibulum tortor
                  quam, feugiat vitae, ultricies eget, tempor sit amet, ante.
                  Donec eu libero sit amet quam egestas semper.{" "}
                  <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend
                  leo. Quisque sit amet est et sapien ullamcorper pharetra.
                  Vestibulum erat wisi, condimentum sed,{" "}
                  <code>commodo vitae</code>, ornare sit amet, wisi. Aenean
                  fermentum, elit eget tincidunt condimentum, eros ipsum rutrum
                  orci, sagittis tempus lacus enim ac dui.{" "}
                  <a href="#">Donec non enim</a> in turpis pulvinar facilisis. Ut
                  felis.
                </p>
  
                <h2>Header Level 2</h2>
  
                <ol>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ol>
  
                <blockquote>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vivamus magna. Cras in mi at felis aliquet congue. Ut a est
                    eget ligula molestie gravida. Curabitur massa. Donec eleifend,
                    libero at sagittis mollis, tellus est malesuada tellus, at
                    luctus turpis elit sit amet quam. Vivamus pretium ornare est.
                  </p>
                </blockquote>
  
                <h3>Header Level 3</h3>
  
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ul>
  
                <pre>
                  <code></code>
                </pre>`,
      },
      {
        name: "Terms of Use",
        link: "terms",
        description: `  <h1>HTML Ipsum Presents</h1>
  
                <p>
                  <strong>Pellentesque habitant morbi tristique</strong> senectus
                  et netus et malesuada fames ac turpis egestas. Vestibulum tortor
                  quam, feugiat vitae, ultricies eget, tempor sit amet, ante.
                  Donec eu libero sit amet quam egestas semper.{" "}
                  <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend
                  leo. Quisque sit amet est et sapien ullamcorper pharetra.
                  Vestibulum erat wisi, condimentum sed,{" "}
                  <code>commodo vitae</code>, ornare sit amet, wisi. Aenean
                  fermentum, elit eget tincidunt condimentum, eros ipsum rutrum
                  orci, sagittis tempus lacus enim ac dui.{" "}
                  <a href="#">Donec non enim</a> in turpis pulvinar facilisis. Ut
                  felis.
                </p>
  
                <h2>Header Level 2</h2>
  
                <ol>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ol>
  
                <blockquote>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vivamus magna. Cras in mi at felis aliquet congue. Ut a est
                    eget ligula molestie gravida. Curabitur massa. Donec eleifend,
                    libero at sagittis mollis, tellus est malesuada tellus, at
                    luctus turpis elit sit amet quam. Vivamus pretium ornare est.
                  </p>
                </blockquote>
  
                <h3>Header Level 3</h3>
  
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ul>
  
                <pre>
                  <code></code>
                </pre>`,
      },
      {
        name: "Security",
        link: "security",
        description: `  <h1>HTML Ipsum Presents</h1>
  
                <p>
                  <strong>Pellentesque habitant morbi tristique</strong> senectus
                  et netus et malesuada fames ac turpis egestas. Vestibulum tortor
                  quam, feugiat vitae, ultricies eget, tempor sit amet, ante.
                  Donec eu libero sit amet quam egestas semper.{" "}
                  <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend
                  leo. Quisque sit amet est et sapien ullamcorper pharetra.
                  Vestibulum erat wisi, condimentum sed,{" "}
                  <code>commodo vitae</code>, ornare sit amet, wisi. Aenean
                  fermentum, elit eget tincidunt condimentum, eros ipsum rutrum
                  orci, sagittis tempus lacus enim ac dui.{" "}
                  <a href="#">Donec non enim</a> in turpis pulvinar facilisis. Ut
                  felis.
                </p>
  
                <h2>Header Level 2</h2>
  
                <ol>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ol>
  
                <blockquote>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vivamus magna. Cras in mi at felis aliquet congue. Ut a est
                    eget ligula molestie gravida. Curabitur massa. Donec eleifend,
                    libero at sagittis mollis, tellus est malesuada tellus, at
                    luctus turpis elit sit amet quam. Vivamus pretium ornare est.
                  </p>
                </blockquote>
  
                <h3>Header Level 3</h3>
  
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ul>
  
                <pre>
                  <code></code>
                </pre>`,
      },
      {
        name: "Privacy",
        link: "privacy",
        description: `  <h1>HTML Ipsum Presents</h1>
  
                <p>
                  <strong>Pellentesque habitant morbi tristique</strong> senectus
                  et netus et malesuada fames ac turpis egestas. Vestibulum tortor
                  quam, feugiat vitae, ultricies eget, tempor sit amet, ante.
                  Donec eu libero sit amet quam egestas semper.{" "}
                  <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend
                  leo. Quisque sit amet est et sapien ullamcorper pharetra.
                  Vestibulum erat wisi, condimentum sed,{" "}
                  <code>commodo vitae</code>, ornare sit amet, wisi. Aenean
                  fermentum, elit eget tincidunt condimentum, eros ipsum rutrum
                  orci, sagittis tempus lacus enim ac dui.{" "}
                  <a href="#">Donec non enim</a> in turpis pulvinar facilisis. Ut
                  felis.
                </p>
  
                <h2>Header Level 2</h2>
  
                <ol>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ol>
  
                <blockquote>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vivamus magna. Cras in mi at felis aliquet congue. Ut a est
                    eget ligula molestie gravida. Curabitur massa. Donec eleifend,
                    libero at sagittis mollis, tellus est malesuada tellus, at
                    luctus turpis elit sit amet quam. Vivamus pretium ornare est.
                  </p>
                </blockquote>
  
                <h3>Header Level 3</h3>
  
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ul>
  
                <pre>
                  <code></code>
                </pre>`,
      },
      {
        name: "Sitemap",
        link: "sitemap",
        description: `  <h1>HTML Ipsum Presents</h1>
  
                <p>
                  <strong>Pellentesque habitant morbi tristique</strong> senectus
                  et netus et malesuada fames ac turpis egestas. Vestibulum tortor
                  quam, feugiat vitae, ultricies eget, tempor sit amet, ante.
                  Donec eu libero sit amet quam egestas semper.{" "}
                  <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend
                  leo. Quisque sit amet est et sapien ullamcorper pharetra.
                  Vestibulum erat wisi, condimentum sed,{" "}
                  <code>commodo vitae</code>, ornare sit amet, wisi. Aenean
                  fermentum, elit eget tincidunt condimentum, eros ipsum rutrum
                  orci, sagittis tempus lacus enim ac dui.{" "}
                  <a href="#">Donec non enim</a> in turpis pulvinar facilisis. Ut
                  felis.
                </p>
  
                <h2>Header Level 2</h2>
  
                <ol>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ol>
  
                <blockquote>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vivamus magna. Cras in mi at felis aliquet congue. Ut a est
                    eget ligula molestie gravida. Curabitur massa. Donec eleifend,
                    libero at sagittis mollis, tellus est malesuada tellus, at
                    luctus turpis elit sit amet quam. Vivamus pretium ornare est.
                  </p>
                </blockquote>
  
                <h3>Header Level 3</h3>
  
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </li>
                  <li>Aliquam tincidunt mauris eu risus.</li>
                </ul>
  
                <pre>
                  <code></code>
                </pre>`,
      },
    ],
  },
  // {
  //   title: "Office Address:",
  //   list: [
  //     {
  //       name: "Customer service",

  //       description: `<p>Accurate Stitch Private Limited</p>
  //                     <p>JP Nagar, Bengaluru, 560078,</p>
  //                     <p>Karnataka, India</p>
  //                     <p>CIN:H51109RA2012PTC04895</p>
  //                     <p>Telephone: 1800 xxx xxxx</p>`,
  //     },
  //   ],
  // },
];
