//material

import React from "react";
import { Link, Route } from "react-router-dom";
import PT from "prop-types";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
const propTypes = {
  menuHeader: PT.string,
  menuItems: PT.arrayOf(PT.object),
};
const useStyles = makeStyles((theme) => ({
  title: {
    // color: theme.palette.type,
    color: theme.palette.text.primary,
    fontSize: 15,
    fontWeight: 600,
  },
  link: {
    color: theme.palette.text.secondary,
    fontSize: 15,
  },
}));

const FooterSubMenu = (props) => {
  let { data } = props;
  let { title, list } = data;
  const classes = useStyles();

  let itemsSubMenuItem = list.map(function (item, index) {
    return (
      <ListItem key={index}>
        {item.link === undefined ? (
          <div className={classes.link}>
            {ReactHtmlParser(item.description)}
          </div>
        ) : (
          <Link
            className={classes.link}
            to={{
              pathname: `/footer/${item.link}`,
              item,
            }}
          >
            <Typography> {item.name}</Typography>
          </Link>
        )}
      </ListItem>
    );
  });

  return (
    <List disablePadding>
      <ListItem>
        <Typography className={classes.title}>{title.toUpperCase()}</Typography>
      </ListItem>
      {itemsSubMenuItem}
    </List>
  );
};

FooterSubMenu.propTypes = propTypes;

export default FooterSubMenu;
