import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PT from "prop-types";
import {
  List,
  Divider,
  Grid,
  Typography,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Table,
} from "@material-ui/core";

const propTypes = {
  orderValue: PT.number,
};
const useStyles = makeStyles((theme) => ({
  TableCell: {
    border: "none",
  },
}));

const OrderBill = (props) => {
  const deliveryValue = 0;
  const classes = useStyles();
  return (
    <TableContainer>
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell className={classes.TableCell}>
              <Typography> ORDER VALUE:</Typography>
            </TableCell>
            <TableCell align="right" className={classes.TableCell}>
              <Typography> ₹{props.orderValue.toFixed(2)}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.TableCell}>
              <Typography> DELIVERY:</Typography>
            </TableCell>
            <TableCell align="right" className={classes.TableCell}>
              <Typography>₹ {deliveryValue}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.TableCell}>
              <Typography> TOTAL:</Typography>
            </TableCell>
            <TableCell align="right" className={classes.TableCell}>
              <Typography>
                ₹{(props.orderValue + deliveryValue).toFixed(2)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
OrderBill.propTypes = propTypes;

export default OrderBill;
