import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

const useStyles = makeStyles({
  root: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
});

export default function HoverRating(props) {
  const { input, meta, mode, initvalue, ...others } = props;

  const [hover, setHover] = React.useState(-1);
  const classes = useStyles();
  const [startValue, setStartValue] = React.useState(others.startValue);
  const [EndValue, setEndValue] = React.useState(others.endValue);
  const [value, setValue] = React.useState([startValue, EndValue]);

  if (startValue === others.startValue && EndValue === others.endValue) {
    input.onChange([others.startValue, others.endValue]);
  }
  return (
    <div className={classes.root}>
      <Rating
        size="large"
        value={EndValue}
        precision={0.5}
        onChange={(event, newValue) => {
          setEndValue(newValue);
          input.onChange(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
      />
      {value !== null && (
        <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>
      )}
    </div>
  );
}
