import React, { useState } from "react";

import PT from "prop-types";

import {
  Typography,
  Divider,
  Grid,
  makeStyles,
  Radio,
  Button,
  Input,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Box,
  Hidden,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useSelector } from "react-redux";
import clsx from "clsx";

const propTypes = {
  handlePaymentsConfirm: PT.func,
};
const useStyles = makeStyles((theme) => ({
  ChooseTitle: {
    fontSize: "1.1rem",
  },

  Card: {
    // maxHeight: "80vh",
    overflow: "auto",
  },
  summary: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  processingLimitContainer: {
    // height: "100%",
    // width: "100%",
    // backgroundColor: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  processingLimit: {
    fontSize: "1.2rem",
    color: theme.palette.primary.main,
    display: "inline",
    [theme.breakpoints.down("md")]: {
      display: "block",
      fontSize: "1rem",
    },
  },
  CardsContainer: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexGrow: 1,
      overflow: "auto",
      maxWidth: "100%",
    },
  },
  limit: {
    width: "10vw",
    display: "inline",
    fontSize: "1.2rem",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      fontSize: "1rem",
      display: "block",
    },
    // backgroundColor: "red",
  },
  selected: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
  },
  FormControlLabel: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      fontSize: "1rem",
      display: "block",
    },
  },
  shippingbtnmobile: {
    marginTop: theme.spacing(2),
  },
}));
const ConfirmStep = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(null);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [isCard, setIsCard] = React.useState(false);

  const handleExpand = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [paymentId, setPaymentId] = useState(
    useSelector((state) => state.shop.payment.paymentId)
  );
  const userMethods = useSelector((state) => state.user.paymentOptions);
  const [securityCode, setsecurityCode] = useState(
    useSelector((state) => state.shop.payment.verificationCode)
  );
  // console.log("securityCode", securityCode, paymentId);
  const handleChange = (e) => {
    setsecurityCode(undefined);
    setPaymentId(e);
  };
  const handleSecurityCode = (e) => setsecurityCode(e.target.value);
  const [userCards, setUserCards] = React.useState([]);
  const savedcards = useSelector((state) => state.user.savedcards);
  React.useEffect(() => {
    setUserCards(savedcards);
  }, [savedcards]);
  return (
    <Grid container spacing={5}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Card className={classes.Card}>
          <CardContent>
            <Typography
              className={classes.ChooseTitle}
              color="textPrimary"
              align="center"
            >
              Choose Payment Method
            </Typography>
          </CardContent>
          {/* <CardHeader title="Choose Payment Method" /> */}
          {userMethods && userMethods.length > 0 ? (
            userMethods.map((method, index) => {
              return (
                <>
                  <Accordion
                    square
                    expanded={expanded === index}
                    className={expanded === index ? classes.selected : null}
                    onChange={() => {
                      if (method.method === "CARD") {
                        handleExpand(index);
                        setIsCard(true);
                      } else {
                        handleExpand(null);
                        setIsCard(false);
                      }
                    }}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className={classes.summary}>
                        <FormControlLabel
                          aria-label="Acknowledge"
                          onClick={(event) => event.stopPropagation()}
                          onFocus={(event) => event.stopPropagation()}
                          control={<Radio />}
                          onChange={() => {
                            // setExpanded(index);
                            if (method.method === "CARD") {
                              setExpanded(index);
                            } else {
                              setExpanded(null);
                            }
                            setPaymentMethodId(method.paymentMethodId);
                            // setPaymentMethodId(method.methodId);
                            setSelectedOption(index);
                          }}
                          checked={selectedOption === index}
                          label={method.method}
                          className={classes.FormControlLabel}
                        />
                        <Box className={classes.processingLimitContainer}>
                          <Typography className={classes.processingLimit}>
                            Proccessing Limit :{" "}
                          </Typography>
                          <Typography className={clsx(classes.limit)}>
                            {" "}
                            ₹ {method.processingLimit}
                          </Typography>
                        </Box>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {method.method === "CARD" && (
                        <TableContainer
                          component={Paper}
                          // className={classes.CardsContainer}
                          elevation={0}
                        >
                          <Table
                            size="small"
                            className={classes.CardsContainer}
                          >
                            <TableBody>
                              {userCards.length > 0 &&
                                userCards.map((ele, index) => {
                                  return (
                                    <TableRow
                                      key={index}
                                      className={classes.TableRow}
                                    >
                                      <TableCell padding="checkbox">
                                        <Radio
                                          color="primary"
                                          checked={ele.id === paymentId}
                                          onChange={(e) => handleChange(ele.id)}
                                          // value={ele.id}
                                        />
                                      </TableCell>

                                      <TableCell>Visa</TableCell>
                                      <TableCell align="center">
                                        {ele.cardNumber}
                                      </TableCell>
                                      <TableCell align="right">
                                        <TextField
                                          maxLength={3}
                                          value={
                                            ele.id === paymentId
                                              ? securityCode
                                              : ""
                                          }
                                          disabled={ele.id !== paymentId}
                                          type="number"
                                          placeholder="Security Code"
                                          onChange={(e) =>
                                            handleSecurityCode(e)
                                          }
                                        />
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </>
              );
            })
          ) : (
            <CardContent>
              <Typography align="center">
                {" "}
                You do not have an payment methods defined
              </Typography>
            </CardContent>
          )}
        </Card>
      </Grid>
      <Grid
        item
        container
        justify="space-between"
        lg={12}
        md={12}
        xs={12}
        lg={12}
      >
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => props.back()}
          >
            Back
          </Button>
        </Grid>

        <Hidden mdDown>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              disabled={paymentMethodId === undefined}
              onClick={() =>
                props.handlePaymentsConfirm(
                  paymentMethodId,
                  paymentId,
                  securityCode
                )
              }
            >
              Confirm Payment Method
            </Button>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item xs={12} sm={12}>
            <Box className={classes.shippingbtnmobile}>
              {isCard ? (
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled={!paymentMethodId && !paymentId}
                  onClick={() =>
                    props.handlePaymentsConfirm(
                      paymentMethodId,
                      paymentId,
                      securityCode
                    )
                  }
                >
                  Confirm Payment Method
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled={paymentMethodId === null}
                  onClick={() =>
                    props.handlePaymentsConfirm(
                      paymentMethodId,
                      paymentId,
                      securityCode
                    )
                  }
                >
                  Confirm Payment Method
                </Button>
              )}
              {/* <Button
                color="primary"
                variant="contained"
                fullWidth
                disabled={!paymentMethodId || (isCard && !paymentId)}
                onClick={() =>
                  props.handlePaymentsConfirm(
                    paymentMethodId,
                    paymentId,
                    securityCode
                  )
                }
              >
                Confirm Payment Method
              </Button> */}
            </Box>
          </Grid>
        </Hidden>
      </Grid>
      {/* <Grid item lg={5} xs={12}>
        <OrderBar />
      </Grid> */}
    </Grid>
  );
};

ConfirmStep.propTypes = propTypes;

export default ConfirmStep;
