import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import TitleContainer from "./TitleContaier";
import AddNewAddress from "./AddNewAddress";
import SingleAddress from "./SingleAddress";
import EditAddress from "./EditAddress";
import { useSelector, useDispatch } from "react-redux";
import LoadingComponent from "../../../Spinners/LoadingComponent";
import {
  saveUserAddresses,
  getUserAddresses,
  deleteUserAddress,
  editUserAddress,
} from "../../../../redux/action/useractions";
import { useParams, useLocation } from "react-router-dom";
import { pushtohistory } from "../../../../redux/action/historyActions";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  loadingContainer: {
    minHeight: "40vh",
  },
}));

//dummy addresses array

const ManageAddress = () => {
  const dispatch = useDispatch();
  //states

  //add new address portion open
  const [addNew, setAddNew] = React.useState(false);

  //edit menu open state
  const [editOpen, setEditopen] = React.useState(false);
  const [editIndex, setEditindex] = React.useState();
  //const edit portion open
  const [editAddress, setEditAddress] = React.useState(false);

  //addresse array
  const [addresses, setAddresses] = React.useState([]);

  //open edit address

  const openEditAddress = (e) => {
    setEditopen(true);
    setEditindex(e);
  };

  //Delete a address
  const DeleteSingleAddress = (id, data) => {
    // let newArray = addresses.filter((item, key) => key !== index);
    // setAddresses(newArray);
    dispatch(deleteUserAddress(id, data));
    let newArray = addresses.filter((item, key) => item.id !== id);
    setAddresses(newArray);
    // dispatch(saveUserAddresses(newArray));
  };

  //add addresses effect
  const stateAddress = useSelector((state) => state.user.addresses);
  const loading = useSelector((state) => state.user.isLoading);
  React.useEffect(() => {
    setAddresses(stateAddress);
  }, [stateAddress]);

  //stub

  const { stub } = useParams();
  const location = useLocation().pathname;

  React.useEffect(() => {
    let newlocation = location === "/" ? location : location.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));
  }, []);
  // React.useEffect(() => {
  //   dispatch(getUserAddresses(token));
  // }, []);

  const classes = useStyles();

  const handleAddressChanges = (e) => {
    // console.log(e, "handle change");
    // setAddresses(e);
    // setAddresses(addresses.concat(e));
    dispatch(saveUserAddresses(e));
  };

  const handleEditAddress = (e, id) => {
    dispatch(editUserAddress(e, id));
  };

  const handleAddresssEdit = () => {};
  return (
    <Paper elevation={1}>
      <div className={classes.root}>
        <Grid container>
          <TitleContainer open={addNew} setOpen={() => setAddNew(true)} />
          <Grid item lg={12} container>
            {addNew ? (
              <AddNewAddress
                open={addNew}
                array={addresses}
                add={(e) => handleAddressChanges(e)}
                close={() => setAddNew(false)}
              />
            ) : null}
            {editOpen ? (
              <EditAddress
                array={addresses}
                index={editIndex}
                edit={(e, id) => handleEditAddress(e, id)}
                close={() => setEditopen(false)}
              />
            ) : null}

            {addresses.length > 0
              ? addresses.map((data, index) => {
                  return (
                    <SingleAddress
                      data={data}
                      key={index}
                      deleteaddress={() => DeleteSingleAddress(data.id, data)}
                      edit={() => openEditAddress(index)}
                    />
                  );
                })
              : null}
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
};
export default ManageAddress;
