import React from "react";
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  Grid,
  Divider,
  Select,
  MenuItem,
  FormControl,
  AppBar,
  Button,
} from "@material-ui/core";
import SortIcon from "@material-ui/icons/Sort";
import FilterIcon from "@material-ui/icons/FilterList";
import Pagination from "@material-ui/lab/Pagination";
import { Hidden } from "@material-ui/core";
import ProductList from "./ProductList";

import SortBarMobile from "./SortBarMobile";
import FilterBarMobile from "./FilterBarMobile";
const useStyles = makeStyles((theme) => ({
  Select: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
    },
  },
  categoryName: {
    textTransform: "capitalize",
  },
  AppBar: {
    backgroundColor: "white",
  },
  btncontainer: {
    height: "7vh",
  },
  btn: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    borderRight: "1px solid #eee",
    borderLeft: "1px solid #eee",
    borderBottom: "1px solid #eee",
    borderRadius: 0,
  },
}));

const options = [
  { text: "Best Match", value: "Best Match" },
  { text: "Lowest Price", value: "Lowest Price" },
  { text: "Highest Price", value: "Highest Price" },
];
const ProductContainer = (props) => {
  const classes = useStyles();
  const [filter, setFilter] = React.useState("Best Match");
  const [sortBarOpen, setSortBarOpen] = React.useState(false);
  const [filterBarOpen, setFilterBarOpen] = React.useState(false);

  const { dataProducts, showcaseHeader } = props;

  // const reducerProducts = dataProducts;

  function handleSelectSort(value) {
    setFilter(value);
  }
  function filterHighestPrice(arr) {
    return arr.length <= 1
      ? arr
      : filterHighestPrice(
          arr
            .slice(1)
            .filter((item) => item.price.saleice >= arr[0].price.salePrPrice)
        ).concat(
          arr[0],
          filterHighestPrice(
            arr
              .slice(1)
              .filter((item) => item.price.salePrice < arr[0].price.salePrice)
          )
        );
  }

  function filterLowestPrice(arr) {
    // 快速排序 Quick Sort
    return arr.length <= 1
      ? arr
      : filterLowestPrice(
          arr
            .slice(1)
            .filter((item) => item.price.salePrice <= arr[0].price.salePrice)
        ).concat(
          arr[0],
          filterLowestPrice(
            arr
              .slice(1)
              .filter((item) => item.price.salePrice > arr[0].price.salePrice)
          )
        );
  }

  // Total Quantity of Products 商品总数量
  let itemsTotal = dataProducts.length;
  // Total Pages 总页码
  var page = 0;
  if (itemsTotal <= 12) {
    page = 1;
  } else if (itemsTotal % 12 !== 0) {
    page = Math.floor(itemsTotal / 12) + 1;
  } else {
    page = itemsTotal / 12;
  }

  let dataDisplay = [];

  switch (filter) {
    case "Best Match":
      dataDisplay = dataProducts;
      break;
    case "Lowest Price":
      dataDisplay = filterLowestPrice(dataProducts.slice(0));
      console.log(dataDisplay);
      break;
    case "Highest Price":
      dataDisplay = filterHighestPrice(dataProducts.slice(0));
      break;

    default:
      dataDisplay = dataProducts;
  }

  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(4);

  const indexOfLastitem = currentPage * itemsPerPage;
  const indexOfFirstitem = indexOfLastitem - itemsPerPage;
  let currentProdcuts = dataDisplay.slice(indexOfFirstitem, indexOfLastitem);

  // const renderProducts = currentProdcuts.map(function (item, index) {
  //   return (
  //     <Grid item lg={3} xs={6} key={index}>
  //       <ProductItem key={item.id} item={item} {...item} index={index} />
  //     </Grid>
  //   );
  // });

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(dataDisplay.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  // console.log("data length", dataDisplay.length, pageNumbers);
  function handleClick(event, value) {
    console.log(event, "pages");
    setCurrentPage(value);
  }
  // dataDisplay = dataDisplay.slice((currentPage - 1) * 12, currentPage * 12);
  // console.log("data products,", dataProducts);

  const ContainerList = () => {
    return (
      <Paper elevation={0}>
        <Grid container alignItems="center">
          <Hidden smDown>
            <Grid item lg={6} md={6} sm={6} xs={3}>
              <List>
                <ListItem>
                  <ListItemText className={classes.categoryName}>
                    {showcaseHeader.replace("/", "")}
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
          </Hidden>
          <Hidden xsDown>
            <Grid
              item
              container
              lg={6}
              md={6}
              sm={6}
              xs={9}
              justify="flex-end"
              alignItems="center"
            >
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={5}
                container
                justify="flex-end"
              >
                <Typography className={classes.Select}>Sort By :</Typography>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={7}
                container
                justify="flex-end"
              >
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  className={classes.Select}
                >
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    defaultValue="Best Match"
                    onChange={(e) => handleSelectSort(e.target.value)}
                  >
                    {options.map((data, index) => {
                      return (
                        <MenuItem key={index} value={data.value}>
                          {data.text}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
        <Divider />

        <Card elevation={0}>
          <CardContent>
            <ProductList dataProducts={currentProdcuts} />
            {/* {console.log("reducerproducts", currentProdcuts)} */}
          </CardContent>
          <CardContent>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Pagination
                  page={currentPage}
                  shape="rounded"
                  color="primary"
                  count={pageNumbers.length}
                  onChange={handleClick}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    );
  };
  const ProductContainerDesktop = () => {
    return (
      <div>
        <ContainerList />
      </div>
    );
  };

  const ProductContainerMobile = () => {
    return (
      <div>
        <Hidden mdUp>
          <SortBarMobile
            filter={filter}
            open={sortBarOpen}
            handleSort={(value) => {
              setSortBarOpen(false);
              handleSelectSort(value);
            }}
            close={() => setSortBarOpen(false)}
          />
        </Hidden>

        <FilterBarMobile
          open={filterBarOpen}
          close={() => setFilterBarOpen(false)}
        />

        <Hidden mdUp>
          <AppBar elevation={0} position="static" className={classes.AppBar}>
            <Grid container className={classes.btncontainer}>
              <Grid item sm={6} xs={6}>
                <Button
                  fullWidth
                  className={classes.btn}
                  startIcon={<SortIcon />}
                  onClick={() => setSortBarOpen(!sortBarOpen)}
                >
                  Sort by
                </Button>
              </Grid>
              <Grid item sm={6} xs={6}>
                <Button
                  fullWidth
                  className={classes.btn}
                  startIcon={<FilterIcon />}
                  onClick={() => setFilterBarOpen(!filterBarOpen)}
                >
                  Filter By
                </Button>
              </Grid>
            </Grid>
          </AppBar>
        </Hidden>

        <ContainerList />
      </div>
    );
  };
  return (
    <div className={classes.root}>
      <Hidden mdUp>
        <ProductContainerMobile />
      </Hidden>
      <Hidden xsDown>
        <ProductContainerDesktop />
      </Hidden>
    </div>
  );
};
export default React.memo(ProductContainer);
