import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  makeStyles,
  Divider,
  ListItemIcon,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ChevronRight";
import BackIcon from "@material-ui/icons/ArrowBack";
const useStyles = makeStyles((theme) => ({
  list: {
    width: "100vw",
    height: "100%",
    // overflow: "auto",

    // backgroundColor: theme.palette.background.default,
  },
  // root: {
  //   backgroundColor: theme.palette.background.default,
  // },
  // titleContainer: {},
  // ListSubheader: {
  //   border: "1px solid green",
  // },
  // categories: {
  //   color: theme.palette.text.primary,

  //   fontSize: 16,
  //   fontWeight: 500,
  // },
  // link: {
  //   fontSize: 15,
  //   color: theme.palette.text.primary,
  //   textTransform: "Capitalize",
  // },
}));
const TypeMenu = (props) => {
  const { subcategorymenu, handleSubCategoryClick, onClose } = props;
  // console.log("catemenu", subcategorymenu);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <List className={classes.list}>
        <ListItem button onClick={() => onClose()}>
          <ListItemIcon>
            <BackIcon />
          </ListItemIcon>
          <ListItemText primary={"Go back"} />
        </ListItem>
        <Divider />
        {subcategorymenu &&
          subcategorymenu.subcategoryList.map((category, index) => {
            return (
              <>
                <ListItem
                  button
                  // className={classes.listItem}
                  onClick={(e) =>
                    handleSubCategoryClick(category, index, category.id)
                  }
                >
                  <ListItemText primary={category.name} />

                  {/* <ListItemSecondaryAction>
                    <IconButton>
                      <ExpandMoreIcon />
                    </IconButton>
                  </ListItemSecondaryAction> */}
                </ListItem>
                <Divider />
              </>
            );
          })}
      </List>
    </div>
  );
};

export default TypeMenu;
