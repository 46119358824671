import React from "react";
import PT from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  media: {
    objectFit: "contain",
    height: 100,
    width: "100%",

    [theme.breakpoints.down("md")]: {
      height: 40,
      border: "none",
    },
  },
  link: {
    color: theme.palette.text.primary,
  },
  marketPrice: {
    color: theme.palette.text.secondary,

    textDecoration: "line-through",
  },
  discount: {
    color: theme.palette.secondary.main,
  },
  Card: {
    "&:hover": {
      boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
    },
  },
  favouritebutton: {
    color: theme.palette.primary.main,
  },
}));

const ProductItem = (props) => {
  const classes = useStyles();
  const [favourite, setfavourite] = React.useState(false);

  let {
    id,
    type,
    category,
    subcategory,
    relatedProductId,
    product,
    listPrice: marketPrice,
    salePrice: salePrice,
    images,
    name,
    index,
    browsingName,
    relatedProduct,
    url,
    defaultImage,
  } = props;

  // console.log("relatedItem", props);

  const styles = useStyles();
  const [value, setValue] = React.useState(0);

  const onChange = (e) => {
    setValue(e);
  };

  function percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }

  const changeFavourite = () => {
    setfavourite(!favourite);
  };

  return (
    <div className={classes.root}>
      <Link
        to={`/product/${browsingName}/${relatedProductId}`}
        onClick={() => {
          window.scrollTo({
            top: 100,
          });
        }}
      >
        <Card elevation={0} className={classes.Card}>
          <CardContent>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              spacing={1}
            >
              <Grid item lg={12} sm={12} md={12} xs={12}>
                <img
                  alt="no image found"
                  className={styles.media}
                  src={defaultImage}
                />
              </Grid>
              <Grid item lg={12} sm={12} md={12} xs={12}>
                <Typography variant="body1" className={classes.link}>
                  {relatedProduct}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Link>
    </div>
  );
};
export default ProductItem;
