import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { getSubCategoryProducts } from "../../redux/selector";
import { useDispatch } from "react-redux";
const useStyles = makeStyles((theme) => ({
  media: {
    objectFit: "contain",

    width: "100%",

    [theme.breakpoints.down("md")]: {
      // backgroundColor: "red",
      objectFit: "contain",
      height: "80%",
      border: "none",
    },
  },
  link: {
    color: theme.palette.primary.main,
  },
  marketPrice: {
    color: theme.palette.text.secondary,

    textDecoration: "line-through",
  },
  discount: {
    color: theme.palette.secondary.main,
  },
  Card: {
    height: "50vh",
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      height: "42vh",
    },
    "&:hover": {
      boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
    },
  },
  favouritebutton: {
    color: theme.palette.primary.main,
  },

  proName: {
    fontSize: "1rem",
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  itemContainer: {
    height: "100%",
    // height: "35vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  imgContainer: {
    flex: 5,
    width: "100%",
  },
  textContainer: {
    flex: 1,
    display: "flex",

    justifyContent: "center",
    alignItems: "center",
  },
}));
const MainCateogryItem = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { subcategory, defaultImage, id } = props.data;

  const handleProductsNavigation = (name, subcatid) => {
    dispatch(getSubCategoryProducts(subcatid));
    props.history.push(`/products/${name}`);
  };
  return (
    <div className={classes.Card}>
      {console.log("subitem", props.data)}
      <div className={classes.itemContainer}>
        <div
          className={classes.imgContainer}
          onClick={(e) => handleProductsNavigation(subcategory, id)}
        >
          <img
            className={classes.media}
            src={defaultImage}

            // onMouseOver={() => setIsFlipped(true)}
          />
        </div>
        <div className={classes.textContainer}>
          <Typography className={classes.proName}>{subcategory}</Typography>
        </div>
        {/* <Grid item sm={6} md={6} sm={6} xs={6}>
          <Typography align="left" variant="h6" display="inline">
            ${salePrice}
          </Typography>
        </Grid> */}
        {/* <Grid item sm={6} md={6} sm={6} xs={6}>
          <Typography
            variant="h6"
            display="inline"
            className={classes.marketPrice}
          >
            ${marketPrice}
          </Typography>
        </Grid> */}
        {/* <Grid item sm={6} md={6} sm={6} xs={6}>
          <Typography
            variant="subtitle1"
            display="inline"
            className={classes.discount}
          >
            {100 - percentage(salePrice, marketPrice).toFixed(2)}% off
          </Typography>
        </Grid> */}
      </div>
    </div>
  );
};

export default withRouter(MainCateogryItem);
