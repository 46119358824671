import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Button, FormControl, Divider, Typography } from "@material-ui/core";
import { Field, reduxForm } from "redux-form";
import * as inputs from "../../inputs";

import dataHeaderMenu from "../../data/productData/categoryInfo.json";
import { searchProducts, filterProducts } from "../../redux/selector";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  Divider: {
    marginBottom: theme.spacing(2),
  },
  List: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    padding: "20px 0px",
  },
  item: {
    paddingBottom: "20px",
  },
}));
const AttributeFilter = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const attributeList = useSelector((state) => state.shop.attributeList);
  const products = useSelector((state) => state.shop.products);

  // const [subLocalCategoryId, setSubLocalCategoryId] = React.useState();

  // const [refreshAttribute, setrefreshAttribute] = React.useState(true);

  // const subIndex = products.findIndex((data) => data.id === subCategoryId);
  // console.log(subIndex, products[subIndex]);
  // React.useEffect(() => {
  //   setSubLocalCategoryId(subCategoryId);
  //   setrefreshAttribute(!refreshAttribute);
  // }, [subCategoryId]);

  const onSubmit = (formData) => {
    // console.log("Form data: ", JSON.stringify(formData));

    const filterKeys = Object.keys(formData);

    const filterData = products.filter((prod) => {
      let result = true;
      if (
        !(
          prod.salePrice >= formData["PriceRange"][0] &&
          prod.salePrice <= formData["PriceRange"][1]
        )
      ) {
        result = false;
      }
      if (
        !(
          prod.discount >= formData["DiscountRange"][0] &&
          prod.discount <= formData["DiscountRange"][1]
        )
      ) {
        result = false;
      }
      if (
        !(
          prod.rating >= formData["RatingRange"][0] &&
          prod.rating <= formData["RatingRange"][1]
        )
      ) {
        result = false;
      }
      if (prod.brand && !formData["Brand"].includes(prod.brand)) {
        result = false;
      }
      if (!formData["Seller"].includes(prod.seller)) {
        result = false;
      }

      return result;
    });
    // console.log('filteddata',filterData)
    dispatch(filterProducts(filterData));
  };

  const { handleSubmit } = props;

  // console.log("attribute props", props);
  return (
    <div className={classes.root}>
      <form className={classes.List} onSubmit={handleSubmit(onSubmit)}>
        {/* {console.log(typeIndex, dataHeaderMenu, "dataheadermenu")} */}
        {attributeList.map((att, attIndex) => {
          // console.log("attributes", att);
          return (
            <FormControl className={classes.List} key={attIndex}>
              <Divider />
              <Typography className={classes.heading}>{att.name}</Typography>
              <div className={classes.item}>
                <Field
                  name={att.name}
                  component={inputs[att.dataType]}
                  {...att}
                />
              </div>
            </FormControl>
          );
        })}

        <Button type="submit" color="primary" variant="outlined">
          Apply Filters
        </Button>
      </form>
    </div>
  );
};
export default reduxForm({ form: "searchAttributes" })(AttributeFilter);
