import React from "react";
import axios from "axios";

import PT from "prop-types";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  Grid,
  Typography,
  Card,
  Paper,
  Button,
  CardHeader,
  Divider,
  CardContent,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { loginuser } from "../../redux/action/useractions";
import { toggleLoginModal } from "../../redux/action/utilActions";

const propTypes = {
  handleLogin: PT.func,
  isLogin: PT.bool,
};
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },

  container: {
    width: "100%",
  },
  Card: {
    width: "100%",

    borderRadius: 0,
  },
  errortext: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: "red",
    textAlign: "center",
  },
}));
const LoginForm = (props) => {
  const actionError = useSelector((state) => state.utilReducer.error);
  const errorMsg = useSelector((state) => state.utilReducer.message);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [active, setactive] = useState(false);
  //const [email, setemail] = useState("ECOMUSER");
  //const [password, setpassword] = useState("manjunath");
  const [email, setemail] = useState(undefined);
  const [password, setpassword] = useState(undefined);
  let loginerror = false;
  function handleOpen() {
    setactive(true);
  }
  function handleClose() {
    setactive(false);
  }
  function changeMail(e) {
    setemail(e.target.value);
  }
  function changePassword(e) {
    setpassword(e.target.value);
  }
  let { isLogin } = props;
  const gridWidth = props.width ? props.width : 9;
  const handleLogin = () => {
    dispatch(loginuser({ email, password }));

    console.log(
      JSON.stringify({
        expiryDate: "10-10-2020",
        manufacturingDate: "9-9-2020",
        batchNumber: "ab12345",
        serialNumber: "123456",
      })
    );
  };

  const handleForgot = () => {
    dispatch(toggleLoginModal("forgot"));
  };
  return (
    <div>
      <Card elevation={0} className={classes.Card}>
        <CardHeader
          title="Login"
          // subheader="Enter Following details here"
          style={{ textAlign: "center" }}
        />

        {!actionError ? (
          <Typography align="center">Enter Following details here</Typography>
        ) : (
          <CardContent>
            <Typography className={classes.errortext}>{errorMsg}</Typography>
          </CardContent>
        )}

        <CardContent>
          <form onSubmit={(e) => handleLogin(email, password)}>
            <Grid container spacing={2} justify="center">
              <Grid item lg={gridWidth} xs={11}>
                <TextField
                  fullWidth
                  value={email}
                  onChange={changeMail}
                  id="outlined-basic"
                  label="Email"
                  type="email"
                  variant="outlined"
                />
              </Grid>
              <Grid item lg={gridWidth} xs={11}>
                <TextField
                  fullWidth
                  value={password}
                  id="outlined-basic"
                  label="Password"
                  type="password"
                  variant="outlined"
                  onChange={changePassword}
                />
              </Grid>
              <Grid item lg={gridWidth} xs={11} container justify="flex-end">
                <Button
                  onClick={() => handleForgot()}
                  variant="text"
                  color="primary"
                  size="small"
                >
                  Forgotten password?
                </Button>
              </Grid>

              <Grid item lg={gridWidth} xs={11}>
                <Button
                  onClick={() => {
                    handleLogin(email, password);
                  }}
                  variant="contained"
                  fullWidth
                  color="primary"
                  size="large"
                >
                  Sign In
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};
export default LoginForm;
