import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const ProductCount = () => {
  const classes = useStyles();

  return <div className={classes.root}>product count</div>;
};
export default ProductCount;

// import React from 'react';
// import { Header } from 'semantic-ui-react';

// class ProductCount extends React.Component {
//   constructor(props) {
//     super(props);

//   }
//   render(){
//     return(
//       <div class="product-count">
//         <Header as='h4'>Count:{" "}<span>Select Count</span></Header>
//       </div>
//     )
//   }
// }

// export default ProductCount;
