import React, { useState } from "react";
import { Route, Link, useLocation, useParams } from "react-router-dom";

import StepProgress from "./StepProgress";
import SigninStep from "./SigninStep";
import ConfirmStep from "./ConfirmStep";
import AddressConfirm from "./AddressConfirm";
import ShippingConfirm from "./ShippingConfirm";
import CardPaymentConfirm from "./CardPaymentConfirm";
import ProductReview from "./ProductReview";
import {
  setShippingAddresss,
  setPaymentDetails,
  setShipmentMethod,
  submitConfirmOrder,
  getShippingMethods,
} from "../../redux/selector";
import { connect, useDispatch, useSelector } from "react-redux";
import orderData from "./orderData.json";

import BillDoneStep from "./BillDoneStep";
import Scroll from "../scroll/Scroll";
import CalculateTotal from "../../utils/calculateTotal";

import PT from "prop-types";
import {
  Grid,
  Container,
  Typography,
  Hidden,
  useTheme,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Stepe from "@material-ui/core/Step";

import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { pushtohistory } from "../../redux/action/historyActions";

const propTypes = {
  isLogin: PT.bool,
  handleLogin: PT.func,
};

function format(n) {
  if (n <= 9) {
    return "0" + n;
  }
  return n;
}
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    padding: "5vh 0vh",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      backgroundColor: theme.palette.background.main,
    },
  },
  container: {
    backgroundColor: theme.palette.background.paper,

    padding: "5vh 0vh",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      backgroundColor: theme.palette.background.main,
      // marginTop: theme.spacing(2),
      // marginBottom: theme.spacing(2),
    },
  },
  title: {
    fontSize: 30,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  btnContainer: {
    marginTop: theme.spacing(3),
  },
  MobileStepper: {
    color: "white",
    backgroundColor: theme.palette.secondary.main,
  },
  btn: {
    color: "white",
  },
  emptyContainer: {
    backgroundColor: theme.palette.background.main,
    height: "80vh",
    display: "flex",
    alignItems: "center",
  },
}));
const CartSite = (props) => {
  const [isReview, setisReview] = useState(false);
  const [addressConfirm, setaddressConfirm] = useState(false);
  const [shippingConfirm, setshippingConfirm] = useState(false);
  const [paymentConfirm, setpaymentConfirm] = useState(false);
  const [isConfirm, setisConfirm] = useState(false);
  const user = useSelector((state) => state.user);
  //stub

  const { stub } = useParams();
  const location = useLocation().pathname;
  const dispatch = useDispatch();

  React.useEffect(() => {
    let newlocation = location === "/" ? location : location.replace(stub, "");

    let historydata = {
      type: "page",
      name: newlocation,
      value: stub,
    };
    dispatch(pushtohistory(historydata));
    dispatch(getShippingMethods());
  }, []);

  function prepareOrderData() {
    let cartList = CalculateTotal(props.cart);

    let orderList = new Array();
    let currentDistributorId = -1;
    let newOrder = {
      detailList: [],
      itemsQuantity: 0,
      discountAmount: 0,
      amount: 0,
      tax: 0,
      charges: 0,
      totalAmount: 0,
      requestedQuantity: 0,
    };
    let newProd = {};
    let lineNumber = 1;

    cartList.forEach((element, index) => {
      newProd = {};

      if (currentDistributorId !== element.distributorId) {
        currentDistributorId = element.distributorId;
        newOrder = {
          detailList: [],
          itemsQuantity: 0,
          discountAmount: 0,
          amount: 0,
          tax: 0,
          charges: 0,
          totalAmount: 0,
          requestedQuantity: 0,
        };

        lineNumber = 1;
        newOrder.addressId = props.addressId;
        newOrder.sellerId = element.sellerId;
        newOrder.shipmentMethodId = props.shipmentMethodId;
        newOrder.distributorId = element.distributorId;
        newOrder.paymentMethodId = props.paymentMethodId;
        newOrder.userCardId = props.payment.paymentId;
        newOrder.securityCode = props.payment.verificationCode;

        var todayTime = new Date();
        var month = format(todayTime.getMonth() + 1);
        var day = format(todayTime.getDate());
        var year = format(todayTime.getFullYear());

        // const formatDate = year + "-" + month + "-" + day;
        const formatDate = day + "-" + month + "-" + year;
        newOrder.orderDate = formatDate;
        newOrder.status = "ACTIVE";
        newOrder.userId = user.userId;
      }
      // newOrder.grandTotal = newOrder.grandTotal + element.salePrice;
      newProd.lineNumber = lineNumber;
      newOrder.itemsQuantity += element.selectedOptions.QUANTITY;
      newOrder.discountAmount += element.discountAmount;
      newOrder.amount += element.netAmount;
      newOrder.tax += element.taxAmount;
      newOrder.charges += element.charges;
      newOrder.totalAmount += element.total;

      newOrder.lineNumber = lineNumber + 1;

      newProd.productId = element.productId;
      let objKeys = Object.keys(element.selectedOptions);
      newProd.orderInputs = objKeys.map((option) => ({
        [option]: element.selectedOptions[option],
      }));
      newProd.requestedQuantity = element.selectedOptions.QUANTITY;
      newProd.discountAmount = element.discountAmount;
      newProd.amount = element.netAmount;
      newProd.tax = element.taxAmount;
      newProd.charges = element.charges;
      newProd.totalAmount = element.total;
      newProd.unitPrice = element.salePrice;
      newProd.uom = "each";
      newProd.basePrice = element.marketPrice;
      newProd.specificationId = element.specificationId;
      newProd.configurationId = element.configurationId;

      if (element.preferences) {
        newProd.orderSpecificationList = element.preferences.specificationList.map(
          (specList, index) => ({
            listId: specList.id,
            listName: specList.name,
            listType: specList.selectionType,
            specificationListAttributes: specList.generalAttributeList.map(
              (ele, ind) => ({
                masterAttributeId: ele.masterPropertyId,
                value: ele.value,
              })
            ),
          })
        );
      }

      if (element.configuration) {
        newProd.partList = element.configuration.choices;
      }

      newOrder.detailList.push(newProd);
    });

    orderList.push(newOrder);

    return orderList;
  }
  function handleAddressConfirm(addressId) {
    setaddressConfirm(true);
    props.dispatch(setShippingAddresss(addressId));
    handleNext();
  }
  function handleShippingConfirm(shipmentMethodId) {
    setshippingConfirm(true);
    props.dispatch(setShipmentMethod(shipmentMethodId));
    handleNext();
  }
  function handlePaymentsConfirm(paymenMethodId, paymentId, verificationCode) {
    setpaymentConfirm(true);
    props.dispatch(
      setPaymentDetails(paymenMethodId, paymentId, verificationCode)
    );
    handleNext();
  }

  function handleReviewConfirm() {
    setisReview(true);
    handleNext();
  }
  function handleBillDone() {
    setisConfirm(true);
    props.dispatch(submitConfirmOrder(prepareOrderData()));
    handleNext();
  }

  const { isLogin, handleLogin } = props;
  let steps = [
    {
      completed: isReview,
      active: isLogin && !isReview,
      title: "REVIEW PRODUCTS",
      icon: "id card outline",
    },
    {
      completed: addressConfirm,
      active: isReview && !addressConfirm,
      title: "ADDRESS",
      icon: "id card outline",
    },
    {
      completed: shippingConfirm,
      active: addressConfirm && !shippingConfirm,
      title: "SHIPPING",
      icon: "id card outline",
    },
    {
      completed: paymentConfirm,
      active: shippingConfirm && !paymentConfirm,
      title: "PAYMENT",
      icon: "id card outline",
    },
    {
      completed: isConfirm,
      active: paymentConfirm && !isConfirm,
      title: "CONFIRM ORDER",
      icon: "idea",
    },
    { completed: isConfirm, active: false, title: "THANK YOU", icon: "truck" },
  ];
  let displayContainer = null;
  if (!isReview) {
    displayContainer = (
      <ProductReview handleReviewConfirm={handleReviewConfirm} />
    );
  } else if (!addressConfirm) {
    displayContainer = (
      <AddressConfirm handleAddressConfirm={handleAddressConfirm} />
    );
  } else if (!shippingConfirm) {
    displayContainer = (
      <ShippingConfirm handleShippingConfirm={handleShippingConfirm} />
    );
  } else if (!paymentConfirm) {
    displayContainer = (
      <CardPaymentConfirm handlePaymentsConfirm={handlePaymentsConfirm} />
    );
  } else if (!isConfirm) {
    displayContainer = <ConfirmStep handleBillDone={handleBillDone} />;
  } else {
    displayContainer = <Route component={BillDoneStep} />;
  }
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(1);

  const getStepslabel = [
    "Review Products",
    "Address",
    "Shipping",
    "Payment",
    "Confirm Order",
    "Thank You",
  ];

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 1:
        return (
          <ProductReview
            handleReviewConfirm={handleReviewConfirm}
            onConfirm={() => setActiveStep(activeStep + 1)}
          />
        );
      case 2:
        return (
          <AddressConfirm
            handleAddressConfirm={handleAddressConfirm}
            back={() => handleBack()}
          />
        );
      case 3:
        return (
          <ShippingConfirm
            handleShippingConfirm={handleShippingConfirm}
            back={() => handleBack()}
          />
        );
      case 4:
        return (
          <CardPaymentConfirm
            handlePaymentsConfirm={handlePaymentsConfirm}
            back={() => handleBack()}
          />
        );
      case 5:
        return (
          <ConfirmStep
            handleBillDone={handleBillDone}
            prepareOrderData={prepareOrderData}
            back={() => handleBack()}
          />
        );
      case 6:
        return <Route component={BillDoneStep} />;
    }
  }

  const maxSteps = steps.length;
  const theme = useTheme();
  return props.cart.length > 0 ? (
    <div className={classes.root}>
      <Container maxWidth="xl">
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.container}
        >
          <Hidden smDown>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <Typography
                as="h3"
                className={classes.title}
                color="textPrimary"
                align="center"
              >
                CHECKOUT
              </Typography>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid item lg={11}>
              <Stepper
                orientation="horizontal"
                activeStep={activeStep - 1}
                alternativeLabel
                className={classes.stepper}
              >
                {steps.map((label) => (
                  <Stepe key={label}>
                    <StepLabel>{label.title}</StepLabel>
                  </Stepe>
                ))}
              </Stepper>
            </Grid>
          </Hidden>

          {activeStep === steps.length ? (
            <Grid
              item
              lg={12}
              sm={12}
              xs={12}
              container
              justify="center"
              alignItems="center"
              direction="column"
            >
              <Grid item>
                <Typography className={classes.instructions}>
                  All steps completed
                </Typography>
              </Grid>
              <Grid item>
                <Button onClick={handleReset}>Reset</Button>
              </Grid>
            </Grid>
          ) : (
            <Grid item container lg={11}>
              {getStepContent(activeStep)}
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  ) : (
    <div className={classes.emptyContainer}>
      <Container maxwidth="xl" className={classes.emptyContainer}>
        <Grid container spacing={2} alignItems="center" justify="center">
          <Grid item container lg={12} md={12} sm={12} xs={12} justify="center">
            <Typography align="center" color="textPrimary">
              Your shopping cart is empty, naviagate to hompage for shopping
            </Typography>
          </Grid>
          <Grid item>
            <Link to="/">
              <Button variant="contained" color="primary">
                Home
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
CartSite.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    cart: state.shop.cart,
    addressId: state.shop.addressId,
    payment: state.shop.payment,
    shipmentMethodId: state.shop.shipmentMethodId,
    paymentMethodId: state.shop.paymentMethodId,

  };
};
 
export default connect(mapStateToProps, null)(CartSite);
